<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>REQUISITOS DE EXAMEN</h2>
          <CRUDIndex
            :permissionRoot="'Validación de examen'"
            :headers="headers"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  props: {
    doyangId: Number
  },
  components: {
    CRUDIndex
  },
  computed: {
    doyangIdPayload () {
      return this.$store.getters['Auth/selectedDoyang']
    }
  },
  methods : {
  },
  data () {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
      ],
      storeModel: 'Requirement',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
