<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <h2 v-if="!this.$route.params['id']">CREAR NUEVA VARIANTE DE PRODUCTO</h2>
        <h2 v-else>EDITAR VARIANTE DE PRODUCTO</h2>
        <h2>{{product.name}}</h2>
        <v-form
        ref="form"
        >
        <CapsVTextField v-model="productVariant.name" outlined label="Nombre*" :rules="nameRules"/>
        <CapsVTextField v-model="productVariant.price" outlined label="Precio"/>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-btn v-if="!this.$route.params['id']" primary @click="createProductVariant" class="blue ligthten-2" dark>Agregar</v-btn>
          <v-btn v-else primary @click="editProductVariant" class="yellow ligthten-2">Editar</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="this.$route.params['id']">
      <v-col>
        <h2>TALLAS</h2>
      </v-col>
    </v-row>
    <v-row v-if="this.$route.params['id']">
      <v-col cols=12>
        <router-link :to="{name:'ProductSizeCreate', params: {product_variant_create: productVariant}}">
          <v-btn class="green lighten-2" dark>Crear talla</v-btn>
        </router-link>
      </v-col>
      <v-col cols=12>
        <v-data-table
          :headers="productSizeHeaders"
          :items="productSizes"
          :sort-by="'order'"
        >
          <template v-slot:body="props">
            <draggable
              :list="props.items"
              tag="tbody"
              @end="endOrdering(props.items)"
            >
              <tr
              v-for="(item, index) in props.items"
              :key="index"
              >
                <td v-for="header in props.headers" :key="header.value">
                  <v-icon v-if="header.value == 'drag'"
                    small
                    class="page__grab-icon"
                  >
                    mdi-arrow-all
                  </v-icon>
                  <div v-else-if="header.value == 'actions'">
                    <v-icon @click="goToEditProductSize( item )">mdi-pencil</v-icon>
                    <v-icon @click="goToDeleteProductSize( item )">mdi-delete</v-icon>
                  </div>
                  <div v-else>
                    {{item[header.value]}}
                  </div>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    product_create: {
      type: Object,
      default : () => {
        return {}
      }
    }
  },
  mounted () {
    if(this.$route.params['id']) {
      this.$store.dispatch('ProductVariant/show', `${this.$route.params['id']}?with=product_sizes,product`).then((data) => {
        this.product = data.product
        this.productVariant = data
        this.$store.dispatch('ProductSize/index', `?product_variant_id=${data.id}`)
      })
    } else {
      this.productVariant.product_id = this.product_create.id
    }
  },
  methods: {
    endOrdering (items) {
      items.forEach( (size, index) => {
        if (size?.order != index){
          size.order = index
          this.$store.dispatch('ProductSize/update', size)
        }
      })
    },
    goToDeleteProductSize: function (item) {
      if (confirm('¿Estás seguro que deseas eliminar este registro?'))
        this.$store.dispatch('ProductSize/delete', item.id)
    },
    goToEditProductSize: function (item) {
      console.log('product_size_id', item.id)
      this.$router.push({name: 'ProductSizeEdit', params: {id: item.id}})
    },
    createProductVariant: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('ProductVariant/create', this.productVariant)
        this.$router.push({name: 'ProductEdit', params: {id: this.productVariant.product_id}})
      }
    },
    editProductVariant: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('ProductVariant/update', this.productVariant)
        this.$router.push({name: 'ProductEdit', params: {id: this.product.id}})
      }
    },
  },
  computed: {
    productSizes : function () {
      return this.$store.getters['ProductSize/productSizes']
    }
  },
  data () {
    return {
      productSizeHeaders: [
        {value: 'drag' , text: 'Ordenar'},
        {value: 'name' , text: 'Nombre'},
        {value: 'prize' , text: 'Precio'},
        {value: 'actions' , text: 'Acciones'},
      ],
      product: {},
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      productVariant: {
        name:'',
        prize: '',
      }
    }
  }
}
</script>

<style>

</style>