<template>
  <v-container>
    <h2>GASTOS</h2>
    <CRUDIndex :permissionRoot="'Gastos'" :items="models" :headers="headers" :storeModel="storeModel"
      :storeModelPlural="storeModelPlural" />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted() {
    this.$store.dispatch('Expense/index', `?doyang_id=${this.selectedDoyang.id}`)
  },
  computed: {
    models() {
      return this.$store.getters['Expense/expenses']
    },
    selectedDoyang() {
      if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && (this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
        return {
          name: 'SIN DOYANG SELECCIONADO'
        }
      } else if ((!this.$store.getters['Auth/selectedDoyang'] || this.$store.getters['Auth/selectedDoyang'].id == undefined) && !(this.authInfo && this.authInfo.user && this.ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
        return {
          name: 'SIN DOYANG SELECCIONADO'
        }
      }
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  data() {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Cantidad', value: 'amount' },
      ],
      storeModel: 'Expense',
      storeModelPlural: 'Expenses',
    }
  }
}
</script>

<style></style>e
