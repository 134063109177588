<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
          <h2 v-if="!$route.params.id">CREAR EVENTO PADRE</h2>
          <h2 v-else>EDITAR EVENTO PADRE</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <caps-v-text-field outlined v-model="parentEvent.name" label="Nombre del evento padre"></caps-v-text-field>
        </v-col>
        <v-col cols=12>
          <v-text-field type="text" outlined v-model="parentEvent.description" label="Descripción"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createParentEvent">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateParentEvent">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue';
export default {
  mounted() {
    // if params id retrieve from store
    if (this.$route.params.id) {
      this.$store.dispatch('ParentEvent/show', this.$route.params.id).then((res) => {
        console.log('res', res)
        this.parentEvent = res
      })
    }
  },
  components: {
    CapsVTextField
  },
  methods: {
    createParentEvent: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('ParentEvent/create', this.parentEvent).then(() => {
        this.$router.push({ name: 'ParentEventIndex' })
      }
      )
    },
    updateParentEvent: function () {
      this.$store.dispatch('ParentEvent/update', this.parentEvent).then(() => {
        this.$router.push({ name: 'ParentEventIndex' })
      }).catch(() => {
      }
      )
    },
  },
  watch: {
  },
  computed: {
  },
  data() {
    return {
      parentEvent: {
        name: '',
        description: '',
      },
    }
  }
}
</script>

<style></style>
