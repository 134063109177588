<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>RECIBOS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="searchAlumno" :label="'Alumno'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-data-table
            :loading="loading"
            id="print-table"
            :server-items-length="totalItems"
            :headers="headers"
            :items="items"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            :items-per-page="15"
            @update:page="(pageNum) => {this.pageNum = pageNum; sendPaginationOptions()}"
            @update:sort-by="(column) => {this.sortBy = column; sendPaginationOptions()}"
            @update:sort-desc="(boolean) => {this.sortDesc = boolean; sendPaginationOptions()}"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-left">
            <div v-if="isEditingId != item.id">
                {{ item.parsed_created_at }}
                <v-icon v-if="hasPermission('Recibos', 'update')" @click="editDate(item.id)">mdi-pencil</v-icon>
            </div>
            <div v-else>
                <DMYDateField v-model="newReceiptDate" :label="'Nueva fecha de recibo'"/>
                <v-btn class="blue lighten-2 xs" dark @click="changeReceiptDate(item.id)">Editar</v-btn>
            </div>
          </td>
          <td class="text-left">
            <ul>
              {{item.is_canceled == 1 ? 'CANCELADO' : ''}}
              <li v-for="payment in item.payments" :key="payment.id">
                <p v-if="payment.doyang_bundle_id">
                  {{payment.title}} ({{payment.client_name}})
                </p>
                <p v-else>
                  {{ payment.qty }} {{ payment.title }} ({{
                    payment.client_name
                  }})
                </p>
              </li>
            </ul>
          </td>
          <td>
              {{item.elaborated_name}}
          </td>
          <td class="text-left">
            <div v-if="item && item.payments && item.client_phone">
                Whatsapp por defecto <v-icon @click="sendWhatsappMessage(item.client_phone)">mdi-whatsapp</v-icon>
            </div>
            <div>
                Otro <v-icon @click="sendWhatsappMessage()">mdi-whatsapp</v-icon>
            </div>
            <div>
              Sin formato<v-icon @click="printReceipt(item.id, true)"
                >mdi-printer</v-icon
              >
            </div>
            <div>
              Con formato
              <v-icon @click="printReceipt(item.id)">mdi-printer</v-icon>
            </div>
            <div v-if="item.is_canceled != 1">
              Cancelar
              <v-icon @click="cancelReceipt(item.id)">mdi-cancel</v-icon>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue';
var debounce = require('debounce');
export default {
  mounted() {
    this.payload = `paginate=1&append=elaborated_name&orderBy[created_at]=desc&with=payments,payments.doyang_bundle&doyang_id=${this.selectedDoyang.id}`
    this.$store.dispatch(
      "Receipt/index",
      `?${this.payload}`
    ).then(() => {this.loading = false})
  },
  methods: {
    hasPermission (name, action = false) {
        const names = name.split(",")
        if (names.length > 1) {
            let hasPermission = false
            names.forEach(name => {
                if (hasPermission) return;
                hasPermission = this.hasPermission(name, action)
            })
            return hasPermission
        }
        const permissions = this.authInfo?.user?.permissions
        if (!permissions) return false
        const singlePermissionkey = Object.keys(permissions).filter( key => {
                return permissions[key].name === name
            })?.[0]
        const singlePermission = permissions?.[singlePermissionkey]
        if (!singlePermissionkey) return false
        if (!action && singlePermission) {
            return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
        }
        return singlePermission?.[action] == 1 || false
    },
    changeReceiptDate(receiptId) {
        this.$store.dispatch('Receipt/update', {
            id: receiptId,
            created_at: this.newReceiptDate
        }).then(() => {
            this.newReceiptDate = null
            this.isEditingId = null
        });
    },
    editDate(id) {
        this.isEditingId = id
    },
    sendPaginationOptions() {
      console.log(this.pageNum)
      const stringFilter = `?${this.payload}&paginate=1&page=${this.pageNum}`
      this.loading = true
      this.$store.dispatch('Receipt/index', stringFilter).then(() => {this.loading = false})
    },
    sendWhatsappMessage(phone) {
      phone = phone || prompt('Teléfono a 10 dígitos')
      window.open(`https://wa.me/+52${phone}`, '_blank')
    },
    printReceipt(id, noFormat = false) {
      this.$store.dispatch(
        `Receipt/print`,
        `${id}?print=1&no_format=${noFormat || 0}`
      );
    },
    cancelReceipt(id) {
      if (!confirm('¿Deseas cancelar este recibo?')) return
      this.$store.dispatch(
        `Receipt/update`,
        {
          id,
          is_canceled:true
        }
      );
    },
  },
  components: {CapsVTextField},
  computed: {
    authInfo () {
      return this.$store.getters['Auth/authInfo']
    },
    totalItems() {
      return this.$store.getters["Receipt/totalItems"]
    },
    items() {
      let getter = this.$store.getters["Receipt/receipts"]
      let array = Array.isArray(getter) ? getter : Object.values(getter)
      array = array.sort((item, item2) => {
          return item.id < item2.id ? 1 : -1
      })
      return array;
    },
    selectedDoyang() {
      return this.$store.getters["Auth/selectedDoyang"];
    },
  },
    watch: {
        searchAlumno: debounce(function () {
            this.loading = true
            const payload = `paginate=1&append=client_name,elaborated_name&orderBy[created_at]=desc&with=payments,payments.doyang_bundle&doyang_id=${this.selectedDoyang.id}&receipt.user.full_name=${this.searchAlumno}`
            this.$store.dispatch(
                "Receipt/index",
                `?${payload}`
            ).then(() => {this.loading = false})
        }, 500)
    },
  data() {
    return {
      newReceiptDate: null,
      isEditingId: 0,
      pageNum: 1,
      column: 1,
      boolean: 1,
      loading: true,
      searchAlumno: "",
      headers: [
        { text: "Fecha", value: "parsed_created_at" },
        { text: "Pagos", value: "" },
        { text: "Elaboró", value: "elaborated_name" },
        { text: "Acciones", value: "" },
      ],
      payload: "?with=payments",
      storeModel: "Receipt",
      itemsPerPage: 20,
    };
  },
};
</script>

<style>
</style>
