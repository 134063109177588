import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    tournamentWeightCategories: [],
    tournamentWeightCategory: {},
    doyangFilters: {}
  },
  mutations: {
    index(state, payload) {
      state.tournamentWeightCategories = payload
    },
    show(state, payload) {
      state.doyang = payload
    },
    delete(state, payload) {
      state.tournamentWeightCategories = state.tournamentWeightCategories.filter((doyang) => doyang.id != payload.id)
    },
    deletePersonal(state, payload) {
      state.doyang.personal = state.doyang.personal.filter( personal => personal.id != payload)
    },
    addFilter(state, payload) {
      state['doyangFilters'] = payload
    },
    addPersonal(state, payload) {
        state.doyang?.personal.push({
          ...payload.user,
          doyang_surrogation_id: payload.doyang_surrogation_id
      })
      state['doyangFilters'] = payload
    },
    none () {},
    create(state, payload) {
        state.tournamentWeightCategories.push(payload)
    },
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/tournament_weight_categories?${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/tournament_weight_categories/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/tournament_weight_categories`, payload).then((res) => {
        commit('create', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/tournament_weight_categories/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/tournament_weight_categories/${payload}`).then((res) => {
        commit('delete', res.data)
      })
    },
    deletePersonal({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/tournament_weight_categories/${payload.parentId}?personal=${payload.childId}`).then((res) => {
        commit('deletePersonal', payload.childId)
        return res.data
      })
    },
    addPersonal({ commit }, payload) {
        return axios.put(`${ENV.API_URL}/api/tournament_weight_categories/${payload.id}`, payload).then((res) => {
            commit('none')
            return res.data
        })
    },
    stablish_goal ({commit}, payload) {
        return axios.put(`${ENV.API_URL}/api/tournament_weight_categories/${payload.doyang_id}`, payload).then((res) => {
            console.log(commit)
            return res
        })
    },
    downloadFile (_, payload) {
        return axios({
            url: `${ENV.API_URL}/api/tournament_weight_categories${payload ? payload : ''}`,
            method: 'GET',
            responseType: 'blob',
        }).then((res) => {
            return res.data
        })
    },
  },
  getters: {
    tournamentWeightCategories: state => {
      return state.tournamentWeightCategories
    },
    tournamentWeightCategory: state => {
      return state.tournamentWeightCategory
    },
    doyangFilters: state => {
      return state.doyangFilters
    }
  },
  modules: {
  }
}
