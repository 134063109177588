<template>
  <v-menu
    ref="openPicker"
    outlined
    v-model="openPicker"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="parsedTime"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      ampm-in-title
      format="ampm"
      v-if="openPicker"
      v-model="time"
      full-width
      @click:minute="updateTime(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value', 'label'],
  methods: {
    updateTime() {
      this.$emit('input', this.time)
      this.openPicker = false
    }
  },
  computed: {
    parsedTime () {
      if(this.value) {
        const hours = this.value.split(':')[0]
        const military = ((parseInt(hours) + 11) % 12 + 1);
        return `${military}:${this.value.split(':')[1]} ${(hours >= 12 ? 'PM' : 'AM')}`
      }
      return ''
    }
  },
  data () {
    return {
      openPicker: null,
      time: null,
    }
  }
}
</script>

<style>

</style>