<template>
    <div>
        <div v-if="!tree">Loading...</div>
        <div v-else>
            <ul>
                <GenealogyNode :node="tree"></GenealogyNode>
            </ul>
        </div>
    </div>
</template>

<script>
import GenealogyNode from './GenealogyNode.vue';

export default {
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        this.$store.dispatch('Student/show', `1?append=genealogy_tree`);
    },
    computed: {
        tree() {
            console.log('sssss', this.$store.getters['Student/student'])
            return this.$store.getters['Student/student'];
        },
    },
    components: {
        GenealogyNode,
    },
};
</script>

<style scoped>
ul {
    list-style-type: none;
}

li {
    margin: 10px 0;
}
</style>
