<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="doyangGroup">ALUMNOS DEL GRUPO {{ doyangGroup.name }}</h2>
        <h3>{{ doyangGroup.short_json_week }}{{ noClassDay ? ' (DÍA SIN CLASE)' : '' }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="bimesterStart && bimesterEnd">
        <h3>BIMESTRE {{ ENV.__parseDateToText(bimesterStart) }}-{{ ENV.__parseDateToText(bimesterEnd) }}</h3>
      </v-col>
      <v-col>
        <v-btn class="green lighten-2" dark @click="() => {
          this.$router.push({ name: 'KyosaList' })
        }">Kyosas</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete chips outlined label="Alumno a agregar" v-model="studentToAdd" :items="activeStudents"
          item-value="id" item-text="full_name" return-object></v-autocomplete>
        <v-btn class="blue lighten-2" @click="addStudent" dark :disabled="!studentToAdd">Añadir Alumno</v-btn>
      </v-col>
      <v-col>
        <DMYDateField :min="hasPermission('Asistencias Extemporáneas (72hrs)') ? null : minAllowedDate"
          :label="'Fecha de la lista'" v-model="assistanceDate">
        </DMYDateField>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn v-if="!loadingGroupList" class="blue lighten-2" dark @click="printAssitance"><v-icon>mdi-printer</v-icon>
          Imprimir lista completa</v-btn>
        <v-progress-circular v-else :size="25" :width="5" color="green lighten-2" indeterminate />
      </v-col>
      <v-col class="text-left">
        <span class="red lighten-3"><strong>Fondo rojo:</strong></span> En riesgo de baja ({{ this.maxAbsencesAllowed -
          5 }} - {{ this.maxAbsencesAllowed }} faltas consecutivas)
        <br>
        <span class="yellow lighten-2"><strong>Fondo amarillo:</strong></span> En riesgo de no hacer examen
        <br>
        <span class="orange lighten-2"><strong>Fondo naranja:</strong></span> 2 faltas consecutivas hasta hoy
      </v-col>
      <v-col class="text-left">
        <v-row><v-col><v-btn small class="green lighten-2" dark><v-icon>mdi-pencil</v-icon></v-btn> Revisión completa
            (listo para examen)</v-col></v-row>
        <v-row><v-col><v-btn small class="orange lighten-2" dark><v-icon>mdi-pencil</v-icon></v-btn> Revisión incompleta
            (Falta algún requisito)</v-col></v-row>
        <v-row><v-col><v-btn small class="grey lighten-2" dark><v-icon>mdi-pencil</v-icon></v-btn> No se ha
            revisado</v-col></v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn :disabled="noClassDay" class="green lighten-2" style="color:white;"
          @click="setNotClassDay"><v-icon>mdi-pencil</v-icon> Día sin clase</v-btn>
        <v-btn class="green lighten-2" style="color:white;" @click="activateRevision"
          :disabled="isRevisionActivated || !isRevisionSeason"><v-icon>mdi-pencil</v-icon> Activar revisión</v-btn>
      </v-col>
      <v-col>
        <v-btn class="blue lighten-2" style="color:white;" @click="downloadRevision"
          :disabled="!(isRevisionActivated || !isRevisionSeason)"><v-icon>mdi-pencil</v-icon> Descargar CSV de
          Revisión</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="loadingList">
        <v-progress-circular :size="25" :width="5" color="green lighten-2" indeterminate />
      </v-col>
      <v-col v-else>
        <v-data-table :search="search" :headers="assistanceHeaders" :items="doyangGroup ? doyangGroup.students : []"
          :items-per-page="50" class="elevation-1" :item-class="(item) => {
          if ((getStudentById(item.id) ? getStudentById(item.id).consecutive_absences : null) >= maxAbsencesAllowed - 5) return 'red lighten-2'
          if ((getStudentById(item.id) ? getStudentById(item.id).bimester_abscenses : null) >= this.permissibleAbscences - 1) return 'yellow lighten-2'
          if ((getStudentById(item.id) ? getStudentById(item.id).consecutive_absences : null) >= 2) return 'orange lighten-2'
        }">
          <template v-slot:item.full_name="{ item }">
            <v-btn small class="mr-2" @click="goToStudentIndex(item.id)">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            {{ item.full_name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="showWhatsApp && item.cellphone" small class="mr-2"
              @click="sendWhatsappMessage(item.cellphone)">
              mdi-whatsapp
            </v-icon>
            <v-icon small @click="deleteStudent(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.assistance="{ item }">
            <v-container>
              <v-row>
                <v-radio-group v-if="assistanceRadioGroup && assistanceRadioGroup[item.id]"
                  v-model="assistanceRadioGroup[item.id]['type']">
                  <v-radio v-for="n in ['F', 'R', 'A']" :key="n" :label="n" :value="n"
                    :class="{ 'green lighten-2': assistanceRadioGroup[item.id].isClassTaken }"
                    @change="(setAssistance(item, n))"></v-radio>
                </v-radio-group>
                <v-icon small style="margin-left:15px" @click="deleteAssitance(assistanceRadioGroup[item.id], item.id)">
                  mdi-cancel
                </v-icon>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.revision="{ item }" v-if="isRevisionActivated && !hideRevisionColumn">
            <v-btn :class="getItemRevisionColor(item)" @click="openRevision(item)"
              dark><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <template v-slot:item.meeting="{ item }">
            <v-container
              :class="assistanceRadioGroup[item.id] && assistanceRadioGroup[item.id].isMeetingTaken ? 'green lighten-2' : ''">
              <v-row v-if="!meeting">
                <v-col v-if="assistanceRadioGroup[item.id] && assistanceRadioGroup[item.id].who">
                  Asistió a la junta: <strong>{{ assistanceRadioGroup[item.id].who }}</strong>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_student" solo :label="'Alumno'" :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_representative
        )" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_mother" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_representative ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Madre'" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_father" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_representative ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Padre'" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_representative" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_father
        ) : (
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Repre.'" @change="(setAssistance(item, 'A'))"></v-checkbox>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.af="{ item }">
            <v-container>
              <v-row v-if="students && students.length > 0">
                <v-col>
                  {{ getStudentById(item.id) ? getStudentById(item.id).bimester_assistances :
          0 }}/{{ getStudentById(item.id) ? getStudentById(item.id).bimester_abscenses : 0 }}
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.status="{ item }">
            <v-container>
              <v-row v-if="students && students.length > 0">
                <v-col>
                  <strong
                    v-if="(getStudentById(item.id) ? getStudentById(item.id).consecutive_absences : 0) > maxAbsencesAllowed">
                    <p>Se dará de baja</p>
                  </strong>
                  <strong
                    v-else-if="(getStudentById(item.id) ? getStudentById(item.id).consecutive_absences : 0) >= maxAbsencesAllowed - 5">
                    <p>En riesgo de baja</p>
                  </strong>
                  <strong
                    v-else-if="(getStudentById(item.id) ? getStudentById(item.id).bimester_abscenses : 0) > permissibleAbscences">
                    <p>Sin derecho a examen</p>
                  </strong>
                  <strong
                    v-else-if="(getStudentById(item.id) ? getStudentById(item.id).bimester_abscenses : 0) >= permissibleAbscences - 1">
                    <p>En riesgo de no hacer examen</p>
                  </strong>
                  <strong v-else-if="(getStudentById(item.id) ? getStudentById(item.id).consecutive_absences : 0) >= 2">
                    <p>Contactar</p>
                  </strong>
                </v-col>
              </v-row>
              <v-row v-if="getStudentById(item.id) && getStudentById(item.id).is_scholarshiped">
                <v-col>Becado</v-col>
              </v-row>
              <v-row v-if="getStudentById(item.id) && getStudentById(item.id).bimester_scholarship_risk">
                <v-col>
                  <strong>
                    <p>En riesgo de perder beca</p>
                  </strong>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row ref="row-print" id="row-print">
      <v-col v-if="isPrintingAssitance">
        <table class="table table-responsive" id="complete-assistance">
          <tr>
            <th v-for="date in bimesterDates" :key="date.text">
              {{ date.text.split("/")[0] }} <br>
              <span style="font-size:10pt">{{ date.text.split("/")[1] }}</span>
            </th>
          </tr>
          <tr v-for="(assistance) in bimesterAssistances" :key="assistance.name">
            <td v-for="(date, index) in bimesterDates" :key="index">
              <div v-if="date.value == 'full_name'">
                <span style="font-size:10pt">{{ assistance[date.value] }}</span>
              </div>
              <div v-else>
                {{ assistance.assistances[date.value] || "o" }}
              </div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="addStudentDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Agregar alumno al grupo</v-toolbar>
          <v-card-text>
            <div class="text-h5">Selecciona los grupos en los que quieres que continúe el alumno:</div>
            <div>
              <div v-for="group in currentGroups" :key="group.id">
                <v-checkbox v-model="stayingInGroups[group.id]" solo :label="group.name" :value="true"></v-checkbox>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="addStudentDialog = false">Cerrar</v-btn>
            <v-btn text class="btn green lighten-2" dark @click="addStudent(false)">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="revisionDialog">
      <v-card>
        <v-toolbar>
          REVISIÓN DE EQUIPO
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols=12>
                <table class="table">
                  <tr>
                    <th>Requisito</th>
                    <th>Cumplió</th>
                    <th>Fecha en la que cumplió</th>
                    <th>Comentario</th>
                  </tr>
                  <tr v-for="requirement in requirementProducts" :key="requirement.name">
                    <td>{{ requirement.name }}</td>
                    <td><v-checkbox v-model="requirement.checked"></v-checkbox></td>
                    <td>
                      <DMYDateField :disabled="true" v-model="requirement.date"></DMYDateField>
                    </td>
                    <td><caps-v-text-field v-model="requirement.comment"></caps-v-text-field></td>
                  </tr>
                </table>
              </v-col>
              <v-col cols=12 v-if="requirementOther && requirementOther.length > 0">
                <table class="table">
                  <tr>
                    <th>Requisito</th>
                    <th>Cumplió</th>
                    <th>Fecha en la que cumplió</th>
                    <th>Comentario</th>
                  </tr>
                  <tr v-for="requirement in requirementOther" :key="requirement.name">
                    <td>{{ requirement.name }}</td>
                    <td><v-checkbox v-model="requirement.checked"></v-checkbox></td>
                    <td>
                      <DMYDateField :disabled="true" v-model="requirement.date"></DMYDateField>
                    </td>
                    <td><caps-v-text-field v-model="requirement.comment"></caps-v-text-field></td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="revisionDialog = false">Cerrar</v-btn>
          <v-btn text class="green lighten-2" dark @click="assignRevision">Asignar</v-btn>
          <v-btn text v-if="isGeneralDirection" class="green lighten-2" dark @click="approveRevision">(DG) Todo cubierto
            y a
            tiempo</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CapsVTextField from '../globals/CapsVTextField.vue'
import { getPermissions } from '../globals/permissions.js'

export default {
  components: { CapsVTextField },

  data() {
    return {
      noClassDay: true,
      bimesterMeeting: null,
      revisionSeasonStart: null,
      revisionSeasonEnd: null,
      isRevisionActivated: false,
      hideRevisionColumn: false,
      studentInRevision: null,
      activeRevision: null,
      assistanceHeaders: [
        { text: 'Nombre', value: 'full_name' },
        { text: 'Pago', value: 'last_covered_month_parsed', other: 1 },
        { text: 'Asistencias', value: 'assistance', other: 1 },
        { text: 'Junta', value: 'meeting', other: 1 },
        { text: 'Acciones', value: 'actions' },
        { text: 'Asistencias/Faltas', value: 'af' },
        { text: 'Status', value: 'status' },
      ],
      requirementProducts: [],
      requirementOther: [],
      revisionDialog: false,
      bimesterEnd: null,
      bimesterStart: null,
      addingKiosa: false,
      maxAbsencesAllowed: null,
      permissibleAbscences: null,
      loadingList: true,
      loadingGroupList: false,
      meeting: null,
      bimesterDates: [],
      isPrintingAssitance: false,
      bimesterAssistances: [],
      today: null,
      assistanceDate: null,
      ENV,
      currentGroups: [],
      stayingInGroups: {},
      addStudentDialog: null,
      addStudentId: null,
      search: null,
      studentToAdd: null,
      daysInt: [],
      nextFourDates: [],
      assistanceRadioGroup: {},
      dayEquivalences: {
        'monday': 0,
        'tuesday': 1,
        'wednesday': 2,
        'thursday': 3,
        'friday': 4,
        'saturday': 5,
        'sunday': 6
      }
    }
  },
  props: [
    'showWhatsApp',
    'itemsPerPage',
  ],
  watch: {
    assistanceDate() {
      this.$store.dispatch('Holiday/index', `?start_date=${this.assistanceDate}&doyang_group_id=${this.doyangGroup.id}`).then(res => {
        this.noClassDay = res.length > 0
      })
      this.assistanceRadioGroupForDate()
      this.assistanceHeaders = this.assistanceHeaders.filter(header => header.value !== 'revision')
      if (!this.isRevisionSeason) {
        this.hideRevisionColumn = true
      } else {
        this.hideRevisionColumn = false
        this.assistanceHeaders.push({ text: 'Revisión', value: 'revision' })
      }
    }
  },
  methods: {
    setNotClassDay() {
      this.noClassDay = true
      this.$store.dispatch('Holiday/create', {
        start_date: this.assistanceDate,
        end_date: this.assistanceDate,
        doyang_group_id: this.doyangGroup.id,
        name: `Día sin clase ${this.assistanceDate}`,
      }).then(() => {
        alert('Se marcó el día como día sin clase')
        Object.keys(this.assistanceRadioGroup).forEach(key => {
          this.assistanceRadioGroup[key].type = null
        })
        this.noClassDay = true
      }).catch(() => {
        alert('No se pudo marcar el día como día sin clase')
        this.noClassDay = false
      })
    },
    goToStudentIndex(id) {
      this.$router.push({ name: 'StudentEdit', params: { id } })
    },
    approveRevision() {
      this.$store.dispatch('ProgrammerResources/approve_student_revision',
        { user_id: this.studentInRevision.id, revision_id: this.activeRevision.id }
      ).then(() => {
        alert('Se aprobó la revisión')
        this.revisionDialog = false
        this.$store.commit('Student/assignResult', {
          user_id: this.studentInRevision.id,
          result: 1
        })
      })
    },
    getStudentById(userId) {
      return this.students.filter((s) => s.id == userId)?.[0]
    },
    downloadRevision() {
      this.$store.dispatch('DoyangGroup/show', `${this.doyangGroup.id}?revision_id=${this.activeRevision.id}&append=revision_csv`).then(res => {
        const revisionCSV = res.revision_csv
        const blob = new Blob([revisionCSV], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `revision_${this.doyangGroup.name}.csv`
        link.click()
      });
    },
    getItemRevisionColor(item) {
      // 0 no result, 1 complete result, 2 incomplete result
      const studentId = item.id;
      const revisionResultInt = this.students.filter((s) => s.id == studentId)?.[0]?.revision_results?.result || 0
      if (revisionResultInt == 0) return 'grey lighten-2';
      if (revisionResultInt == 1) return 'green lighten-2';
      if (revisionResultInt == 2) return 'orange lighten-2';
    },
    activateRevision() {
      if (!confirm('¿Estás seguro de activar la revisión?')) return;
      if (this.activeRevision) return;
      this.$store.dispatch('Revision/create', {
        doyang_group_id: this.doyangGroup.id,
        name: 'Revisión ' + this.doyangGroup.name + ' (' + this.doyangGroup.doyang.name + ') - ' + this.getTodayDateYYYMMDD(),
      }).then((res) => {
        this.activeRevision = res.data;
        alert('Tu revisión se activó correctamente')
      });
    },
    assignRevision() {
      this.$store.dispatch('Revision/assign', {
        id: this.activeRevision.id,
        user_id: this.studentInRevision.id,
        requirementOther: this.requirementOther,
        requirementProducts: this.requirementProducts,
      }).then((res) => {
        alert('Tu revisión se asignó exitosamente')
        res.user_id = this.studentInRevision.id
        this.$store.commit('Student/assignResult', res)
      });
    },
    openRevision(item) {
      this.studentInRevision = item;
      this.revisionDialog = true;
      this.requirementProducts = [];
      this.requirementOther = [];
      this.$store.dispatch('Student/show', `${item.id}?revision_id=${this.activeRevision?.id || ''}&append=revision_results`).then((res) => {
        const requirementsForDegreeDialog = this.getOtherRequirementsForDegree(item.degree_id)
        const productResults = res?.revision_results?.products || []
        const otherResults = res?.revision_results?.others || []
        this.requirementOther = requirementsForDegreeDialog?.other_exam_requisites.map(requirement => {
          const result = otherResults.filter(result => result.id == requirement.id)?.[0] || {}
          requirement.checked = Boolean(result?.checked)
          requirement.date = result?.date
          requirement.comment = result?.comment
          return requirement
        })
        this.requirementProducts = requirementsForDegreeDialog?.products.map(requirement => {
          const result = productResults.filter(result => result.id == requirement.id)?.[0] || {}
          requirement.checked = Boolean(result?.checked)
          requirement.date = result?.date
          requirement.comment = result?.comment
          return requirement
        })
        const requisites_order = [5, 42, 41, 45, 36, 47, 44, 32, 34, 33, 48, 37, 35, 51, 49]
        this.requirementProducts = this.requirementProducts.sort((a, b) => {
          return requisites_order.indexOf(a.id) - requisites_order.indexOf(b.id)
        })
      });
    },
    getTodayDateYYYMMDD() {
      return new Date().toISOString().slice(0, 10);
    },
    getProductsRequirementsForDegree(degreeId) {
      return this.degreesRequirements.filter(requirement => requirement.min_grade_id == degreeId)?.[0] || []
    },
    getOtherRequirementsForDegree(degreeId) {
      return this.degreesRequirements.filter(requirement => requirement.min_grade_id == degreeId)?.[0] || []
    },
    hasPermission(name, action = false) {
      const permissions = this.authInfo?.user?.permissions
      return getPermissions(permissions, name, action)
    },
    _printTable() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('row-print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      this.isPrintingAssitance = false
      this.groupedItems = []
    },
    printAssitance() {
      this.loadingGroupList = true
      this.$store.dispatch('DoyangGroup/index', `/${this.doyangGroup.id}?append=assistances_csv&from_date=${this.bimesterStart}&to_date=${this.bimesterEnd}`).then((res) => {
        this.downloadStringInBlankPage(res.assistances_csv)
        this.loadingGroupList = false
      })
    },
    downloadStringInBlankPage(string, filename = 'assistances.csv') {
      const bom = '\uFEFF';  // UTF-8 BOM
      const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    initializeBimester() {
      // Initalize bimester dates
      this.$store.dispatch('Configuration/index', `?append=bimester_start,bimester_end`).then(res => {
        let localBimesterDates = []
        this.daysInt.forEach(day => {
          const parsedDay = day + 1
          const bimester_start = new Date(res[0]['bimester_start'])
          const bimester_end = new Date(res[0]['bimester_end'])
          localBimesterDates = this.daysBetweenDates(bimester_start, bimester_end, parsedDay)
          localBimesterDates.forEach(date => {
            const dateObject = new Date(date)
            const text = `${dateObject.getDate()}/${ENV.months[dateObject.getMonth() + 1].abv.toUpperCase()}`
            var tzoffset = (new Date()).getTimezoneOffset() * 60000;
            this.bimesterDates.push({ text: text, value: `${(new Date(date.getTime() - tzoffset)).toISOString().split('T')[0]}` })
          })
        })

        this.bimesterDates.unshift({ text: 'Nombre', value: "full_name" })
        this.getBimesterAssistance()
      })

    },
    getBimesterAssistance() {
      if (!this.doyangGroup?.students) return []
      this.bimesterAssistances = []
      this.doyangGroup.students.forEach(student => {
        const assistanceObject = {
          full_name: student.full_name,
          assistances: {}
        }
        student.assistances.forEach(assistance => {
          assistanceObject.assistances[assistance.pivot.date] = assistance.pivot.type
        })
        this.bimesterAssistances.push(assistanceObject)
      })
    },
    daysBetweenDates(date_1, date_2, day) {
      const milis = date_2.getTime() - date_1.getTime();
      let days = Math.abs(Math.ceil(milis / (1000 * 3600 * 24)));
      const nextDay = this._nextWeekDay(day, date_1)
      if (nextDay.getTime() > date_2.getTime()) return []
      let daysToReturn = [new Date(nextDay)]
      let currentDate = nextDay
      for (let i = 1; i < days; i++) {
        currentDate.setDate(currentDate.getDate() + 7)
        const evalDate = new Date(currentDate)
        if (evalDate.getTime() > (date_2.getTime() + (24 * 60 * 60 * 1000))) break;
        daysToReturn.push(evalDate)
      }
      return daysToReturn
    },
    assistanceRadioGroupForDate() {
      const parsedDate = this.assistanceDate.split("T")[0]
      this.$store.dispatch('Assistance/index', `?date=${parsedDate}&doyang_group_id=${this.doyangGroup.id}`).then((assistances) => {
        let studentKeyAssistances = {}
        let newArray = {}
        Object.keys(assistances).forEach((assistanceKey) => {
          const assistance = assistances[assistanceKey]
          studentKeyAssistances[assistance['user_id']] = assistance
        })
        this.doyangGroup.students.forEach((student) => {
          const assistance = studentKeyAssistances[student.id]
          newArray[student.id] = {
            id: assistance?.id,
            type: assistance?.type,
            is_student: assistance?.is_student == "1",
            is_mother: assistance?.is_mother == "1",
            is_father: assistance?.is_father == "1",
            is_representative: assistance?.is_representative == "1",
            who: this.getStudentById(student.id)?.meeting_assistance?.who || null,
          }
        });
        this.$set(this, 'assistanceRadioGroup', newArray)
        if (parsedDate == this.bimesterMeeting?.date.split("T")[0]) {
          this.$store.dispatch('Event/index', `?date=${parsedDate}&is_meeting=1`).then(events => {
            this.meeting = events?.[0] || null
          })
        } else {
          this.meeting = null
        }
      })
    },
    deleteAssitance(assistance, studentId) {
      this.$store.dispatch('Assistance/delete', assistance).then(() => {
        this.assistanceRadioGroup[studentId] = {
          id: null,
          type: null,
          is_student: null,
          is_mother: null,
          is_father: null,
          is_representative: null,
        }
      })
    },
    setAssistance(student, type) {
      let data = {
        id: this.doyangGroup.id,
        student,
        type,
        date: this.assistanceDate
      }
      let isMeeting = false;
      let isClass = true;
      this.$set(this.assistanceRadioGroup[student.id], "isMeetingTaken", false)
      this.$set(this.assistanceRadioGroup[student.id], "isClassTaken", false)
      if (this.assistanceRadioGroup[student.id].is_student) {
        data['is_student'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_mother) {
        data['is_mother'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_father) {
        data['is_father'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_representative) {
        data['is_representative'] = true;
        isMeeting = true;
      }
      this.$store.dispatch('DoyangGroup/update', data).then(() => {
        if (isMeeting) this.$set(this.assistanceRadioGroup[student.id], "isMeetingTaken", true)
        if (isClass) this.$set(this.assistanceRadioGroup[student.id], "isClassTaken", true)
      })
    },
    nextFourDays() {
      this.nextFourDates = this.daysInt.map((day) => {
        const nextDate = this._nextWeekDay(day).toISOString()
        const date = nextDate.substring(0, 10)
        const [year, month, dateDay] = date.split('-')
        return {
          string: `${dateDay.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`,
          date: nextDate
        }
      })
      //Add previous days
      this.daysInt.forEach((day) => {
        const nextDate = this._prevtWeekDay(day + 1).toISOString()
        const date = nextDate.substring(0, 10)
        const [year, month, dateDay] = date.split('-')
        this.nextFourDates.push({
          string: `${dateDay.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`,
          date: nextDate
        })
      })
      this.nextFourDates = this.nextFourDates.sort((firstDate, secondDate) => {
        return firstDate.date < secondDate.date ? -1 : 0
      })
    },
    _nextWeekDay(x, startDate = null) {
      var now = startDate || new Date();
      now.setDate(now.getDate() + (x + (7 - now.getDay())) % 7);
      return now;
    },
    _prevtWeekDay(x) {
      var now = new Date();
      now.setDate(now.getDate() - (x + (7 - now.getDay())) % 7);
      return now;
    },
    deleteStudent: function (item) {
      let payload = {}
      payload.id = this.$route.params.id
      payload.students = item.id
      this.$store.dispatch('DoyangGroup/deleteStudent', payload)
    },
    addStudent: function () {
      // check if student is already in group
      const studentExist = Boolean(this.doyangGroup.students.filter((student) => {
        return student.id == this.studentToAdd.id
      }).length)
      if (studentExist) {
        alert("El estudiante ya se encuentra en el grupo")
        this.studentToAdd = null
        return
      }
      let payload = {}
      this.$store.commit('DoyangGroup/addStudent', this.studentToAdd);
      payload.groupsAsStudent = this.stayingInGroups
      payload.groupsAsStudent[this.$route.params.id] = true
      payload.id = this.studentToAdd.id
      this.$store.dispatch('Student/update', payload)
      this.addStudentDialog = false
    }
  },
  async mounted() {
    this.$store.dispatch('Configuration/show', 1).then((res) => {
      this.bimesterStart = res.value
    })
    this.$store.dispatch('Configuration/show', 2).then((res) => {
      this.bimesterEnd = res.value
    })
    this.$store.dispatch('Configuration/show', 24).then((res) => {
      this.revisionSeasonStart = res.value
    });
    this.$store.dispatch('Configuration/show', 25).then((res) => {
      this.revisionSeasonEnd = res.value
    });
    this.$store.dispatch('Revision/index', `?doyang_group_id=${this.$route.params.id}`).then(res => {
      // res = Object.values(res)
      if (!res.length > 0) return
      this.activeRevision = res.filter(revsion => revsion.active != 0)?.[0]
      this.isRevisionActivated = true;
      this.assistanceHeaders.push({ text: 'Revisión', value: 'revision' },)
    })
    this.$store.dispatch('Configuration/show', 12).then((res) => {
      this.permissibleAbscences = res.value
    })
    this.$store.dispatch('Configuration/show', 4).then((res) => {
      this.maxAbsencesAllowed = res.value
    })
    this.loadingList = true
    this.$store.dispatch('Requirement/index', '?with=products,other_exam_requisites');
    let today = new Date()
    let prevMonth = new Date()
    prevMonth.setDate(today.getDate() - 60)

    this.$store.dispatch('DoyangGroup/show', `${this.$route.params.id}?with=doyang&append=bimester_meeting`).then((doyangGroup) => {
      this.bimesterMeeting = doyangGroup?.bimester_meeting;
      // Get asssitance Headers where value = 'meeting' and add an 's' to the value
      this.assistanceHeaders = this.assistanceHeaders.map(header => {
        if (header.value == 'meeting') {
          header.text = `Junta ${this.bimesterMeeting?.parsed_date}`
        }
        return header
      })
      this.$store.dispatch('Student/index', `?doyang_id=${doyangGroup.doyang_id}&doyang_group_id=${doyangGroup.id}&revision_id=${this.activeRevision?.id || ''}&assistance_group_id=${this.doyangGroup.id}&append=bimester_scholarship_risk,meeting_assistance,revision_results,next_degree,last_covered_month_parsed,consecutive_absences,bimester_abscenses,bimester_assistances`).then(() => {
        this.$store.dispatch('Time/time').then((res) => {
          this.assistanceDate = res.substring(0, 10);
          this.loadingList = false
        });
      })
      const json_week = doyangGroup?.json_week
      if (json_week) {
        Object.keys(json_week).forEach((weekDay) => {
          if (json_week[weekDay]) {
            this.daysInt.push(this.dayEquivalences[weekDay])
          }
        })
      }
      this.nextFourDays()
    })
  },
  computed: {
    isGeneralDirection() {
      return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID) || ENV.permissions.can_see_general_direction.includes(parseInt(this.authInfo?.user?.role_id))
    },
    isRevisionSeason() {
      return this.assistanceDate >= this.revisionSeasonStart && this.assistanceDate <= this.revisionSeasonEnd
    },
    degreesRequirements() {
      return this.$store.getters['Requirement/requirements']
    },
    isUnique() {
      return this.meeting ? this.meeting.unique_assistance : false;
    },
    minAllowedDate() {
      // date from 3 days before
      let date = new Date()
      date.setDate(date.getDate() - 5)
      return date.toISOString().substring(0, 10)
    },
    students() {
      return this.$store.getters['Student/students']
    },
    activeStudents() {
      return this.students.filter((student) => {
        return student.active
      })
    },
    event() {
      return this.$store.getters['Event/event']
    },
    configurations() {
      return this.$store.getters['Configuration/configurations']
    },
    doyangGroup() {
      return this.$store.getters['DoyangGroup/doyangGroup']
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
  }
}
</script>

<style></style>
