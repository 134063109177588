<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col>
          <CapsVTextField :rules="nonEmpty" v-if="model" v-model="model.name" outlined label="Nombre del evento">
          </CapsVTextField>
        </v-col>
        <v-col v-if="eventType == 'exam'">
          <CapsVTextField type="number" v-model="model.number" label="Número de examen"></CapsVTextField>
        </v-col>
        <v-col cols=12>
          <v-autocomplete label="Evento Padre" :items="parentEvents" item-text="name" item-value="id"
            v-model="model.parent_event_id" outlined :rules="nonEmpty"> </v-autocomplete>
          <v-checkbox v-model="model.active" :label="'Activo'"></v-checkbox>
          <v-checkbox v-if="eventType == 'event'" v-model="model.is_general_meeting"
            :label="'Junta General'"></v-checkbox>
          <v-checkbox v-if="eventType == 'event'" v-model="model.is_meeting" :label="'Junta Bimestral'"></v-checkbox>
          <v-checkbox v-if="eventType == 'event'" v-model="model.is_seminar" :label="'Seminario'"></v-checkbox>
          <v-checkbox v-if="eventType == 'exam'" v-model="model.is_black_belt"
            :label="'Examen de Cintas Negras'"></v-checkbox>
          <v-checkbox v-model="model.show_table_in_dashboard" :label="'Mostrar tabla en carátula'"></v-checkbox>
          <v-radio-group v-model="model.is_local" mandatory class="mt-n5">
            <v-checkbox v-if="eventType == 'event'" v-model="model.unique_assistance"
              :label="'Junta Asistencia Única'"></v-checkbox>
            <v-radio :label="'Local'" :value="1"></v-radio>
            <v-radio :label="'Foráneo'" :value="0"></v-radio>
          </v-radio-group>
          <v-select outlined v-model="model.multilevel_discount_type_id" :items="multilevelDiscountTypes"
            item-value="id" item-text="name" label="Tipo de descuento" />
        </v-col>
        <v-col cols=6 v-if="eventType == 'tournament'">
          <DMYDateField v-model="model.graph_creation_date" :label="'Fecha de creación de gráficas'" />
        </v-col>
        <v-col cols=6 v-if="eventType == 'tournament'">
          <CapsVTextField type="number" v-model="model.optional_discount_percentage"
            :label="'Descuento opcional (%)'" />
        </v-col>
        <v-col cols=3>
          <!--Cpasvfield numbers only for base_comission_price-->
          <CapsVTextField type="number" v-model="model.base_comission_price" label="Precio base para comisión">
          </CapsVTextField>
        </v-col>
        <v-col cols=6>
          <v-autocomplete label="Sede" :items="venues" item-text="name" item-value="id" v-model="model.venue_id"
            outlined :rules="nonEmpty"> </v-autocomplete>
        </v-col>
        <v-col cols=6>
          <CapsVTextField v-if="model.venue && model.venue.city" disabled :value="model.venue.city.name">
          </CapsVTextField>
        </v-col>
        <v-col cols=6>
          <DMYDateField :rules="nonEmpty" v-model="model.date" :label="'Fecha del evento'" />
        </v-col>
        <v-col cols=6>
          <DMYDateField v-model="model.end_date" :label="'Fin del evento'" />
        </v-col>
        <v-col cols=6>
          <v-row v-if="eventType == 'event' || eventType == 'tournament'">
            <v-col cols=12>
              <v-autocomplete v-model="model.degrees_allowed" item-text="name" item-value="id" :items="degrees"
                :label="'Grados permitidos'" outlined chips multiple />
            </v-col>
            <v-col cols=6>
              <v-checkbox label="Marrones, Rojas y Negras" @change="tickBrownRedBlack"
                :disabled="degrees.length == 0"></v-checkbox>
            </v-col>
            <v-col cols=6>
              <v-checkbox label="Todos" @change="tickAll" :disabled="degrees.length == 0"></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=6>
          <v-menu v-model="beginsAtPickerMenu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined v-model="model.begins_at" label="Hora de Inicio Evento" readonly v-bind="attrs"
                v-on="on" required></v-text-field>
            </template>
            <v-time-picker v-model="model.begins_at" @input="beginsAtPickerMenu = false"></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols=12 md=6>
          <v-menu v-model="endsAtPickerMenu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined v-model="model.ends_at" label="Fin del Evento" readonly v-bind="attrs" v-on="on"
                required></v-text-field>
            </template>
            <v-time-picker v-model="model.ends_at" @input="endsAtPickerMenu = false"></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="showFieldSinodal">
        <v-col>
          <v-autocomplete label="Sinodal" :items="sinodals" item-text="full_name" item-value="id"
            v-model="model.sinodal_id" outlined> </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="showFieldSinodal && $route.params.id">
        <v-col>
          <v-autocomplete label="Sinodales de apoyo" :items="sinodals" item-text="full_name" item-value="id"
            v-model="model.extra_sinodals" chips multiple outlined> </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="eventType == 'exam'" cols=12>
          <v-row>
            <v-col>
              <h3>PAGOS POR GRADO
                <v-icon @click="showDegree" v-if="isShowDegree">mdi-chevron-up</v-icon>
                <v-icon @click="showDegree" v-else>mdi-chevron-down</v-icon>
              </h3>
            </v-col>
          </v-row>
          <v-row v-if="isShowDegree">
            <v-col>
              <v-row>
                <v-col><strong>Grado</strong></v-col>
                <v-col><strong>Precio Normal 1 Competencia</strong></v-col>
                <v-col><strong>Precio Normal 2 Competencia</strong></v-col>
                <v-col><strong>Prepago 1 Competencia</strong></v-col>
                <v-col><strong>Prepago 2 Competencia</strong></v-col>
              </v-row>
              <v-row v-for=" blackBelt in blackBelts " :key="blackBelt.id">
                <v-col>{{ blackBelt.name }}</v-col>
                <v-col> <v-text-field placeholder="Precio Normal 1" outlined type="nubber"></v-text-field> </v-col>
                <v-col> <v-text-field placeholder="Precio Normal 2" outlined type="nubber"></v-text-field> </v-col>
                <v-col> <v-text-field placeholder="Precio Prepago 1" outlined type="nubber"></v-text-field> </v-col>
                <v-col> <v-text-field placeholder="Precio Prepago 2" outlined type="nubber"></v-text-field> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Pre Pago</h3>
        </v-col>
      </v-row>
      <v-row v-if="eventType != 'exam'">
        <v-col>
          <CapsVTextField :rules="nonEmpty" outlined prefix="$" label="Pre Pago 1 Competencia"
            v-model="model.early_payment_amount" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <CapsVTextField outlined prefix="$" label="Descuento junta" v-model="model.prepay_discount_meeting" />
        </v-col>
        <v-col>
          <CapsVTextField outlined prefix="$" label="Descuento requisitos" v-model="model.prepay_discount_revision" />
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament'">
        <v-col>
          <CapsVTextField :rules="nonEmpty" outlined prefix="$" label="Pre Pago 2 Competencias"
            v-model="model.early_payment_amount_two_modalities" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CapsVTextField outlined prefix="$" label="Pre Pago Paquete" v-model="model.early_payment_amount_companion" />
        </v-col>
      </v-row>
      <v-row>
        <v-col md=6 cols=12>
          <DMYDateField :rules="nonEmpty" v-model="model.early_payment_student_due_date"
            :label="'Fecha Límite Pre Pago Alumno'" />
        </v-col>
        <v-col md=6 cols=12>
          <DMYDateField :rules="nonEmpty" v-model="model.early_payment_professor_due_date"
            :label="'Fecha Límite Pre Pago Profesor'" />
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament' || eventType == 'event'">
        <v-col cols="12">
          <h3>Comisiones</h3>
        </v-col>
        <v-col cols="12">
          <CapsVTextField type="number" v-model="model.comision_for_profesor" label="Comisión para profesores ($)"
            placeholder="$"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Pago Normal</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CapsVTextField :rules="nonEmpty" outlined prefix="$" label="Precio Normal 1 Competencia"
            v-model="model.price" />
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament'">
        <v-col>
          <CapsVTextField :rules="nonEmpty" outlined prefix="$" label="Precio Normal 2 Competencias"
            v-model="model.price_amount_two_modalities" />
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament' || eventType == 'event'">
        <v-col>
          <CapsVTextField :label="'Numero de parcialidades permitidas'" v-model="model.number_of_patialities"
            type="number">
          </CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CapsVTextField outlined prefix="$" label="Precio Normal Paquete" v-model="model.price_companion" />
        </v-col>
      </v-row>
      <v-row>
        <v-col md=6 cols=12>
          <DMYDateField :rules="nonEmpty" v-model="model.payment_student_due_date"
            :label="'Fecha Límite Pago Alumno'" />
        </v-col>
        <v-col md=6 cols=12>
          <DMYDateField :rules="nonEmpty" v-model="model.payment_professor_due_date"
            :label="'Fecha Límite Pago Profesor'" />
        </v-col>
      </v-row>
      <v-row v-if="isMeeting">
        <v-col>
          <v-row>
            <v-col>
              <h3>PUNTAJE</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CapsVTextField v-model="model.general_points" outlined
                :label="model.unique_assistance ? 'Valor total de la junta' : 'Puntos por asistente'" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete label="Examen para descuento" :items="exams" item-text="name" item-value="id"
                v-model="model.exam_to_discount_id" chips outlined> </v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="eventType != 'exam' && !isMeeting">
        <v-col cols=12>
          <h3>PUNTAJES LOCALES</h3>
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.general_points" outlined label="General (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.mom_colaborated_points" outlined label="Colaboró Mamá (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.dad_colaborated_points" outlined label="Colaboró Papá (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.colaborated_points" outlined label="Colaboró (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField v-model="model.first_place_not_competition_points" outlined
            label="Primer lugar sin competir (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField outlined v-model="model.first_place_points" label="Primer lugar (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField outlined v-model="model.second_place_points" label="Segundo lugar (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField outlined v-model="model.third_place_points" label="Tercer lugar (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField outlined v-model="model.eliminated_place_points" label="Participó (pts)" />
        </v-col>
        <v-col cols=12>
          <h3>PUNTAJES FORÁNEOS</h3>
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.foreign_general_points" outlined label="Foráneo General (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.foreign_mom_colaborated_points" outlined label="Foráneo Colaboró Mamá (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.foreign_dad_colaborated_points" outlined label="Foráneo Colaboró Papá (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField v-model="model.foreign_colaborated_points" outlined label="Foráneo Colaboró (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField v-model="model.foreign_first_place_not_competition_points" outlined
            label="Foráneo Primer lugar sin competir (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField outlined v-model="model.foreign_first_place_points" label="Foráneo Primer lugar (pts)" />
        </v-col>
        <v-col md=2>
          <CapsVTextField outlined v-model="model.foreign_second_place_points" label="Foráneo Segundo lugar (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField outlined v-model="model.foreign_third_place_points" label="Foráneo Tercer lugar (pts)" />
        </v-col>
        <v-col md=3>
          <CapsVTextField outlined v-model="model.foreign_eliminated_place_points" label="Foráneo Participó (pts)" />
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament'">
        <v-col>
          <h3>COMPETENCIAS</h3>
        </v-col>
      </v-row>
      <v-row v-if="eventType == 'tournament' && model">
        <v-col>
          <v-checkbox :label="'Combate'" v-model="model.has_combat"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox :label="'Formas'" v-model="model.has_forms"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox :label="'Pasos de combate'" v-model="model.has_techniques"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox :label="'Idan Chaguis'" v-model="model.has_jumping_kicks"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox :label="'Rompimientos'" v-model="model.has_boards"></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox :label="'Velocidad de pateo'" v-model="model.has_kicking_speed"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="blue lighten-2" v-if="!$route.params.id" @click="createModel">Añadir</v-btn>
          <v-btn class="yellow lighten-2" v-else @click="updateModel">Editar</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12" v-if="this.$route.params.id && this.eventType == 'tournament'">
          <h3>Áreas de torneo</h3>
          <v-row>
            <v-col col-lg="2">
              <CapsVTextField type="number" :label="'Número de área'" v-model="areaEditting.area_number" />
            </v-col>
            <v-col cols-lg="8">
              <v-autocomplete label="Encargado de área" chips outlined :search-input.sync="searchStudent"
                :items="students" v-model="areaEditting.area_responsible_id" item-text="full_name" item-value="id" />
            </v-col>
            <v-col col-lg="2">
              <v-btn class="green lighten-2" dark @click="addArea">Añadir área</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ul>
                <li v-for=" area in tournamentAreas " :key="area.id">Area: {{ area.area_number }} - Encargado: {{
                  area.area_responsible.full_name }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col col=12 v-if="this.$route.params.id && this.eventType == 'tournament'">
          <h2>Grupos Participantes</h2>
          <v-dialog v-if="this.$route.params.id" v-model="createItemGroupDialog" width="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="green lighten-2" dark v-bind="attrs" v-on="on">
                Añadir grupo
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Añadir un grupo de participantes
              </v-card-title>
              <component :key="component + edditingGroupId" :is="component" :hasManyId="edditingGroupId"
                :parentId="this.model.id" @created="toggleGroupCreated" @eddited="toggleGroupEdited"
                :triggerCreate="triggerGroupCreate" :triggerEdit="triggerGroupEdit" :hideAddButton="true" />
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn color="red lighten-2" text>
                  Descartar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text v-if="edditingGroupId == 0" @click="toggleGroupCreated(false)">
                  Añadir
                </v-btn>
                <v-btn color="primary" text v-else @click="toggleGroupEdited(false)">
                  Editar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="this.$route.params.id">
          <v-data-table :headers="itemGroupHeaders" :items="itemGroups" :items-per-page="20" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="goToEditItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <v-alert class="mt-5" type="success" v-model="createdSuccess">El registro se ha creado con exito</v-alert>
    <v-alert class="mt-5" type="error" v-model="submitError">Hubo un error al guardar los datos, intentalo mas
      tarde</v-alert>
    <v-alert class="mt-5" type="success" v-model="edittedSuccess">El registro se ha editado con exito</v-alert>
    <v-row v-if="eventType == 'tournament'">
      <v-col cols="12">
        <h3>Alta de categorías de torneo</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4>Edad</h4>
          </v-col>
          <v-col>
            <CapsVTextField type="number" v-model="edittingAgeCategory.min_age" placeholder="Edad mínima">
            </CapsVTextField>
          </v-col>
          <v-col>
            <CapsVTextField type="number" v-model="edittingAgeCategory.max_age" placeholder="Edad máxima">
            </CapsVTextField>
          </v-col>
          <v-col>
            <v-select label="Grupo de edad" v-model="edittingAgeCategory.tournament_age_group_id"
              :items="tournamentAgeGroups" item-value="id" item-text="name" outlined></v-select>
          </v-col>
          <v-col cols="1"><v-btn class="blue ligthen-2" @click="uploadAgeCategory">+</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :items="ageCategories" :headers="[
              { 'value': 'min_age', 'text': 'Edad Mínima' },
              { 'value': 'max_age', 'text': 'Edad Máxima' },
              { 'value': 'age_group_name', 'text': 'Categoría de edad' },
              { 'value': 'actions', 'text': 'Acciones' },
            ]
              ">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="deleteAgeCategory(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h3>Staff</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col>
                <v-autocomplete label="Ayudante de Gráficas" chips outlined :search-input.sync="searchStudent"
                  :items="students" v-model="areaEditting.area_responsible_id" item-text="full_name" item-value="id" />
              </v-col>
              <v-col>
                <v-autocomplete label="Grados" chips multiple outlined :search-input.sync="searchStudent"
                  :items="degrees" item-text="name" item-value="id" />
              </v-col>
              <v-col>
                <v-btn class="green lighten-2" dark>Añadir Ayudante</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h4>Cinta</h4>
          </v-col>
          <v-col>
            <v-autocomplete chips v-model="edittingDegreeCategory.min_degree_id" :items="degrees"
              :search-input.sync="degreeSearchString" outlined color="white" hide-no-data hide-selected item-text="name"
              item-value="id" label="Grado Mínimo" placeholder="Grado Mínimo"></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete chips v-model="edittingDegreeCategory.max_degree_id" :items="degrees"
              :search-input.sync="degreeSearchString" outlined color="white" hide-no-data hide-selected item-text="name"
              item-value="id" label="Grado Máximo" placeholder="Grado Máximo"></v-autocomplete>
          </v-col>
          <v-col cols="1"><v-btn class="blue ligthen-2" @click="uploadDegreeCategory">+</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :items="degreeCategories" :headers="[
              { 'value': 'min_degree_name', 'text': 'Grado mínimo' },
              { 'value': 'max_degree_name', 'text': 'Grado Máximo' },
              { 'value': 'actions', 'text': 'Acciones' },
            ]
              ">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="deleteDegreeCategory(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h4>Peso</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CapsVTextField v-model="edittingWeightCategory.name" placeholder="Nombre"></CapsVTextField>
          </v-col>
          <v-col>
            <CapsVTextField v-model="edittingWeightCategory.min_weight" placeholder="Peso Mínimo"></CapsVTextField>
          </v-col>
          <v-col>
            <CapsVTextField v-model="edittingWeightCategory.max_weight" placeholder="Peso Máximo"></CapsVTextField>
          </v-col>
          <v-col>
            <v-radio-group v-model="edittingWeightCategory.sex">
              <v-radio label="Varones" :value="'M'"></v-radio>
              <v-radio :value="'F'" label="Mujeres"></v-radio>
              <v-radio :value="'B'" label="Ambos"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="1"><v-btn class="blue ligthen-2" @click="uploadWeightCategory">+</v-btn></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :items="weightCategories" :headers="[
              { 'value': 'name', 'text': 'name' },
              { 'value': 'min_weight', 'text': 'Peso mínimo' },
              { 'value': 'max_weight', 'text': 'Peso Máximo' },
              { 'value': 'sex', 'text': 'Sexo' },
              { 'value': 'group_categories_names', 'text': 'Categoría de grupo' },
              { 'value': 'actions', 'text': 'Acciones' },
            ]
              ">
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="deleteWeightCategory(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
var debounce = require('debounce');
import ENV from '../../../env'
export default {
  components: {
    'ExamGroupCreate': () => import('../exam_groups/Create'),
    'EventGroupCreate': () => import('../event_groups/Create'),
  },
  mounted: function () {
    this.edittingAgeCategory.tournament_id = this.$route.params.id
    this.edittingWeightCategory.tournament_id = this.$route.params.id
    this.edittingDegreeCategory.tournament_id = this.$route.params.id
    this.$store.dispatch('Degree/index')
    this.$store.dispatch('ParentEvent/index')
    this.$store.dispatch(`Exam/index`, '?active=1')
    this.$store.dispatch(`Degree/index`)
    this.$store.dispatch(`Venue/index`)
    this.$store.dispatch(`Professor/index`, '?sinodal=1')
    this.$store.dispatch(`DoyangSurrogation/index`)
    this.$store.dispatch(`TournamentWeightCategory/index`, `tournament_id=${this.$route.params.id}&append=group_categories_names`)
    this.$store.dispatch(`TournamentAgeCategory/index`, `tournament_id=${this.$route.params.id}`)
    this.$store.dispatch(`TournamentDegreeCategory/index`, `tournament_id=${this.$route.params.id}`)
    let payload = ''
    if (this.$route.params.id) {
      if (this.storeModel === 'Exam') {
        this.$store.dispatch(`EventComission/index`, `?exam_id=${this.$route.params.id}&with=doyang_surrogation`)
        this.commission.exam_id = this.$route.params.id
        payload += '?with=extra_sinodals'
      }
      if (this.storeModel === 'Tournament') {
        this.$store.dispatch('TournamentAgeGroup/index')
        this.$store.dispatch(`Degree/index`)
        this.$store.dispatch(`TournamentArea/index`, `?tournament_id=${this.$route.params.id}&with=area_responsible`)
        payload += '?with=degreesAllowed'
      }
      if (this.storeModel === 'Event') {
        payload += '?with=degreesAllowed'
      }
      this.$store.dispatch(`${this.storeModel}/show`, `${this.$route.params.id}${payload}`)
      this.$store.dispatch(`${this.storeModelGroup}/index`, `?${this.storeModel.toLowerCase()}_id=${this.$route.params.id}`)
      this.$store.dispatch(`MultilevelDiscountType/index`)
    }
  },
  methods: {
    tickBrownRedBlack(isChecked) {
      if (isChecked) {
        this.model.degrees_allowed = this.degrees.filter(degree => degree.order >= 9)
      }
      else {
        this.model.degrees_allowed = []
      }
      return this.model.degrees_allowed
    },
    tickAll(isChecked) {
      if (isChecked) {
        this.model.degrees_allowed = this.degrees.map(degree => degree.id)
      } else {
        this.model.degrees_allowed = []
      }
      return this.model.degrees_allowed
    },
    addArea() {
      this.$store.dispatch('TournamentArea/create', {
        area_number: this.areaEditting.area_number,
        area_responsible_id: this.areaEditting.area_responsible_id,
        tournament_id: this.$route.params.id
      })
    },
    uploadWeightCategory() {
      this.$store.dispatch('TournamentWeightCategory/create', this.edittingWeightCategory)
    },
    deleteWeightCategory(id) {
      this.$store.dispatch('TournamentWeightCategory/delete', id)
    },
    uploadAgeCategory() {
      console.log('clicked')
      this.$store.dispatch('TournamentAgeCategory/create', this.edittingAgeCategory)
    },
    deleteAgeCategory(id) {
      this.$store.dispatch('TournamentAgeCategory/delete', id)
    },
    uploadDegreeCategory() {
      this.$store.dispatch('TournamentDegreeCategory/create', this.edittingDegreeCategory)
    },
    deleteDegreeCategory(id) {
      this.$store.dispatch('TournamentDegreeCategory/delete', id)
    },
    showDegree() {
      this.isShowDegree = !this.isShowDegree
    },
    toggleSubmitError: function () {
      if (this.submitError === false) {
        setTimeout(() => {
          const el = document.querySelector(".v-messages.error--text").parentElement.parentElement
          el.scrollIntoView()
        }, 100)
        this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
      }
      else {
        this.submitError = false
      }
    },
    addCommitions() {
      this.$store.dispatch('EventComission/create', this.commission)
    },
    deleteComission(id) {
      this.$store.dispatch('EventComission/delete', id)
    },
    updateModel() {
      const modelCopy = { ...this.model }
      if (this.eventType != 'tournament') {
        delete modelCopy.graph_creation_date
      }
      this.$store.dispatch(`${this.storeModel}/update`, modelCopy).then(() => {
        alert('El evento se ha guardado con éxito')
        this.$router.push({ name: `${this.storeModel}Index` })
      })
    },
    createModel() {
      if (this.$refs.form.validate() !== true) {
        this.toggleSubmitError()
        return
      }
      const modelCopy = { ...this.model }
      if (this.eventType != 'tournament') {
        delete modelCopy.graph_creation_date
      }
      this.$store.dispatch(`${this.storeModel}/create`, modelCopy).then(() => {
        alert('El evento se ha guardado con éxito')
        this.$router.push({ name: `${this.storeModel}Index` })
      })
    },
    goToEditItem(item) {
      this.edditingGroupId = item.id
      this.createItemGroupDialog = true
    },
    deleteItem(item) {
      this.$store.dispatch(`${this.storeModelGroup}/deleteGroup`, {
        eventId: this.$route.params.id,
        eventGroupId: item.id
      });
    },
    uncapitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    toggleGroupCreated(wasCreated = false) {
      console.log(wasCreated)
      if (wasCreated) {
        this.createItemGroupDialog = false
      } else {
        this.triggerGroupCreate = !this.triggerGroupCreate
      }
    },
    toggleGroupEdited(wasEddited = false) {
      console.log(wasEddited)
      if (wasEddited) {
        this.createItemGroupDialog = false
      } else {
        this.triggerGroupEdit = !this.triggerGroupEdit
      }
    },
  },
  computed: {
    parentEvents() {
      return this.$store.getters['ParentEvent/parentEvents']
    },
    tournamentAreas() {
      return this.$store.getters['TournamentArea/tournament_areas']
    },
    students() {
      return this.$store.getters['Student/students']
    },
    tournamentAgeGroups() {
      const tournamentAgeGroups = this.$store.getters['TournamentAgeGroup/tournamentAgeGroups']
      return tournamentAgeGroups
    },
    ageCategories() {
      const ageCategories = this.$store.getters['TournamentAgeCategory/tournamentAgeCategories']
      const mappedAgeCategories = ageCategories.map(ageCategory => {
        const ageGroupName = this.tournamentAgeGroups?.find(tournamentAgeGroup => ageCategory.tournament_age_group_id === tournamentAgeGroup.id)?.name
        return {
          ...ageCategory,
          age_group_name: ageGroupName
        }
      })
      return mappedAgeCategories
    },
    ageGroups() {
      return this.$store.getters['TournamentAgeGroup/tournamentAgeGroups']
    },
    weightCategories() {
      return this.$store.getters['TournamentWeightCategory/tournamentWeightCategories']
    },
    degreeCategories() {
      return this.$store.getters['TournamentDegreeCategory/tournamentDegreeCategories']
    },
    degrees: function () {
      return this.$store.getters['Degree/degrees']
    },
    blackBelts() {
      return this.$store.getters['Degree/degrees'].filter(degree => degree.order > 11)
    },
    isMeeting() {
      return Boolean(this.model.is_general_meeting || this.model.is_meeting)
    },
    has2Competitions() {
      if (!this.model) return false
      let howManyCompetititions = 0;
      console.log('this.model', this.model)
      this.model.has_forms ? howManyCompetititions++ : null
      this.model.has_boards ? howManyCompetititions++ : null
      this.model.has_combat ? howManyCompetititions++ : null
      this.model.has_techniques ? howManyCompetititions++ : null
      return howManyCompetititions > 1
    },
    exams() {
      return this.$store.getters['Exam/exams']
    },
    doyangSurrogations() {
      return this.$store.getters['DoyangSurrogation/doyangSurrogations']
    },
    eventComissions() {
      return this.$store.getters['EventComission/event_comissions']
    },
    multilevelDiscountTypes() {
      return this.$store.getters['MultilevelDiscountType/multilevelDiscountTypes']
    },
    venues: function () {
      return this.$store.getters[`Venue/venues`]
    },
    sinodals: function () {
      return this.$store.getters[`Professor/professors`]
    },
    objectDefaultModel: function () {
      return this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`]
    },
    itemGroups: function () {
      return this.$store.getters[`${this.storeModelGroup}/${this.uncapitalizeFirstLetter(this.storeModelGroup)}s`]
    },
    isGeneralDirection() {
      return this.selectedDoyang.id == ENV.DIRECCIÓN_GENERAL_ID
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  watch: {
    'searchStudent': debounce(function () {
      this.$store.dispatch('Student/index', `?name=${this.searchStudent}`)
    }, 1000),
    createItemGroupDialog: function () {
      if (this.createItemGroupDialog === false) {
        this.edditingGroupId = 0
      }
    },
    objectDefaultModel: function () {
      this.model = this.objectDefaultModel
      this.model.venue_id = this.model.venue_id ? Number(this.model.venue_id) : this.model.venue_id
      this.model.active = this.model.active == "1"
      this.model.is_black_belt = this.model.is_black_belt == "1"
      this.model.show_table_in_dashboard = this.model.show_table_in_dashboard == "1"
      this.model.is_meeting = this.model.is_meeting == "1"
      this.model.is_general_meeting = this.model.is_general_meeting == "1"
      this.model.is_seminar = this.model.is_seminar == "1"
      this.model.unique_assistance = this.model.unique_assistance == "1"
      this.model.has_combat = this.model?.has_combat || null
      this.model.has_forms = this.model?.has_forms || null
      this.model.has_boards = this.model?.has_boards || null
      this.model.has_techniques = this.model?.has_techniques || null
      this.model.has_jumping_kicks = this.model?.has_jumping_kicks || null

    },
    defaultDate: function () {
      this.$set(this.model, 'date', this.defaultDate)
    }
  },
  data() {
    return {
      ENV: ENV,
      searchStudent: "",
      degreeSearchString: null,
      edittingWeightCategory: {
        name: null,
        min_weight: null,
        max_weight: null,
        sex: null,
      },
      edittingAgeCategory: {
        min_age: null,
        max_age: null,
        tournament_age_group_id: null,
      },
      edittingDegreeCategory: {
        min_degree_id: null,
        max_degree_id: null,
      },
      isShowDegree: false,
      nonEmpty: [
        v => !!v || v === "0" || v === 0 || 'El campo no puede estar vacio',
      ],
      commission: {
        percentage: null,
        exam_id: null,
        tournament_id: null,
        event_id: null,
        doyang_surrogation_id: null,
        min_participants: null,
        max_participants: null,
      },
      commissions: [],
      date: null,
      paymentStudentDueDatePickerMenu: null,
      paymentProfessorDueDatePickerMenu: null,
      earlyPaymentStudentDueDatePickerMenu: null,
      earlyPaymentProfessorDueDatePickerMenu: null,
      endsAtPickerMenu: false,
      dateRules: [],
      edditingGroupId: 0,
      triggerGroupCreate: false,
      triggerGroupEdit: false,
      itemGroupHeaders: [
        { text: 'Nombre', value: 'name' },
        { text: 'Hora de inicio', value: 'parsed_begins_at' },
        { text: 'Hora de fin', value: 'parsed_ends_at' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      createdSuccess: false,
      submitError: false,
      edittedSuccess: false,
      createItemGroupDialog: false,
      datePickerMenu: false,
      beginsAtPickerMenu: false,
      menuDatePicker: false,
      areaEditting: {
        area_responsible_id: null,
        area_number: null,
      },
      model: {
        optional_discount_percentage: null,
        graph_creation_date: null,
        number: null,
        base_comission_price: null,
        degrees_allowed: [],
        exam_to_discount_id: null,
        extra_sinodals: [],
        end_date: null,
        number_of_patialities: 1,
        is_meeting: false,
        is_general_meeting: false,
        unique_assistance: false,
        active: false,
        prepay_price: null,
        is_local: true,
        name: null,
        venue_id: null,
        sinodal_id: null,
        date: null,
        price: null,
        begins_at: null,
        ends_at: null,
        is_seminar: null,
        has_combat: null,
        has_forms: null,
        has_boards: null,
        has_techniques: null,
        has_jumping_kicks: null,
        price_amount_two_modalities: 0,
        early_payment_amount: 0,
        early_payment_amount_two_modalities: 0,
      }
    }
  },
  props: {
    defaultDate: {
      type: String
    },
    component: {
      type: String,
      default: 'ExamGroupCreate'
    },
    eventType: {
      type: String,
      default: 'exam'
    },
    showFieldSinodal: { type: Boolean, default: true },
    storeModel: String,
    storeModelGroup: String,
  }
}
</script>

<style></style>
