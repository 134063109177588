import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    requirements: [],
    requirement: {}
  },
  mutations: {
    index(state, payload) {
      state.requirements = payload
    },
    show(state, payload) {
      state.requirement = payload
    },
    delete(state, payload) {
      state.requirements = state.requirements.filter((requirement) => requirement.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/requirements${payload != undefined? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/requirements/${payload != undefined? payload.id : ''}`).then((res) => {
        commit('delete', res.data)
        return res
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/requirements/${payload != undefined? payload : ''}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/requirements`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/requirements/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
  },
  getters: {
    requirements: state => {
      return state.requirements
    },
    requirement: state => {
      return state.requirement
    }
  },
  modules: {
  }
}
