<template>
  <v-dialog
      width="500"
      :value="value"
      @input="closeDialog"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Comunicarse a Whatsapp
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="self"
            text
            @click="sendWhatsappMessage(self)"
          >
            <v-icon color="green lighten-2">mdi-whatsapp</v-icon> Propio
          </v-btn>
          <v-btn
            v-if="mother"
            text
            @click="sendWhatsappMessage(mother)"
          >
            <v-icon color="green lighten-2">mdi-whatsapp</v-icon> Madre
          </v-btn>
          <v-btn
            v-if="father"
            text
            @click="sendWhatsappMessage(father)"
          >
            <v-icon color="green lighten-2">mdi-whatsapp</v-icon> Padre
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  methods: {
    closeDialog () {
      this.$emit('input', false)
    },
    sendWhatsappMessage(phone) {
      window.open(`https://wa.me/+52${phone}`, '_blank')
      this.closeDialog()
    },
  },
  props: {
    value: {type: Boolean},
    mother: {type: String, default:""},
    father: {type: String, default:""},
    self: {type: String, default:""}
  }
}
</script>

<style>

</style>