<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="!id">NUEVO PAQUETE DE DOYANG</h2>
        <h2 v-else>EDITAR PAQUETE DE DOYANG</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 md=12>
        <caps-v-text-field v-model="doyangBundle.name" outlined label="Nombre" type="text"></caps-v-text-field>
        <v-text-field v-model="doyangBundle.price" prefix="$" outlined label="Precio" type="number"></v-text-field>
      </v-col>
      <v-col>
        <v-checkbox label="Para todos" v-model="doyangBundle.for_everyone"></v-checkbox>
      </v-col>
      <v-col>
        <DMYDateField :label="`Fecha límite`" v-model="doyangBundle.due_date" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md=6>
        <v-text-field v-model="doyangBundle.covers_monthly_payments_qty" outlined label="¿Cuantas mensualidades cubre?"
          type="number"></v-text-field>
      </v-col>
      <v-col md=6>
        <v-checkbox :disabled="doyangBundle.covers_monthly_payments_qty < 1"
          v-model="doyangBundle.ancticipated_monthly_payments" outlined
          label="Deben ser pagos anticipados"></v-checkbox>
      </v-col>
      <v-col md=6>
        <v-checkbox v-model="doyangBundle.covers_inscription" outlined label="Inscripción"></v-checkbox>
        <v-checkbox v-model="doyangBundle.covers_insurance" outlined label="Seguro"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 md=12>
        <h3>AÑADIR PRODUCTO</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 v-if="bundle_product.concept_id === envVars.PAYMENT_CONCEPT_PRODUCT_ID">
        <v-autocomplete label="Producto" solo v-model="bundle_product.product" :items="products" item-text="name" chips
          return-object />
      </v-col>
      <v-col cols=12 md=4>
        <v-text-field v-model="bundle_product.qty" outlined label="Cantidad" type="number"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn primary @click="addProductToBundle" class="blue ligthten-2" dark>Agregar</v-btn>
      </v-col>
    </v-row>
    <br>
    <h3>PRODUCTOS EN ESTE PAQUETE</h3>
    <v-data-table :key="doyangBundle.products.id" :headers="productsTableHeaders" :items="doyangBundle.products"
      item-text="name" item-value="id" :items-per-page="5" class="elevation-1">
      <template v-slot:item.actions="{ item }">
        <v-icon @click="unsyncProduct(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-btn v-if="!id" class="green lighten-2" @click="createNewdoyangBundle" dark>Crear</v-btn>
        <v-btn v-else class="yellow lighten-2" @click="editNewdoyangBundle" dark>Editar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CapsVTextField from '../globals/CapsVTextField.vue'

export default {
  components: {
    CapsVTextField
  },
  mounted() {
    this.$store.dispatch('PaymentConcept/index')
    this.$store.dispatch('Product/index')
    this.doyangBundle.doyang_category_id = this.doyangCategory.id
    if (this.id) {
      this.$store.dispatch('DoyangBundle/show', `${this.id == 0 ? '' : this.id}?with=products`).then((res) => {
        res.for_everyone = Number.parseInt(res.for_everyone) || 0
        res.products = res.products.map(product => {
          product['product'] = {}
          product['product']['name'] = product.name
          product['product']['id'] = product.id
          product.qty = product.pivot.qty
          return product
        })
        this.doyangBundle = res
        this.doyangBundle['ancticipated_monthly_payments'] = Number.parseInt(res['ancticipated_monthly_payments'])
        this.doyangBundle['covers_inscription'] = Number.parseInt(res['covers_inscription'])
        this.doyangBundle['covers_insurance'] = Number.parseInt(res['covers_insurance'])
      })
    }
  },
  methods: {
    unsyncProduct: function (item) {
      this.$set(this.doyangBundle, 'products', this.doyangBundle.products.filter((product) => product.id != item.id))
      this.$store.dispatch('DoyangBundle/update', this.doyangBundle).then(() => {

      })
    },
    resetDoyangBundle() {
      this.doyangBundle['is_monthly_payment'] = null
    },
    createNewdoyangBundle: function () {
      this.$store.dispatch('DoyangBundle/create', this.doyangBundle).then(() => {
        alert('Se ha creado el registro con éxito')
        this.$router.push({ name: 'DoyangCategoryEdit', params: { id: this.doyangCategory.id } })
      }).catch(() => {
        alert('Hubo un error al crear tu registro')
      })
    },
    addProductToBundle() {
      if (!this.bundle_product?.qty && this.bundle_product.qty == 0) {
        alert('¡La cantidad no puede ser 0!')
        return
      }
      if (!this?.bundle_product?.product?.name) {
        alert('¡Debes definir un producto!')
        return
      }
      if (this.bundle_product.concept_id === this.envVars.PAYMENT_CONCEPT_PRODUCT_ID) {
        this.doyangBundle.products.push(JSON.parse((JSON.stringify(this.bundle_product))))
      }
      if (this.bundle_product.concept_id === this.envVars.PAYMENT_CONCEPT_MONTHLY_PAYMENT) {
        this.resetDoyangBundle()
        this.bundle_product['is_monthly_payment'] = true;
        this.bundle_product['product'] = {};
        this.bundle_product['name'] = 'Mensualidad';
        this.bundle_product['product']['name'] = 'Mensualidad';
        this.bundle_product['product']['id'] = 0;
        this.bundle_product['product']['qty'] = this.addProductQty;
        this.doyangBundle.products.push(JSON.parse((JSON.stringify(this.bundle_product))))
      }
    },
    goToEditDoyang: function (item) {
      console.log(item, item.id)
      this.$router.push({ name: 'DoyangBundleEdit', params: { id: item.id } })
    },
    editNewdoyangBundle: function () {
      this.$store.dispatch('DoyangBundle/update', this.doyangBundle).then(() => {
        alert('Se ha modificado el registro con éxito')
        this.$router.push({ name: 'DoyangCategoryEdit', params: { id: this.doyangCategory.id } })
      }).catch((e) => {
        alert('Hubo un error al modificar tu registro')
        console.log(e)
      })
    }
  },
  computed: {
    paymentConcepts() {
      return this.$store.getters['PaymentConcept/paymentConcepts'].filter(concept => this.validConcepts.includes(Number.parseInt(concept.id)))
    },
    products() {
      return this.$store.getters['Product/products']
    },
    defaultdoyangBundle() {
      return this.$store.getters['DoyangBundle/doyangBundle']
    },
    id() {
      return this.$route.params.id
    }
  },
  watch: {
    'doyangBundle.price'() { },
    defaultdoyangBundle() {
      if (this.id != 0) {
        this.doyangBundle = this.defaultdoyangBundle
      }
    },
    id() {
      if (this.id != 0) {
        this.$store.dispatch('DoyangBundle/show', `${this.id == 0 ? '' : this.id}?with=products`).then((res) => {
          this.doyangBundle = res
        })
      } else {
        this.doyangBundle = {
          name: '',
          price: '',
          products: [],
          doyang_category_id: this.$route.params.id
        }
      }
    },
    triggerCreate() {
      if (!this.triggerCreate)
        return
      return this.$store.dispatch('DoyangBundle/create', this.doyangBundle)
        .then(() => {
          this.$store.dispatch('DoyangCategory/show', `${this.$route.params.id}?with=bundles`)
        })
        .catch(() => {
          this.$emit('errorSavedBundle')
        })
    },
    triggerEdit() {
      console.log('trigger edit')
      if (!this.triggerEdit)
        return
      return this.$store.dispatch('DoyangBundle/update', this.doyangBundle)
        .then(() => {
          this.$store.dispatch('DoyangCategory/show', `${this.$route.params.id}?with=bundles`)
        })
        .catch(() => {
          this.$emit('errorSavedBundle')
        })
    }
  },
  data() {
    return {
      anticipatedPayment: true,
      bundle_product: { concept_id: ENV.PAYMENT_CONCEPT_PRODUCT_ID, product: {}, qty: 0 },
      envVars: ENV,
      addProduct: 0,
      addProductQty: 0,
      createProuctDialog: false,
      percentage: '',
      doyangBundle: {
        name: '',
        price: '',
        products: [],
        doyang_category_id: 0,
        ancticipated_monthly_payments: false,
        covers_monthly_payments_qty: 0,
        covers_inscription: false,
        due_date: null,
      },
      productsTableHeaders: [
        { text: 'Nombre', value: 'product.name', sortable: true },
        { text: 'Cantidad', value: 'qty', sortable: true },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      validConcepts: [
        ENV.PAYMENT_CONCEPT_PRODUCT_ID,
        ENV.PAYMENT_CONCEPT_MONTHLY_PAYMENT
      ]
    }
  },
  props: {
    inscriptionPrice: {
      type: Number,
      defualt: 0
    },
    doyangCategory: {
      type: Object
    },
    triggerCreate: {
      type: Boolean,
      defualt: false
    },
    triggerEdit: {
      type: Boolean,
      defualt: false
    },
  }
}
</script>

<style></style>
