<template>
  <v-container>
    <h2>EXÁMENES</h2>
    <v-checkbox label="Solo este año" v-model="filterThisYearOnly"></v-checkbox>
    <GlobalEventProcessIndex :storeModel="storeModel" :routeCreate="routeCreate" :routeProspects="routeProspects"
      :routeEdit="routeEdit" :filterThisYearOnly="filterThisYearOnly" :permissionRoot="'Examen,Doyang Examen'" />
  </v-container>
</template>

<script>
import GlobalEventProcessIndex from '../globals/CRUDEventProcessIndex'
export default {
  components: {
    GlobalEventProcessIndex
  },
  methods: {
    initializeFilter() {
      if (!localStorage.getItem('filterThisYearOnly'))
        localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly);
      this.filterThisYearOnly = localStorage.getItem('filterThisYearOnly') == 'true';
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initializeFilter();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.initializeFilter();
    next();
  },
  watch: {
    filterThisYearOnly() {
      localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly)
    }
  },
  data() {
    return {
      filterThisYearOnly: true,
      storeModel: 'Exam',
      routeCreate: 'ExamCreate',
      routeEdit: 'ExamEdit',
      routeProspects: 'ExamProspects'
    }
  }
}
</script>

<style></style>e
