<template>
  <v-container>
    <v-col>
      <h2>Crear un nuevo descuento</h2>
      <v-form
      ref="form"
      @submit.prevent="submit"
      >
      <v-text-field v-model="discount.name" solo label="Nombre del descuento*" :rules="nameRules" required></v-text-field>
      <v-btn primary @click="createNewDiscount" class="blue ligthten-2">Agregar</v-btn>
      </v-form>
      <v-alert
      class="mt-5"
      type="success"
      v-model="createdSuccess"
      >El registro se ha creado con exito</v-alert>
      <v-alert
      class="mt-5"
      type="error"
      v-model="submitError"
      >Hubo un error al guardar los datos, intentalo mas tarde</v-alert>
      <v-alert
      class="mt-5"
      type="success"
      v-model="edittedSuccess"
      >El registro se ha editado con exito</v-alert>
    </v-col>
  </v-container>
</template>

<script>
export default {
  methods:{
    createNewDiscount: function () {
      if (this.$refs.form.validate() === true){
        this.toggleCreatedSuccess()
        }
      },
    toggleCreatedSuccess: function(){
        if (this.createdSuccess === false) {
          this.createdSuccess = true,
          setTimeout(this.toggleCreatedSuccess, 2000)
          }
        else {
          this.createdSuccess = false
          }
      },
      toggleSubmitError: function(){
        if (this.submitError === false) {
          this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
          }
        else {
          this.submitError = false
          }
      },
      toggleEdittedSuccess: function(){
        if (this.edittedSuccess === false) {
          this.edittedSuccess = true,
          setTimeout(this.toggleEdittedSuccess, 2000)
          }
        else {
          this.edittedSuccess = false
          }
      },
    },
  data () {
    return {
      nameRules:[
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Tiene que ser menos de 100 caracteres',
      ],
      createdSuccess:false,
      submitError:false,
      edittedSuccess:false,
      discount: {
        name:''
      }
    }
  }
}
</script>

<style>

</style>