<template>
    <v-card @click="selectDoyang(doyang)" class="mx-auto" max-width="500px">
        <v-card-title>
            <router-link v-if="isGeneralDirection" :to="{ name: 'DoyangEdit', params: { id: doyang.id } }">
                {{ doyang.name }}
            </router-link>
            <span v-else>{{ doyang.name }}</span>
        </v-card-title>
        <v-card-text style="margin-top: -10px;">
            <span v-if="openingDate">Apertura: {{ openingDate }}</span><span v-if="doyangAge != 'N/A'"> ({{ doyangAge }}
                AÑOS)</span>
            <br>
            <strong>Activos: {{ doyang.student_count }}</strong>
            <ActiveStudentsDialog :doyang="doyang"></ActiveStudentsDialog>
            <br>
            <span>Click para entrar a este doyang</span>
        </v-card-text>
    </v-card>
</template>

<script>
import ActiveStudentsDialog from '../home/ActiveStudentsDialog.vue'
import ENV from '../../../env.js'
export default {
    name: 'DoyangCard',
    components: {
        ActiveStudentsDialog
    },
    data() { return { ENV } },
    mounted() {
    },
    props: {
        doyang: {
            type: Object,
            required: true
        }
    },
    computed: {
        serverTime() {
            if (!this.$store.getters['Time/time']) {
                return new Date().toISOString()
            }
            return this.$store.getters['Time/time'].split(' ').join('T')
        },
        doyangAge() {
            if (!this.doyang.opening_date) return 'N/A'
            const openingDate = new Date(this.doyang.opening_date)
            const today = new Date(this.serverTime)
            const age = today.getFullYear() - openingDate.getFullYear()
            const m = today.getMonth() - openingDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < openingDate.getDate())) {
                return age - 1
            }
            return age
        },
        openingDate() {
            if (!this.doyang.opening_date) return 'N/A'
            const yearMonthStringDay = this.doyang.opening_date.split('-')
            let month = new Date(this.doyang.opening_date).toLocaleString('default', { month: 'short' }).toUpperCase();
            if (month == 'JAN') month = 'ENE';
            if (month == 'APR') month = 'ABR';
            if (month == 'AUG') month = 'AGO';
            if (month == 'DEC') month = 'DIC';
            return `${yearMonthStringDay[2].split('T')[0]}/${month}/${yearMonthStringDay[0]}`
        },
        selectedDoyang() {
            return this.$store.getters['Auth/selectedDoyang']
        },
        isGeneralDirection() {
            return this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID
        },
    },
    methods: {
        selectDoyang(doyang) {
            this.$store.commit('Auth/selectDoyang', doyang)
            this.$store.dispatch('MonthlyDuty/doyangMonthlyDuties', `?doyang_id=${doyang.id}`).then((res) => {
                this.selectedDoyangMonthlyDuties = res
            })
        }
    },
}
</script>

<style scoped>
/* Your style code here */
</style>