import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    exams: [],
    exam: {
      prospects: [],
      candidates: [],
    },
  },
  mutations: {
    delete(state,payload) {
      state.exams = state.exams.filter((exam) => exam.id != payload.id)
    },
    empty() {},
    index(state, payload) {
      state.exams = payload
    },
    show(state,payload) {
      state.exam = payload
    },
    deleteProspect(state, payload) {
      let deletedProspect = {}
      state.exam.prospects = state.exam.prospects.filter((prospect) => {
        if (payload.userId == prospect.id) {
          deletedProspect = Object.assign(prospect)
        }
        return payload.userId != prospect.id
      })
      state.exam.doyangProspects = state.exam.doyangProspects.filter((prospect) => {
        if (payload.userId == prospect.id) {
          deletedProspect = Object.assign(prospect)
        }
        return payload.userId != prospect.id
      })
      state.exam.candidates.push(deletedProspect)
    },
    addProspect(state, payload) {
      let candidateAdded = {}
      state.exam.candidates = state.exam.candidates.filter((candidate) => {
        if (payload.userId == candidate.id) {
          candidateAdded = payload.user
        }
          return payload.userId != candidate.id
      })
      state.exam.prospects.push(candidateAdded)
      state.exam.doyangProspects.push(candidateAdded)
    },
    addResult (state, {userId, resultId}) {
      state.exam.prospects.forEach((user) => {
        if (user.id == userId) {
          user.pivot.exam_result_id = resultId
        }
      })
    },
    approveRejectUser (state, {userId, isProspectApproved}) {
      state.exam.prospects.forEach((user) => {
        if (user.id == userId) {
          user.pivot.is_prospect_approved = isProspectApproved
        }
      })
    },
  },
  actions: {
    downloadAllExamSheets({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exams/download-all-exam-sheets/${payload}`, {crossDomain: true, responseType: 'blob'}).then(async (response) => {
        commit('empty')
        try {
            const text = await JSON.parse(await response?.data?.text())
            if (text.message) {
                alert(text.message);
                return;
            }
        } catch {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'solicitud_de_examen.pdf'
            link.click()
        }
      }).catch(() => 0)
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/exams/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    },
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exams${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    printApplication ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exam_applications/exam/${payload.examId}/user/${payload.userId}${payload.payload}`, {crossDomain: true, responseType: 'blob'}).then(async (response) => {
        commit('empty')
        //response.data blob to text
        try {
            const text = await JSON.parse(await response?.data?.text())
            if (text.message) {
                alert(text.message);
                return;
            }
        } catch {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'solicitud_de_examen.pdf'
            link.click()
        }
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exams/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      axios.post(`${ENV.API_URL}/api/exams`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/exams/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    aproveRejectProspect ({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/exams/${payload.examId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('approveRejectUser', res.data)
      })
    },
    assignResult ({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/exams/${payload.examId}/prospects/${payload.userId}/result`, payload).then((res) => {
        commit('addResult', res)
      })
    },
    deleteProspect({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/exams/${payload.examId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('deleteProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    addProspect({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/exams/${payload.examId}/prospects`, {userId : payload.userId}).then((res) => {
        commit('addProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    printSheet({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exams/result_sheet/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'solicitud_de_examen.pdf'
        link.click()
        return true
      }).catch(() => 0)
    },
    printSheetByCertifier({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exams/result_sheet_by_certifier/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        console.log('hi')
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'solicitud_de_examen.pdf'
        link.click()
      }).catch(() => 0)
    },
  },
  getters: {
    exams: state => {
      return state.exams
    },
    exam: state => {
      return state.exam
    }
  },
  modules: {
  }
}
