<template>
    <div>
        <span>
            Inscripciones:
            {{ doyang.daily_inscriptions }} (<a @click.stop="downloadDailyInscriptions(doyang.id)">diario</a>) /
            {{ doyang.bimester_inscriptions || 0 }}(<a @click.stop="downloadBimesterCsv(doyang.id)">bimestre</a>)/{{
                doyang.bimester_inscriptions_goal || 0 }}(meta <v-icon small
                @click="establishGoal(doyang.id, null, 'inscription')"
                v-if="canEditGoals && areGoalsModifiable">mdi-pencil</v-icon>)
        </span>
    </div>
</template>

<script>
import ENV from '../../../env'
export default {
    data() {
        return {
            message: "This is the Active Students Text component.",
            ENV
        };
    },
    props: {
        doyang: {
            type: Object,
            required: true
        },
        areGoalsModifiable: {
            type: Boolean,
            default: false
        }
    },
    isWindowToEditGoals() {
        const bimesterStart = new Date(this.bimesterStart)
        const today = new Date()
        const fifteenDaysAfterBimesterStart = new Date(bimesterStart.setDate(bimesterStart.getDate() + 15))
        return today < fifteenDaysAfterBimesterStart
    },
    computed: {
        authInfo() {
            return this.$store.getters['Auth/authInfo']
        },
        canEditGoals() {
            return true;
        },
        canSeeGeneralDirection() {
            if (!this.authInfo) return false
            return ENV.permissions.can_see_general_direction.includes(parseInt(this?.authInfo?.user?.role_id))
        },
    },
    methods: {
        establishGoal(doyangId, examName, type) {
            const goal = prompt('Introduce la nueva meta')
            this.$store.dispatch('Doyang/stablish_goal', {
                doyang_id: doyangId,
                event_name: examName,
                type,
                goal
            })
        },
        downloadDailyInscriptions(doyangId) {
            this.$store.dispatch('Doyang/show', `${doyangId}/daily_inscriptions_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            })
        },
        downloadBimesterCsv(doyangId) {
            this.$store.dispatch('Doyang/show', `${doyangId}/bimester_inscriptions_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            })
        },
        downloadStringInNewPage(string, filename) {
            const blob = new Blob([string], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    }
};
</script>

<style scoped>
h1 {
    color: blue;
}

p {
    font-size: 18px;
}
</style>