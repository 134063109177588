<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Categorías de Doyang MDK</h2>
          <CRUDIndex
            :permissionRoot="'Categories de doyang'"
            :headers="headers"
            :storeModel="storeModel"
            :storeModelPlural="storeModelPlural"
            :itemsPerPage="itemsPerPage"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data () {
    return {
      headers: [
        { text: 'Categoría', value: 'name' },
        { text: 'Inscripción', value: 'inscription_price' },
        { text: 'Mensualidad', value: 'monthly_fee_price' }
      ],
      storeModel: 'DoyangCategory',
      storeModelPlural: 'DoyangCategories',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
