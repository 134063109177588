<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>PERIODO DEL BIMESTRE</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <DMYDateField v-model="bimesterStart" :label="'Inicio del bimestre'"></DMYDateField>
      </v-col>
      <v-col cols="4">
        <DMYDateField v-model="bimesterEnd" :label="'Fin del bimestre'"></DMYDateField>
      </v-col>
      <v-col cols="4">
        <v-btn color="green lighten-2" @click="addBimester" dark>Añadir bimestre</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>BAJAS AUTOMÁTICAS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="maxMonthDebtAllowed"
          :label="'Número máximo de mensualidades debidas PERMITIDAS'"></caps-v-text-field>
      </v-col>
      <v-col>
        <caps-v-text-field v-model="maxAbsencesAllowed"
          :label="'Número máximo de faltas cotinuas PERMITIDAS'"></caps-v-text-field>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>DESCUENTO POR RECOMENDADOS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="percentageDiscount"
          :label="'% de descuento en CADA recomendado'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>DÍAS DESPUES DE INICIO DE BIMESTRE PARA MODIFICAR METAS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="daysToModify" :label="'días para modificar metas'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>PRECIO DE SEGURO</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="insurancePrice" :label="'Precio del seguro ($)'"></caps-v-text-field>
      </v-col>
      <v-col>
        <DMYDateField v-model="insuranceEndDate" :label="'Fecha de fin de seguro'"></DMYDateField>
      </v-col>
      <v-col>
        <v-checkbox v-model="sellNextYearInsurance" label="Vender seguro del próximo año"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row lg=12 cols=12>
          <v-col>
            <h2>DÍAS FESTIVOS</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=3>
            <DMYDateField v-model="holyDayModel.start_date" :label="'Inicio'"></DMYDateField>
          </v-col>
          <v-col cols=3>
            <DMYDateField v-model="holyDayModel.end_date" :label="'Fin'"></DMYDateField>
          </v-col>
          <v-col cols=3>
            <caps-v-text-field v-model="holyDayModel.name" :label="'Nombre'"></caps-v-text-field>
          </v-col>
          <v-col cols=3>
            <v-btn color="primary" @click="addHoliday">Agregar</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :items="holidays" :headers="holidaysHeaders">
              <template v-slot:item.actions="{ item }">
                <v-icon @click="removeHoliday(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>AUSENCIAS EXTRAODINARIAS</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <caps-v-text-field v-model="permissibleAbscences" :label="'Faltas permitidas'"></caps-v-text-field>
      </v-col>
      <v-col>
        <caps-v-text-field v-model="extraAbscences" :label="'Faltas adultos'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <v-row>
          <v-col>
            <h2>REVISIÓN</h2>
          </v-col>
        </v-row>
        <v-row>
          <DMYDateField v-model="startRevisionDate" :label="'Inicio de revisiones'"></DMYDateField>
          <DMYDateField v-model="endRevisionDate" :label="'Fin de revisiones'"></DMYDateField>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>PORCENTAJE DE ALUMNOS BECADOS</h2>
      </v-col>
      <v-col cols=12>
        <caps-v-text-field v-model="maxScholarshippedPercentage" :label="'% de becados'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>EDAD MÁXIMA PARA PRE-TKD</h2>
      </v-col>
      <v-col cols=12>
        <caps-v-text-field v-model="maximumAgePreTKD" :label="'Edad (años)'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>EFICACIA MINIMA ACEPTABLE</h2>
      </v-col>
      <v-col cols=4>
        <caps-v-text-field v-model="minAceptableEficiencyInLocalEvents"
          :label="'EVENTOS LOCALES (%)'"></caps-v-text-field>
      </v-col>
      <v-col cols=4>
        <caps-v-text-field v-model="minAceptableEventsEficiencyInForeignEvents"
          :label="'EN EVENTOS FORÁNEOS (%)'"></caps-v-text-field>
      </v-col>
      <v-col cols=4>
        <caps-v-text-field v-model="minAceptableTournamentsEficiencyInBus" :label="'EN CAMIÓN (%)'"></caps-v-text-field>
      </v-col>
      <v-col cols=4>
        <caps-v-text-field v-model="minAceptableEficiency" :label="'EN EXAMENES (%)'"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>PUNTAJES POR DEFECTO</h2>
      </v-col>
      <v-col cols=12>
        <h2>TORNEOS</h2>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <h3>Puntajes para premiación de doyangs</h3>
          </v-col>
          <v-col>
            <caps-v-text-field v-model="doyangPointsFirstPlace" :label="'Primer lugar compitiendo'"></caps-v-text-field>
            <caps-v-text-field v-model="doyangPointsFirstPlaceNoContest"
              :label="'Primer lugar sin competir'"></caps-v-text-field>
            <caps-v-text-field v-model="doyangPointsSecondPlace" :label="'Segundo lugar'"></caps-v-text-field>
            <caps-v-text-field v-model="doyangPointsThirdPlace" :label="'Tercer lugar'"></caps-v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <caps-v-text-field v-model="minimumAgeForMixingFemalesAndMales"
              :label="'Edad máxima para mezclar mujeres y hombres'"></caps-v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12>
            <h3>Puntaje para curriculum</h3>
          </v-col>
          <v-col cols=6>
            <h3>Local</h3>
          </v-col>
          <v-col cols=6>
            <h3>Foráneo</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=6>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="firstPlaceLocalDefaultPoints" :label="'Primer lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="firstPlaceNoContestLocalDefaultPoints"
                  :label="'Primer lugar sin competir'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="secondPlaceLocalDefaultPoints" :label="'Segundo lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="thirdPlaceLocalDefaultPoints" :label="'Tercer lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="eliminatedPlaceLocalDefaultPoints" :label="'Participó'"></caps-v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols=6>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="firstPlaceForeignDefaultPoints" :label="'Primer lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="firstPlaceNoContestForeignDefaultPoints"
                  :label="'Primer lugar sin competir'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="secondPlaceForeignDefaultPoints"
                  :label="'Segundo lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="thirdPlaceForeignDefaultPoints" :label="'Tercer lugar'"></caps-v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <caps-v-text-field v-model="eliminatedPlaceForeignDefaultPoints"
                  :label="'Participó'"></caps-v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>CATEGORÍAS DE EDAD EN TORNEOS</h2>
      </v-col>
      <v-col cols=6>
        <caps-v-text-field v-model="tournamentAgeGroup.name" :label="'Nombre'"></caps-v-text-field>
      </v-col>
      <v-col cols=6><v-btn class="blue lighten-2" @click="addTournamentAgeGroup">Agregar</v-btn></v-col>
      <v-col cols=12>
        <ul>
          <li class="text-left" v-for="tournamentAgeGroup in tournamentAgeGroups" :key="tournamentAgeGroup.id">
            {{ tournamentAgeGroup.name }}
            <v-icon @click="deleteTournamentAgeGroup(tournamentAgeGroup)">mdi-delete</v-icon>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>DURACIÓN DE CONTIENDAS</h2>
      </v-col>
      <v-col cols="6">
        <caps-v-text-field v-model="combatDuration" :label="'Combate (minutos)'"></caps-v-text-field>
      </v-col>
      <v-col cols="6">
        <caps-v-text-field v-model="formDuration" :label="'Formas (minutos)'"></caps-v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue'
export default {
  components: { CapsVTextField },
  computed: {
    tournamentAgeGroups() {
      return this.$store.getters['TournamentAgeGroup/tournamentAgeGroups']
    },
    holidays() {
      return this.$store.getters['Holiday/holidays']
    }
  },
  mounted() {
    this.$store.dispatch('TournamentAgeGroup/index')
    this.$store.dispatch('Holiday/index')
    this.$store.dispatch('Configuration/show', 1).then((res) => {
      this.bimesterStart = res.value
    })
    this.$store.dispatch('Configuration/show', 2).then((res) => {
      this.bimesterEnd = res.value
    })
    this.$store.dispatch('Configuration/show', 3).then((res) => {
      this.maxMonthDebtAllowed = res.value
    })
    this.$store.dispatch('Configuration/show', 4).then((res) => {
      this.maxAbsencesAllowed = res.value
    })
    this.$store.dispatch('Configuration/show', 5).then((res) => {
      this.percentageDiscount = res.value
    })
    this.$store.dispatch('Configuration/show', 6).then((res) => {
      this.daysToModify = res.value
    })
    this.$store.dispatch('Configuration/show', 7).then((res) => {
      this.insurancePrice = res.value
    })
    this.$store.dispatch('Configuration/show', 11).then((res) => {
      this.extraAbscences = res.value
    })
    this.$store.dispatch('Configuration/show', 12).then((res) => {
      this.permissibleAbscences = res.value
    })
    this.$store.dispatch('Configuration/show', 13).then((res) => {
      this.insuranceEndDate = res.value
    })
    // Tornaments
    this.$store.dispatch('Configuration/show', 14).then((res) => {
      this.firstPlaceLocalDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 15).then((res) => {
      this.firstPlaceNoContestLocalDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 16).then((res) => {
      this.secondPlaceLocalDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 17).then((res) => {
      this.thirdPlaceLocalDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 18).then((res) => {
      this.eliminatedPlaceLocalDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 19).then((res) => {
      this.firstPlaceForeignDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 20).then((res) => {
      this.firstPlaceNoContestForeignDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 21).then((res) => {
      this.secondPlaceForeignDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 22).then((res) => {
      this.thirdPlaceForeignDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 23).then((res) => {
      this.eliminatedPlaceForeignDefaultPoints = res.value
    })
    this.$store.dispatch('Configuration/show', 24).then((res) => {
      this.startRevisionDate = res.value
    })
    this.$store.dispatch('Configuration/show', 25).then((res) => {
      this.endRevisionDate = res.value
    })
    this.$store.dispatch('Configuration/show', 26).then((res) => {
      this.maximumAgePreTKD = res.value
    })
    this.$store.dispatch('Configuration/show', 27).then((res) => {
      this.minAceptableEficiency = res.value
    })
    this.$store.dispatch('Configuration/show', 28).then((res) => {
      this.minAceptableEventsEficiency = res.value
    })
    this.$store.dispatch('Configuration/show', 29).then((res) => {
      this.minAceptableTournamentsEficiency = res.value
    })
    this.$store.dispatch('Configuration/show', 30).then((res) => {
      this.maxScholarshippedPercentage = res.value
    })
    this.$store.dispatch('Configuration/show', 31).then((res) => {
      this.minAceptableEficiencyInLocalEvents = res.value
    })
    this.$store.dispatch('Configuration/show', 32).then((res) => {
      this.minAceptableEventsEficiencyInForeignEvents = res.value
    })
    this.$store.dispatch('Configuration/show', 33).then((res) => {
      this.minAceptableTournamentsEficiencyInBus = res.value
    })
    this.$store.dispatch('Configuration/show', 34).then((res) => {
      this.minimumAgeForMixingFemalesAndMales = res.value
    })
    this.$store.dispatch('Configuration/show', 35).then((res) => {
      this.combatDuration = res.value
    })
    this.$store.dispatch('Configuration/show', 36).then((res) => {
      this.formDuration = res.value
    })
    this.$store.dispatch('Configuration/show', 37).then((res) => {
      this.doyangPointsFirstPlaceNoContest = res.value
    })
    this.$store.dispatch('Configuration/show', 38).then((res) => {
      this.doyangPointsFirstPlace = res.value
    })
    this.$store.dispatch('Configuration/show', 39).then((res) => {
      this.doyangPointsSecondPlace = res.value
    })
    this.$store.dispatch('Configuration/show', 40).then((res) => {
      this.doyangPointsThirdPlace = res.value
    })
    this.$store.dispatch('Configuration/show', 41).then((res) => {
      this.sellNextYearInsurance = res.value != 0
    })
  },
  methods: {
    deleteTournamentAgeGroup(tournamentAgeGroup) {
      this.$store.dispatch('TournamentAgeGroup/delete', tournamentAgeGroup.id).then(() => {
        alert('División de edad eliminada')
      }).catch(() => {
        alert('Error al eliminar división de edad')
      })
    },
    addTournamentAgeGroup() {
      this.$store.dispatch('TournamentAgeGroup/create', this.tournamentAgeGroup).then(() => {
        alert('División de edad agregada')
        this.tournamentAgeGroup.name = null
      }).catch(() => {
        alert('Error al agregar división de edad')
      })
    },
    addHoliday() {
      this.$store.dispatch('Holiday/create', this.holyDayModel).then(() => {
        alert('Día festivo agregado')
      }).catch(() => {
        alert('Error al agregar día festivo')
      })
    },
    removeHoliday(holiday) {
      this.$store.dispatch('Holiday/delete', holiday.id).then(() => {
        alert('Día festivo eliminado')
      }).catch(() => {
        alert('Error al eliminar día festivo')
      })
    },
    async addBimester() {
      this.$store.dispatch('Bimester/create', {
        start_date: this.bimesterStart,
        end_date: this.bimesterEnd,
      }).then(async () => {
        await this.$store.dispatch('Configuration/update', {
          id: 1,
          value: this.bimesterStart,
        }).then(() => {
          alert('Inicio de bimestre actualizado')
        }).catch(() => {
          alert('Error al actualizar inicio de bimestre')
        })
        await this.$store.dispatch('Configuration/update', {
          id: 2,
          value: this.bimesterEnd,
        }).then(() => {
          alert('Fin de bimestre actualizado')
        }).catch(() => {
          alert('Error al actualizar fin de bimestre')
        })
        alert('Bimestre agregado')
      }).catch(() => {
        alert('Error al agregar bimestre')
      })
    },
  },
  data() {
    return {
      tournamentAgeGroup: {
        name: null,
      },
      holidaysHeaders: [
        { text: 'Inicio', value: 'parsed_start_date' },
        { text: 'Fin', value: 'parsed_end_date' },
        { text: 'Nombre', value: 'name' },
        { text: 'Acciones', value: 'actions' },
      ],
      holyDayModel: {
        start_date: null,
        end_date: null,
        name: null,
      },
      bimesterEnd: null,
      bimesterStart: null,
      maxMonthDebtAllowed: null,
      maxAbsencesAllowed: null,
      percentageDiscount: null,
      daysToModify: null,
      insurancePrice: null,
      extraAbscences: null,
      permissibleAbscences: null,
      insuranceEndDate: null,
      firstPlaceLocalDefaultPoints: null,
      firstPlaceNoContestLocalDefaultPoints: null,
      secondPlaceLocalDefaultPoints: null,
      thirdPlaceLocalDefaultPoints: null,
      eliminatedPlaceLocalDefaultPoints: null,
      firstPlaceForeignDefaultPoints: null,
      firstPlaceNoContestForeignDefaultPoints: null,
      secondPlaceForeignDefaultPoints: null,
      thirdPlaceForeignDefaultPoints: null,
      eliminatedPlaceForeignDefaultPoints: null,
      startRevisionDate: null,
      endRevisionDate: null,
      maximumAgePreTKD: null,
      minAceptableEficiency: null,
      minAceptableEventsEficiency: null,
      minAceptableTournamentsEficiency: null,
      maxScholarshippedPercentage: null,
      minAceptableEficiencyInLocalEvents: null,
      minAceptableEventsEficiencyInForeignEvents: null,
      minAceptableTournamentsEficiencyInBus: null,
      minimumAgeForMixingFemalesAndMales: null,
      combatDuration: null,
      formDuration: null,
      doyangPointsFirstPlaceNoContest: null,
      doyangPointsFirstPlace: null,
      doyangPointsSecondPlace: null,
      doyangPointsThirdPlace: null,
      sellNextYearInsurance: null,
    }
  },
  watch: {
    // bimesterEnd () {
    //   this.$store.dispatch('Configuration/update', {
    //     id: 2,
    //     value: this.bimesterEnd
    //   })
    // },
    // bimesterStart () {
    //   this.$store.dispatch('Configuration/update', {
    //     id: 1,
    //     value: this.bimesterStart
    //   })
    // },
    maxMonthDebtAllowed() {
      this.$store.dispatch('Configuration/update', {
        id: 3,
        value: this.maxMonthDebtAllowed
      })
    },
    maxAbsencesAllowed() {
      this.$store.dispatch('Configuration/update', {
        id: 4,
        value: this.maxAbsencesAllowed
      })
    },
    percentageDiscount() {
      this.$store.dispatch('Configuration/update', {
        id: 5,
        value: this.percentageDiscount
      })
    },
    daysToModify() {
      this.$store.dispatch('Configuration/update', {
        id: 6,
        value: this.daysToModify
      })
    },
    insurancePrice() {
      this.$store.dispatch('Configuration/update', {
        id: 7,
        value: this.insurancePrice
      })
    },
    extraAbscences() {
      this.$store.dispatch('Configuration/update', {
        id: 11,
        value: this.extraAbscences
      })
    },
    permissibleAbscences() {
      this.$store.dispatch('Configuration/update', {
        id: 12,
        value: this.permissibleAbscences
      })
    },
    insuranceEndDate() {
      this.$store.dispatch('Configuration/update', {
        id: 13,
        value: this.insuranceEndDate
      })
    },
    firstPlaceLocalDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 14,
        value: this.firstPlaceLocalDefaultPoints
      });
    },
    firstPlaceNoContestLocalDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 15,
        value: this.firstPlaceNoContestLocalDefaultPoints
      });
    },
    secondPlaceLocalDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 16,
        value: this.secondPlaceLocalDefaultPoints
      });
    },
    thirdPlaceLocalDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 17,
        value: this.thirdPlaceLocalDefaultPoints
      });
    },
    eliminatedPlaceLocalDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 18,
        value: this.eliminatedPlaceLocalDefaultPoints
      });
    },
    firstPlaceForeignDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 19,
        value: this.firstPlaceForeignDefaultPoints
      });
    },
    firstPlaceNoContestForeignDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 20,
        value: this.firstPlaceNoContestForeignDefaultPoints
      });
    },
    secondPlaceForeignDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 21,
        value: this.secondPlaceForeignDefaultPoints
      });
    },
    thirdPlaceForeignDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 22,
        value: this.thirdPlaceForeignDefaultPoints
      });
    },
    eliminatedPlaceForeignDefaultPoints() {
      this.$store.dispatch('Configuration/update', {
        id: 23,
        value: this.eliminatedPlaceForeignDefaultPoints
      });
    },
    startRevisionDate() {
      this.$store.dispatch('Configuration/update', {
        id: 24,
        value: this.startRevisionDate
      });
    },
    endRevisionDate() {
      this.$store.dispatch('Configuration/update', {
        id: 25,
        value: this.endRevisionDate
      });
    },
    maximumAgePreTKD() {
      this.$store.dispatch('Configuration/update', {
        id: 26,
        value: this.maximumAgePreTKD
      });
    },
    minAceptableEficiency() {
      this.$store.dispatch('Configuration/update', {
        id: 27,
        value: this.minAceptableEficiency
      });
    },
    minAceptableEventsEficiency() {
      this.$store.dispatch('Configuration/update', {
        id: 28,
        value: this.minAceptableEventsEficiency
      });
    },
    minAceptableTournamentsEficiency() {
      this.$store.dispatch('Configuration/update', {
        id: 29,
        value: this.minAceptableTournamentsEficiency
      });
    },
    maxScholarshippedPercentage() {
      this.$store.dispatch('Configuration/update', {
        id: 30,
        value: this.maxScholarshippedPercentage
      });
    },
    minAceptableEficiencyInLocalEvents() {
      this.$store.dispatch('Configuration/update', {
        id: 31,
        value: this.minAceptableEficiencyInLocalEvents
      });
    },
    minAceptableEventsEficiencyInForeignEvents() {
      this.$store.dispatch('Configuration/update', {
        id: 32,
        value: this.minAceptableEventsEficiencyInForeignEvents
      });
    },
    minAceptableTournamentsEficiencyInBus() {
      this.$store.dispatch('Configuration/update', {
        id: 33,
        value: this.minAceptableTournamentsEficiencyInBus
      });
    },
    minimumAgeForMixingFemalesAndMales() {
      this.$store.dispatch('Configuration/update', {
        id: 34,
        value: this.minimumAgeForMixingFemalesAndMales
      });
    },
    combatDuration() {
      this.$store.dispatch('Configuration/update', {
        id: 35,
        value: this.combatDuration
      });
    },
    formDuration() {
      this.$store.dispatch('Configuration/update', {
        id: 36,
        value: this.formDuration
      });
    },
    doyangPointsFirstPlaceNoContest() {
      this.$store.dispatch('Configuration/update', {
        id: 37,
        value: this.doyangPointsFirstPlaceNoContest
      });
    },
    doyangPointsFirstPlace() {
      this.$store.dispatch('Configuration/update', {
        id: 38,
        value: this.doyangPointsFirstPlace
      });
    },
    doyangPointsSecondPlace() {
      this.$store.dispatch('Configuration/update', {
        id: 39,
        value: this.doyangPointsSecondPlace
      });
    },
    doyangPointsThirdPlace() {
      this.$store.dispatch('Configuration/update', {
        id: 40,
        value: this.doyangPointsThirdPlace
      });
    },
    sellNextYearInsurance() {
      this.$store.dispatch('Configuration/update', {
        id: 41,
        value: !!this.sellNextYearInsurance
      });
    },
  }
}
</script>

<style></style>
