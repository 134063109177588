<template>
    <div class="chart-container" v-if="chartData">
        <Bar :chart-options="{
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }" :chart-data="chartDataCopy" :chart-id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins"
            :css-classes="cssClasses" :styles="styles" :width="200" :height="200" />
    </div>
</template>

<style scoped>
.chart-container {
    width: 75%;
    position: relative;
}
</style>

<script>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import { Bar } from 'vue-chartjs/legacy'
import env from '../../../env'
export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        labels: { type: Array, default: () => [] },
        chartId: { type: String, default: 'bar-chart' },
        datasetIdKey: { type: String, default: 'label' },
        width: { type: Number, default: 400 },
        height: { type: Number, default: 400 },
        cssClasses: { type: String, default: '' },
        styles: { type: Object, default: () => ({}) },
        plugins: { type: Array, default: () => [] },
        dataArray: { type: Array, default: () => [] }
    },
    computed: {
        chartDataCopy: function () {
            return JSON.parse(JSON.stringify(this.chartData));
        },
        chartData() {
            if (!this.dataArray || !this.dataArray.length) return null;

            const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'grey', 'black'];
            const datasets = this.dataArray.map((dataPoint, i) => {
                const dataParsed = Object.keys(dataPoint).reduce((acc, key) => {
                    // if key is number
                    if (!isNaN(key)) {
                        const monthAbv = env.months[key]['abv'];
                        acc[monthAbv] = dataPoint[key];
                        return acc;
                    }
                    acc[`...${key.substring(key.length - 21)}`] = dataPoint[key];
                    return acc;
                }, {});
                return {
                    label: this.labels?.[i] || `Dataset ${i + 1}`,
                    backgroundColor: colors[i % colors.length], // Avoid going out of bounds
                    data: dataParsed // Ensure data format matches expected chart.js input
                };
            });
            return {
                datasets,
                env: env
            };
        },
    }
}
</script>
