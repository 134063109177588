import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    inventories: [],
  },
  mutations: {
    index(state, payload) {
      state.inventories = payload
    },
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyangs/${payload}`).then((res) => {
        const inventories = res.data.products.map((product) => product)
        commit('index', inventories)
        return inventories
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/products/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/products`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/products/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    }
  },
  getters: {
    inventories: state => {
      return state.inventories
    },
  },
  modules: {}
}
