export const getPermissions = (permissions, name, action = false) => {
        if (!permissions) return false
        const singlePermissionkey = Object.keys(permissions).filter( key => {
                return permissions[key].name === name
            })?.[0]
        const singlePermission = permissions?.[singlePermissionkey]
        if (!singlePermissionkey) return false
        if (!action && singlePermission) {
            return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
        }
        return singlePermission?.[action] == 1 || false
}
