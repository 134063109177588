<template>
    <v-dialog v-model="active">
        <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
                <span style="font-size: small" v-if="doyang.responsible">{{ `${doyang.responsible.name}
                    ${doyang.responsible.last_name} ` }}</span>
                <a>({{ doyang.students_count ? `Activos: ${doyang.students_count}` : 'más información' }})</a>
            </span>
        </template>
        <v-card v-if="!isLoading">
            <v-card-title>Alumnos | {{ doyang.name }}</v-card-title>
            <v-card-text>
                <div v-if="isGeneralDirection">
                    <a href="#" @click.prevent="downloadGeneralDirectionStudentsCsv">CSV de
                        alumnos</a> |
                    <a href="#" @click.prevent="downloadHomeBeltsCsv"> CSV de cintas</a>
                </div>
                <div v-else>
                    <a href="#" @click.prevent="downloadActiveStudents">CSV de alumnos activos</a>
                </div>
                <ul>
                    <li>
                        <span>
                            <strong>Record inscritos: </strong>{{ recordDate }}</span>
                    </li>
                    <li><strong>Prospectos para cinta negra:</strong>
                        {{ downloadedDoyang.black_belt_prospects_count }} <a a
                            v-if="downloadedDoyang.black_belt_prospects_count > 0" href="#"
                            @click.prevent="downloadBlackBelts">Descargar CSV</a></li>
                    <li v-for="beltString in Object.keys(studentCountsByBelt)" :key="beltString">
                        <strong>{{ beltString }}:</strong> {{ studentCountsByBelt[beltString] }}
                    </li>
                </ul>
                <h4>Cumpleaños del mes</h4>
                <ul>
                    <li v-for="student in birthdays" :key="student.id">
                        <span>{{ student.name }} {{ student.last_name }} - {{ student.birthdayDate }}</span>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
        <span v-else>Cargando...</span>
    </v-dialog>
</template>

<script>
import ENV from '../../../env.js'

export default {
    mounted() { },
    data() {
        return {
            ENV,
            active: false,
            isLoading: true,
            message: "This is the Active Students Text component."
        };
    },
    props: {
        doyang: {
            type: Object,
            required: true
        }
    },
    watch: {
        active() {
            if (this.active) {
                this.$store.dispatch('Doyang/show', `${this.doyang.id}?append=month_birthdays,totals_by_belt,inscription_record,black_belt_prospects_count`).then(() => {
                    this.isLoading = false
                })
            } else {
                this.isLoading = true
            }
        }
    },
    computed: {
        isGeneralDirection() {
            return this.doyang.id == ENV.DIRECCIÓN_GENERAL_ID
        },
        birthdays() {
            if (!this.downloadedDoyang?.month_birthdays) return [];

            // Clone the array and sort it without modifying the original
            const sortedBirthdays = [...this.downloadedDoyang.month_birthdays].sort((a, b) => {
                return a.birth_date.split('-')[2] > b.birth_date.split('-')[2] ? 1 : -1;
            });

            // Map over the sorted array and create new student objects with the modified birthdayDate property
            const studentsWithFormattedBirthday = sortedBirthdays.map(student => {
                const birthdayDateParts = student.birth_date.split('-');
                const formattedBirthdayDate = `${birthdayDateParts[2]}/${birthdayDateParts[1]}`;
                // Return a new object with the modified birthdayDate property
                return {
                    ...student,
                    birthdayDate: formattedBirthdayDate
                };
            });

            return studentsWithFormattedBirthday;
        },
        recordDate() {
            const doyangDate = this.downloadedDoyang?.inscription_record?.date || ''
            const splittedDojangDate = doyangDate.split('/')
            if (splittedDojangDate.length !== 2) {
                return doyangDate
            }
            return `${splittedDojangDate[1]}/${splittedDojangDate[0]}`
        },
        downloadedDoyang() {
            return this.$store.getters['Doyang/doyang']
        },
        studentCountsByBelt() {
            let studentsByDegree = {}
            studentsByDegree['11vo a 10mo kup'] = this.downloadedDoyang.totals_by_belt['BLANCA 11° KUP']
            studentsByDegree['10mo a 9no kup'] = this.downloadedDoyang.totals_by_belt['BLANCA 10° KUP']
            studentsByDegree['Amarillas'] = this.downloadedDoyang.totals_by_belt['AMARILLA 8° KUP'] + this.downloadedDoyang.totals_by_belt['AMARILLA 9° KUP']
            studentsByDegree['Verdes'] = this.downloadedDoyang.totals_by_belt['VERDE 6° KUP'] + this.downloadedDoyang.totals_by_belt['VERDE 7° KUP']
            studentsByDegree['Azules'] = this.downloadedDoyang.totals_by_belt['AZUL 4° KUP'] + this.downloadedDoyang.totals_by_belt['AZUL 5° KUP']
            studentsByDegree['Marrones'] = this.downloadedDoyang.totals_by_belt['MARRÓN 2° KUP'] + this.downloadedDoyang.totals_by_belt['MARRÓN 3° KUP']
            studentsByDegree['Rojas'] = this.downloadedDoyang.totals_by_belt['ROJA 1° KUP'] + this.downloadedDoyang.totals_by_belt['ROJA IEBY']
            studentsByDegree['Negras'] = this.downloadedDoyang.totals_by_belt['CN 1°'] + this.downloadedDoyang.totals_by_belt['CN 2°'] + this.downloadedDoyang.totals_by_belt['CN 3°'] + this.downloadedDoyang.totals_by_belt['CN 4°'] + this.downloadedDoyang.totals_by_belt['CN 5°'] + this.downloadedDoyang.totals_by_belt['CN 6°'] + this.downloadedDoyang.totals_by_belt['CN 7°'] + this.downloadedDoyang.totals_by_belt['CN 8°'] + this.downloadedDoyang.totals_by_belt['CN 9°']
            return studentsByDegree
        }
    },
    methods: {
        downloadGeneralDirectionStudentsCsv() {
            this.$store.dispatch("Doyang/show", `${this.doyang.id}?append=general_direction_home_csv`).then((response) => {
                this.downloadStringInNewPage(response?.general_direction_home_csv, 'ReporteGeneralAlumnosDireccionGeneral.csv')
            })
        },
        downloadActiveStudents() {
            this.$store.dispatch("Doyang/show", `${this.doyang.id}?append=download_active_students_csv`).then((response) => {
                this.downloadStringInNewPage(response?.download_active_students_csv, 'ReporteAlumnosActivos.csv')
            })
        },
        downloadHomeBeltsCsv() {
            this.$store.dispatch("Doyang/show", `${this.doyang.id}?append=belts_home_csv`).then((response) => {
                this.downloadStringInNewPage(response?.belts_home_csv, 'ReporteGeneralCintasDireccionGeneral.csv')
            })
        },
        downloadBlackBelts() {
            this.$store.dispatch("Doyang/show", `${this.doyang.id}/black_belt_prospects_csv`).then((response) => {
                this.downloadStringInNewPage(response, 'black_belts.csv')
            })
        },
        downloadStringInNewPage(string, filename) {
            const bom = '\uFEFF';  // UTF-8 BOM
            const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
}
</script>

<style scoped>
h1 {
    color: blue;
}

p {
    font-size: 18px;
}
</style>