import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    assign({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/revision/${payload.id}/users/${payload.user_id}`, payload).then((res) => {
        commit('iassignndex', res.data)
        return res.data;
      })
    },
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/revision${payload}`).then((res) => {
        console.log('commit', commit)
        return res.data;
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/revision`, payload).then((res) => {
        commit('create', res.data)
        return res.data;
      })
    },
  },
  getters: {},
  modules: {}
}
