<template>
  <v-container>
    <v-row>
      <v-col />
      <v-col>
        <h2>¡Iniciar Sesión!</h2>
        <v-text-field label="E-mail" outlined v-model="user.email"></v-text-field>
        <v-text-field :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-outline'" v-model="user.password"
          label="Contraseña" :type="!passwordVisibility ? 'password' : 'text'"
          @click:append="() => passwordVisibility = !passwordVisibility" outlined />
        <v-btn v-if="!logginInLoading" @click="login" dark class="green lighten-2">Ingresar</v-btn>
        <v-progress-circular v-else :size="50" :width="5" color="green lighten-2" indeterminate />
        <v-alert v-if="errorMessage" class="error lighten-1 mt-5" dark>{{ errorMessage }}</v-alert>
      </v-col>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    if (this.authInfo?.user?.id) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    login() {
      this.logginInLoading = true;
      this.$store.dispatch('Auth/login', this.user).then().catch(() => {
        this.errorMessage = "Error al iniciar sesión"
        setTimeout(() => {
          this.errorMessage = null
        }, 1500);
        this.logginInLoading = false;
      }).then(() => {
        this.logginInLoading = false;
        this.$router.push({ name: 'Home' })
      })
    },
    parseInput(text, model, property) {
      this.$set(model, property, text.toUpperCase())
    }
  },
  computed: {
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    token() {
      return this.$store.getters['Auth/authInfo']
    }
  },
  data() {
    return {
      logginInLoading: false,
      passwordVisibility: null,
      errorMessage: null,
      user: {
        email: null,
        password: null
      }
    }
  }
}
</script>

<style></style>
