<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">AÑADIR OTRO REQUISITO DE EXAMEN</h2>
        <h2 v-else>EDITAR OTRO REQUISITO DE EXAMEN</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <CapsVTextField
            outlined
            v-model="otherExamRequisite.name"
            label="Nombre del requisito"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" dark @click="createotherExamRequisite">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateotherExamRequisite">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  mounted () {
    if (this.$route.params.id) {
      this.$store.dispatch('OtherExamRequisite/show', this.$route.params.id).then(res => {
        this.otherExamRequisite = res
        this.otherExamRequisite.checked = parseInt(this.otherExamRequisite.checked) != 0
      })
    }
  },
  components: {
  },
  methods: {
    createotherExamRequisite: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('OtherExamRequisite/create', this.otherExamRequisite).then(() => {
          this.$router.push({name: 'OtherExamRequisiteIndex'})
        }
      )
    },
    updateotherExamRequisite: function () {
      this.$store.dispatch('OtherExamRequisite/update', this.otherExamRequisite).then(() => {
          this.$router.push({name: 'OtherExamRequisiteIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
  },
  computed : {
  },
  data () {
    return {
      otherExamRequisite: {
        name: null,
      },
    }
  }
}
</script>

<style>

</style>