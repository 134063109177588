import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  insurance: {
    paginated: [],
    insurances: [],
    insurance: {}
  },
  mutations: {
    index(insurance, payload) {
      insurance.paginated = payload
      insurance.insurances = payload.data
    },
    show(insurance,payload) {
      insurance.insurance = payload
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/insurances${payload}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/insurances/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    printSheet({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/insurances/print${payload}`).then((response) => {
          console.log('commit', commit)
          return response.data
        }).catch(() => 0)
    },
    downloadRejectionDocument({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/insurances/${payload}/rejection_document`, {crossDomain: true, responseType: 'blob'}).then((response) => {
          console.log('commit', commit)
          const contentDisposition = response.headers['content-disposition'];
          const urlSegments = contentDisposition.split('.');
          const extension = urlSegments[urlSegments.length - 1];
          let blob = new Blob([response.data], { })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `documento_de_rechazo.${extension}`
          link.click()
          return true
        }).catch(() => 0)
    },
  },
  getters: {
    paginated: insurance => {
        return insurance.paginated
    },
    insurances: insurance => {
      return insurance.insurances
    },
    insurance: insurance => {
      return insurance.insurance
    }
  },
  modules: {
  }
}
