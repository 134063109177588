var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('a',[_vm._v(_vm._s(_vm.tournamentName)+":")])])]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.tournamentName)+" | "+_vm._s(_vm.doyang.name))]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-row',[_c('v-col',[_c('TournamentTotalsCard',{attrs:{"activeDetailsDoyang":_vm.doyangWithTraffic,"tournamentId":_vm.tournamentId,"minAcceptableExamEfficiency":1}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('span',[(_vm.tournament_record.count == undefined)?_c('span',[_vm._v("No hay torneos")]):_c('strong',[_vm._v("Tu record:")]),_vm._v(" "+_vm._s(_vm.tournament_record.count)+" ("+_vm._s(_vm.tournament_record.name)+" - "+_vm._s(_vm.tournament_record.date)+") ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_c('strong',[_vm._v("Ventas: ")]),_vm._v(_vm._s(_vm.tournament ? _vm.tournament['count'] : 0)+"/"+_vm._s(_vm.tournament ? _vm.tournament['goal'] : 0)+" (meta "),(_vm.canEditGoals && _vm.areGoalsModifiable)?_c('v-icon',{on:{"click":function($event){return _vm.establishGoal(_vm.renderDoyang.id, _vm.tournamentName, 'tournament')}}},[_vm._v("mdi-pencil")]):_vm._e(),_vm._v(") ")],1)])],1),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.downloadTournamentListCsv(_vm.tournament['id'], _vm.doyang.id)}}},[_vm._v("Descargar csv | ")]),_c('span',{staticStyle:{"margin-left":"6px","color":"blue","cursor":"pointer"},on:{"click":function($event){_vm.downloadTournamentListExcel(
                                (_vm.doyang.next_tournaments_totals && _vm.tournament ? _vm.tournament['id'] : null)
                                , _vm.doyang.id)}}},[_vm._v("Descargar excel | ")]),_c('span',{staticStyle:{"margin-left":"6px","color":"blue","cursor":"pointer"},on:{"click":function($event){_vm.downloadTournamentHotelBusListExcel(
                                (_vm.doyang.next_tournaments_totals && _vm.tournament ? _vm.tournament['id'] : null)
                                , _vm.doyang.id)}}},[_vm._v("Descargar excel de camión/hotel")])])],1)],1),(_vm.tournament && _vm.tournament.show_table_in_dashboard)?_c('v-data-table',{attrs:{"headers":[
                    { 'value': 'range', 'text': 'Rango' },
                    { 'value': 'percentage', 'text': 'Porciento' },
                    { 'value': 'price', 'text': 'Costo del evento' },
                    { 'value': 'utility', 'text': 'Utilidad' },
                    { 'value': 'minimum', 'text': 'Mínimo' },
                    { 'value': 'maximum', 'text': 'Máximo' },
                    { 'value': 'annual', 'text': 'Anual' },
                ],"hide-default-footer":"","items":_vm.doyang_surrogation_tournaments},scopedSlots:_vm._u([{key:"item.range",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.min_inscriptions)+"-"+_vm._s(item.max_inscriptions)+" ")])]}},{key:"item.percentage",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.percentage)+"% ")])]}},{key:"item.price",fn:function({}){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format(_vm.tournament['price'] || "$0"))+" ")])]}},{key:"item.utility",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.tournament['price'] || 0) * (item.percentage / 100)))+" ")])]}},{key:"item.minimum",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.tournament['price'] || 0) * item.min_inscriptions * (item.percentage / 100)))+" ")])]}},{key:"item.maximum",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.tournament['price'] || 0) * item.max_inscriptions * (item.percentage / 100)))+" ")])]}},{key:"item.annual",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.tournament['price'] || 0) * item.max_inscriptions * (item.percentage / 100) * 6))+" ")])]}}],null,false,4279872934)}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',[_vm._v("Close")])],1)],1)],1),_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm.tournament ? _vm.tournament['count'] : 0)+"/"+_vm._s(_vm.tournament ? _vm.tournament['goal'] : 0)+" | Requeridos "+_vm._s(_vm.tournament ? _vm.tournament['required'] : 0)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }