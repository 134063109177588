import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import './registerServiceWorker'
import CapsVTextField from './components/globals/CapsVTextField.vue'
import CapsVTextArea from './components/globals/CapsVTextArea.vue'
import DMYDateField from './components/globals/DMYDateField.vue'
import MYDateField from './components/globals/MYDateField.vue'
import draggable from 'vuedraggable'

// eslint-disable-next-line no-unused-vars
Vue.prototype.$_ = require('lodash')

Vue.component('MYDateField', MYDateField)
Vue.component('CapsVTextField', CapsVTextField)
Vue.component('CapsVTextArea', CapsVTextArea)
Vue.component('DMYDateField', DMYDateField)
Vue.component('draggable', draggable)
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
