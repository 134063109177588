import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangSurrogationEvents: [],
    doyangSurrogationEvent: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangSurrogationEvents = payload
    },
    show(state, payload) {
      state.doyangSurrogationEvent = payload
    },
    delete(state,payload) {
      state.doyangSurrogationEvents = state.doyangSurrogationEvents.filter((size) => size.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_events${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_events/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_surrogation_events`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_surrogation_events/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_surrogation_events/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    doyangSurrogationEvent: state => {
      return state.doyangSurrogationEvent
    },
    doyangSurrogationEvents: state => {
      return state.doyangSurrogationEvents
    }
  },
  modules: {
  }
}
