import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangSurrogationExams: [],
    doyangSurrogationExam: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangSurrogationExams = payload
    },
    show(state, payload) {
      state.doyangSurrogationExam = payload
    },
    delete(state,payload) {
      state.doyangSurrogationExams = state.doyangSurrogationExams.filter((size) => size.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_exams${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_exams/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_surrogation_exams`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_surrogation_exams/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_surrogation_exams/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    doyangSurrogationExam: state => {
      return state.doyangSurrogationExam
    },
    doyangSurrogationExams: state => {
      return state.doyangSurrogationExams
    }
  },
  modules: {
  }
}
