import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    monthly_dutys: [],
    monthly_duty: {},
    doyang_monthly_duties: []
  },
  mutations: {
    index(state, payload) {
      state.monthly_dutys = payload
      state.monthly_dutys.map( monthlyDuty => {
        monthlyDuty.checked = parseInt(monthlyDuty.checked) != 0
        return monthlyDuty
      })
    },
    update(state, payload) {
      state.monthly_dutys = state.monthly_dutys.map( monthlyDuty => {
        return monthlyDuty.id == payload.id ? payload : monthlyDuty
      })
    },
    show(state,payload) {
      state.monthly_duty = payload
      state.monthly_duty.checked = parseInt(state.monthly_duty.checked) != 0
    },
    delete(state,payload) {
      state.monthly_dutys = state.monthly_dutys.filter((duty) => duty.id != payload.id)
    },
    doyangMonthlyDuties(state,payload) {
      state.doyang_monthly_duties = payload
    }
  },
  actions: {
    index ({commit}, payload = false) {
      return axios.get(`${ENV.API_URL}/api/monthly_duties${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    doyangMonthlyDuties ({commit}, payload = false) {
      return axios.get(`${ENV.API_URL}/api/monthly_duties${payload || ''}`).then((res) => {
        commit('doyangMonthlyDuties', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/monthly_duties/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/monthly_duties`, payload).then((res) => {
        commit('update', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/monthly_duties/${payload.id}`, payload).then((res) => {
        commit('update', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/monthly_duties/${payload.id || payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    monthlyDutys: state => {
      return state.monthly_dutys
    },
    monthlyDuty: state => {
      return state.monthly_duty
    },
    doyangMonthlyDuties: state => {
      return state.doyang_monthly_duties
    }
  },
  modules: {
  }
}
