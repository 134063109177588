import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    generalFileResources: [],
    generalFileResource: {}
  },
  mutations: {
    index(state, payload) {
      state.generalFileResources = payload
    },
    show(state, payload) {
      state.generalFileResource = payload
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/general_file_resources${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data;
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/general_file_resources/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data;
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/general_file_resources`, payload, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        contentType: false,
        processData: false,
      }).then((res) => {
        commit('show', res.data)
        return res.data;
      })
    },
    update({ commit }, payload) {
        return axios.post(`${ENV.API_URL}/api/general_file_resources`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            contentType: false,
            processData: false,
          }).then((res) => {
            commit('show', res.data)
            return res.data;
          })
    },
    delete({ commit }, payload) {
        return axios.delete(`${ENV.API_URL}/api/general_file_resources/${payload}`).then((res) => {
            commit('show', res.data)
            return res.data;
        })
    },
    download({commit}, payload) {
        return axios.get(`${ENV.API_URL}/api/general_file_resources/${payload}/download`, {crossDomain: true, responseType: 'blob'}).then((response) => {
          console.log('commit', commit)
          const contentDisposition = response.headers['content-disposition'];
          const urlSegments = contentDisposition.split('.');
          const extension = urlSegments[urlSegments.length - 1];
          const removedSpecialFilesFromExtension = extension.replace('"', '').replace('_', '');
          let blob = new Blob([response.data], { })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          const filename = response.headers['content-disposition'].split(';')[1].split('filename')[1].split('=')[1].trim()
          link.download = `${filename}.${removedSpecialFilesFromExtension}`
          link.click()
          return true
        }).catch(() => 0)
    },
  },
  getters: {
    generalFileResources: state => {
      return state.generalFileResources
    },
    generalFileResource: state => {
      return state.generalFileResource
    }
  },
  modules: {}
}
