<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols=12>
        <h2 v-if="!$route.params.id">CREAR REQUISITOS DE CATEGORÍA DE DOYANG</h2>
        <h2 v-else>EDITAR REQUISITOS DE CATEGORÍA DE DOYANG</h2>
        </v-col>
        <v-col cols=12>
          <h3 v-if="!$route.params.id">PARA EL DOYANG "{{doyangCategory.name}}"</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=4>
          <CapsVTextField
            outlined
            v-model="doyangCategoryRequisite.name"
            :rules="notEmptyRule"
            label="Requisito"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createdoyangCategoryRequisite">Añadir</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updatedoyangCategoryRequisite">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
export default {
  props: {
    doyangCategory: {
      type: Object,
      default: () => {
        return {name: 'Ninguno'}
      }
    }
  },
  mounted () {
    this.$store.dispatch('DoyangCategoryRequisite/index');
    this.doyangCategoryRequisite.doyang_category_id = this.doyangCategory.id
    if (this.$route.params.id) {
      this.$store.dispatch('DoyangCategoryRequisite/show', this.$route.params.id).then(() => {
        this.doyangCategoryRequisite = this.$store.getters['DoyangCategoryRequisite/doyangCategoryRequisite']
        this.doyangCategoryRequisite.active = this.doyangCategoryRequisite.active != "0"
      });
    }
  },
  components: {
  },
  methods: {
    createdoyangCategoryRequisite () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('DoyangCategory/show', this.doyangCategory.id)
      this.$store.dispatch('DoyangCategoryRequisite/create', this.doyangCategoryRequisite).then(() => {
          this.$router.push({name: 'DoyangCategoryEdit', params: {id: this.doyangCategory?.id}})
        }
      )
    },
    updatedoyangCategoryRequisite () {
      console.log('DoyangCategoryRequisite/update')
      this.$store.dispatch('DoyangCategoryRequisite/update', this.doyangCategoryRequisite).then(() => {
          this.$router.push({name: 'DoyangCategoryEdit', params: {id: this.doyangCategory?.id}})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
    'state': function () {
      this.doyangCategoryRequisite.state_id = this.state.id
    }
  },
  data () {
    return {
      notEmptyRule: [
        v => v ? v.length > 0 : 'Este campo no puede quedar vacío'
      ],
      doyangCategoryRequisite: {
        name: null,
        doyang_category_id: null,
      },
    }
  }
}
</script>

<style>

</style>