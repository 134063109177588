<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>CAMBIAR CONTRASEÑA</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field outlined v-model="password.oldPassword" label="Contraseña vieja"/>
      </v-col>
      <v-col>
        <v-text-field outlined v-model="password.password" label="Contraseña nueva"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="green lighten-2" @click.stop="changePassword" dark>Cambiar contraseña</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods : {
    changePassword () {
      this.$store.dispatch('Auth/changePassword', this.password).
      catch(() => {
        alert('La contraseña no ha podido cambiarse, verifique su vieja contraseña.')
      }).
      then((changed) =>{
        if (changed) {
          alert('La contraseña se ha cambiado con exito')
          this.$router.push({name: 'Home'})
        } else {
          alert('La contraseña no ha podido cambiarse, verifique su vieja contraseña.')
        }
      })
    }
  },
  data () {
    return {
      password: {
        password: null,
        oldPassword: null,
      }
    }
  }
}
</script>

<style>

</style>