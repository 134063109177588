<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Otros requisitos de examen</h2>
          <CRUDIndex
            :permissionRoot="'Otros requisitos'"
            :headers="headers"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
            :items="items"
            :routeCreate="'OtherExamRequisiteCreate'"
            :routeIndex="'OtherExamRequisiteIndex'"
            :routeEdit="'OtherExamRequisiteEdit'"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted() {
    this.$store.dispatch('OtherExamRequisite/index')
  },
  computed: {
    items() {
      return this.$store.getters['OtherExamRequisite/otherExamRequisites']
    }
  },
  data () {
    return {
      payload: `?`,
      headers: [
        { text: 'Tarea', value: 'name' }
      ],
      itemsPerPage: 20,
      storeModel: 'OtherExamRequisite'
    }
  },
}
</script>

<style>

</style>
