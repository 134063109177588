<template>
  <v-container>
    <v-col>
      <h2 v-if="this.$route.params.id">EDITAR GRADO</h2>
      <h2 v-else>CREAR UN NUEVO GRADO</h2>
      <v-form
      ref="form"
      @submit.prevent="submit"
      >
      <CapsVTextField outlined v-model="degree.order" solo label="Orden*" required :rules="orderRules"></CapsVTextField>
      <CapsVTextField outlined v-model="degree.name" solo label="Nombre*" required :rules="nameRules"></CapsVTextField>
      <v-checkbox v-model="degree.has_partials" solo label="Hace parciales*" required></v-checkbox>
      <CapsVTextField outlined v-model="degree.partials_to_next_degree" solo label="Parciales para el siguiente grado"></CapsVTextField>
      <v-btn primary v-if="this.$route.params.id" @click="editDegree" dark class="yellow ligthten-2">Editar</v-btn>
      <v-btn primary v-else @click="createNewDegree" dark class="blue ligthten-2">Agregar</v-btn>
      </v-form>
      <v-alert
      class="mt-5"
      type="success"
      v-model="createdSuccess"
      >El registro se ha creado con exito</v-alert>
      <v-alert
      class="mt-5"
      type="error"
      v-model="submitError"
      >Hubo un error al guardar los datos, intentalo mas tarde</v-alert>
      <v-alert
      class="mt-5"
      type="success"
      v-model="edittedSuccess"
      >El registro se ha editado con exito</v-alert>
    </v-col>
  </v-container>
</template>

<script>
export default {
  mounted() {
    if (this.$route.params.id) {
      this.$store.dispatch('Degree/show', this.$route.params.id)
    }
  },
  methods:{
    createNewDegree: function () {
      if (this.$refs.form.validate() !== true){
        return;
      }
      this.$store.dispatch('Degree/create', this.degree).then(() => {
        this.toggleCreatedSuccess()
        alert('Tu registro se ha creado')
        this.$router.push({name: 'DegreeIndex'})
      })
    },
    editDegree: function () {
      this.$store.dispatch('Degree/update', this.degree).then(() => {
        alert('Tu registro se ha editado')
        this.$router.push({name: 'DegreeIndex'})
      })
    },
    toggleCreatedSuccess: function(){
        if (this.createdSuccess === false) {
          this.createdSuccess = true,
          setTimeout(this.toggleCreatedSuccess, 2000)
          }
        else {
          this.createdSuccess = false
          }
      },
      toggleSubmitError: function(){
        if (this.submitError === false) {
          this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
          }
        else {
          this.submitError = false
          }
      },
      toggleEdittedSuccess: function(){
        if (this.edittedSuccess === false) {
          this.edittedSuccess = true,
          setTimeout(this.toggleEdittedSuccess, 2000)
          }
        else {
          this.edittedSuccess = false
          }
      },
  },
  computed: {
    defaultDegree () {
      return this.$store.getters['Degree/degree']
    }
  },
  watch: {
    defaultDegree () {
      this.degree = this.defaultDegree
      this.degree.has_partials = this.defaultDegree.has_partials != "0"
    }
  },
  data () {
    return {
      nameRules:[
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Tiene que ser menos de 100 caracteres',
      ],
      orderRules:[
        v => !!v || 'El campo no puede estar vacio',
        v => (v > 0 ) || 'El numero de orden debe ser mayor a 0',
        v => (v || '').indexOf('.') < 0 || 'El numero debe ser un entero'
      ],
      createdSuccess:false,
      submitError:false,
      edittedSuccess:false,
      degree: {
        name:'',
        order:'',
        partials_to_next_degree: 0
      }
    }
  }
}
</script>

<style>

</style>
