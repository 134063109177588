<template>
    <v-col class="node-container">
        <div class="node">
            <div class="node-content">{{ node.full_name }}</div>
            <div class="line" v-if="node.children && node.children.length"></div>
        </div>
        <v-row v-if="node.children && node.children.length" class="children-row" no-gutters>
            <genealogy-node v-for="child in node.children" :key="child.id" :node="child"></genealogy-node>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'GenealogyNode',
    props: {
        node: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
.node-container {
    position: relative;
    text-align: center;
}

.node {
    position: relative;
}

.node-content {
    background-color: #fff;
    border: 1px solid #000;
    padding: 5px 10px;
    display: inline-block;
    z-index: 1;
    /* Ensure the content is above the line */
}

.line {
    position: absolute;
    left: 50%;
    top: 100%;
    /* Position line just below the node */
    height: 20px;
    /* Length of the vertical line */
    width: 2px;
    /* Width of the vertical line */
    background-color: #000;
    transform: translateX(-50%);
    /* Center the line horizontally */
    z-index: 0;
    /* Ensure the line is below the content */
}

.children-row {
    margin-top: 20px;
    /* Space between parent and children */
    justify-content: center;
}
</style>
