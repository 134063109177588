import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    approve_student_revision (_, payload) {
      return axios.post(`${ENV.API_URL}/api/programmers_help/approve_revision`, payload).then((res) => {
        return res.data
      })
    },
  },
  getters: {
  },
  modules: {
  }
}
