<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>ALUMNOS MDK</h2>
        <v-select v-if="isDireccionGeneral" v-model="filterDoyangId" :items="doyangs" item-value="id" item-text="name"
          outlined></v-select>
        <v-checkbox label="Solo alumnos activos" v-model="activeStudentsOnly"></v-checkbox>
        <v-checkbox label="Deudores" v-model="debtsOnly"></v-checkbox>
        <v-autocomplete label="Grado" solo v-model="searchDegree" :items="degrees" item-text="name" return-object />
        <v-btn @click="downloadStudentCsv" class="blue lighten-2" dark>Descargar CSV de alumnos</v-btn>
        <CRUDIndex :permissionRoot="'Alumnos,Doyang Alumnos'" :showWhatsApp="true" :headers="headers" :items=students
          :storeModel="storeModel" :itemsPerPage="itemsPerPage" :searchPlaceholder="'Nombre del alumno'"
          :apiPagination="true" :totalItems="totalItems" :runModelRequest="false"
          @pageUpdated="(num) => { this.pageNum = num; sendPaginationOptions() }"
          @sortedBy="(column) => { this.sortBy = column; sendPaginationOptions() }"
          @sortedDesc="(boolean) => { this.sortDesc = boolean; sendPaginationOptions() }" @apiSearch="apiSearch"
          :loading=loading />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CRUDIndex from '../globals/CRUDIndex'
export default {
  mounted() {
    this.authInfo = this.$store.getters['Auth/authInfo']
    const payload = this.payload + (`${this.selectedDoyang.id === ENV.DIRECCIÓN_GENERAL_ID || this.generalDirection ? '' : '&doyang_id=' + this.selectedDoyang.id}`)
    if (this.selectedDoyang.id === ENV.DIRECCIÓN_GENERAL_ID || this.generalDirection || ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id)) {
      const doyangHeader = { text: 'Doyang', value: 'doyang.name' }
      // push in the second to last positions
      this.headers.splice(this.headers.length - 1, 0, doyangHeader)
    }
    this.$store.dispatch('Degree/index')
    this.$store.dispatch('Doyang/index')
    const savedFilters = this.$store.getters['Auth/filters']
    if (savedFilters?.students) {
      this.loading = true
      this.activeStudentsOnly = savedFilters.students.activeStudentsOnly
      this.searchText = savedFilters.students.searchText
      this.pageNum = savedFilters.students.pageNum || 1
      this.filterDoyangId = savedFilters.students.filterDoyangId
      this.debtsOnly = savedFilters.students.debtsOnly
      setTimeout(() => {
        this.loading = false
        this.sendPaginationOptions(false)
      }, 500)
    } else {
      this.$store.dispatch('Student/index', `${payload}&paginate=1&active=1&orderBy[degree_id]=asc`).then(() => this.loading = false)
    }
    if (!localStorage.getItem('selectedDoyang') && !(this.authInfo && this.authInfo.user && ENV.permissions.can_see_general_direction.includes(this.authInfo.user.role_id))) {
      this.$router.push({ name: 'Home' })
    }
  },
  props: {
    doyangId: Number,
    generalDirection: Boolean
  },
  components: {
    CRUDIndex
  },
  computed: {
    degrees() {
      return this.$store.getters['Degree/degrees']
    },
    students() {
      return this.$store.getters['Student/students']
    },
    totalItems() {
      return this.$store.getters['Student/totalItems'] || 0
    },
    doyangs() {
      if (this.$store.getters['Doyang/doyangs'].length > 0) {
        const doyangs = JSON.parse(JSON.stringify(this.$store.getters['Doyang/doyangs']))
        const exampleDoyang = JSON.parse(JSON.stringify(doyangs[0]))
        exampleDoyang.id = 0
        exampleDoyang.name = 'TODOS LOS DOYANGS'
        doyangs.unshift(exampleDoyang)
        return doyangs
      }
      return this.$store.getters['Doyang/doyangs']
    },
    doyangIdPayload() {
      return localStorage.getItem('selectedDoyang') ? JSON.parse(localStorage.getItem('selectedDoyang')) : ''
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang'];
    },
    isDireccionGeneral() {
      return this.selectedDoyang.id == ENV.DIRECCIÓN_GENERAL_ID;
    }
  },
  watch: {
    searchDegree() {
      this.sendPaginationOptions()
    },
    generalDirection() {
      this.authInfo = this.$store.getters['Auth/authInfo']
      const payload = this.payload + (`${this.selectedDoyang.id === ENV.DIRECCIÓN_GENERAL_ID || this.generalDirection ? '' : '&doyang_id=' + this.selectedDoyang.id}`)
      this.$store.dispatch('Student/index', `${payload}&paginate=1&active=1&orderBy[degree_id]=asc`).then(() => this.loading = false)
    },
    activeStudentsOnly() {
      this.sendPaginationOptions()
    },
    debtsOnly() {
      if (this.debtsOnly) {
        this.activeStudentsOnly = true
      }
      this.sendPaginationOptions()
    },
    filterDoyangId() {
      this.sendPaginationOptions()
    }
  },
  methods: {
    downloadStudentCsv() {
      this.$store.dispatch('Doyang/show', `${this.selectedDoyang.id}/students_csv`).then((data) => {
        this.downloadStringInBlankPage(data)
      })
    },
    downloadStringInBlankPage(string, filename = 'doyang_list.csv') {
      const bom = '\uFEFF';  // UTF-8 BOM
      const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    apiSearch(text) {
      this.searchText = text
      this.sendPaginationOptions()
    },
    sendPaginationOptions(saveFilters = true) {
      if (this.loading) return
      const payload = this.payload + (`${this.selectedDoyang.id === ENV.DIRECCIÓN_GENERAL_ID || this.generalDirection ? '' : '&doyang_id=' + this.selectedDoyang.id}`) + (this.filterDoyangId ? "&doyang_id=" + this.filterDoyangId : "") + `&debtsOnly=${this.debtsOnly ? 1 : 0}`
      this.loading = true
      const stringFilter = `${payload}&orderBy[degree_id]=asc&active=${this.activeStudentsOnly ? 1 : 0}${this.searchText ? "&name=" + this.searchText : ""}&paginate=1&page=${this.pageNum}` +
        `${this.sortBy ? "&orderBy[" + this.sortBy + "]=" + (this.sortDesc ? "desc" : "asc") : ""}${this.searchDegree?.id ? "&degree_id=" + this.searchDegree.id : ""}`
      this.$store.dispatch('Student/index', stringFilter).then(() => this.loading = false)
      if (saveFilters) {
        this.$store.dispatch('Auth/addFilter', {
          filter: 'students',
          value: {
            activeStudentsOnly: this.activeStudentsOnly || 1,
            searchText: this.searchText || "",
            pageNum: this.pageNum || 1,
            filterDoyangId: this.filterDoyangId || null,
            debtsOnly: this.debtsOnly || 0
          }
        })
      }
    },
  },
  data() {
    return {
      searchDegree: {},
      debtsOnly: false,
      searchText: "",
      loading: true,
      pageNum: 1,
      sortBy: 1,
      sortDesc: 1,
      activeStudentsOnly: true,
      filterDoyangId: 0,
      ENV,
      search: '',
      payload: `?with=degree,doyang`,
      headers: [
        { text: 'Nombre Completo', value: 'full_name' },
        { text: 'Grado', value: 'degree.name' },
        { text: 'Fecha de nacimiento', value: 'parsed_birth_date' },
      ],
      storeModel: 'Student',
      itemsPerPage: 20
    }
  },
}
</script>

<style></style>
