<template>
  <v-container>
    <v-row>
      <v-col col=12>
      <h2 v-if="!$route.params.id">CREAR EXAMEN</h2>
      <h2 v-else>EDITAR EXAMEN</h2>
      <CRUDEventProcessCreate
        :storeModel="storeModel"
        :storeModelGroup="storeModelGroup"
        :defaultDate="defaultDate"
      />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDEventProcessCreate from '../globals/CRUDEventProcessCreate'
export default {
  mounted() {
    if (!this.$route.params.id) {
        this.$store.dispatch('Configuration/show', 2).then((res) => {
            this.defaultDate = res.value
        })
    }
  },
  components: {
    CRUDEventProcessCreate
  },
  data () {
    return {
      defaultDate: null,
      storeModel: 'Exam',
      storeModelGroup: 'ExamGroup',
    }
  }
}
</script>

<style>

</style>
