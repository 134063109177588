<template>
  <v-container>
    <v-row>
      <v-col col=12>
      <h2 v-if="!$route.params.id">CREAR EVENTO</h2>
      <h2 v-else>EDITAR EVENTO</h2>
      <CRUDEventProcessCreate
        :earlyPaymentAmountField="'early_payment_amount'"
        :eventType="'event'"
        :showFieldSinodal="false"
        :storeModel="storeModel"
        :storeModelGroup="storeModelGroup"
        :component="component"
      />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDEventProcessCreate from '../globals/CRUDEventProcessCreate'
export default {
  components: {
    CRUDEventProcessCreate
  },
  data () {
    return {
      storeModel: 'Event',
      storeModelGroup: 'EventGroup',
      component: 'EventGroupCreate'
    }
  }
}
</script>

<style>

</style>
