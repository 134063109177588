import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  mutations: {
    loading(state, isLoading) {
      state.loading = isLoading
    },
  },
  actions: {
      loading({commit}, payload) {
          commit('loading', payload)
      }
  },
  getters: {
    loading: state => {
      return state.loading
    },
  },
  modules: {
  }
}
