<template>
    <v-container>
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR CATEGORÍA DE TORNEO</h2>
        <h2 v-else>EDITAR CATEGORÍA DE TORNEO</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
        <caps-v-text-field
            outlined
            v-model="tournament_category.name"
            label="Nombre de la categoría"
            />
        </v-col>
        <v-col>
            <v-autocomplete
                chips
                v-model="tournament_category.min_degree_id"
                :items="degrees"
                :search-input.sync="search"
                outlined
                color="white"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Grado Mínimo"
                placeholder="Grado Mínimo"
            ></v-autocomplete>
        </v-col>
        <v-col>
            <v-autocomplete
                chips
                v-model="tournament_category.max_degree_id"
                :items="degrees"
                :search-input.sync="search"
                outlined
                color="white"
                hide-no-data
                hide-selected
                item-text="name"
                item-value="id"
                label="Grado Máximo"
                placeholder="Grado Máximo"
            ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <caps-v-text-field
                outlined
                type="number"
                v-model="tournament_category.min_age_years"
                label="Edad Mínima (años)"
                />
        </v-col>
        <v-col>
            <caps-v-text-field
                outlined
                type="number"
                v-model="tournament_category.max_age_years"
                label="Edad Máxima (años)"
                />
        </v-col>
        <v-col>
            <caps-v-text-field
                outlined
                type="number"
                v-model="tournament_category.min_weight_kg"
                label="Peso Mínimo (kg)"
                />
        </v-col>
        <v-col>
            <caps-v-text-field
                outlined
                type="number"
                v-model="tournament_category.max_weight_kg"
                label="Peso Máximo (kg)"
                />
        </v-col>
        <v-col>
            <v-radio-group v-model="tournament_category.is_male" row>
                <v-radio label="Varonil" :value="true"></v-radio>
                <v-radio label="Femenil" :value="false"></v-radio>
            </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-btn
                color="primary"
                @click="save"
            >
                Guardar
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  export default {
    components: {},
    methods: {
        save () {
            if (this.$route.params.id) {
                this.$store.dispatch('TournamentCategory/update', {
                    id: this.$route.params.id,
                    tournament_category: this.tournament_category
                }).then(() => {
                    this.$router.push({ name: 'TournamentCategoryIndex' })
                })
            } else {
                this.$store.dispatch('TournamentCategory/create', this.tournament_category).then(() => {
                    this.$router.push({ name: 'TournamentCategoryIndex' })
                })
            }
        }
    },
    mounted: function () {
        this.$store.dispatch('Degree/index')
        if (this.$route.params.id) {
            this.$store.dispatch('TournamentCategory/show', this.$route.params.id).then(res => {
                this.tournament_category = res
                this.tournament_category.is_male = res.is_male != 0
            })
        }
    },
    computed: {
        degrees: function () {
            return this.$store.getters['Degree/degrees']
        },
    },
    data () {
      return {
        search: null,
        tournament_category: {
          name: null,
          min_degree_id: null,
          max_degree_id: null,
          min_age_years: null,
          max_age_years: null,
          min_weight_kg: null,
          max_weight_kg: null,
          is_male: true,
        },
      }
    }
  }
  </script>

  <style>

  </style>
