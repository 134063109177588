import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    roles: [],
    role: {}
  },
  mutations: {
    index(state, payload) {
      state.roles = payload
    },
    show(state, payload) {
      state.role = payload
    }
  },
  actions: {
    index({ commit }) {
      return axios.get(`${ENV.API_URL}/api/roles`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/roles/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
      axios.post(`${ENV.API_URL}/api/roles`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    uptade({ commit }, payload) {
      axios.put(`${ENV.API_URL}/api/roles/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    roles: state => {
      return state.roles
    },
    role: state => {
      return state.role
    }
  },
  modules: {
  }
}
