<template>
    <div>
        <span v-if="!isLoading">
            <a @click.stop="downloadBimesterDisenrollsCsv(doyang.id)">Bajas: </a>
            <span>{{ doyang.risk_students_count || 0 }}</span>
        </span>
        <!--Spinning wheel-->
        <span v-else>
            Cargando bajas...
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            message: "This is the Active Students Text component.",
            isLoading: false,
        }
    },
    props: {
        doyang: {
            type: Object,
            required: true
        }
    },
    methods: {
        downloadBimesterDisenrollsCsv(doyangId) {
            this.isLoading = true
            this.$store.dispatch('Doyang/show', `${doyangId}/bimester_disenrolls_csv`).then((res) => {
                this.downloadStringInNewPage(res, 'inscriptions.csv')
            }).then(() => {
                this.isLoading = false
            })
        },
        downloadStringInNewPage(string, filename) {
            const blob = new Blob([string], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    }
};
</script>

<style scoped>
h1 {
    color: blue;
}

p {
    font-size: 18px;
}
</style>