import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    multilevel_discount_types: [],
    multilevel_discount_type: {}
  },
  mutations: {
    index(state, payload) {
      state.multilevel_discount_types = payload
    },
    update(state, payload) {
      state.multilevel_discount_types = state.multilevel_discount_types.map( multilevelDiscountType => {
        return multilevelDiscountType.id == payload.id ? payload : multilevelDiscountType
      })
    },
    show(state,payload) {
      state.multilevel_discount_type = payload
      state.multilevel_discount_type.checked = parseInt(state.multilevel_discount_type.checked) != 0
    },
    delete(state,payload) {
      state.multilevel_discount_types = state.multilevel_discount_types.filter((duty) => duty.id != payload.id)
    }
  },
  actions: {
    index ({commit}, payload = false) {
      return axios.get(`${ENV.API_URL}/api/multilevel_discount_types${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/multilevel_discount_types/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/multilevel_discount_types`, payload).then((res) => {
        commit('update', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/multilevel_discount_types/${payload.id}`, payload).then((res) => {
        commit('update', res.data)
        return res
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/multilevel_discount_types/${payload.id || payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    multilevelDiscountTypes: state => {
      return state.multilevel_discount_types
    },
    multilevelDiscountType: state => {
      return state.multilevel_discount_type
    }
  },
  modules: {
  }
}
