<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>Tareas Bimestrales</h2>
          <CRUDIndex
            :permissionRoot="'Tareas bimestrales,Doyang Tareas Bimestrales'"
            :headers="headers"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
            :items="items"
            :payload="this.isGeneralDirection ? '' : `?doyang_id=${this.selectedDoyang.id}`"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
import ENV from '../../../env'
export default {
  components: {
    CRUDIndex
  },
  mounted() {
    let payload = this.isGeneralDirection ? '' : `?doyang_id=${this.selectedDoyang.id}`
    this.$store.dispatch('MonthlyDuty/index', payload)
  },
  computed: {
    isGeneralDirection() {
      return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID)
    },
    items() {
      return this.$store.getters['MonthlyDuty/monthlyDutys'].map (duty => {
        duty.checked = duty.checked ? 'Sí' : ''
        return duty
      })
    },
    selectedDoyang () {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  data () {
    return {
      ENV,
      payload: `?`,
      headers: [
        { text: 'Tarea', value: 'name' },
      ],
      storeModel: 'MonthlyDuty',
      storeModelPlural: 'MonthlyDutys',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
