<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <h2 v-if="!this.$route.params['id']">CREAR NUEVA TALLA</h2>
        <h2 v-else>EDITAR TALLA</h2>
        <h2>{{product_variant_create.name ? product_variant_create.name : productVariant.name}}</h2>
        <v-form
        ref="form"
        >
        <CapsVTextField v-model="productSize.name" outlined label="Nombre*" :rules="nameRules"/>
        <CapsVTextField type="number" v-model="productSize.prize" outlined label="Precio"/>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-btn v-if="!this.$route.params['id']" primary @click="createProductSize" class="blue ligthten-2" dark>Agregar</v-btn>
          <v-btn v-else primary @click="editProductSize" class="yellow ligthten-2">Editar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    product_variant_create: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    if(this.$route.params['id']) {
      console.log(this.$route.params['id'])
      this.$store.dispatch('ProductSize/show', `${this.$route.params['id']}?with=product_variant`).then((data) => {
        this.productSize = data
        this.productVariant = data.product_variant
        this.productSize.product_variant_id = data.product_variant.id
        this.productSize.product_id = data.product_variant.product_id
      })
    } else {
      this.productSize.product_variant_id = this.product_variant_create.id
      this.productSize.product_id = this.product_variant_create.product_id
    }
  },
  methods: {
    createProductSize: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('ProductSize/create', this.productSize)
        this.$router.push({name: 'ProductVariantEdit', params: {id: this.product_variant_create.id}})
      }
    },
    editProductSize: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('ProductSize/update', this.productSize)
        this.$router.push({name: 'ProductVariantEdit', params: {id: this.productVariant.id}})
      }
    },
  },
  computed: {
  },
  data () {
    return {
      productVariant: {},
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      productSize: {
        name:'',
        prize: '',
      }
    }
  }
}
</script>

<style>

</style>