import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    event_comissions: [],
    event_comission: {}
  },
  mutations: {
    index(state, payload) {
      state.event_comissions = payload
    },
    show(state, payload) {
      state.event_comission = payload
    },
    add(state, payload) {
      state.event_comissions.append(payload)
    },
    delete(state, payload) {
        state.event_comissions = state.event_comissions.filter(comission => comission.id != payload.id);
    },
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/event_comissions${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data;
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/event_comissions/${payload}`).then((res) => {
        commit('show', res.data)
        commit('add', res.data)
        return res.data;
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/event_comissions`, payload).then((res) => {
        commit('show', res.data)
        return res.data;
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/event_comissions/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data;
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/event_comissions/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data;
      })
    }
  },
  getters: {
    event_comissions: state => {
      return state.event_comissions
    },
    event_comission: state => {
      return state.event_comission
    }
  },
  modules: {}
}
