<template>
  <v-container>
    <v-row>
      <v-col><h2> {{doyangGroup.name}} </h2></v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-card v-for="student in doyangGroup.students" :key="student.id" class="mt-2 float-left"> 
        <v-card-title auto>
        {{student.name}}
      </v-card-title>
      <v-btn small class="green lighten2">
        {{"Asistencia"}}
      </v-btn>
      <v-btn small class="orange lighten2">
        {{"Retardo"}}
      </v-btn>
      </v-card>
    </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("DoyangGroup/show", `${this.$route.params.id}`);
    this.$store.dispatch("DoyangGroup/show", `${this.$route.params.id}?with=students`)
  },
  data() {
    return {}
  },
  computed: {
    doyangGroup() {
      return this.$store.getters["DoyangGroup/doyangGroup"];
    },
    takeAttendance(isLate = false){
      if (isLate == false) {
        alert("Asistencia tomada con éxito")
        return isLate
      } else{ 
        alert("Retardo tomado con éxito")
        return isLate
      }
    }
  }
}
</script>

<style>

</style>

