<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="!this.$route.params.id">NUEVO DESCUENTO MULTINIVEL</h2>
        <h2 v-else>EDITAR DESCUENTO MULTINIVEL</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 md=12>
        <caps-v-text-field v-model="doyangSurrogation.name" outlined label="Nombre" type="text"></caps-v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class= "orange lighten-2 white--text" @click="editNewDoyangSurrogation" v-if="this.$route.params.id">Editar</v-btn>
        <v-btn primary @click="createNewDoyangSurrogation" v-else class="blue ligthten-2" dark>Agregar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>DESCUENTOS</h3>
        <v-data-table :items="doyangSurrogation.parsed_multilevel_discount_types" :headers="[
          {text: 'Artículo', value: 'name'},
          {text: 'Porcentaje', value: 'percentage'},
          {text: 'Acciones', value: 'actions'},
        ]">
      <template v-slot:item.actions="{ item }">
          <div>
            <v-icon @click="edittingDoyangSurrogation = item; editPercentageDialog = true;">mdi-pencil</v-icon>
          </div>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Eventos</h3>
        <v-row>
            <v-col><v-text-field v-model="minInscEvents" outlined label="Min. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="maxInscEvents" outlined label="Max. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="percentageEvents" outlined label="%" prepend-icon="mdi-clock-time-four-outline"></v-text-field></v-col>
            <v-col><v-btn @click="saveEvent('Events')" dark class="blue lighten-2">Añadir</v-btn></v-col>
        </v-row>
        <v-row>
            <v-col v-if="doyangSurrogation && doyangSurrogation.doyang_surrogation_events">
                <ul>
                    <li v-for="event in doyangSurrogation.doyang_surrogation_events.filter(seminar => !seminar.is_seminar)" :key="event.id">
                        {{event.min_inscriptions}}-{{event.max_inscriptions}} {{event.percentage}}%
                        <v-icon @click="deleteEvent('Events', event.id)">mdi-delete</v-icon>
                    </li>
                </ul>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Torneos</h3>
        <v-row>
            <v-col><v-text-field v-model="minInscTournaments" outlined label="Min. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="maxInscTournaments" outlined label="Max. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="percentageTournaments" outlined label="%" prepend-icon="mdi-clock-time-four-outline"></v-text-field></v-col>
            <v-col><v-btn @click="saveEvent('Tournaments')" dark class="blue lighten-2">Añadir</v-btn></v-col>
        </v-row>
        <v-row>
            <v-col>
                <ul>
                    <li v-for="tournament in doyangSurrogation.doyang_surrogation_tournaments" :key="tournament.id">
                        {{tournament.min_inscriptions}}-{{tournament.max_inscriptions}} {{tournament.percentage}}%
                        <v-icon @click="deleteEvent('Tournaments', tournament.id)">mdi-delete</v-icon>
                    </li>
                </ul>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Seminarios</h3>
        <v-row>
            <v-col><v-text-field v-model="minInscSeminars" outlined label="Min. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="maxInscSeminars" outlined label="Max. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="percentageSeminars" outlined label="%" prepend-icon="mdi-clock-time-four-outline"></v-text-field></v-col>
            <v-col><v-btn @click="saveEvent('Events', true)" dark class="blue lighten-2">Añadir</v-btn></v-col>
        </v-row>
        <v-row>
            <v-col>
                <ul v-if="doyangSurrogation && doyangSurrogation.doyang_surrogation_events">
                    <li v-for="seminar in doyangSurrogation.doyang_surrogation_events.filter(seminar => seminar.is_seminar)" :key="seminar.id">
                        {{seminar.min_inscriptions}}-{{seminar.max_inscriptions}} {{seminar.percentage}}%
                        <v-icon @click="deleteEvent('Events', seminar.id)">mdi-delete</v-icon>
                    </li>
                </ul>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Exámenes</h3>
        <v-row>
            <v-col><v-text-field v-model="minInscExams" outlined label="Min. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="maxInscExams" outlined label="Max. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="percentageExams" outlined label="%" prepend-icon="mdi-clock-time-four-outline"></v-text-field></v-col>
            <v-col><v-btn @click="saveEvent('Exams')" dark class="blue lighten-2">Añadir</v-btn></v-col>
        </v-row>
        <v-row>
            <v-col>
                <ul>
                    <li v-for="exam in doyangSurrogation.doyang_surrogation_exams" :key="exam.id">
                        {{exam.min_inscriptions}}-{{exam.max_inscriptions}} {{exam.percentage}}%
                        <v-icon @click="deleteEvent('Exams', exam.id)">mdi-delete</v-icon>
                    </li>
                </ul>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Camión</h3>
        <v-row>
            <v-col><v-text-field v-model="minInscBus" outlined label="Min. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="maxInscBus" outlined label="Max. Inscripciones"></v-text-field></v-col>
            <v-col><v-text-field v-model="percentageBus" outlined label="%" prepend-icon="mdi-clock-time-four-outline"></v-text-field></v-col>
            <v-col><v-btn @click="saveEvent('Buss')" dark class="blue lighten-2">Añadir</v-btn></v-col>
        </v-row>
        <v-row>
            <v-col v-if="doyangSurrogation.doyang_surrogation_bus">
                <ul>
                    <li v-for="soldBus in doyangSurrogation.doyang_surrogation_bus" :key="soldBus.id">
                        {{soldBus.min_inscriptions}}-{{soldBus.max_inscriptions}} {{soldBus.percentage}}%
                        <v-icon @click="deleteEvent('Bus', soldBus.id)">mdi-delete</v-icon>
                    </li>
                </ul>
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="editPercentageDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">EDITAR PORCENTAJE</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="edittingDoyangSurrogation.percentage"
                  :suffix="'%'"
                  label="Porcentaje"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="editPercentageDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="green lighten-2"
            dark
            text
            @click="savePercentage"
          >
            Editar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  mounted () {
    if (this.$route.params.id) {
      this.$store.dispatch('DoyangSurrogation/show', `${this.$route.params.id}?append=parsed_multilevel_discount_types&with=doyang_surrogation_events,doyang_surrogation_tournaments,doyang_surrogation_exams,doyang_surrogation_bus`).then( (doyangSurrogation) => {
        this.doyangSurrogation = doyangSurrogation
      })
      this.$store.dispatch('MultilevelDiscountType/index')
    }
  },
  methods:{
    savePercentage() {
      let model = {
        id: this.$route.params.id,
        multilevelDiscountType: this.edittingDoyangSurrogation
      }
      this.$store.dispatch('DoyangSurrogation/update', model).then( () => {
        this.editPercentageDialog = false
      })
    },
    saveEvent(type, isSeminar = false) {
      if (type == 'Buss') type = 'Bus';
      let model = {
        doyang_surrogation_id: this.$route.params.id,
        min_inscriptions: isSeminar? this[`minInscSeminars`] : this[`minInsc${type}`],
        max_inscriptions: isSeminar? this[`maxInscSeminars`] : this[`maxInsc${type}`],
        percentage: isSeminar? this[`percentageSeminars`] : this[`percentage${type}`],
        is_seminar: isSeminar
      }
      if (type == 'Bus') type = 'Buss';
      this.$store.dispatch(`DoyangSurrogation${type.slice(0, -1)}/create`, model).then( (res) => {
          if (type == 'Buss') type = 'Bus';
          this.doyangSurrogation[`doyang_surrogation_${isSeminar ? 'events' : type.toLowerCase()}`].push(res)
      })
    },
    deleteEvent(type, id) {
      if (type == 'Bus') type = 'Buss';
      console.log(`DoyangSurrogation${type.slice(0, -1)}/delete`)
      this.$store.dispatch(`DoyangSurrogation${type.slice(0, -1)}/delete`, id).then( (res) => {
          if (type == 'Buss') type = 'Bus';
          this.doyangSurrogation[`doyang_surrogation_${type.toLowerCase()}`] = this.doyangSurrogation[`doyang_surrogation_${type.toLowerCase()}`].filter( surrogation => res.id != surrogation.id)
      })
    },
    editNewDoyangSurrogation: function () {
      this.$store.dispatch('DoyangSurrogation/update', this.doyangSurrogation).then(() => {
        alert('Se ha modificado el registro con éxito')
        this.$router.push({name: 'DoyangSurrogationIndex'})
      }).catch(() => {
        alert('Hubo un error al modificar tu registro')
      })
    },
    createNewDoyangSurrogation: function () {
      this.$store.dispatch('DoyangSurrogation/create', this.doyangSurrogation).then(() => {
        alert('Se ha creado el registro con éxito')
        this.$router.push({name: 'DoyangSurrogationIndex'})
      }).catch(() => {
        alert('Hubo un error al crear tu registro')
      })
    },
  },
  computed: {
    defaultDoyangSurrogation () {
      return this.$store.getters['DoyangSurrogation/doyangSurrogations']
    },
    multilevelDiscountTypes () {
      return this.$store.getters['MultilevelDiscountType/multilevelDiscountTypes']
    }
  },
  watch : {
    defaultDoyangSurrogation () {
      if (this.$route.params.id){
        this.doyangSurrogation = this.defaultDoyangSurrogation
      }
    },
  },
  data () {
    return {
      doyangSurrogation: {
        parsed_multilevel_discount_types: []
      },
      editPercentageDialog: false,
      edittingDoyangSurrogation: {},
      minInscSeminars: null,
      maxInscSeminars: null,
      percentageSeminars: null,
      minInscEvents: null,
      maxInscEvents: null,
      percentageEvents: null,
      minInscTournaments: null,
      maxInscTournaments: null,
      percentageTournaments: null,
      minInscExams: null,
      maxInscExams: null,
      minInscBus: null,
      maxInscBus: null,
      percentageBus: null,
      percentageExams: null,
  }
}
}
</script>

<style>

</style>
