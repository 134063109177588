<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>USUARIOS</h2>
          <h2 v-if="selectedDoyang">{{selectedDoyang.name}}</h2>
          <v-checkbox label="Activos" v-model="filterActive"></v-checkbox>
          <CRUDIndex
            :permissionRoot="'Doyang Personal'"
            :showWhatsApp="true"
            :headers="headers"
            :items="selectedDoyangStudents"
            :payload="payload"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
            :isCanDelete="false"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted() {
    this.$store.dispatch('Student/index', this.payload);
  },
  computed : {
    selectedDoyangStudents () {
      return this.$store.getters['Student/students'] ? this.$store.getters['Student/students'].filter((professor) => !this.filterActive || (professor.active != "0") ) : null;
    },
    selectedDoyang () {
      return this.$store.getters['Auth/selectedDoyang'];
    }
  },
  data () {
    return {
      filterActive: true,
      payload: `?role_id=${ENV.ROLE_PUBLIC_RELATIONS},${ENV.ROLE_LINK_AND_ORIENTATION},${ENV.ROLE_DEPENDANT_KYOSA_ID},${ENV.ROLE_CLUB_KYOSA_ID},${ENV.ROLE_DOYANG_KYOSA_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}&with=degree,role`,
      headers: [
        { text: 'Nombre Completo', value: 'full_name_honorific' },
        { text: 'Grado', value: 'degree.name' },
        { text: 'Rol', value: 'role.name' },
        { text: 'Activo', value: 'active' },
      ],
      storeModel: 'Professor',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
