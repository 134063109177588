<template>
    <v-container>
        <v-row>
            <v-col>
            <h2>RECURSOS DIGITALES MDK</h2>
            </v-col>
        </v-row>
      <v-row>
        <v-col>
            <v-btn v-if="hasPermission('Recursos Digitales', 'create')"  color="primary" @click="$router.push({ name: 'GeneralFileResourceCreate' })">Crear</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
              <v-data-table
                :headers="headers"
                :items="objects"
                :items-per-page="itemsPerPage"
                class="elevation-1">
                <template v-slot></template>
                <template v-slot:item.actions="{ item }">
                    <!-- <v-icon @click="edit(item)">mdi-pencil</v-icon> -->
                    <v-icon @click="download(item)">mdi-download</v-icon>
                    <v-icon @click="deleteResource(item)" v-if="hasPermission('Recursos Digitales', 'delete')">mdi-delete</v-icon>
                </template>
            </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import ENV from '../../../env'
  export default {
    components: {},
    mounted () {
      this.$store.dispatch('GeneralFileResource/index', '')
    },
    methods: {
        deleteResource(item) {
            this.$store.dispatch('GeneralFileResource/delete', item.id).then(() => {
                this.$store.dispatch('GeneralFileResource/index', '')
            }).catch(() => {
                alert('Hubo un error al eliminar el recurso digital. Inténtalo de nuevo.')
            })
        },
        hasPermission (name, action = false) {
            const names = name.split(",")
            if (names.length > 1) {
                let hasPermission = false
                names.forEach(name => {
                    if (hasPermission) return;
                    hasPermission = this.hasPermission(name, action)
                })
                return hasPermission
            }
            if(!name) return false;
            const permissions = this.authInfo?.user?.permissions
            if (!permissions) return false
            const singlePermissionkey = Object.keys(permissions).filter( key => {
                    return permissions[key].name === name
                })?.[0]
            const singlePermission = permissions?.[singlePermissionkey]
            if (!singlePermissionkey) return false
            if (!action && singlePermission) {
                return (singlePermission?.create || singlePermission?.read || singlePermission?.update || singlePermission?.delete) ? true : false
            }
            return singlePermission?.[action] == 1 || false
        },
        download(item) {
            this.$store.dispatch('GeneralFileResource/download', item.id).catch(() => {
                alert('Hubo un error al descargar el recurso digital. Inténtalo de nuevo.')
            })
        }
    },
    watch: {
    },
    computed: {
      authInfo () {
        return this.$store.getters['Auth/authInfo']
      },
      objects () {
        return this.$store.getters['GeneralFileResource/generalFileResources']
      },
    },
    data () {
      return {
        ENV,
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ],
        storeModel: 'GeneralFileResource',
        itemsPerPage: 20
      }
    },
  }
  </script>

  <style>

  </style>
