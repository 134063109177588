import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    configurations: [],
    configuration: { kyosas: [] }
  },
  mutations: {
    index(state, payload) {
      state.configurations = payload
    },
    show(state,payload) {
      state.configuration = payload
    },
    addToIndex (state, payload) {
      state.configurations.push(payload)
    },
    update (state, payload) {
      state.configuration = payload
      state.configurations = state.configurations.map((doyangGroup) => {
        if (doyangGroup.id == payload.id) {
          doyangGroup = payload
        }
        return doyangGroup
      })
    },
    remove(state, payload) {
      state.configurations = state.configurations.filter((doyangGroup) => doyangGroup.id != payload.id)
    },
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/configuration${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/configuration/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/configuration`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/configuration/${payload.id}`, payload).then((res) => {
        if (payload.date) {
          commit('updateAssistance', res.data)
          return res.data
        }
        commit('update', res.data)
        return res.data
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/configurations/${payload}`).then((res) => {
        commit('remove', res.data)
      })
    }
  },
  getters: {
    configurations: state => {
      return state.configurations
    },
    configuration: state => {
      return state.configuration
    }
  },
  modules: {
  }
}
