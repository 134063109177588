import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    other_exam_requisites: [],
    other_exam_requisite: {}
  },
  mutations: {
    index(state, payload) {
      state.other_exam_requisites = payload
      state.other_exam_requisites.map( monthlyDuty => {
        monthlyDuty.checked = parseInt(monthlyDuty.checked) != 0
        return monthlyDuty
      })
    },
    show(state,payload) {
      state.other_exam_requisite = payload
      state.other_exam_requisite.checked = parseInt(state.other_exam_requisite.checked) != 0
    },
    delete(state,payload) {
      state.other_exam_requisites = state.other_exam_requisites.filter((duty) => duty.id != payload.id)
    }
  },
  actions: {
    index ({commit}) {
      return axios.get(`${ENV.API_URL}/api/other_exam_requisites`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/other_exam_requisites/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/other_exam_requisites`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/other_exam_requisites/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/other_exam_requisites/${payload.id || payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    otherExamRequisites: state => {
      return state.other_exam_requisites
    },
    otherExamRequisite: state => {
      return state.other_exam_requisite
    }
  },
  modules: {
  }
}
