<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="!this.$route.params.id">Nuevo doyang</h2>
        <h2 v-else>EDITAR DOYANG {{ doyang['name'] ? doyang['name'].toUpperCase() : '' }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 md=6>
        <v-select :items="doyangCategories" v-model="doyang.doyang_category_id" outlined label="Categoría Económica"
          item-text="name" item-value="id"></v-select>
      </v-col>
      <v-col cols=12 md=6>
        <v-select :items="doyangTypes" v-model="doyang.doyang_type_id" outlined label="Tipo" item-text="name"
          item-value="id"></v-select>
      </v-col>
      <v-col cols=6 md=6>
        <v-autocomplete label="Ciudad/región" :items="cities" item-text="name" item-value="id" v-model="doyang.city_id"
          outlined> </v-autocomplete>
      </v-col>
      <v-col cols=6 md=3>
        <DMYDateField v-model="doyang.opening_date" label="Fecha de apertura"></DMYDateField>
      </v-col>
      <v-col cols=6 md=3>
        <v-checkbox label="Activo" v-model="doyang.active"></v-checkbox>
      </v-col>
      <v-col cols=6 md=3>
        <v-checkbox label="Cobra comisiones en red" v-model="doyang.is_independent_for_comissions"></v-checkbox>
      </v-col>
      <v-col cols=12 md=6>
        <v-row>
          <v-col>
            <CapsVTextField class="center" ref="name" v-model="doyang.name" outlined label="Nombre del Doyang">
              <template v-slot:prepend>
                {{ doyang.doyang_type_id == ENV.DOYANG_TYPE_DOYANG_ID ? 'MDK ORIENTE' : 'CLUB MDK ORIENTE' }}
              </template>
            </CapsVTextField>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12 md=6>
        <CapsVTextField v-model="doyang.address" outlined label="Calle y número"></CapsVTextField>
      </v-col>
      <v-col cols=12 md=3>
        <CapsVTextField v-model="doyang.neighborhood" outlined label="Colonia"></CapsVTextField>
      </v-col>
      <v-col cols=12 md=3>
        <CapsVTextField v-model="doyang.city" outlined label="Ciudad"></CapsVTextField>
      </v-col>
      <v-col cols=12 md=4>
        <CapsVTextField v-model="doyang.cellphone" outlined label="Celular"></CapsVTextField>
      </v-col>
      <v-col cols=12 md=6>
        <CapsVTextField v-model="doyang.phone1" outlined label="Teléfono"></CapsVTextField>
      </v-col>
      <v-col cols=12 md=6>
        <CapsVTextField v-model="doyang.phone2" outlined label="Otro teléfono"></CapsVTextField>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox label="El responsable puede becar" v-model="doyang.responsible_can_scholarship" />
      </v-col>
      <v-col>
        <v-checkbox label="Puede vender a público en general (permanente)" v-model="doyang.can_sell_gp"></v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox label="Puede vender a público en general (una vez)" v-model="doyang.can_sell_gp_tmp"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete chips outlined label="Quién Certifica" v-model="doyang.user_certifier_id"
          :items="certifiedProfessors" item-value="id" item-text="full_name"></v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete chips outlined label="Profesor Responsable" v-model="doyang.user_responsible_id"
          :items="professors" item-value="id" item-text="full_name"></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="eventType != 'exam'">
      <v-col>
        <v-checkbox v-model="modifyMonthlyFee"></v-checkbox>
        <CapsVTextField outlined prefix="$" :disabled="!modifyMonthlyFee" label="Mensualidad"
          v-model="doyang.monthly_fee" />
      </v-col>
      <v-col>
        <v-checkbox v-model="modifyInscriptionFee"></v-checkbox>
        <CapsVTextField outlined prefix="$" :disabled="!modifyInscriptionFee" label="Inscripción"
          v-model="doyang.inscription_fee" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select label="Descuento Multinivel" v-model="doyang.doyang_surrogation_id" :items="doyangSurrogations"
          item-value="id" item-text="name" outlined></v-select>
      </v-col>
      <v-col>
        <v-select label="Depende de (multinivel)" v-model="doyang.surrogated_to_doyang_id" :items="doyangs"
          item-value="id" item-text="name" outlined></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Requisitos para subir a {{ doyang.nextCategory.name }}</h3>
        <v-data-table v-if="doyang.doyang_category" :items="doyang.doyang_category.requisites" :headers="[
          { text: 'Cumplido', value: 'select' },
          { text: 'Requisito', value: 'name' },
        ]">
          <template v-slot:item.select="{ item }">
            {{ item.doyangs.filter((aDoyang) => aDoyang.id == doyang.id).length ? 'Sí' : 'No' }}
            <v-btn class="blue lighten-2" small dark @click.stop="selectRequisite(item)">Cambiar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="orange lighten-2 white--text" @click="editNewDoyang" v-if="this.$route.params.id">Editar</v-btn>
        <v-btn primary @click="createNewDoyang" v-else class="blue ligthten-2">Agregar</v-btn>
      </v-col>
    </v-row>
    <CRUDModelCreate :storeModel="storeModel" :hasMany="hasMany" />
    <!-- <CRUDHABTMModelCreate
        :storeModel="storeModel"
        :hasAndBelongsToMany="hasAndBelongsToMany"
    /> -->
    <v-row v-if="doyang">
      <v-col cols=12>
        <h2>PERSONAL</h2>
      </v-col>
      <v-col cols=12>
        <v-row>
          <v-col>
            <v-autocomplete outlined label="Buscar usuario" chips :items="professors" v-model="selectedNewPersonalId"
              item-text="full_name_honorific" item-value="id"></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete outlined label="Buscar tipo multinivel" chips :items="doyangSurrogations"
              v-model="selectedSurrogationId" item-text="name" item-value="id"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols=12>
            <v-btn :disabled="!activeAddPersonalButon" :class="activeAddPersonalButon ? 'green lighten-2 dark' : ''"
              dark @click="addPersonal">Agregar</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=12>
        <v-data-table :headers="[
          { text: 'Nombre', value: 'full_name_honorific' },
          { text: 'Categoría de instructor', value: 'pivot.doyang_surrogation_id' },
          { text: 'Acciones', value: 'actions', sortable: false }
        ]" :items="doyang.personal">
          <template v-slot:item.pivot.doyang_surrogation_id="{ item }">
            <v-select @change="changeMultilevelDiscount" v-if="instructorOnModification == item.id"
              label="Descuento Multinivel" :items="doyangSurrogations" item-value="id" item-text="name"
              outlined></v-select>
            <span v-else>{{ (item.pivot && item.pivot.doyang_surrogation_id) ? doyangSurrogations.filter(sur => sur.id
          ==
          item.pivot.doyang_surrogation_id)[0].name : '' }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deletePersonal(item.id)">
              mdi-delete
            </v-icon>
            <v-icon small @click="() => instructorOnModification = item.id">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="doyang">
      <v-col cols=12>
        <h2>DOYANGS DEPENDIENTES DE {{ doyang['name'] ? doyang['name'].toUpperCase() : '' }}</h2>
      </v-col>
      <v-col cols=12 v-if="doyang.surrogated_doyangs">
        <v-data-table :items="doyang.surrogated_doyangs.filter(doyang => doyang.active)" :headers="[{
          text: 'Nombre', value: 'name'
        }]">
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="this.$route.params.id">
      <v-col cols=12>
        <h2>METAS DE EVENTOS</h2>
      </v-col>
      <v-col cols=12 v-if="doyang.next_tournaments_totals">
        <h3>Torneos</h3>
        <ul>
          <li v-for="touranmentName in Object.keys(doyang.next_tournaments_totals)" :key="touranmentName">
            {{ touranmentName }}: meta {{ doyang.next_tournaments_totals[touranmentName]['goal'] }}
            <v-icon
              @click="establishGoal(doyang.next_tournaments_totals[touranmentName]['id'], touranmentName, 'tournament')">mdi-pencil</v-icon>
          </li>
        </ul>
      </v-col>
      <v-col cols=12 v-if="doyang.next_events_totals">
        <h3>Eventos</h3>
        <ul>
          <li v-for="eventName in Object.keys(doyang.next_events_totals)" :key="eventName">
            {{ eventName }}: meta {{ doyang.next_events_totals[eventName]['goal'] }}
            <v-icon
              @click="establishGoal(doyang.next_events_totals[eventName]['id'], eventName, 'event')">mdi-pencil</v-icon>
          </li>
        </ul>
      </v-col>
      <v-col cols=12 v-if="doyang.next_exams_totals">
        <h3>Exámenes</h3>
        <ul>
          <li v-for="examName in Object.keys(doyang.next_exams_totals)" :key="examName">
            {{ examName }}: meta {{ doyang.next_exams_totals[examName]['goal'] }}
            <v-icon
              @click="establishGoal(doyang.next_exams_totals[examName]['id'], examName, 'exam')">mdi-pencil</v-icon>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CRUDModelCreate from '../globals/CRUDModels/CRUDModelGroup'
// import CRUDHABTMModelCreate from '../globals/CRUDModels/CRUDHABTMModelCreate'
import DoyangGroupCreate from '../doyang_groups/Create'
import ProfessorCreate from '../professors/Create'

export default {
  components: {
    CRUDModelCreate,
    // CRUDHABTMModelCreate,
  },
  mounted() {
    this.$store.dispatch('DoyangType/index', ``)
    this.$store.dispatch('DoyangCategory/index')
    this.$store.dispatch('Professor/index', `?role_id=${ENV.ROLE_PUBLIC_RELATIONS},${ENV.ROLE_DEPENDANT_KYOSA_ID},${ENV.ROLE_CLUB_KYOSA_ID},${ENV.ROLE_DOYANG_KYOSA_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}`)
    this.$store.dispatch('Doyang/index', 'active=1')
    this.$store.dispatch('DoyangSurrogation/index')
    this.$store.dispatch('City/index')

    if (this.$route.params.id) {
      this.$store.dispatch('Doyang/show', `${this.$route.params.id}?with=doyangCategoryRequisites,doyang_category,doyang_category.requisites,doyang_category.requisites.doyangs,surrogated_doyangs&append=next_events_totals,next_exams_totals,next_tournaments_totals,nextCategory,allPersonal`).then((doyang) => {
        this.$set(this, 'doyang', doyang)
        this.doyang.active = doyang.active != "0"
        this.doyang.responsible_can_scholarship = doyang.responsible_can_scholarship != "0"
        this.doyang.can_sell_gp = doyang.can_sell_gp != "0"
        this.doyang.can_sell_gp_tmp = doyang.can_sell_gp_tmp != "0"
        this.doyang.doyang_category_id = doyang.doyang_category_id ? Number(doyang.doyang_category_id) : doyang.doyang_category_id
        this.doyang.doyang_surrogation_id = doyang.doyang_surrogation_id ? Number(doyang.doyang_surrogation_id) : doyang.doyang_surrogation_id
        this.doyang.surrogated_to_doyang_id = doyang.surrogated_to_doyang_id ? Number(doyang.surrogated_to_doyang_id) : doyang.surrogated_to_doyang_id
        this.doyang.doyang_type_id = doyang.doyang_type_id ? Number(doyang.doyang_type_id) : doyang.doyang_type_id
        this.doyang.user_certifier_id = doyang.user_certifier_id ? Number(doyang.user_certifier_id) : doyang.user_certifier_id
        this.doyang.user_responsible_id = doyang.user_responsible_id ? Number(doyang.user_responsible_id) : doyang.user_responsible_id
        this.doyang.name = doyang.name.replace(ENV.CLUB_PREPEND_STRING, "")
        this.doyang.name = doyang.name.replace(ENV.DOYANG_PREPEND_STRING, "")
      }).then(() => {
      })
    }
    this.$nextTick(() => this.$refs.name.$el.focus())
  },
  methods: {
    establishGoal(eventId, eventName, type) {
      const doyangId = this.$route.params.id
      const goal = prompt('Introduce la nueva meta')
      const id_key = `${type}_id`
      const id_name = `${type}_name`
      this.$store.dispatch('Doyang/stablish_goal', {
        doyang_id: doyangId,
        [id_name]: eventName,
        type,
        goal,
        [id_key]: eventId
      }).then(() => {
        const key = `next_${type}s_totals`;
        this.doyang[key][eventName]['goal'] = goal
      })
    },
    changeMultilevelDiscount(selected) {
      this.$store.dispatch('Doyang/addPersonal', {
        id: this.$route.params.id,
        personal: this.instructorOnModification,
        doyang_surrogation_id: selected,
      }).then((user) => {
        this.instructorOnModification = null;
        this.doyang.personal = this.doyang.personal.filter((backUser) => backUser.id != user.id)
        this.doyang.personal?.push({
          ...user,
          pivot: { doyang_surrogation_id: selected }
        })
      })
    },
    deletePersonal(personalId) {
      this.$store.dispatch('Doyang/deletePersonal', {
        parentId: this.doyang.id,
        childId: personalId
      });
    },
    addPersonal() {
      this.$store.dispatch('Doyang/addPersonal', {
        id: this.doyang.id,
        personal: this.selectedNewPersonalId,
        doyang_surrogation_id: this.selectedSurrogationId,
      }).then((user) => {
        console.log('user', user)
        this.doyang.personal?.push({
          ...user,
          pivot: { doyang_surrogation_id: this.selectedSurrogationId }
        })
      })
    },
    selectRequisite: function (item) {
      console.log('Selected requisite')
      console.log('item', item)
      this.$store.dispatch('DoyangCategoryRequisite/update', {
        id: item.id,
        doyangs: [this.doyang.id]
      }).then(() => {
        this.doyang.doyang_category.requisites = this.doyang.doyang_category.requisites.map(requisite => {
          if (requisite.id == item.id) {
            // if it was in
            const wasIn = requisite.doyangs.filter(doyang => {
              return doyang.id == this.doyang.id
            }).length > 0
            //stripping it out
            if (wasIn) {
              requisite.doyangs = requisite.doyangs.filter(doyang => {
                return doyang.id != this.doyang.id
              })
            }
            // Putting it in
            else {
              requisite.doyangs.push(this.doyang)
            }
          }
          return requisite
        })
      })
    },
    createNewDoyang: function () {
      this.$store.dispatch('Doyang/create', this.doyang).then(() => {
        alert('Se ha creado el registro con éxito')
        this.$router.push({ name: 'DoyangIndex' })
      }).catch(() => {
        alert('Hubo un error al crear tu registro')
      })
    },
    editNewDoyang: function () {
      let previousUser = this.$store.getters['Student/student']
      this.$store.dispatch('Doyang/update', this.doyang).then(() => {
        if (previousUser.id) {
          this.$store.dispatch('Student/show', `${previousUser.id}?append=all_doyangs`)
        }
        alert('Se ha modificado el registro con éxito')
        this.$router.push({ name: 'DoyangIndex' })
      }).catch(() => {
        alert('Hubo un error al modificar tu registro')
      })
    }
  },
  computed: {
    cities() {
      const allowedCitiesIds = [1, 6]; // 1 los mochis, 6 hermosiilo
      return this.$store.getters['City/cities'].filter(city => {
        return allowedCitiesIds.includes(city.id)
      });
    },
    activeAddPersonalButon() {
      return this.selectedNewPersonalId
    },
    doyangs() {
      return this.$store.getters['Doyang/doyangs']
    },
    doyangSurrogations() {
      return this.$store.getters['DoyangSurrogation/doyangSurrogations']
    },
    defaultDoyang() {
      return this.$store.getters['Doyang/doyang']
    },
    doyangTypes() {
      return this.$store.getters['DoyangType/doyangTypes']
    },
    doyangCategories() {
      return this.$store.getters['DoyangCategory/doyangCategories']
    },
    professors() {
      return this.$store.getters['Professor/professors']?.filter((user) => user.active)
    },
    certifiedProfessors() {
      return this.$store.getters['Professor/certifiedProfessors']
    },
  },
  watch: {
    doyangCategories() {
    },
    defaultDoyang() {
    },
  },
  data() {
    return {
      ENV: ENV,
      eventType: null,
      selectedNewPersonalId: null,
      selectedSurrogationId: null,
      modifyMonthlyFee: false,
      modifyInscriptionFee: false,
      instructorOnModification: null,
      doyang2: {
        name: '',
        city_id: null,
        opening_date: null,
        doyang_category_id: null,
        cellphone: '',
        monthly_fee: null,
        inscription_fee: null,
        doyang_type_id: null,
        user_responsible_id: 0,
        phone1: '',
        phone2: '',
        nextCategory: {
          name: '',
          requisites: [],
        }
      },
      storeModel: 'Doyang',
      hasMany: [
        {
          model: 'DoyangGroups',
          title: 'Grupos',
          createButtonText: 'Añadir Grupo',
          component: DoyangGroupCreate,
          sortBy: ['begins_at'],
          sortDesc: [false],
          tableHeaders: [
            { text: 'Nombre', value: 'name' },
            { text: 'Horario', value: 'schedule' },
            { text: 'Alumnos', value: 'none' },
            { text: 'Días', value: 'short_json_week' },
            { text: 'Acciones', value: 'actions', sortable: false }
          ]
        },
      ],
      hasAndBelongsToMany: [
        {
          base: 'Doyangs',
          model: 'Professors',
          title: 'PERSONAL',
          deleteMethod: 'deletePersonal',
          createButtonText: 'AÑADIR PERSONAL',
          relName: 'personal',
          component: ProfessorCreate,
          tableHeaders: [
            { text: 'Nombre', value: 'full_name_honorific' },
            { text: 'Categoría de instructor', value: 'doyang_surrogation_id' },
            { text: 'Acciones', value: 'actions', sortable: false }
          ]
        },
      ],
      doyang: {
        name: '',
        city_id: null,
        scholarship_percentage_allowed: null,
        doyang_category_id: null,
        cellphone: '',
        monthly_fee: null,
        inscription_fee: null,
        doyang_type_id: null,
        user_responsible_id: 0,
        active: true,
        phone1: '',
        phone2: '',
        doyang_surrogation_id: null,
        surrogated_to_doyang_id: null,
        nextCategory: {
          name: '',
          requisites: [],
        }
      }
    }
  }
}
</script>

<style></style>
