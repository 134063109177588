<template>
  <v-text-field v-bind="$attrs" outlined @input="parseInput" :value="value"></v-text-field>
</template>

<script>
export default {
  methods: {
    parseInput(text) {
      this.$emit('input', text.toUpperCase())
      this.$emit('change', text?.length > 2 ? text.toUpperCase() : "")
    }
  },
  props: ['value'],
  data() {
    return {
      text: null
    }
  }
}
</script>

<style>

</style>
