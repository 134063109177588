<template>
    <div>
        <HomeOptimized></HomeOptimized>
    </div>
</template>

<script>
import HomeOptimized from './HomeOptimized.vue';

export default {
    name: 'ChooseHome',
    components: {
        HomeOptimized,
    },
    methods: {
        toggleHome() {
            this.$store.dispatch("Auth/toggleHome", !this.useOldHome).then(() => {
                window.location.reload();
            });
        },
    },
    computed: {
        useOldHome() {
            return this.$store.getters["Auth/useOldHome"];
        }
    },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>