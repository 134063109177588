<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR PRODUCTO</h2>
        <h2 v-else>EDITAR PRODUCTO</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=6>
          <CapsVTextField
            outlined
            v-model="product.name"
            label="Nombre del producto"
            />
        </v-col>
        <v-col cols=6>
            <v-autocomplete
                label="Sinónimos de Revisión"
                solo
                chips
                multiple
                v-model="product.synonyms"
                :items="products"
                item-text="name"
                return-object
            ></v-autocomplete>
        </v-col>
        <v-col col=3>
          <v-checkbox v-model="product.inventory" :label="'Aparece en inventario'"></v-checkbox>
        </v-col>
        <v-col cols=4>
          <v-text-field
            outlined
            v-model="product.price"
            label="Precio al público"
            />
        </v-col>
        <v-col cols=4>
          <v-select
            outlined
            v-model="product.multilevel_discount_type_id"
            :items="multilevelDiscountTypes"
            item-value="id"
            item-text="name"
            label="Tipo de descuento"
            />
        </v-col>
        <v-col cols=2>
          <v-checkbox
            oulined
            v-model="product.active"
            label="activo"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createProduct">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateProduct">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-col v-if="$route.params.id" cols=12>
      <h3>AÑADIR VARIANTES DE PRODUCTO</h3>
    </v-col>
    <v-col cols=12 v-if="$route.params.id">
      <router-link :to="{name:'ProductVariantCreate', params: {product_create: product}}">
        <v-btn class="green lighten-2" dark>Crear variante</v-btn>
      </router-link>
    </v-col>
    <v-col cols=12 v-if="$route.params.id">
      <v-data-table
        :headers="productVariantHeaders"
        :items="productVariants"
        :sort-by="'order'"
      >
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            @end="endOrdering(props.items)"
          >
            <tr
            v-for="(item, index) in props.items"
            :key="index"
            >
              <td v-for="header in props.headers" :key="header.value">
                <v-icon v-if="header.value == 'drag'"
                  small
                  class="page__grab-icon"
                >
                  mdi-arrow-all
                </v-icon>
                <div v-else-if="header.value == 'actions'">
                  <v-icon @click="goToEditProductVariant( item )">mdi-pencil</v-icon>
                  <v-icon @click="goToDeleteProductVariant( item )">mdi-delete</v-icon>
                </div>
                <div v-else>
                  {{item[header.value]}}
                </div>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-col>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  mounted () {
    if (this.$route.params.id) {
      this.$store.dispatch('Product/index');
      this.$store.dispatch('MultilevelDiscountType/index').then(() => {
        this.$store.dispatch('ProductVariant/index', `?product_id=${this.$route.params.id}`).then(() => {});
        this.$store.dispatch('Product/show', `${this.$route.params.id}?append=all_synonyms`).then(() => {
          this.product = this.$store.getters['Product/product']
          this.product.multilevel_discount_type_id = parseInt(this.product.multilevel_discount_type_id)
          this.product.synonyms = this.product.all_synonyms
          this.$store.dispatch('Role/index').then((roles) =>{
              roles.forEach(role => {
              this.product.prices.push({
                id: role.id,
                name: role.name,
                price: null
              })
            });
          });
        });
      })
    }
  },
  methods: {
    endOrdering (items) {
      items.forEach( (variant, index) => {
        if (variant?.order != index){
          variant.order = index
          this.$store.dispatch('ProductVariant/update', variant)
        }
      })
    },
    goToDeleteProductVariant (item) {
      if(confirm("¿Estás seguro que quieres eliminar este registro?"))
        this.$store.dispatch('ProductVariant/delete', item.id)
    },
    goToEditProductVariant (item) {
      this.$router.push({name:'ProductVariantEdit', params: {id: item.id, product: this.product}})
    },
    createProduct: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('Product/create', this.product).then(() => {
          this.$router.push({name: 'ProductIndex'})
        }
      )
    },
    updateProduct: function () {
      this.$store.dispatch('Product/update', this.product).then(() => {
          this.$router.push({name: 'ProductIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
    'product.synonyms': function () {
      if (this.product?.synonyms?.length > 1) {
        this.product.synonyms = [this.product.synonyms[this.product.synonyms.length - 1]]
      }
    }
  },
  computed : {
    multilevelDiscountTypes () {
      return this.$store.getters['MultilevelDiscountType/multilevelDiscountTypes']
    },
    roles () {
      return this.$store.getters['Role/roles'];
    },
    productVariants () {
      return this.$store.getters['ProductVariant/productVariants'];
    },
    products () {
        return this.$store.getters['Product/products'];
    }
  },
  data () {
    return {
      productVariantHeaders: [
        {text: 'Ordenar', value: 'drag'},
        {text: 'Nombre', value: 'name'},
        {text: 'Precio', value: 'price'},
        {text: 'Acciones', value: 'actions'},
      ],
      product: {
        name: null,
        price: null,
        active: true,
        multilevel_discount_type_id: 0,
        prices: [],
        synonyms: [],
      },
    }
  }
}
</script>

<style>

</style>
