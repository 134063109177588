<template>
  <v-container>
    <v-row v-for="(hasManyItem, index) in hasManyItems" :key="index">
      <v-col col=12 v-if="$route.params.id">
        <h2>{{hasManyItem.title}}</h2>
        <v-dialog
          v-model="hasManyDialogTogglers[hasManyItem.model]"
          width="1000"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-2"
              dark
              v-bind="attrs"
              v-on="on"
              @click="addToIdCreation(idCreation)"
            >
              {{hasManyItem.createButtonText}}
            </v-btn>
          </template>
          <v-card>
            <v-card-actions>
              <v-btn
                color="red lighten-2"
                text
              >
                Descartar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-if="edditingGroupId == 0"
                @click="triggerGroupCreated(hasManyItem.model, true)"
              >
                Añadir
              </v-btn>
              <v-btn
                color="primary"
                text
                v-else
                @click="triggerGroupEditted(hasManyItem.model, true)"
              >
                Editar
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-text>
            <component
              :key="idCreation"
              :is="hasManyItem.component"
              :hasManyId="edditingGroupId"
              :parentId="model.id"
              @created="triggerGroupCreated(hasManyItem.model, false)"
              @editted="triggerGroupEditted(hasManyItem.model, false)"
              :triggerCreate="hasManyCreateTriggers[hasManyItem.model]"
              :triggerEdit="hasManyUpdateTriggers[hasManyItem.model]"
              :hideAddButton="true"/>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="red lighten-2"
                text
              >
                Descartar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-if="edditingGroupId == 0"
                @click="triggerGroupCreated(hasManyItem.model, true)"
              >
                Añadir
              </v-btn>
              <v-btn
                color="primary"
                text
                v-else
                @click="triggerGroupEditted(hasManyItem.model, true)"
              >
                Editar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="$route.params.id" cols=12>
        <v-data-table
        :headers="hasManyItem.tableHeaders"
        :items="hasManyItem.objects"
        :items-per-page="20"
        :sort-by="hasManyItem.sortBy"
        :sort-desc="hasManyItem.sortDesc"
        :group-by="['short_json_week']"
        class="elevation-1"
        >
        <template v-slot:group.header="{ group, headers, toggle, isOpen }">
          <td :colspan="headers.length">
            <v-btn @click="toggle" x-small icon :ref="group">
                <v-icon v-if="!isOpen">mdi-plus</v-icon>
                <v-icon v-else>mdi-minus</v-icon>
            </v-btn>
            <span class="font-weight-bold">DÍAS: {{ group }}</span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="goToEditItem(hasManyItem, item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(hasManyItem.model, item)"
          >
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted: function () {
    if (this.$route.params.id) {
      this.$store.dispatch(`${this.storeModel}/show`, this.$route.params.id)
      this.hasMany.forEach( (hasManyRel) => {
        this.$store.dispatch(`${hasManyRel.model.substring(0, hasManyRel.model.length - 1)}/index`, `?${this.storeModel.toLowerCase()}_id=${this.$route.params.id}`)
      } )
    }
    this.hasMany.forEach((hasManyRel) => {
        this.$set(this.hasManyDialogTogglers, hasManyRel.model, false)
        this.$set(this.hasManyCreateTriggers, hasManyRel.model, false)
        this.$set(this.hasManyUpdateTriggers, hasManyRel.model, false)
    })
  },
  methods: {
    addToIdCreation() {
      console.log('trigger')
      this.edditingGroupId = 0
      this.idCreation = this.idCreation + 1
    },
    toSnakeCase (str) {
      return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    },
    goToEditItem (relationship, edditingItem) {
      this.edditingGroupId = edditingItem.id
      this.$set(this.hasManyDialogTogglers, relationship.model, true)
    },
    deleteItem (item, group) {
      this.$store.dispatch(`${item.substring(0, item.length - 1)}/deleteGroup`, {
        eventId: this.$route.params.id,
        eventGroupId: group.id
      });
    },
    uncapitalizeFirstLetter (string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    toggleDialog (model) {
      //toggling the dialog
      this.hasManyDialogTogglers[model] = !this.hasManyDialogTogglers[model]
    },
    triggerGroupCreated (model, trigger = true) {
      //trigger the create
      this.$set(this.hasManyCreateTriggers, model, trigger)
      this.$set(this.hasManyDialogTogglers, model, false)
    },
    triggerGroupEditted (model, trigger = true) {
      //trigger the update
      this.$set(this.hasManyUpdateTriggers, model, trigger)
      this.$set(this.hasManyDialogTogglers, model, trigger)
    },
  },
  computed: {
    objectDefaultModel: function () {
      return this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`]
    },
    hasManyItems: function () {
      return this.hasMany.map( (hasManyRel) => {
        let getterName = `${hasManyRel.model.substring(0, hasManyRel.model.length - 1)}/${this.uncapitalizeFirstLetter(hasManyRel.model)}`
        hasManyRel.objects = this.$store.getters[getterName]
        return hasManyRel
      })
    },
  },
  watch: {
    createItemGroupDialog: function () {
      if (this.createItemGroupDialog === false) {
        this.edditingGroupId = 0
      }
    },
    objectDefaultModel: function() {
      this.model = this.objectDefaultModel
    }
  },
  data () {
    return {
      idCreation: 0,
      hasManyDialogTogglers: {},
      hasManyCreateTriggers: {},
      hasManyUpdateTriggers: {},
      edditingGroupId: 0,
      triggerGroupCreate: false,
      triggerGroupEdit: false,
      createItemGroupDialogs: [],
      datePickerMenu: false,
      menuDatePicker: false,
      model: {
        name:'',
        date: null,
      }
    }
  },
  props: {
    component: {
      type: String,
      default: 'ExamGroupCreate'
    },
    storeModel: String,
    hasMany: Array,
  }
}
</script>

<style>

</style>