import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    states: [],
    state: {}
  },
  mutations: {
    index(state, payload) {
      state.states = payload
    },
    show(state,payload) {
      state.state = payload
    }
  },
  actions: {
    index ({commit}) {
      return axios.get(`${ENV.API_URL}/api/states`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/states/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/states`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    uptade({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/states/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    states: state => {
      return state.states
    },
    state: state => {
      return state.state
    }
  },
  modules: {
  }
}
