<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>INVENTARIO DEL PRODUCTO</h2>
        <h2 v-if = "product.name">{{product.name.toUpperCase()}}</h2>
        <h4 v-if="doyang.name">DOYANG: {{doyang.name.toUpperCase()}}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field type="number" v-model="inventory.quantity" outlined label="Existencia"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-btn class="green lighten-2" dark @click="addExistence">Editar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('Product/show', `${this.$route.params.id}?with=doyangs`).then((productInv) => {
        this.inventory = productInv
        this.$store.dispatch('Doyang/show', 1).then((res) => {
          this.inventory.doyangs = []
          this.inventory.doyangs.push(res)
        })
    })
  },
  methods: {
    addExistence () {
      this.inventory.doyang_id = this.$store.getters['Auth/selectedDoyang']['id'] ? this.$store.getters['Auth/selectedDoyang']['id'] : 1;
      this.inventory.inventory = true;
      this.$store.dispatch('Inventory/update', this.inventory).then(() => {
        this.$router.push({name: 'InventoryIndex'})
      })
    }
  },
  computed: {
    product () {
      return this.$store.getters['Product/product']
    },
    doyang () {
      return this.$store.getters['Doyang/doyang']
    }
  },
  data () {
    return {
      inventory : {}
    }
  }
}
</script>

<style>

</style>