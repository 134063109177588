<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR TIPO DE DESCUENTO MULTINIVEL</h2>
        <h2 v-else>EDITAR TIPO DE DESCUENTO MULTINIVEL</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <CapsVTextField
            outlined
            v-model="multilevelDiscountType.name"
            :label="'Nombre del tipo de descuento'"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" dark @click="createmultilevelDiscountType">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updatemultilevelDiscountType">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('State/index');
    if (this.$route.params.id) {
      this.$store.dispatch('MultilevelDiscountType/show', this.$route.params.id).then(res => {
        this.multilevelDiscountType = res
      })
    }
  },
  components: {
  },
  methods: {
    createmultilevelDiscountType: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('MultilevelDiscountType/create', this.multilevelDiscountType).then(() => {
          this.$router.push({name: 'MultilevelDiscountTypeIndex'})
        }
      )
    },
    updatemultilevelDiscountType: function () {
      this.$store.dispatch('MultilevelDiscountType/update', this.multilevelDiscountType).then(() => {
          this.$router.push({name: 'MultilevelDiscountTypeIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
  },
  computed : {
  },
  data () {
    return {
      multilevelDiscountType: {
        name: null,
      },
    }
  }
}
</script>

<style>

</style>