import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'
import Auth from './auth'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangs: [],
    doyang: {},
    doyangFilters: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangs = payload
    },
    show(state, payload) {
      state.doyang = payload
    },
    delete(state, payload) {
      state.doyangs = state.doyangs.filter((doyang) => doyang.id != payload.id)
    },
    deletePersonal(state, payload) {
      state.doyang.personal = state.doyang.personal.filter( personal => personal.id != payload)
    },
    addFilter(state, payload) {
      state['doyangFilters'] = payload
    },
    addPersonal(state, payload) {
        state.doyang?.personal.push({
          ...payload.user,
          doyang_surrogation_id: payload.doyang_surrogation_id
      })
      state['doyangFilters'] = payload
    },
    stablishGoal (state, payload) {
      let nextKey = `next_${payload.type}s_totals`;
      if (payload.type === 'inscription') {
        nextKey = `bimester_inscriptions_goal`;
      }
      if (state?.auth?.authInfo?.user?.doyangs_as_personal) {
        state.auth.authInfo.user.doyangs_as_personal = state.auth.authInfo.user.doyangs_as_personal.map(doyang => {
          if(doyang.id === payload.doyang_id) {
            Object.keys(doyang[nextKey]).forEach((eventTotalsKey) => {
              const eventTotals = doyang[nextKey][eventTotalsKey]
              if (eventTotals.id === payload[`${payload.type}_id`]) {
                doyang[nextKey][eventTotalsKey].goal = payload.goal
              }
            });
          }
          return doyang
        });
      }
      if (state?.auth?.authInfo?.user?.doyangs_as_responsible) {
        state.auth.authInfo.user.doyangs_as_responsible = state.auth.authInfo.user.doyangs_as_responsible.map(doyang => {
          if(doyang.id === payload.doyang_id) {
            if (nextKey === 'bimester_inscriptions_goal') {
              doyang[nextKey] = payload.goal
              return doyang
            }
            Object.keys(doyang[nextKey]).forEach((eventTotalsKey) => {
              const eventTotals = doyang[nextKey][eventTotalsKey]
              if (eventTotals.id === payload[`${payload.type}_id`]) {
                doyang[nextKey][eventTotalsKey].goal = payload.goal
              }
            });
          }
          return doyang
        });
      }
    },
    none () {}
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyangs?${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyangs/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/doyangs`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyangs/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyangs/${payload}`).then((res) => {
        commit('delete', res.data)
      })
    },
    deletePersonal({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyangs/${payload.parentId}?personal=${payload.childId}`).then((res) => {
        commit('deletePersonal', payload.childId)
        return res.data
      })
    },
    addPersonal({ commit }, payload) {
        return axios.put(`${ENV.API_URL}/api/doyangs/${payload.id}`, payload).then((res) => {
            commit('none')
            return res.data
        })
    },
    stablish_goal ({commit}, payload) {
        return axios.put(`${ENV.API_URL}/api/doyangs/${payload.doyang_id}`, payload).then((res) => {
            commit('stablishGoal', {...payload})
            return res
        })
    },
    downloadFile (_, payload) {
        return axios({
            url: `${ENV.API_URL}/api/doyangs${payload ? payload : ''}`,
            method: 'GET',
            responseType: 'blob',
        }).then((res) => {
            return res.data
        })
    }
  },
  getters: {
    doyangs: state => {
      return state.doyangs
    },
    doyang: state => {
      return state.doyang
    },
    doyangFilters: state => {
      return state.doyangFilters
    }
  },
  modules: {
    'auth': Auth
  }
}
