import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    paymentConcepts: [],
    paymentConcept: {} 
  },
  mutations: {
    index(state, payload) {
      state.paymentConcepts = payload
    },
    show(state,payload) {
      state.paymentConcept = payload
    }
  },
  actions: {
    index ({commit}) {
      axios.get(`${ENV.API_URL}/api/payment_concepts`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      axios.get(`${ENV.API_URL}/api/payment_concepts/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      axios.post(`${ENV.API_URL}/api/payment_concepts`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/payment_concepts/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    paymentConcepts: state => {
      return state.paymentConcepts
    },
    paymentConcept: state => {
      return state.paymentConcept
    }
  },
  modules: {
  }
}
