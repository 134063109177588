<template>
  <v-container>
    <v-row>
      <v-col>
            <v-tabs  v-model="tabs">
              <v-tab>DOYANGS</v-tab>
              <v-tab>CLUBES</v-tab>
            </v-tabs>
            <v-tabs  v-model="tabActives">
              <v-tab>Activos</v-tab>
              <v-tab>Inactivos</v-tab>
            </v-tabs>
            <h2 v-if="tabs == 0">DOYANGS {{tabActives == 0 ? 'ACTIVOS': 'INACTIVOS'}} MDK</h2>
            <h2 v-if="tabs == 1">CLUBES {{tabActives == 0 ? 'ACTIVOS': 'INACTIVOS'}} MDK</h2>
            <CRUDIndex
                :permissionRoot="'Doyangs'"
                :items="objects"
                :headers="headers"
                :storeModel="storeModel"
                :itemsPerPage="itemsPerPage"
              />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted () {
    this.$store.dispatch('Doyang/index', 'with=responsible&append=parsed_cellphone,parsed_phone')
    const filters = this.$store.getters['Doyang/doyangFilters']
    this.tabs = filters?.tabs || 0
    this.tabActives = filters?.tabActives || 0
    console.log(this.tabs, filters?.doyangFilters?.tabs)
    console.log(this.tabActives, filters?.doyangFilters?.tabActives)
  },
  methods: {
    addFilters() {
      this.$store.commit('Doyang/addFilter',{
        tabs: this.tabs,
        tabActives: this.tabActives
      })
    }
  },
  watch: {
    tabs () {
      this.addFilters()
    },
    tabActives () {
      this.addFilters()
    }
  },
  computed: {
    objects () {
      if (this.tabs == 0){
        return this.$store.getters['Doyang/doyangs'].filter(doyang => {
          return doyang.doyang_type_id == this.ENV.DOYANG_TYPE_DOYANG_ID && (parseInt(doyang.active) == this.activeTab)
        })
      }
        return this.$store.getters['Doyang/doyangs'].filter(doyang => doyang.doyang_type_id == this.ENV.DOYANG_TYPE_CLUB_ID  && (parseInt(doyang.active) == this.activeTab))
    },
    activeTab () {
      return this.tabActives == 0 ? 1 : 0
    }
  },
  data () {
    return {
      ENV,
      tabs: 0,
      tabActives: 0,
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Telefono fijo (Doyang)', value: 'parsed_phone' },
        { text: 'Celular (Doyang)', value: 'parsed_cellphone' },
        { text: 'Celular (Profesor)', value: 'responsible.parsed_cellphone' },
      ],
      storeModel: 'Doyang',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
