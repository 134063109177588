import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    time: null
  },
  mutations: {
    time(state, payload) {
      state.time = payload
    },
  },
  actions: {
    time({commit}) {
      return axios.get(`${ENV.API_URL}/api/time`).then((res) => {
        commit('time', res.data)
        return res.data
      })
    },
  },
  getters: {
    time: state => {
      return state.time
    },
  },
  modules: {
  }
}
