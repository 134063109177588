<template>
  <v-container>
    <v-dialog v-model="showForeignersCsv">
      <v-card>
        <v-card-title>
          <h3>CSV DE FORÁNEOS</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-file-input v-model="foreignersCsv" accept=".csv" label="Archivo CSV" outlined></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete :items="professors" outlined :label="'Usuario'" item-value="id" item-text="full_name"
                return-object></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showForeignersCsv = false" class="grey lighten-2" dark>Cancelar</v-btn>
          <v-btn @click="uploadForeignersCsv" class="green lighten-2" style="color:white">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="revisionDialog">
      <v-card>
        <v-toolbar>
          REVISIÓN DE EQUIPO
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols=12>
                <table v-if="requirementProducts" class="table">
                  <tr>
                    <th>Requisito</th>
                    <th>Cumplió</th>
                    <th>Cumplió primer revisión</th>
                    <th>Cumplió segunda revisión</th>
                    <th>Comentario</th>
                  </tr>
                  <tr v-for="requirement in requirementProducts" :key="requirement.name">
                    <td>{{ requirement.name }}</td>
                    <td><v-checkbox v-model="requirement.checked"></v-checkbox></td>
                    <td>
                      <DMYDateField :disabled="false" v-model="requirement.date"></DMYDateField>
                    </td>
                    <td>
                      <DMYDateField :disabled="false" v-model="requirement.second_date"></DMYDateField>
                    </td>
                    <td><caps-v-text-field v-model="requirement.comment"></caps-v-text-field></td>
                  </tr>
                </table>
              </v-col>
              <v-col cols=12 v-if="requirementOther && requirementOther.length > 0">
                <table class="table">
                  <tr>
                    <th>Requisito</th>
                    <th>Cumplió</th>
                    <th>Fecha en la que cumplió</th>
                    <th>Comentario</th>
                  </tr>
                  <tr v-for="requirement in requirementOther" :key="requirement.name">
                    <td>{{ requirement.name }}</td>
                    <td><v-checkbox v-model="requirement.checked"></v-checkbox></td>
                    <td>
                      <DMYDateField :disabled="true" v-model="requirement.date"></DMYDateField>
                    </td>
                    <td><caps-v-text-field v-model="requirement.comment"></caps-v-text-field></td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <!--<v-btn text @click="revisionDialog = false">Cerrar</v-btn>
          <v-btn text class="green lighten-2" dark @click="assignRevision">Asignar</v-btn>
          <v-btn text v-if="isGeneralDirection" class="green lighten-2" dark @click="approveRevision">(DG) Todo cubierto
            y a
            tiempo</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <h2>Prospectos de "{{ model ? model.name : '' }}"</h2>
        <h5>Fecha {{ model ? model.parsed_date : '' }}</h5>
        <v-btn :disabled="assignResultsButtonDisabled" @click="downloadTournamentHotelBusListExcel"
          v-if="isGeneralDirection && this.eventType == 'tournament'">Descargar CSV de camión</v-btn>
        <v-btn :disabled="assignAllResultsSheetDisabled" @click="downloadAllExamSheets"
          v-if="this.eventType == 'exam'">Descargar todas las solicitudes</v-btn>
        <v-btn :disabled="assignResultsButtonDisabled" v-if="isGeneralDirection && this.eventType == 'tournament'"
          @click="assignResultsFromGraphs">Pasar
          resultados de
          gráficas</v-btn>
        <v-btn v-if="this.eventType == 'tournament'" @click="goToGraphics">Gráficas</v-btn>
        <v-btn v-if="isGeneralDirection && this.eventType == 'tournament'" @click="loadTournamentCronogram">Cronograma
          de torneo</v-btn>
        <v-btn v-if="isGeneralDirection && this.eventType == 'exam'"
          @click="isCronogramhowing = true">Cronograma</v-btn>
        <v-btn v-if="!this.selectedDoyangIsGeneralDireciton && this.eventType == 'exam'" class='primary'
          :disabled="loadingPrintSheet" @click='printResultSheet(false)'><v-icon>mdi-printer</v-icon> Imprimir hoja de
          resultados (Vacía)</v-btn>
        <v-btn v-if="!this.selectedDoyangIsGeneralDireciton && this.eventType == 'exam'" class='primary'
          :disabled="loadingPrintSheet" @click='printResultSheet(true)'><v-icon>mdi-printer</v-icon> Imprimir hoja de
          resultados (Llena)</v-btn>
        <v-select v-if="this.selectedDoyangIsGeneralDireciton && this.eventType == 'exam'" :items="certifiers" outlined
          v-model="certifier_id" item-text="full_name" item-value="id" label="Certificador"></v-select>
        <v-btn v-if="this.selectedDoyangIsGeneralDireciton" :disabled="certifier_id == null" class='primary'
          @click='printResultSheetByCertifier'><v-icon>mdi-printer</v-icon> Imprimir hoja de resultados del
          profesor</v-btn>
        <v-btn :disabled="loadingPrintSheet" class='primary'
          @click='printCompleteResultList'><v-icon>mdi-printer</v-icon> Descargar lista completa</v-btn>
        <v-text-field outlined v-model="search" placeholder="Búsqueda"></v-text-field>
        <v-btn tile v-if="activeTab == 1" color="primary" @click.stop="bulkMoveToPospect"
          :disabled="selectedCandidates.length == 0">
          <v-icon left>
            mdi-account
          </v-icon>
          Pasar a prospecto
        </v-btn>
        <v-btn tile v-if="activeTab == 0 && isGeneralDirection" class="teal mr-10" @click.stop="bulkAproveProspect"
          :disabled="selectedProspects.length == 0">
          <v-icon left>
            mdi-account
          </v-icon>
          Aprobar candidatura
        </v-btn>
        <v-btn tile v-if="activeTab == 0 && isGeneralDirection" color="red lighten-2" class="mr-10"
          @click.stop="bulkRejectProspect" :disabled="selectedProspects.length == 0">
          <v-icon left>
            mdi-account
          </v-icon>
          Rechazar Candidatura
        </v-btn>
        <v-btn tile v-if="activeTab == 0 && isGeneralDirection" color="warning"
          :disabled="selectedProspects.length == 0" @click.stop="bulkMoveToCandidate">
          <v-icon left>
            mdi-account
          </v-icon>
          Devolver a candidato
        </v-btn>
        <v-btn tile v-if="activeTab == 0 && isGeneralDirection && this.eventType == 'tournament'" color="primary"
          @click="openForeignersCsv">
          <v-icon left>
            mdi-account
          </v-icon>
          Ingresar CSV (Foráneos)
        </v-btn>
        <v-tabs v-model="activeTab">
          <v-tab>Prospectos</v-tab>
          <v-tab>Candidatos</v-tab>
          <!-- <v-tab>Universo de alumnos</v-tab> -->
        </v-tabs>
        <!-- Prospects -->
        <v-progress-circular v-if="loadingTable" :size="25" :width="5" color="green lighten-2" indeterminate />
        <v-data-table v-if="!loadingTable && activeTab == 0" :search="search" :headers="headers.prospects"
          :items="model ? model.doyangProspects : []" :items-per-page="20" :server-items-length="serverTotal"
          :sort-by="['already_promoted', 'doyang_id', 'degree_id', 'id']" :sort-asc="[false, true, false, false]"
          class="elevation-1" :show-select="isGeneralDirection" v-model="selectedProspects"
          :custom-filter="filterOnRequiredFields" :item-class="prospectRowBackground" @update:page="updatePage"
          :page.sync="currentPage">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon md class="" @click="moveToCandidate(item.id)">
              mdi-window-close
            </v-icon>
            <v-icon v-if="!isEventOver" md class="" @click="printExamAppilcation(model.id, item)">
              mdi-text-box-outline
            </v-icon>
            <v-btn v-if="isEventOver && !(edittingResults.includes(parseInt(item.id))) && isGeneralDirection" small
              class='warning ml-2' @click.stop="addEditingItem(item)">
              Asignar Resultado
            </v-btn>
            <v-btn v-if="isEventOver && isGeneralDirection && eventType == 'exam'" small dark class='blue lighten-2'
              @click.stop="pastExamApplicaton(model.id, item)">
              <v-icon>mdi-text-box-outline</v-icon> Solicitud
            </v-btn>
            <v-btn v-if="isEventOver && (edittingResults.includes(parseInt(item.id))) && isGeneralDirection" small
              class="primary" @click.stop="assignResult(item)">
              Asignar
            </v-btn>
            <v-btn v-if="isGeneralDirection && eventType == 'exam' && model.is_black_belt" small class="primary"
              @click.stop="openRevisionDialog(item)">
              <v-icon>mdi-microscope</v-icon>
              Revisión de cinta negra
            </v-btn>
            <v-btn v-if="eventType == 'tournament'" class="green lighten-2" small dark
              @click="openSomatometryDialog(item)">
              <v-icon>mdi-pencil</v-icon> Editar peso y talla
            </v-btn>
          </template>
          <template v-slot:[`item.approvedTemplate`]="{ item }" v-if="!isEventOver && isGeneralDirection">
            <v-icon md class="">
              {{ item.pivot.is_prospect_approved === null ? 'mdi-help' : (item.pivot.is_prospect_approved ?
                'mdi-check'
                :
                'mdi-window-close') }}
            </v-icon>
          </template>
          <template v-slot:[`item.result`]="{ item }">
            <v-progress-circular v-if="assigningResultId == item.id" indeterminate
              color="primary"></v-progress-circular>
            <template v-else>
              <div v-if='!(edittingResults.includes(parseInt(item.id)))'>
                <span v-if="eventType == 'exam'">{{ item.pivot.numeric_result }} - ({{ item.result }})</span>
                <span v-if="eventType == 'tournament'">
                  <span v-if="getResultById(item.pivot.result_combat)">Combate:
                    {{ getResultById(item.pivot.result_combat) }} | </span>
                  <span v-if="getResultById(item.pivot.result_forms)">Formas: {{
                    getResultById(item.pivot.result_forms)
                  }}
                    | </span>
                  <span v-if="getResultById(item.pivot.result_boards)">Rompimientos:
                    {{ getResultById(item.pivot.result_boards) }} | </span>
                  <span v-if="getResultById(item.pivot.result_kicking_speed)">Velocidad de pateo:
                    {{ getResultById(item.pivot.result_kicking_speed) }} | </span>
                  <span v-if="getResultById(item.pivot.result_techniques)">Técnicas:
                    {{ getResultById(item.pivot.result_techniques) }} | </span>
                  <span v-if="getResultById(item.pivot.result_jumping_kicks)">Idan Chaguis:
                    {{ getResultById(item.pivot.result_jumping_kicks) }} | </span>
                  <span v-if="item.pivot.colaborated">Colaboró/</span>
                  <span v-if="item.pivot.colaborated_mother">Colaboró mamá/</span>
                  <span v-if="item.pivot.colaborated_father">Colaboró Papá</span>
                </span>
                <span v-else-if="!item.already_promoted">
                  <span v-if="item.pivot.colaborated">Colaboró/</span>
                  <span v-if="item.pivot.participated">Participó/</span>
                  <span v-if="item.pivot.colaborated_mother">Colaboró mamá/</span>
                  <span v-if="item.pivot.colaborated_father">Colaboró Papá</span>
                  <span
                    v-if="!item.pivot.colaborated && !item.pivot.participated && !item.pivot.colaborated_mother && !item.pivot.colaborated_father">No
                    Participó</span>
                </span>
                <span v-else>Asignado</span>
              </div>
              <div v-else>
                <v-text-field v-if="eventType == 'exam'" outlined small type="number" placeholder='Resultado'
                  @input="setResultWithNumeric(item)" v-model="item.pivot.numeric_result"></v-text-field>
                <v-checkbox v-if="eventType == 'exam'" label="No Presentó" @click="didntTakeExam(item)"></v-checkbox>
                <v-select v-if="eventType == 'exam' && modelResults.length != 0" small outlined class="mt-5 mb-n5"
                  :items="modelResults" item-text="name" item-value="id" :disabled="true"
                  v-model="item.pivot.exam_result_id">
                </v-select>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_combat">
                  Combate
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_combat" />
                </v-col>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_forms">
                  Formas
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_forms" />
                </v-col>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_boards">
                  Rompimientos
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_boards" />
                </v-col>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_kicking_speed">
                  Velocidad de Pateo
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_kicking_speed" />
                </v-col>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_techniques">
                  Técnicas
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_techniques" />
                </v-col>
                <v-col v-if="item && item.paidCompetitions && item.paidCompetitions.pay_jumping_kicks">
                  Idan Chaguis
                  <v-select v-if="eventType == 'tournament' && modelResults.length != 0" small outlined
                    class="mt-5 mb-n5" :items="modelResults" item-text="name" item-value="id"
                    v-model="item.pivot.result_jumping_kicks" />
                </v-col>
                <v-col v-if="eventType != 'event'">
                  <v-checkbox v-model="item.pivot.colaborated" label="Colaboró"></v-checkbox>
                  <v-checkbox v-model="item.pivot.colaboratedMother" label="Colaboró mamá"></v-checkbox>
                  <v-checkbox v-model="item.pivot.colaboratedFather" label="Colaboró papá"></v-checkbox>
                </v-col>
                <v-col v-if="eventType == 'event' && edittingResults.includes(parseInt(item.id))">
                  <v-checkbox v-model="item.pivot.participated" label="Participó"></v-checkbox>
                  <v-checkbox v-model="item.pivot.colaborated" label="Colaboró"></v-checkbox>
                  <v-checkbox v-model="item.pivot.colaboratedMother" label="Colaboró mamá"></v-checkbox>
                  <v-checkbox v-model="item.pivot.colaboratedFather" label="Colaboró papá"></v-checkbox>
                </v-col>
              </div>
            </template>
          </template>
          <template v-slot:[`item.next_degree`]="{ item }">
            <v-progress-circular v-if="assigningResultId == item.id" indeterminate
              color="primary"></v-progress-circular>
            <template v-else>
              <div
                v-if='!(edittingResults.includes(parseInt(item.id))) && modelResults.length != 0 && eventType == "exam"'>
                {{
                  item.degree.has_partials || item.already_promoted ?
                    item.degree.name :
                    item.next_degree.name
                }}
                {{
                  item.already_promoted ? '(Ya fue promovido)' : ''
                }}
              </div>
              <div v-else-if="edittingResults.includes(parseInt(item.id))">
                <v-select small outlined class="mt-5 mb-n5" :items="degrees" item-text="name" item-value="id"
                  v-model="item.next_degree" return-object />
                <caps-v-text-field v-if="item.degree.has_partials" small outlined class="mt-5 mb-n5" :disabled="true"
                  label="Parcial a subir" v-model="item.next_partial">
                </caps-v-text-field>
              </div>
            </template>
          </template>
        </v-data-table>
        <!-- Candidates -->
        <v-data-table v-if="!loadingTable && activeTab == 1" v-model="selectedCandidates" :search="search"
          :headers="headers.prospects" :items="model.candidates" :items-per-page="20"
          :server-items-length="serverTotalCandidates" :sort-by="['already_promoted', 'doyang_id', 'degree_id', 'id']"
          :sort-asc="[false, true, false, false]" class="elevation-1" :show-select="isGeneralDirection"
          :custom-filter="filterOnRequiredFields" :item-class="prospectRowBackground" @update:page="updatePage"
          :page.sync="currentPage">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon md class="" @click="moveToProspect(item)">
              mdi-check
            </v-icon>
            <v-btn v-if="eventType == 'tournament'" class="green lighten-2" small dark
              @click="openSomatometryDialog(item)">
              <v-icon>mdi-pencil</v-icon> Editar peso y talla
            </v-btn>
          </template>
        </v-data-table>
        <!-- Universo de alumnos -->
        <!-- <v-data-table
        v-if="activeTab == 2"
        :search="search"
        :headers="headers.universe"
        :items="model.universe"
        :items-per-page="20"
        class="elevation-1"
        :show-select="isGeneralDirection"
        :custom-filter="filterOnRequiredFields"
        :item-class="itemRowBackground"
        >
          <template v-slot:[`item.actions`]="{ item }" v-if="!isEventOver">
            <v-icon
              small
              class=""
              @click="moveToProspect(item)"
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table> -->
      </v-col>
    </v-row>
    <v-dialog v-model="addSomatometryDialogOpen">
      <v-card>
        <v-card-title>
          <h3>MEDICIONES</h3>
        </v-card-title>
        <v-card-text>
          <caps-v-text-field type="number" v-model="weightSomatometry" :placeholder="'Peso (kg)'"></caps-v-text-field>
          <caps-v-text-field type="number" v-model="heightSomatometry" :placeholder="'Talla (m)'"></caps-v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addSomatometryDialogOpen = false" class="grey lighten-2" dark>Cancelar</v-btn>
          <v-btn @click="updateStudent('add_tournament_somatometry')" class="green lighten-2"
            style="color:white">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTournamentCronogram">
      <v-card>
        <v-card-title>
          <h3>CRONOGRAMA</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-btn @click="autogenerateCronograms">Generar cronograma</v-btn>
                <v-btn @click="printCronogram" :disabled="!(tournament && tournament.name)">Imprimir
                  Cronograma</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <ul v-for="tournamentCronogram in tournamentCrongrams" :key="tournamentCronogram.id" class="print-cronogram">
            <li>{{ tournamentCronogram.name }} - {{ tournamentCronogram.start_time }} {{ tournamentCronogram.end_time
              }}
              <v-btn small class="ml-2 no-print" @click="addCronogramRecess(tournamentCronogram)">Agregar
                descanso</v-btn>
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addSomatometryDialogOpen = false" class="grey lighten-2" dark>Cancelar</v-btn>
          <v-btn @click="updateStudent('add_tournament_somatometry')" class="green lighten-2"
            style="color:white">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="model" v-model="isCronogramhowing">
      <v-card>
        <v-card-title>CRONOGRAMA</v-card-title>
        <v-card-text>
          BLOQUES:
          <v-btn @click="createExamBlock">Añadir bloque</v-btn>
          <br>
          <v-row class="mt-5">
            <v-col><caps-v-text-field :label="'Nombre del bloque'"
                v-model="createExamBlockPayload.name"></caps-v-text-field></v-col>
            <v-col md="1"><caps-v-text-field :label="'Duración (min)'" type="number"
                v-model="createExamBlockPayload.duration_min"></caps-v-text-field></v-col>
            <v-col><v-select outlined :label="'Grado de Inicio'" :items="degrees" item-text="name" item-value="id"
                v-model="createExamBlockPayload.start_degree_id"></v-select></v-col>
            <v-col><v-select outlined :label="'Grado Final'" :items="degrees" item-text="name" item-value="id"
                v-model="createExamBlockPayload.end_degree_id"></v-select></v-col>
            <v-col><caps-v-text-field type="number" :label="'Edad de inicio'"
                v-model="createExamBlockPayload.start_age"></caps-v-text-field></v-col>
            <v-col><caps-v-text-field type="number" :label="'Edad final'"
                v-model="createExamBlockPayload.end_age"></caps-v-text-field></v-col>
          </v-row>
          <v-col>
            <v-btn @click="saveOrder">Guardar orden</v-btn>
          </v-col>
          <v-data-table :headers="blocksHeaders" :items="model.blocks" :sort-by="'pivot.order'">
            <template v-slot:body="props">
              <draggable :list="props.items" tag="tbody" @end="endOrdering(props.items)">
                <tr v-for="(item, index) in props.items" :key="index">
                  <td v-for="header in blocksHeaders" :key="header.value">
                    <v-icon v-if="header.value == 'drag'" small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                    <div v-else-if="header.value == 'actions'">
                      <v-icon @click="deleteBlock(item)">mdi-delete</v-icon>
                    </div>
                    <div v-else-if="header.value == 'duration_min'">
                      <caps-v-text-field type="number" v-model="item.duration_min"
                        :placeholder="'Duración'"></caps-v-text-field>
                    </div>
                    <div v-else-if="header.value == 'pivot.max_quantity'">
                      <caps-v-text-field type="number" v-model="item.pivot.max_quantity"
                        :placeholder="'Alumnos por bloque'"></caps-v-text-field>
                    </div>
                    <div v-else-if="header.value == 'pivot.actual_quantity'">
                      <caps-v-text-field type="number" v-model="item.pivot.actual_quantity"
                        :placeholder="'# de alumnos'"></caps-v-text-field>
                    </div>
                    <div v-else>
                      {{ item[header.value] }}
                    </div>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import CapsVTextField from './CapsVTextField.vue';
var debounce = require('debounce');
export default {
  components: { CapsVTextField },
  mounted() {
    this.$store.dispatch('Time/time')
    this.$store.dispatch(`Degree/index`)
    this.$store.dispatch(`Professor/index`, `?certifier=1`)
    this.$store.dispatch('Requirement/index', '?with=products,other_exam_requisites');
    const blocksString = this.storeModel == 'Exam' ? 'with=blocks&' : '';
    this.$store.dispatch(`${this.storeModel}Result/index`).then(() => {
      const examId = this.$route.params.id
      this.loadingTable = true;
      this.$store.dispatch(`${this.storeModel}/show`, `${this.$route.params.id}?${blocksString}append=candidates,doyangProspects&exam_id=${examId}&doyang_id=${this.selectedDoyangIsGeneralDireciton ? '' : this.selectedDoyang.id}`).then(() => {
        let model = this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`] ? this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`] : {}
        if (model.candidates && (this?.selectedDoyang?.id != ENV.DIRECCIÓN_GENERAL_ID)) {
          this.serverTotalCandidates = model?.candidates?.total
          model.candidates = model.candidates.prospects.filter(candidate => {
            return candidate.doyang_id == this.selectedDoyang.id
          })
        } else {
          this.serverTotalCandidates = model?.candidates?.total
          model.candidates = model.candidates.prospects
        }
        this.serverTotal = model?.doyangProspects?.total

        model.doyangProspects = model?.doyangProspects?.prospects?.map(prospect => {
          this.modelResults.filter((result) => result.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`]).length > 0 ?
            this.modelResults.filter((result) => {
              return result.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`]
            })?.[0]['name']
            : 'N/A'
          prospect.already_promoted = prospect.already_promoted ?? prospect.pivot.exam_result_id > 0
          prospect.result = this.modelResults.filter((res) => res.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`])?.[0]?.['name']
          prospect.degree.has_partials = prospect.does_partials ?? false
          return prospect
        })

        this.model = model
        if (this.model.blocks)
          this.parseBlocksSchedule()
        this.loadingTable = false;
      })
    })
  },
  watch: {
    activeTab() {
      this.currentPage = 1
    },
    'search': debounce(function () {
      this.updateSearch()
    }, 1000),
    currentPage() {
      this.updateSearch()
    }
  },
  methods: {
    openForeignersCsv() {
      this.showForeignersCsv = true
      let payload = `?active=1&role_id=${ENV.ROLE_PUBLIC_RELATIONS},${ENV.ROLE_LINK_AND_ORIENTATION},${ENV.ROLE_DEPENDANT_KYOSA_ID},${ENV.ROLE_CLUB_KYOSA_ID},${ENV.ROLE_DOYANG_KYOSA_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}&with=degree,role`
      this.$store.dispatch('Student/index', payload);
    },
    uploadForeignersCsv() {
      alert('Subiendo archivo')
    },
    downloadAllExamSheets() {
      this.$store.dispatch('Exam/downloadAllExamSheets', `${this.$route.params.id}?doyang_id=${this.selectedDoyang.id}`)
    },
    // openRevisionDialog() {
    //   this.revisionDialog = true
    // },
    getProductsRequirementsForDegree(degreeId) {
      return this.degreesRequirements.filter(requirement => requirement.min_grade_id == degreeId)?.[0] || []
    },
    getOtherRequirementsForDegree(degreeId) {
      return this.degreesRequirements.filter(requirement => requirement.min_grade_id == degreeId)?.[0] || []
    },
    openRevisionDialog(item) {
      this.studentInRevision = item;
      this.revisionDialog = true;
      this.requirementProducts = [];
      this.requirementOther = [];
      this.$store.dispatch('Student/show', `${item.id}?black_belt_exam_id=${this.model.id}&append=revision_results`).then((res) => {
        console.log('res', res)
        const requirementsForDegreeDialog = this.getOtherRequirementsForDegree(item.degree_id)
        const productResults = res?.revision_results?.products || []
        const otherResults = res?.revision_results?.others || []
        console.log('requirementsForDegreeDialog', requirementsForDegreeDialog)
        this.requirementOther = requirementsForDegreeDialog?.other_exam_requisites.map(requirement => {
          const result = otherResults.filter(result => result.id == requirement.id)?.[0] || {}
          requirement.checked = Boolean(result?.checked)
          requirement.date = result?.date
          requirement.comment = result?.comment
          return requirement
        })
        this.requirementProducts = requirementsForDegreeDialog?.products.map(requirement => {
          const result = productResults.filter(result => result.id == requirement.id)?.[0] || {}
          requirement.checked = Boolean(result?.checked)
          requirement.date = result?.date
          requirement.comment = result?.comment
          return requirement
        })
        const requisites_order = [5, 42, 41, 45, 36, 47, 44, 32, 34, 33, 48, 37, 35, 51, 49]
        this.requirementProducts = this.requirementProducts.sort((a, b) => {
          return requisites_order.indexOf(a.id) - requisites_order.indexOf(b.id)
        })
      });
    },
    getAgeInYears(birthDate) {
      if (!birthDate) {
        return '';
      }
      var today = new Date();
      birthDate = new Date(birthDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    downloadTournamentHotelBusListExcel() {
      const eventId = this.$route.params.id;
      this.$store.dispatch('Tournament/downloadFile', `/${eventId}/payment_bus_hotel_list_excel?doyang_id=23`).then((res) => {
        this.downloadExcel(res, 'tournament_excel_' + eventId + '.xlsx')
      })
    },
    downloadExcel(response, fileName) {
      try {
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    assignResultsFromGraphs() {
      this.assignResultsButtonDisabled = true
      this.$store.dispatch('Tournament/index', `/${this.$route.params.id}/prospects/results_from_graphs`).then(() => {
        alert('Resultados asignados')
        this.assignResultsButtonDisabled = false
      }).catch(() => {
        alert('Error al asignar resultados')
        this.assignResultsButtonDisabled = false
      })
    },
    printCronogram() {
      const stringAsTable = this.tournamentCrongrams.map((cronogram) => {
        return `<tr><td>${cronogram.name}</td><td>${cronogram.start_time}</td><td>${cronogram.end_time}</td></tr>`
      }).join('\n')
      // print as a div
      const printWindow = window.open('', '', 'width=600,height=600');
      printWindow.document.write('<html><head><title>Cronograma</title>');
      printWindow.document.write('</head><body >');
      // h1 crenograma torneo name
      printWindow.document.write('<h1>CRONOGRAMA</h1>');
      printWindow.document.write(`<h2>${this.tournament.name}</h2>`);

      // table
      printWindow.document.write('<table border="1">');
      printWindow.document.write('<tr><td>Nombre</td><td>Inicio</td><td>Fin</td></tr>');
      printWindow.document.write(stringAsTable);
      printWindow.document.write('</body></html>');
      printWindow.print();
      printWindow.document.close();
    },
    loadTournamentCronogram() {
      this.$store.dispatch('Tournament/indexCronograms', `?tournament_id=${this.$route.params.id}`).then(() => {
        this.showTournamentCronogram = true
      })
    },
    addCronogramRecess(beforeCronogram) {
      const duration = prompt('Duración del receso (minutos)');
      if (isNaN(duration) || duration <= 0) {
        alert('Duración inválida');
        return;
      }
      this.$store.dispatch('Tournament/addCronogramRecess', {
        tournament_id: this.$route.params.id,
        duration: duration,
        start_time: beforeCronogram.end_time,
        order: beforeCronogram.order + 1,
        name: 'Receso',
        tournament_degree_category_id: beforeCronogram.tournament_degree_category_id,
        tournament_age_group_id: beforeCronogram.tournament_age_group_id,
      })
    },
    autogenerateCronograms() {
      if (!confirm('¿Estás seguro que queires reiniciar el cronograma?')) {
        return;
      }
      this.$store.dispatch('Tournament/autogenerateCronograms', this.$route.params.id);
    },
    goToGraphics() {
      this.$router.push({ name: 'TournamentGraphs', params: { id: this.$route.params.id } })
    },
    deleteBlock(item) {
      this.$store.dispatch('ExamBlock/delete', item.id).then(() => {
        this.model.blocks = this.model.blocks.filter((block) => {
          return block.id != item.id
        })
        this.parseBlocksSchedule()
      })
    },
    parseBlocksSchedule() {
      const examStartTimeString = this.model.begins_at;
      const examStartTime = new Date(`01/01/2007 ${examStartTimeString}`);
      let accumulativeTime = null;
      if (this.model.blocks) {
        this.model.blocks = this.model.blocks.sort((a, b) => {
          return a.pivot.order - b.pivot.order
        })
      }
      this.model.blocks = this.model?.blocks.map((block) => {
        const blockDurationMin = block.duration_min
        const numberOfBlocks = Math.ceil((block.pivot.actual_quantity || 0) / (block.pivot.max_quantity || 1));
        const blockStartTime = accumulativeTime || new Date(examStartTime.getTime() + (block.pivot.order * (blockDurationMin * 60000)));
        const blockEndTime = new Date(blockStartTime.getTime() + (block.duration_min * 60000 * numberOfBlocks));
        accumulativeTime = blockEndTime;
        block['start_time'] = blockStartTime.toLocaleTimeString();
        block['end_time'] = blockEndTime.toLocaleTimeString();
        return block;
      })

    },
    endOrdering(items) {
      this.model.blocks = items.map((block, index) => {
        if (block?.pivot.order != index) {
          block['pivot'].order = index
        }
        return block
      })
      this.parseBlocksSchedule()
    },
    saveOrder() {
      const promises = [];
      this.disableSaveOrderButton = true;
      console.log('this.model.blocks', this.model.blocks)
      this.model.blocks.forEach((block, index) => {
        console.log('block', block)
        console.log('block.duration', block.duration_min)
        promises.push(this.$store.dispatch('ExamBlock/update', {
          id: block.id,
          order: index,
          duration_min: block.duration_min,
          max_quantity: block.pivot.max_quantity,
          actual_quantity: block.pivot.actual_quantity,
          exam_id: this.$route.params.id,
        }))
      })
      Promise.all(promises).then(() => {
        this.disableSaveOrderButton = false;
        this.parseBlocksSchedule()
        alert('Horarios actualizados')
      }).catch(() => {
        this.disableSaveOrderButton = false;
        alert('Error al actualizar horarios')
      })
    },
    createExamBlock() {
      this.$store.dispatch('ExamBlock/create', this.createExamBlockPayload).then((res) => {
        res = {
          ...res,
          order: this.model.blocks.length,
          max_quantity: 0,
          actual_quantity: 0,
          pivot: {
            order: this.model.blocks.length,
            max_quantity: 0,
            actual_quantity: 0,
          }
        }
        this.model.blocks.push(res)
        this.parseBlocksSchedule()
      })
    },
    downloadStringInNewPage(string, filename) {
      const blob = new Blob([string], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    printCompleteResultList() {
      const doyangIdParam = `?doyang_id=${this.selectedDoyang.id}`;
      const idString = `${this.eventType}_id`
      const examIdParam = `&${idString}=${this.$route.params.id}&perPage=0`
      const params = `${doyangIdParam}${examIdParam}`
      this.loadingPrintSheet = true
      this.$store.dispatch(`${this.storeModel}/index`, `/prospects_list_csv${params}`).then((res) => {
        this.loadingPrintSheet = false
        this.downloadStringInNewPage(res, 'lista_completa.csv')
      }).catch(() => {
        this.loadingPrintSheet = false
      })
    },
    openSomatometryDialog(item) {
      this.edditingSomatometryStudent = item;
      const tournamentId = this.$route.params.id;
      this.$store.dispatch('Student/show', `${item.id}?append=somatometry_for_tournament&tournament_id=${tournamentId}`).then((response) => {
        this.weightSomatometry = response.somatometry_for_tournament?.weight_kg;
        this.heightSomatometry = response.somatometry_for_tournament?.height_m;
      })
      this.addSomatometryDialogOpen = true;
    },
    updateStudent(action = null) {
      const user_id = this.edditingSomatometryStudent.id;
      this.$store.dispatch('Student/update', {
        action: action,
        id: user_id,
        weight_kg: this.weightSomatometry,
        height_m: this.heightSomatometry,
        tournament_id: this.$route.params.id,
      }).then(() => {
        this.addSomatometryDialogOpen = false;
        this.weightSomatometry = null;
        this.heightSomatometry = null;
      })
    },
    didntTakeExam(item) {
      item.pivot.exam_result_id = 12 // no presentó
      this.assignResult(item)

    },
    updatePage(page) {
      this.currentPage = page
    },
    updateSearch() {
      const examId = this.$route.params.id
      const searchString = this.search ? 'prospectSearch=' + this.search + '&' : '';
      this.loadingTable = true;
      this.$store.dispatch(`${this.storeModel}/show`, `${this.$route.params.id}?${searchString}page=${this.currentPage}&append=candidates,doyangProspects&exam_id=${examId}&doyang_id=${this.selectedDoyangIsGeneralDireciton ? '' : this.selectedDoyang.id}`).then(() => {
        let model = this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`] ? this.$store.getters[`${this.storeModel}/${this.storeModel.toLowerCase()}`] : {}
        if (model.candidates && (this?.selectedDoyang?.id != ENV.DIRECCIÓN_GENERAL_ID)) {
          this.serverTotalCandidates = model?.candidates?.total
          model.candidates = model.candidates.prospects.filter(candidate => {
            return candidate.doyang_id == this.selectedDoyang.id
          })
        } else {
          this.serverTotalCandidates = model?.candidates?.total
          model.candidates = model.candidates.prospects
        }
        this.serverTotal = model?.doyangProspects?.total
        model.doyangProspects = model?.doyangProspects?.prospects?.map(prospect => {
          this.modelResults.filter((result) => result.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`]).length > 0 ?
            this.modelResults.filter((result) => {
              return result.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`]
            })?.[0]['name']
            : 'N/A'
          prospect.already_promoted = prospect.already_promoted ?? prospect.pivot.exam_result_id > 0
          prospect.result = this.modelResults.filter((res) => res.id == prospect.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`])?.[0]?.['name']
          prospect.degree.has_partials = prospect.does_partials ?? false
          return prospect
        })
        this.model = model
        this.loadingTable = false
      })
    },
    setResultWithNumeric(item) {
      const numericResult = item.pivot.numeric_result;
      const equivalences = [
        { id: 1, name: "Excelente", limit: 98 }, // -Infinity means no lower limit
        { id: 2, name: "Muy bien arriba", limit: 95 },
        { id: 3, name: "Muy bien", limit: 90 },
        { id: 4, name: "Muy bien abajo", limit: 85 },
        { id: 5, name: "Bien arriba", limit: 80 },
        { id: 6, name: "Bien", limit: 75 },
        { id: 7, name: "Bien abajo", limit: 70 },
        { id: 8, name: "Suficiente arriba", limit: 65 },
        { id: 9, name: "Suficiente", limit: 63 },
        { id: 10, name: "Suficiente abajo", limit: 60 }, // -Infinity means no lower limit
        { id: 11, name: "No aprobado", limit: -Infinity }, // -Infinity means no lower limit
      ];
      const selectedEventResult = equivalences.find((equivalence) => numericResult >= equivalence.limit);
      item.pivot.exam_result_id = selectedEventResult.id;
    },

    getResultById(searchId) {
      if (!searchId) return false;
      if (!this.modelResults) return false;
      const result = this.modelResults.filter((modelResult) => {
        return searchId == modelResult.id
      })
      if (result.length == 0) return false
      return result[0].name
    },
    changeResult(item, resultId) {
      let index = false
      this.model.doyangProspects.filter((prospecItem, elementIndex) => {
        index = (prospecItem.id == item.id && !index) ? elementIndex : index
      })
      this.$set(this.model.doyangProspects[index].pivot, 'exam_result_id', resultId)
      this.edittingResults.push(item.id)
      this.$set(this, 'edittingResults', JSON.parse(JSON.stringify(this.edittingResults)))
    },
    printResultSheetByCertifier() {
      const params = `?doyang_id=${this.selectedDoyang.id}&certifier_id=${this.certifier_id}`
      const payload = `${this.$route.params.id}${params}`
      this.$store.dispatch(`Exam/printSheetByCertifier`, payload)
    },
    printResultSheet(filled) {
      const params = this.selectedDoyangIsGeneralDireciton ? `` : `?doyang_id=${this.selectedDoyang.id}`
      this.loadingPrintSheet = true
      if (filled) {
        this.$store.dispatch(`${this.storeModel}/printSheet`, `${this.$route.params.id}${params}`).then(() => {
          this.loadingPrintSheet = false
        })
      } else {
        this.$store.dispatch(`${this.storeModel}/printSheet`, `${this.$route.params.id}${params}`).then(() => {
          this.loadingPrintSheet = false
        })
      }
    },
    pastExamApplicaton(examId, user) {
      this.printExamAppilcation(examId, user)
    },
    prospectRowBackground: function (item) {
      return (item?.already_promoted) ? 'green lighten-2' : ''
    },
    itemRowBackground: function (item) {
      return item ? 'green-row' : 'green-row'
    },
    printExamAppilcation(examId, user) {
      console.log('user', user)
      const ageInYears = this.getAgeInYears(user.birth_date)
      console.log('ageInYears', ageInYears)
      let errorText = ''
      if (!user.last_aproved_exam_date && user.degree_id != 1)
        errorText += 'El alumno debe tener fecha de ultimo examen aprobado\n'
      if (!user.civil_state)
        errorText += 'El alumno debe tener estado civil\n'
      if (!user.job_description)
        errorText += 'El alumno debe tener ocupación\n'
      if (!user.start_tkd_date)
        errorText += 'El alumno debe tener fecha de inicio en Taekwondo\n'
      if (!user.birth_date)
        errorText += 'El alumno debe tener fecha de nacimiento\n'
      if (!user.responds_to_user || !user.responds_to_user.name)
        errorText += 'El alumno debe tener un profesor\n'
      if (!user?.exam_discounts?.revision && !this.model.is_black_belt)
        errorText += 'El alumno debe tener su revisión de requisitos completa'
      if (ageInYears >= 18 && !user.cellphone)
        errorText += 'El alumno debe tener un número de celular\n'
      if (ageInYears < 18 && !user.father_cellphone && !user.mother_cellphone)
        errorText += 'El alumno debe tener un número de celular de alguno de sus padres\n'
      if (!user.address)
        errorText += 'El alumno debe tener dirección\n'

      //TODO faltas y asistencias
      if (errorText.length > 0) {
        alert(errorText)
        return
      }
      let partialNumber = null
      if (user?.does_partials && user?.number_of_partials === undefined) {
        partialNumber = prompt('Introduce el número de parcial')
      }
      const payload = `?${partialNumber ? "partial_number=" + partialNumber : ''}`
      this.$store.dispatch('Exam/printApplication', { examId, userId: user.id, payload })
    },
    uncapitalizeFirstLetter(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    addEditingItem(item) {
      this.edittingResults.push(item.id)
    },
    testMoveEvent() {
      this.eventTestDate = '2020-01-01'
    },
    assignResult(student) {
      this.assigningResultId = student.id
      let payload = {}
      payload[`${this.uncapitalizeFirstLetter(this.storeModel)}Id`] = this.model.id
      payload['userId'] = student.id
      payload['number_of_partials'] = student.next_partial
      payload[`${this.uncapitalizeFirstLetter(this.storeModel)}ResultId`] = student.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`]
      payload['next_degree'] = student.degree.has_partials ?
        student.degree :
        student.next_degree
      payload['numeric_result'] = student.pivot.numeric_result
      //tournament results
      payload['result_boards'] = student?.pivot?.result_boards;
      payload['result_techniques'] = student?.pivot?.result_techniques;
      payload['result_forms'] = student?.pivot?.result_forms;
      payload['result_combat'] = student?.pivot?.result_combat;
      payload['result_jumping_kicks'] = student?.pivot?.result_jumping_kicks;
      payload['result_kicking_speed'] = student?.pivot?.result_kicking_speed;
      payload['colaborated'] = student?.pivot?.colaborated;
      payload['colaborated_mother'] = student?.pivot?.colaboratedMother;
      payload['colaborated_father'] = student?.pivot?.colaboratedFather;
      if (this.eventType == 'event') {
        payload['colaborations'] = {
          participated: student.pivot.participated,
          colaborated: student.pivot.colaborated,
          colaboratedMother: student.pivot.colaboratedMother,
          colaboratedFather: student.pivot.colaboratedFather,
        }
      }
      this.$store.dispatch(`${this.storeModel}/assignResult`, payload).then(() => {
        if (this.eventType == 'exam') {
          student.degree = student.next_degree
        }
      }).catch(() => {
        alert('Error al asignar al usuario');
      }).finally(() => {
        student.already_promoted = 1
        this.assigningResultId = 0
        this.edittingResults.splice(this.edittingResults.indexOf(student.id), 1)
        student.result = this.modelResults.filter((res) => res.id == student.pivot[`${this.uncapitalizeFirstLetter(this.storeModel)}_result_id`])?.[0]['name']
      })
    },
    bulkAproveProspect() {
      this.selectedProspects.forEach((user) => {
        let payload = { is_prospect_approved: true }
        payload[`${this.uncapitalizeFirstLetter(this.storeModel)}Id`] = this.model.id
        payload['userId'] = user.id
        this.$store.dispatch(`${this.storeModel}/aproveRejectProspect`, payload)
      });
    },
    bulkRejectProspect() {
      this.selectedProspects.forEach((user) => {
        let payload = { is_prospect_approved: false }
        payload[`${this.uncapitalizeFirstLetter(this.storeModel)}Id`] = this.model.id
        payload['userId'] = user.id
        this.$store.dispatch(`${this.storeModel}/aproveRejectProspect`, payload)
      });
    },
    bulkMoveToCandidate() {
      this.selectedProspects.forEach((user) => {
        this.moveToCandidate(user.id)
        this.selectedProspects = []
      });
    },
    bulkMoveToPospect() {
      this.selectedCandidates.forEach((user) => {
        this.moveToProspect(user);
        this.selectedCandidates = []
      });
    },
    filterOnRequiredFields(value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        item.full_name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
    },
    moveToCandidate(userId) {
      let payload = {
        userId,
      }
      payload[`${this.uncapitalizeFirstLetter(this.storeModel)}Id`] = this.model.id
      this.$store.dispatch(`${this.storeModel}/deleteProspect`, payload).then((res) => {
        if (res.data == 0) {
          alert('Hubo un error al cambiar este alumno a candidato')
        }
      }).catch(() => {
        alert('Hubo un error al cambiar este alumno a candidato')
      })
    },
    async moveToProspect(user) {
      let payload = {
        userId: user.id,
      }
      payload[`${this.uncapitalizeFirstLetter(this.storeModel)}Id`] = this.model.id
      if (this.eventType == 'tournament') {
        const tournamentId = this.$route.params.id
        const hasSomatometry = await this.$store.dispatch(`Student/show`, `${user.id}?append=somatometry_for_tournament&tournament_id=${tournamentId}`).then((reponse) => {
          return reponse.somatometry_for_tournament?.weight_kg && reponse.somatometry_for_tournament?.height_m
        })
        const hasPassed = this.time >= this.model.date
        if (!hasSomatometry && !hasPassed) {
          alert('El alumno no tiene peso y talla registrados')
          return
        }
      }
      this.$store.dispatch(`${this.storeModel}/addProspect`, payload).then(() => {
      })
      return
    }
  },
  computed: {
    professors() {
      return this.$store.getters['Student/students'].filter(user => user.active)
    },
    hasPassed() {
      return this.time >= this.model.date
    },
    degreesRequirements() {
      return this.$store.getters['Requirement/requirements']
    },
    tournament() {
      return this.$store.getters['Tournament/tournament']
    },
    tournamentCrongrams() {
      return this.$store.getters['Tournament/cronograms']
    },
    time() {
      return this.$store.getters['Time/time']
    },
    eventType() {
      return this.storeModel.toLowerCase()
    },
    certifiers() {
      return this.$store.getters['Professor/professors']
    },
    selectedDoyangIsGeneralDireciton() {
      return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID)
    },
    isGeneralDirection() {
      return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID) || ENV.permissions.can_see_general_direction.includes(parseInt(this.authInfo?.user?.role_id))
    },
    degrees() {
      return this.$store.getters['Degree/degrees']
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
    selectedDoyang() {
      return this.$store.getters['Auth/selectedDoyang']
    },
    defaultStoreModelResult: function () {
      return `${this.storeModel}Result`
    },
    modelResults: function () {
      return this.$store.getters[`${this.defaultStoreModelResult}/${this.uncapitalizeFirstLetter(this.defaultStoreModelResult)}s`]
    },
    isEventOver: function () {
      let todayDate = (new Date().toISOString()).substr(0, 10)
      let eventDate = this.model?.date
      if (this.eventTestDate) {
        eventDate = this.eventTestDate
      }
      return todayDate >= eventDate
    },
    headers: function () {
      let headers = {
        prospects: [
          { text: 'Nombre', value: 'full_name' },
          { text: 'Doyang', value: 'doyang.name' },
          { text: 'FN', value: 'birth_date' },
          { text: 'Grado', value: 'degree.name' }
        ],
        candidates: [
          { text: 'Nombre', value: 'full_name' },
          { text: 'Doyang', value: 'doyang.name' },
          { text: 'FN', value: 'birth_date' },
          { text: 'Grado', value: 'degree.name' }
        ],
        // universe: [
        //     {text: 'Nombre', value: 'full_name'},
        //     {text: 'Doyang', value: 'doyang.name'},
        //     {text: 'FN', value: 'birth_date'},
        //     {text: 'Grado', value: 'degree.name'}
        // ]
      };
      if (this.eventType == 'tournament') {
        headers.prospects.push(
          // {text: 'Peso', value: 'weight_kg', sortable: false},
          // {text: 'Talla', value: 'height_m', sortable: false}
        )
      }
      if (this.isEventOver) {
        headers.prospects.push({ text: 'Resultado', value: 'result', sortable: false })
        if (this.eventType == 'exam') {
          headers.prospects.push({ text: 'Grado a promover', value: 'next_degree', sortable: false })
        }
        headers.prospects.push({ text: 'Acciones', value: 'actions', sortable: false })
      } else {
        headers.prospects.push({ text: 'Aprobado', value: 'approvedTemplate', sortable: false })
        headers.prospects.push({ text: 'Acciones', value: 'actions', sortable: false })
        headers.candidates.push({ text: 'Acciones', value: 'actions', sortable: false })
      }
      return headers;
    }
  },
  data() {
    return {
      foreignersCsv: null,
      showForeignersCsv: false,
      assignAllResultsSheetDisabled: false,
      revisionDialog: false,
      requirementProducts: [],
      requirementOther: [],
      assignResultsButtonDisabled: false,
      showTournamentCronogram: false,
      disableSaveOrderButton: false,
      blocksHeaders: [
        { text: 'Ordenar', value: 'drag' },
        { text: 'Acciones', value: 'actions' },
        { 'text': 'Bloque', 'value': 'name' },
        { 'text': 'Duración(m)', 'value': 'duration_min' },
        { 'text': 'Alumnos p/bloque', 'value': 'pivot.max_quantity' },
        { 'text': '# de alumnos', 'value': 'pivot.actual_quantity' },
        { 'text': 'Hora de inicio', 'value': 'start_time' },
        { 'text': 'Hora de fin', 'value': 'end_time' },
      ],
      createExamBlockPayload: {
        name: null,
        start_degree_id: 1,
        duration_min: 0,
        end_degree_id: 1,
        order: 1,
        exam_id: this.$route.params.id
      },
      isCronogramhowing: false,
      serverTotalCandidates: 0,
      weightSomatometry: null,
      heightSomatometry: null,
      addSomatometryDialogOpen: false,
      loadingTable: false,
      serverTotal: 0,
      currentPage: 1,
      edittingNumericResult: null,
      loadingPrintSheet: false,
      certifier_id: null,
      edittingResults: [],
      edittingResultsValue: {
        participated: false,
        colaborated: false,
        colaboratedMother: false,
        colaboratedFather: false,
      },
      eventTestDate: null,
      selectedProspects: [],
      selectedCandidates: [],
      assigningResultId: 0,
      activeTab: null,
      search: null,
      model: null
    }
  },
  props: {
    storeModel: String,
    storeModelResult: String
  }
}
</script>

<style>
.green-row {
  /* background-color: rgb(93, 194, 93); */
}
</style>
