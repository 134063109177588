import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    holidays: [],
    holiday: {}
  },
  mutations: {
    index(state, payload) {
      state.holidays = payload
    },
    show(state,payload) {
      state.holiday = payload
    },
    addToIndex (state, payload) {
      state.holidays = [payload, ...state.holidays]
    },
    update (state, payload) {
      state.holiday = payload
      state.holidays = state.holidays.map((doyangGroup) => {
        if (doyangGroup.id == payload.id) {
          doyangGroup = payload
        }
        return doyangGroup
      })
    },
    remove(state, payload) {
      state.holidays = state.holidays.filter((doyangGroup) => doyangGroup.id != payload.id)
    },
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/holidays${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/holidays/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/holidays`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/holidays/${payload.id}`, payload).then((res) => {
        if (payload.date) {
          commit('updateAssistance', res.data)
          return res.data
        }
        commit('update', res.data)
        return res.data
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/holidays/${payload}`).then((res) => {
        commit('remove', res.data)
      })
    }
  },
  getters: {
    holidays: state => {
      return state.holidays
    },
    holiday: state => {
      return state.holiday
    }
  },
  modules: {
  }
}
