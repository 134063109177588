<template>
  <v-container>
    <h2>EVENTOS</h2>
    <v-row>
      <v-col>
        <v-checkbox label="Solo este año" v-model="filterThisYearOnly"></v-checkbox>
      </v-col>
    </v-row>
    <GlobalEventProcessIndex :storeModel="storeModel" :filterThisYearOnly="filterThisYearOnly"
      :permissionRoot="'Eventos,Doyang Eventos'" />
  </v-container>
</template>

<script>
import GlobalEventProcessIndex from '../globals/CRUDEventProcessIndex'
export default {
  components: {
    GlobalEventProcessIndex
  },
  methods: {
    initializeFilter() {
      if (!localStorage.getItem('filterThisYearOnly'))
        localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly);
      this.filterThisYearOnly = localStorage.getItem('filterThisYearOnly') == 'true';
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initializeFilter();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.initializeFilter();
    next();
  },
  watch: {
    filterThisYearOnly() {
      localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly)
    }
  },
  data() {
    return {
      filterThisYearOnly: true,
      storeModel: 'Event'
    }
  }
}
</script>

<style></style>
