<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>PRODUCTOS MDK</h2>
          <CRUDIndex
            :permissionRoot="'Productos'"
            :headers="headers"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  mounted () {
    this.$store.dispatch('Product/index', this.$route.params.id).then((products) => {
      const product = products[0]
      product.prices.forEach((price) => {
        this.headers.push({
          text: `Precio ${price.name}`,
          value: price.price
        })
      })
    })
  },
  data () {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Precio al público', value: 'price' },
      ],
      storeModel: 'Product',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
