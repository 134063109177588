import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    venues: [],
    venue: {}
  },
  mutations: {
    index(state, payload) {
      state.venues = payload
    },
    show(state,payload) {
      state.venue = payload
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/venues${payload || ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/venues/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/venues`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/venues/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res;
      })
    }
  },
  getters: {
    venues: state => {
      return state.venues
    },
    venue: state => {
      return state.venue
    }
  },
  modules: {
  }
}
