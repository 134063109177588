import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    exam_blocks: [],
    exam_block: {},
  },
  mutations: {
    delete(state,payload) {
      state.exam_blocks = state.exam_blocks.filter((exam_block) => exam_block.id != payload.id)
    },
    empty() {},
    index(state, payload) {
      state.exam_blocks = payload
    },
    show(state,payload) {
      state.exam_block = payload
    },
  },
  actions: {
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/exam_blocks/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    },
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exam_blocks${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/exam_blocks/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/exam_blocks`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/exam_blocks/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    deleteProspect({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/exam_blocks/${payload.exam_blockId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('deleteProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
  },
  getters: {
    exam_blocks: state => {
      return state.exam_blocks
    },
    exam_block: state => {
      return state.exam_block
    }
  },
  modules: {
  }
}
