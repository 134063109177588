import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    products: [],
    product: {}
  },
  mutations: {
    index(state, payload) {
      state.products = payload
    },
    show(state, payload) {
      state.product = payload
    },
    delete(state, payload) {
      // payload.all_doyangs = state.sinodal.all_doyangs ? state.sinodal.all_doyangs : payload.all_doyangs
      state.products = state.products.filter( product => {
        return product.id != payload.id
      })
    },
    editExistence(state, payload) {
      state.products = state.products.map(product => {
        if (product.id == payload.id) {
          return payload
        }
        return product
      });
    }
  },
  actions: {
    index({ commit }, payload = '') {
      return axios.get(`${ENV.API_URL}/api/products${payload}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/products/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/products`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/products/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({ commit }, payload) {
      console.log(`${ENV.API_URL}/api/products/${payload}`);
      return axios.delete(`${ENV.API_URL}/api/products/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    },
    editExistence ({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/product_sizes/${payload.product_size_id}`, payload).then((res) => {
        commit('editExistence', res.data)
        return res.data
      })
    }
  },
  getters: {
    products: state => {
      return state.products
    },
    product: state => {
      return state.product
    }
  },
  modules: {}
}
