import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    sinodals: [],
    sinodal: {},
    EXTRAS: {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
  },
  mutations: {
    index(state, payload) {
      state.sinodals = payload
    },
    show(state, payload) {
      // payload.all_doyangs = state.sinodal.all_doyangs ? state.sinodal.all_doyangs : payload.all_doyangs
      state.sinodal = payload
    },
    delete(state, payload) {
      // payload.all_doyangs = state.sinodal.all_doyangs ? state.sinodal.all_doyangs : payload.all_doyangs
      console.log('payload', payload)
      state.sinodals = state.sinodals.filter( sinodal => {
        return sinodal.id != payload.id
      })
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/users${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/users/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
        let formData = new FormData();

        Object.keys(payload).forEach((key) => {
            formData.append(key, formData[key])
        })
        const EXTRAS = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(`${ENV.API_URL}/api/users`, formData, EXTRAS).then((res) => {
            commit('show', res.data)
        })
    },
    update({ commit }, payload) {
        let formData = new FormData();

        Object.keys(payload).forEach((key) => {
            formData.append(key, formData[key])
        })
        const EXTRAS = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        print(formData);
        return axios.put(`${ENV.API_URL}/api/users/${payload.id}`, formData, EXTRAS).then((res) => {
            commit('show', res.data)
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/users/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    sinodals: state => {
      return state.sinodals
    },
    sinodal: state => {
      return state.sinodal
    }
  },
  modules: {
  }
}
