<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>Descuentos</h2>
      </v-col>
    </v-row>
    <CRUDIndex
      :headers="headers"
      :payload="payload"
      :storeModel="storeModel"
      :itemsPerPage="itemsPerPage"
      :routeCreate="routeCreate"
    />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data () {
    return {
      routeCreate: 'DiscountCreate',
      headers: [
        {text: 'Nombre', value:'name'}
      ],
      payload: '',
      storeModel: 'Discount',
      itemsPerPage: 20
    }
  }
}
</script>

<style>

</style>e