<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>TORNEOS MDK</h2>
        <v-row>
          <v-col>
            <v-checkbox label="Solo este año" v-model="filterThisYearOnly"></v-checkbox>
          </v-col>
        </v-row>
        <GlobalEventProcessIndex :permissionRoot="'Torneos,Doyang Torneos'" :storeModel="storeModel"
          :routeCreate="routeCreate" :routeProspects="routeProspects" :routeEdit="routeEdit"
          :filterThisYearOnly="filterThisYearOnly" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GlobalEventProcessIndex from '../globals/CRUDEventProcessIndex'
export default {
  components: {
    GlobalEventProcessIndex
  },
  methods: {
    initializeFilter() {
      if (!localStorage.getItem('filterThisYearOnly'))
        localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly);
      this.filterThisYearOnly = localStorage.getItem('filterThisYearOnly') == 'true';
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.initializeFilter();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.initializeFilter();
    next();
  },
  watch: {
    filterThisYearOnly() {
      localStorage.setItem('filterThisYearOnly', this.filterThisYearOnly)
    }
  },
  data() {
    return {
      headers: [
        { text: 'Torneo', value: 'name' },
        { text: 'Fecha', value: 'date' },
      ],
      filterThisYearOnly: true,
      storeModel: 'Tournament',
      routeCreate: 'TournamentCreate',
      routeEdit: 'TournamentEdit',
      routeProspects: 'TournamentProspects',
      itemsPerPage: 20
    }
  },
}
</script>

<style></style>
