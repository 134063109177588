import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    discounts: [],
    discount: {}
  },
  mutations: {
    index(state, payload) {
      state.discounts = payload 
    },
    show(state, payload) {
      state.discount = payload
    }
  },
  actions: {
    index({commit}) {
      axios.get(`${ENV.API_URL}/api/discounts`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      axios.get(`${ENV.API_URL}/api/discounts/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      axios.post(`${ENV.API_URL}/api/discounts`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    uptade({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/discounts/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    discounts: state => {
      return state.discounts
    },
    discount: state => {
      return state.discount
    }
  },
  modules: {}
}
