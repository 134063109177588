import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangSurrogationBuses: [],
    doyangSurrogationBus: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangSurrogationBus = payload
    },
    show(state, payload) {
      state.doyangSurrogationBus = payload
    },
    delete(state,payload) {
      state.doyangSurrogationBuses = state.doyangSurrogationBuses.filter((size) => size.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_bus${payload || ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_surrogation_bus/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_surrogation_bus`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_surrogation_bus/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_surrogation_bus/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    doyangSurrogationBuses: state => {
      return state.doyangSurrogationBuses
    },
    doyangSurrogationBus: state => {
      return state.doyangSurrogationBus
    }
  },
  modules: {
  }
}
