<template>
  <v-container>
    <CRUDEventProspects :storeModel="storeModel"/>
  </v-container>
</template>

<script>
import CRUDEventProspects from '../globals/CRUDEventProspects'
export default {
  components: {
    CRUDEventProspects
  },
  data () {
    return {
      storeModel: 'Exam',
    }
  }
}
</script>

<style>

</style>