<template>
  <v-container>
    <v-row>
        <v-col>
            <h2 v-if="generalFileResourceId">EDITAR RECURSO DIGITALES MDK</h2>
            <h2 v-else>CREAR RECURSO DIGITALES MDK</h2>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-row>
                <v-col><CapsVTextField placeholder="Nombre del Archivo" v-model="name"></CapsVTextField></v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-file-input
                        v-model="file"
                        accept="application/pdf,image/*"
                        label="Archivo"
                        outlined
                    ></v-file-input>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-btn v-if="!generalFileResourceId" :disabled="isUploadingFile" color="primary" @click="create">Crear</v-btn>
            <v-btn v-else color="primary" :disabled="isUploadingFile" @click="update">Actualizar</v-btn>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    mounted() {
        this.generalFileResourceId = this.$route.params.id
    },
    methods: {
        create() {
            const formData = new FormData()
            formData.append('name', this.name)
            formData.append('file', this.file)
            this.isUploadingFile = true
            this.$store.dispatch('GeneralFileResource/create', formData).then(() => {
                this.$router.push({ name: 'GeneralFileResourceIndex' })
            }).catch(() => {
                alert('Hubo un error al crear el recurso digital. Inténtalo de nuevo.')
                this.isUploadingFile = false
            })
        },
        update() {
            const formData = new FormData()
            formData.append('name', this.name)
            formData.append('file', this.file)
            formData.append('id', this.generalFileResourceId)
            this.isUploadingFile = true
            this.$store.dispatch('GeneralFileResource/update', formData).then(() => {
                this.$router.push({ name: 'GeneralFileResourceIndex' })
            }).catch(() => {
                alert('Hubo un error al actualizar el recurso digital. Inténtalo de nuevo.')
            })
        }
    },
    data() {
        return {
            isUploadingFile: false,
            generalFileResourceId: null,
            name: null,
            file: null,
        }
    }
}
</script>

<style>

</style>
