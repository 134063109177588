<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="doyangGroup">KYOSAS DEL GRUPO {{ doyangGroup.name }}</h2>
        <v-btn @click="() => {
          this.$router.go(-1)
        }" class="green lighten-2" dark>Regresar a lista de grupo</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="bimesterStart && bimesterEnd">
        <h3>BIMESTRE {{ ENV.__parseDateToText(bimesterStart) }}-{{ ENV.__parseDateToText(bimesterEnd) }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete label="Kyosa" chips outlined :search-input.sync="searchStudent" :items="students"
          v-model="kyosaToAdd" item-text="full_name" return-object />
      </v-col>
      <v-col cols=12>
        <v-btn :disabled="kyosaToAdd == null" @click="addKyosa" class="blue lighten-2" style="color:white;">Añadir
          kyosa</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DMYDateField :min="hasPermission('Asistencias Extemporáneas (72hrs)') ? null : minAllowedDate"
          :label="'Fecha de la lista'" v-model="assistanceDate">
        </DMYDateField>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn v-if="!loadingGroupList" class="blue lighten-2" dark @click="printAssitance"><v-icon>mdi-printer</v-icon>
          Imprimir lista completa</v-btn>
        <v-progress-circular v-else :size="25" :width="5" color="green lighten-2" indeterminate />
      </v-col>
      <v-col>
        <span class="red lighten-3"><strong>Fondo rojo:</strong></span> En riesgo (3 faltas en el bimestre)
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="loadingList">
        <v-progress-circular :size="25" :width="5" color="green lighten-2" indeterminate />
      </v-col>
      <v-col v-else>
        <v-data-table :search="search" :headers="[
          { text: 'Nombre', value: 'full_name' },
          { text: 'Asistencia', value: 'assistance', other: 1 },
          { text: 'Asistencias/Faltas', value: 'af' },
          { text: 'Status', value: 'status' },
        ]" :items="doyangGroup ? doyangGroup.kyosas : []" :items-per-page="50" class="elevation-1" :item-class="(item) => {
          if (kyosas.filter((s) => s.id == item.id)[0].bimester_abscenses >= 3) return 'red lighten-2'
        }">
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="showWhatsApp && item.cellphone" small class="mr-2"
              @click="sendWhatsappMessage(item.cellphone)">
              mdi-whatsapp
            </v-icon>
            <v-icon small @click="deleteStudent(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.assistance="{ item }" v-if="assistanceDate">
            <v-container v-if="assistanceRadioGroup && assistanceRadioGroup[item.id]">
              <v-row>
                <v-radio-group v-model="assistanceRadioGroup[item.id]['type']">
                  <v-radio v-for="n in ['F', 'R', 'A']" :key="n" :label="n" :value="n"
                    :class="{ 'green lighten-2': assistanceRadioGroup[item.id].isClassTaken }"
                    @change="(setAssistance(item, n))"></v-radio>
                </v-radio-group>
                <v-icon small style="margin-left:15px" @click="deleteAssitance(assistanceRadioGroup[item.id], item.id)">
                  mdi-cancel
                </v-icon>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.meeting="{ item }" v-if="meeting">
            <v-container :class="assistanceRadioGroup[item.id].isMeetingTaken ? 'green lighten-2' : ''">
              <v-row>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_student" solo :label="'Alumno'" :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_representative
        )" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_mother" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_representative ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Madre'" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_father" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_representative
        ) : (
          assistanceRadioGroup[item.id].is_representative ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Padre'" @change="(setAssistance(item, 'A'))"></v-checkbox>
                <v-checkbox v-model="assistanceRadioGroup[item.id].is_representative" solo :disabled="isUnique ? (
          assistanceRadioGroup[item.id].is_student ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_father
        ) : (
          assistanceRadioGroup[item.id].is_father ||
          assistanceRadioGroup[item.id].is_mother ||
          assistanceRadioGroup[item.id].is_student
        )" :label="'Repre.'" @change="(setAssistance(item, 'A'))"></v-checkbox>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.af="{ item }">
            <v-container>
              <v-row v-if="kyosasList && kyosasList.length > 0">
                <v-col>
                  {{ kyosasList.filter((s) => s.id == item.id)[0].bimester_assistances }}/{{ kyosasList.filter((s) =>
          s.id
          == item.id)[0].bimester_abscenses }}
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.status="{ item }">
            <v-container>
              <v-icon @click="deleteKyosa(item)">mdi-delete</v-icon>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row ref="row-print" id="row-print">
      <v-col v-if="isPrintingAssitance">
        <table class="table table-responsive" id="complete-assistance">
          <tr>
            <th v-for="date in bimesterDates" :key="date.text">
              {{ date.text.split("/")[0] }} <br>
              <span style="font-size:10pt">{{ date.text.split("/")[1] }}</span>
            </th>
          </tr>
          <tr v-for="(assistance) in bimesterAssistances" :key="assistance.name">
            <td v-for="(date, index) in bimesterDates" :key="index">
              <div v-if="date.value == 'full_name'">
                <span style="font-size:10pt">{{ assistance[date.value] }}</span>
              </div>
              <div v-else>
                {{ assistance.assistances[date.value] || "o" }}
              </div>
            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="addStudentDialog">
      <template>
        <v-card>
          <v-toolbar color="primary" dark>Agregar alumno al grupo</v-toolbar>
          <v-card-text>
            <div class="text-h5">Selecciona los grupos en los que quieres que continúe el alumno:</div>
            <div>
              <div v-for="group in currentGroups" :key="group.id">
                <v-checkbox v-model="stayingInGroups[group.id]" solo :label="group.name" :value="true"></v-checkbox>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="addStudentDialog = false">Cerrar</v-btn>
            <v-btn text class="btn green lighten-2" dark @click="addStudent(false)">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import ENV from '../../../env'
import { getPermissions } from '../globals/permissions.js'
var debounce = require('debounce');


export default {

  data() {
    return {
      searchStudent: "",
      kyosaToAdd: null,
      kyosasList: [],
      bimesterEnd: null,
      bimesterStart: null,
      addingKiosa: false,
      maxAbsencesAllowed: null,
      permissibleAbscences: null,
      loadingList: true,
      loadingGroupList: false,
      meeting: null,
      bimesterDates: [],
      isPrintingAssitance: false,
      bimesterAssistances: [],
      today: null,
      assistanceDate: null,
      ENV,
      currentGroups: [],
      stayingInGroups: {},
      addStudentDialog: null,
      addStudentId: null,
      search: null,
      studentToAdd: null,
      daysInt: [],
      nextFourDates: [],
      assistanceRadioGroup: {},
      dayEquivalences: {
        'monday': 0,
        'tuesday': 1,
        'wednesday': 2,
        'thursday': 3,
        'friday': 4,
        'saturday': 5,
        'sunday': 6
      }
    }
  },
  props: [
    'showWhatsApp',
    'itemsPerPage',
  ],
  watch: {
    'searchStudent': debounce(function () {
      const activeString = `active=1`
      this.$store.dispatch('Student/index', `?append=first_uncovered_month&with=has_recommended,doyang,doyang.doyang_category,doyang.doyang_category.bundles&name=${this.searchStudent}&${activeString}`)
    }, 1000),
    assistanceDate() {
      this.assistanceRadioGroupForDate()
    }
  },
  methods: {
    deleteKyosa(item) {
      this.$store.dispatch('DoyangGroup/deleteKyosa', { id: this.$route.params.id, kyosas: item.id }).then(() => {
        this.students = this.students.filter(() => {
        })
      })
    },
    addKyosa() {
      this.$store.dispatch('DoyangGroup/addKyosa', { id: this.$route.params.id, kyosa: this.kyosaToAdd }).then(() => {
        this.kyosasList.push(this.kyosaToAdd)
        this.kyosaToAdd = null
      })
    },
    hasPermission(name, action = false) {
      const permissions = this.authInfo?.user?.permissions
      return getPermissions(permissions, name, action)
    },
    _printTable() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('row-print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      this.isPrintingAssitance = false
      this.groupedItems = []
    },
    printAssitance() {
      this.loadingGroupList = true
      this.$store.dispatch('DoyangGroup/index', `/${this.doyangGroup.id}?append=kyosas_assistances_csv&from_date=${this.bimesterStart}&to_date=${this.bimesterEnd}`).then((res) => {
        this.downloadStringInBlankPage(res.kyosas_assistances_csv)
        this.loadingGroupList = false
      })
    },
    downloadStringInBlankPage(string, filename = 'assistances.csv') {
      const bom = '\uFEFF';  // UTF-8 BOM
      const blob = new Blob([bom + string], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', filename);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    initializeBimester() {
      // Initalize bimester dates
      this.$store.dispatch('Configuration/index', `?append=bimester_start,bimester_end`).then(res => {
        let localBimesterDates = []
        this.daysInt.forEach(day => {
          const parsedDay = day + 1
          const bimester_start = new Date(res[0]['bimester_start'])
          const bimester_end = new Date(res[0]['bimester_end'])
          localBimesterDates = this.daysBetweenDates(bimester_start, bimester_end, parsedDay)
          localBimesterDates.forEach(date => {
            const dateObject = new Date(date)
            const text = `${dateObject.getDate()}/${ENV.months[dateObject.getMonth() + 1].abv.toUpperCase()}`
            var tzoffset = (new Date()).getTimezoneOffset() * 60000;
            this.bimesterDates.push({ text: text, value: `${(new Date(date.getTime() - tzoffset)).toISOString().split('T')[0]}` })
          })
        })

        this.bimesterDates.unshift({ text: 'Nombre', value: "full_name" })
        this.getBimesterAssistance()
      })

    },
    getBimesterAssistance() {
      if (!this.doyangGroup?.students) return []
      this.bimesterAssistances = []
      this.doyangGroup.students.forEach(student => {
        const assistanceObject = {
          full_name: student.full_name,
          assistances: {}
        }
        student.assistances.forEach(assistance => {
          assistanceObject.assistances[assistance.pivot.date] = assistance.pivot.type
        })
        this.bimesterAssistances.push(assistanceObject)
      })
    },
    daysBetweenDates(date_1, date_2, day) {
      const milis = date_2.getTime() - date_1.getTime();
      let days = Math.abs(Math.ceil(milis / (1000 * 3600 * 24)));
      const nextDay = this._nextWeekDay(day, date_1)
      if (nextDay.getTime() > date_2.getTime()) return []
      let daysToReturn = [new Date(nextDay)]
      let currentDate = nextDay
      for (let i = 1; i < days; i++) {
        currentDate.setDate(currentDate.getDate() + 7)
        const evalDate = new Date(currentDate)
        if (evalDate.getTime() > (date_2.getTime() + (24 * 60 * 60 * 1000))) break;
        daysToReturn.push(evalDate)
      }
      return daysToReturn
    },
    assistanceRadioGroupForDate() {
      const parsedDate = this.assistanceDate.split("T")[0]
      const kyosasIds = this.kyosas.map(kyosa => kyosa.id)
      const stringIdSeparatedByCommas = kyosasIds.join(',')
      this.$store.dispatch('Assistance/index', `?where[date]=${parsedDate}&where[user_id]=${stringIdSeparatedByCommas}`).then((assistances) => {
        assistances = assistances.filter((assistance) => {
          return (assistance.doyang_group_id == this.doyangGroup.id) && (assistance.date == parsedDate)
        });
        let studentKeyAssistances = {}
        let newArray = {}
        Object.keys(assistances).forEach((assistanceKey) => {
          const assistance = assistances[assistanceKey]
          studentKeyAssistances[assistance['user_id']] = assistance
        })
        this.doyangGroup.kyosas.forEach((student) => {
          const assistance = studentKeyAssistances[student.id]
          newArray[student.id] = {
            id: assistance?.id,
            type: assistance?.type,
            is_student: assistance?.is_student == "1",
            is_mother: assistance?.is_mother == "1",
            is_father: assistance?.is_father == "1",
            is_representative: assistance?.is_representative == "1"
          }
        });
        this.$set(this, 'assistanceRadioGroup', newArray)
        this.$store.dispatch('Event/index', `?date=${parsedDate}&is_meeting=1`).then(events => {
          this.meeting = events?.[0] || null
        })
      })
    },
    deleteAssitance(assistance, studentId) {
      this.$store.dispatch('Assistance/delete', assistance).then(() => {
        this.assistanceRadioGroup[studentId] = {
          id: null,
          type: null,
          is_student: null,
          is_mother: null,
          is_father: null,
          is_representative: null,
        }
      })
    },
    setAssistance(student, type) {
      let data = {
        id: this.doyangGroup.id,
        student,
        type,
        date: this.assistanceDate
      }
      let isMeeting = false;
      let isClass = true;
      this.$set(this.assistanceRadioGroup[student.id], "isMeetingTaken", false)
      this.$set(this.assistanceRadioGroup[student.id], "isClassTaken", false)
      if (this.assistanceRadioGroup[student.id].is_student) {
        data['is_student'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_mother) {
        data['is_mother'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_father) {
        data['is_father'] = true;
        isMeeting = true;
      }
      if (this.assistanceRadioGroup[student.id].is_representative) {
        data['is_representative'] = true;
        isMeeting = true;
      }
      this.$store.dispatch('DoyangGroup/update', data).then(() => {
        if (isMeeting) this.$set(this.assistanceRadioGroup[student.id], "isMeetingTaken", true)
        if (isClass) this.$set(this.assistanceRadioGroup[student.id], "isClassTaken", true)
      })
    },
    nextFourDays() {
      this.nextFourDates = this.daysInt.map((day) => {
        const nextDate = this._nextWeekDay(day).toISOString()
        const date = nextDate.substring(0, 10)
        const [year, month, dateDay] = date.split('-')
        return {
          string: `${dateDay.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`,
          date: nextDate
        }
      })
      this.daysInt.forEach((day) => {
        const nextDate = this._prevtWeekDay(day + 1).toISOString()
        const date = nextDate.substring(0, 10)
        const [year, month, dateDay] = date.split('-')
        this.nextFourDates.push({
          string: `${dateDay.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`,
          date: nextDate
        })
      })
      this.nextFourDates = this.nextFourDates.sort((firstDate, secondDate) => {
        return firstDate.date < secondDate.date ? -1 : 0
      })
    },
    _nextWeekDay(x, startDate = null) {
      var now = startDate || new Date();
      now.setDate(now.getDate() + (x + (7 - now.getDay())) % 7);
      return now;
    },
    _prevtWeekDay(x) {
      var now = new Date();
      now.setDate(now.getDate() - (x + (7 - now.getDay())) % 7);
      return now;
    },
    deleteStudent: function (item) {
      let payload = {}
      payload.id = this.$route.params.id
      payload.students = item.id
      this.$store.dispatch('DoyangGroup/deleteStudent', payload)
    },
    addStudent: function () {
      const studentExist = Boolean(this.doyangGroup.students.filter((student) => {
        return student.id == this.studentToAdd.id
      }).length)
      if (studentExist) {
        alert("El estudiante ya se encuentra en el grupo")
        this.studentToAdd = null
        return
      }
      let payload = {}
      this.$store.commit('DoyangGroup/addStudent', this.studentToAdd);
      payload.groupsAsStudent = this.stayingInGroups
      payload.groupsAsStudent[this.$route.params.id] = true
      payload.id = this.studentToAdd.id
      this.$store.dispatch('Student/update', payload)
      this.addStudentDialog = false
    }
  },
  mounted() {
    this.$store.dispatch('Configuration/show', 12).then((res) => {
      this.permissibleAbscences = res.value
    })
    this.$store.dispatch('Configuration/show', 4).then((res) => {
      this.maxAbsencesAllowed = res.value
    })
    this.loadingList = true
    this.$store.dispatch('Configuration/show', 1).then((res) => {
      this.bimesterStart = res.value
    })
    this.$store.dispatch('Configuration/show', 2).then((res) => {
      this.bimesterEnd = res.value
    })
    let today = new Date()
    let prevMonth = new Date()
    prevMonth.setDate(today.getDate() - 60)

    this.$store.dispatch('DoyangGroup/show', `${this.$route.params.id}?with=kyosas`).then((doyangGroup) => {
      const kyosas = doyangGroup.kyosas
      kyosas?.forEach((kyosa) => {
        this.$store.dispatch('Student/show', `${kyosa.id}?assistance_group_id=${doyangGroup.id}&append=last_covered_month_parsed,consecutive_absences,bimester_abscenses,bimester_assistances`).then((response) => {
          this.kyosasList.push(response)
        })
      })
      this.$store.dispatch('Time/time').then((res) => {
        this.assistanceDate = res.substring(0, 10);
        this.loadingList = false
      });
      const json_week = doyangGroup?.json_week
      if (json_week) {
        Object.keys(json_week).forEach((weekDay) => {
          if (json_week[weekDay]) {
            this.daysInt.push(this.dayEquivalences[weekDay])
          }
        })
      }
      this.nextFourDays()
    })
  },
  computed: {
    isUnique() {
      return this.meeting ? this.meeting.unique_assistance : false;
    },
    minAllowedDate() {
      // date from 3 days before
      let date = new Date()
      date.setDate(date.getDate() - 3)
      return date.toISOString().substr(0, 10)
    },
    students() {
      return this.$store.getters['Student/students']
    },
    kyosas() {
      return this.doyangGroup?.kyosas
    },
    event() {
      return this.$store.getters['Event/event']
    },
    configurations() {
      return this.$store.getters['Configuration/configurations']
    },
    doyangGroup() {
      return this.$store.getters['DoyangGroup/doyangGroup']
    },
    authInfo() {
      return this.$store.getters['Auth/authInfo']
    },
  }
}
</script>

<style></style>
