import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    useOldHome: true,
    authInfo: {
      allDoyangs: null
    },
    selectedDoyang: null,
    filters: {
      students: ''
    }
  },
  mutations: {
    authInfo(state, payload) {
      state.authInfo = payload
    },
    selectDoyang(state, payload) {
      state.selectedDoyang = payload
      localStorage.setItem('selectedDoyang', JSON.stringify(payload))
    },
    addFilter(state, payload) {
      state['filters'][payload['filter']] = payload['value']
    },
    setUseOldHome(state, payload) {
      state.useOldHome = payload
    },
  },
  actions: {
    toggleHome({ commit }, payload) {
      localStorage.setItem('useOldHome', payload)
      commit('setUseOldHome', payload)
    },
    login({ commit }, payload) {
      console.log('payload', payload)
      return axios.post(`${ENV.API_URL}/api/login`, payload).then((res) => {
        commit('authInfo', res.data)
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem('auth_info', JSON.stringify(res.data))
        return res.data
      })
    },
    addFilter({ commit }, payload) {
      commit('addFilter', payload)
      return payload
    },
    changePassword({ state }, payload) {
      return axios.put(`${ENV.API_URL}/api/users/${state.authInfo.user.id}`, payload).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
    logout({ commit }, payload) {
      commit('authInfo', {})
      return axios.post(`${ENV.API_URL}/api/logout`, payload).then(() => {
        localStorage.setItem('access_token', null)
        localStorage.setItem('selectedDoyang', null)
        localStorage.setItem('auth_info', null)
        return false
      }).catch( () => {
        localStorage.setItem('access_token', null)
        localStorage.setItem('selectedDoyang', null)
        localStorage.setItem('auth_info', null)
        return false
      })
    },
    me ({commit}) {
      return axios.post(`${ENV.API_URL}/api/me`).then((res) => {
        commit('authInfo', {user: res.data})
        return {user: res.data}
      }).catch(()=> {
        commit('authInfo', {})
        return false
      })
    },

  },
  getters: {
    useOldHome: () => {
      if (localStorage.getItem('useOldHome') == "false") return false
      return true
    },
    filters: state => state.filters,
    authInfo: state => {
      return state.authInfo?.user?.id ? state.authInfo : JSON.parse(localStorage.getItem('auth_info'))
    },
    selectedDoyang: state => {
      if (localStorage.getItem('selectedDoyang') == "undefined") return undefined
      return state.selectedDoyang ? state.selectedDoyang : JSON.parse(localStorage.getItem('selectedDoyang'))
    },
  },
  modules: {
  }
}
