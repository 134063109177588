<template>
  <v-container>
    <h2>Grados</h2>
    <GlobalIndex
      :permissionRoot="'Grados'"
      :headers="headers"
      :payload="'?'"
      :storeModel="storeModel"
      :itemsPerPage="itemsPerPage"/>
  </v-container>
</template>

<script>
import GlobalIndex from '../globals/CRUDIndex'
export default {
  components: {
    GlobalIndex
  },
  data () {
    return {
      itemsPerPage: 30,
      storeModel: 'Degree',
      headers: [
        {text: 'Orden', value: 'order'},
        {text: 'Nombre', value: 'name'},
      ]
    }
  }
}
</script>

<style>

</style>e
