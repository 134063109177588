import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyang_groups: [],
    doyang_group: { kyosas: [], students: [] }
  },
  mutations: {
    index(state, payload) {
      state.doyang_groups = payload
    },
    show(state,payload) {
      state.doyang_group = payload
    },
    addToIndex (state, payload) {
      state.doyang_groups.push(payload)
    },
    update (state, payload) {
      state.doyang_group = payload
      state.doyang_groups = state.doyang_groups.map((doyangGroup) => {
        if (doyangGroup.id == payload.id) {
          doyangGroup = payload
        }
        return doyangGroup
      })
    },
    updateKyosas (state, payload) {
      state.doyang_group = payload
    },
    removeExamGroup(state, payload) {
      state.doyang_groups = state.doyang_groups.filter((doyangGroup) => doyangGroup.id != payload.id)
    },
    remove(state, payload) {
      state.doyang_groups = state.doyang_groups.filter((doyangGroup) => doyangGroup.id != payload.id)
    },
    removeStudent(state, payload) {
      state.doyang_group.students = payload.students
    },
    removeKyosa(state, payload) {
      state.doyang_group.kyosas = state.doyang_group.kyosas = payload.kyosas
    },
    addStudent(state, payload) {
        console.log('hi', payload);
        state.doyang_group.students.push(payload)
    },
    updateAssistance(state, payload) {
      state.doyang_group.students.forEach((student, studentIndex) => {
        if(student?.id == payload?.user_id) {
          student.assistances.forEach((assistance, assitanceIndex) => {
            if (assistance.pivot.id == payload.id) {
              state.doyang_group.students[studentIndex].assistances[assitanceIndex].pivot.type = payload.type
            }
          })
        }
      })
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_groups${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_groups/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_groups`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_groups/${payload.id}`, payload).then((res) => {
        if (payload.date) {
          return res.data
        }
        commit('update', res.data)
        commit('updateKyosas', res.data)
        return res.data
      })
    },
    addKyosa({commit}, payload) {
        return axios.post(`${ENV.API_URL}/api/doyang_groups/${payload.id}/kyosas/add`, payload).then((res) => {
            commit('updateKyosas', res.data)
        });
    },
    deleteKyosa ({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_groups/${payload.id}?kyosas=${payload.kyosas}`).then((res) => {
        commit('removeKyosa', res.data)
      })
    },
    deleteStudent ({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_groups/${payload.id}?students=${payload.students}`).then((res) => {
        commit('removeStudent', res.data)
      })
    },
    deleteGroup ({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_groups/${payload.eventGroupId}`, payload).then((res) => {
        commit('removeExamGroup', res.data)
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_groups/${payload}`).then((res) => {
        commit('remove', res.data)
      })
    }
  },
  getters: {
    doyangGroups: state => {
      return state.doyang_groups
    },
    doyangGroup: state => {
      return state.doyang_group
    }
  },
  modules: {
  }
}
