<template>
  <v-container>
    <h2>SEDES</h2>
    <CRUDIndex
      :permissionRoot="'Sedes'"
      :headers="headers"
      :storeModel="storeModel"
    />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data () {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Estado', value: 'state.name' },
        { text: 'Localidad', value: 'city.name' },
        { text: 'Teléfono', value: 'parsed_phone' },
      ],
      storeModel: 'Venue',
    }
  }
}
</script>

<style>

</style>e
