import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    tournament_age_groups: [],
    tournament_age_group: {}
  },
  mutations: {
    index(state, payload) {
      state.tournament_age_groups = payload
    },
    show(state,payload) {
      state.tournament_age_group = payload
    },
    delete(state, payload) {
      state.tournament_age_groups = state.tournament_age_groups.filter(tournament_age_group => tournament_age_group.id !== payload.id)
    },
    create(state, payload) {
        state.tournament_age_groups.push(payload)
    },
  },
  actions: {
    index ({commit}) {
      return axios.get(`${ENV.API_URL}/api/tournament_age_groups`).then((res) => {
        commit('index', res.data)
        return (res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/tournament_age_groups/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/tournament_age_groups`, payload).then((res) => {
        commit('show', res.data)
        commit('create', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/tournament_age_groups/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/tournament_age_groups/${payload}`).then((res) => {
        commit('delete', res.data)
        return (res.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  getters: {
    tournamentAgeGroups: state => {
      return state.tournament_age_groups
    },
    tournamentAgeGroup: state => {
      return state.tournament_age_group
    }
  },
  modules: {
  }
}
