import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangCategoryRequisites: [],
    doyangCategoryRequisite: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangCategoryRequisites = payload
    },
    show(state, payload) {
      state.doyangCategoryRequisite = payload
    },
    delete(state, payload) {
      state.doyangCategoryRequisites = state.doyangCategoryRequisites.filter(requisite => requisite.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_category_requisites${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_category_requisites/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      axios.post(`${ENV.API_URL}/api/doyang_category_requisites`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    delete({ commit }, payload) {
      axios.delete(`${ENV.API_URL}/api/doyang_category_requisites/${payload}`).then((res) => {
        commit('delete', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_category_requisites/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    }
  },
  getters: {
    doyangCategoryRequisites: state => {
      return state.doyangCategoryRequisites
    },
    doyangCategoryRequisite: state => {
      return state.doyangCategoryRequisite
    }
  },
  modules: {
  }
}
