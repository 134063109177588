<template>
  <v-textarea
    v-bind="$attrs" outlined @input="parseInput" :value="value"
    name="input-7-4"
  ></v-textarea>
</template>

<script>
export default {
  methods: {
    parseInput(text) {
      this.$emit('input', text.toUpperCase())
    }
  },
  props: ['value'],
  data() {
    return {
      text: null
    }
  }
}
</script>

<style>

</style>