import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    privileges: [],
    privilege: {}
  },
  mutations: {
    index(state, payload) {
      state.privileges = payload
    },
    show(state, payload) {
      state.privilege = payload
    }
  },
  actions: {
    index({ commit }) {
      axios.get(`${ENV.API_URL}/api/privileges`).then((res) => {
        commit('index', res.data)
      })
    },
    show({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/privileges/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
      axios.post(`${ENV.API_URL}/api/privileges`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    uptade({ commit }, payload) {
      axios.put(`${ENV.API_URL}/api/privileges/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    privileges: state => {
      return state.privileges
    },
    privilege: state => {
      return state.privilege
    }
  },
  modules: {
  }
}
