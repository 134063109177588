<template>
    <div class="chart-container">
        <div v-if="chartData">
            <LineChart :options="{
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }" :data="chartData" :chartData="chartData" :id="chartId" :dataset-id-key="datasetIdKey" :plugins="plugins"
                :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
        </div>
    </div>
</template>

<style scoped>
.chart-container {
    width: 75%;
    position: relative;
}
</style>

<script>
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)
import { Line as LineChart } from 'vue-chartjs/legacy' // Rename the import to avoid conflict
import env from '../../../env'

export default {
    name: 'LineChartComponent', // Change the component name if needed
    components: { LineChart }, // Use the renamed component here
    props: {
        labels: { type: Array, default: () => [] },
        chartId: { type: String, default: 'line-chart' },
        datasetIdKey: { type: String, default: 'label' },
        width: { type: Number, default: 400 },
        height: { type: Number, default: 400 },
        cssClasses: { type: String, default: '' },
        styles: { type: Object, default: () => ({}) },
        plugins: { type: Array, default: () => [] },
        dataArray: { type: Array, default: () => [] }
    },
    computed: {
        chartData() {
            if (!this.dataArray || !this.dataArray.length) return null;

            const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink', 'brown', 'grey', 'black'];
            const datasets = this.dataArray.map((dataPoint, i) => {
                const dataParsed = Object.keys(dataPoint).reduce((acc, key) => {
                    if (!isNaN(key)) {
                        const monthAbv = env.months[key]['abv'];
                        acc[monthAbv] = dataPoint[key];
                        return acc;
                    }
                    acc[`...${key.substring(key.length - 21)}`] = dataPoint[key];
                    return acc;
                }, {});
                return {
                    label: this.labels?.[i] || `Dataset ${i + 1}`,
                    borderColor: colors[i % colors.length],
                    backgroundColor: colors[i % colors.length],
                    fill: false,
                    data: dataParsed
                };
            });
            console.log('datasets', datasets)

            return {
                labels: Object.keys(this.dataArray[0]), // Assuming each dataPoint has the same keys
                datasets
            };
        }
    }
}
</script>
