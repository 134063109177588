<template>
  <v-container>
    <v-row>
      <v-col col=12>
      <h2 v-if="!$route.params.id">CREAR TORNEO</h2>
      <h2 v-else>EDITAR TORNEO</h2>
      <CRUDEventProcessCreate
        :eventType="'tournament'"
        :showFieldSinodal="false"
        :storeModel="storeModel"
        :storeModelGroup="storeModelGroup"
        :component="component"
      />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDEventProcessCreate from '../globals/CRUDEventProcessCreate'
export default {
  components: {
    CRUDEventProcessCreate
  },
  data () {
    return {
      storeModel: 'Tournament',
      storeModelGroup: 'TournamentGroup',
      component: 'TournamentGroupCreate'
    }
  }
}
</script>

<style>

</style>
