import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    degrees: [],
    degree: {}
  },
  mutations: {
    index(state, payload) {
      state.degrees = payload
    },
    show(state, payload) {
      state.degree = payload
    },
    delete(state, payload) {
      state.degrees = state.degrees.filter( degree => degree.id != payload.id )
    }
  },
  actions: {
    index({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/degrees${payload != undefined? payload : ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    delete({ commit }, payload) {
      axios.delete(`${ENV.API_URL}/api/degrees/${payload != undefined? payload : ''}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/degrees/${payload != undefined? payload : ''}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/degrees`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/degrees/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    degrees: state => {
      return state.degrees
    },
    degree: state => {
      return state.degree
    }
  },
  modules: {
  }
}
