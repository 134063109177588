<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR TÍTULO HONORÍFICO</h2>
        <h2 v-else>EDITAR TÍTULO HONORÍFICO</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=8>
          <CapsVTextField
            outlined
            v-model="honorificTitle.description"
            :rules="notEmptyRule"
            label="Honorífico"
            />
        </v-col>
        <v-col cols=12 md=4>
          <CapsVTextField
            outlined
            v-model="honorificTitle.name"
            :rules="notEmptyRule"
            label="Abreviatura"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createHonorificTitle">Añadir</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateHonorificTitle">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('HonorificTitle/index');
    if (this.$route.params.id) {
      this.$store.dispatch('HonorificTitle/show', this.$route.params.id).then((res) => {
        console.log(res);
        this.honorificTitle = this.$store.getters['HonorificTitle/honorificTitle']
      });
    }
  },
  components: {
  },
  methods: {
    createHonorificTitle: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('HonorificTitle/create', this.honorificTitle).then(() => {
          this.$router.push({name: 'HonorificTitleIndex'})
        }
      )
    },
    updateHonorificTitle: function () {
      this.$store.dispatch('HonorificTitle/update', this.honorificTitle).then(() => {
          this.$router.push({name: 'HonorificTitleIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
    'state': function () {
      this.municipalities = this.state.municipalities
      this.honorificTitle.state_id = this.state.id
    }
  },
  computed : {
    states()  {
      return this.$store.getters['State/states'];
    },
  },
  data () {
    return {
      notEmptyRule: [
        v => v ? v.length > 0 : 'Este campo no puede quedar vacío'
      ],
      honorificTitle: {
        name: null,
        description: null,
      },
    }
  }
}
</script>

<style>

</style>