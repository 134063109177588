import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangTypes: [],
    doyangType: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangTypes = payload
    },
    show(state, payload) {
      state.doyangType = payload
    }
  },
  actions: {
    index({ commit }) {
      return axios.get(`${ENV.API_URL}/api/doyang_types`).then((res) => {
        commit('index', res.data)
      })
    },
    show({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/doyang_types/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_types`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/doyang_types/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    doyangTypes: state => {
      return state.doyangTypes
    },
    doyangType: state => {
      return state.doyangType
    }
  },
  modules: {
  }
}