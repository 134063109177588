import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import ENV from "../../env";

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {
        parentEvents: [],
        parentEvent: {}
    },
    mutations: {
        index(state, payload) {
            state.parentEvents = payload;
        },
        show(state,payload) {
            state.parentEvent = payload;
        },
        delete(state, payload) {
            state.parentEvents = state.parentEvents.filter((parentEvent) => {
                return parentEvent.id !== payload;
            });
        }
    },
    actions: {
        index ({commit}) {
            return axios.get(`${ENV.API_URL}/api/parent-events`).then((res) => {
                commit("index", res.data);
                return (res.data);
            });
        },
        show({commit}, payload) {
            return axios.get(`${ENV.API_URL}/api/parent-events/${payload}`).then((res) => {
                commit("show", res.data);
                return (res.data);
            });
        },
        create({commit}, payload) {
            return axios.post(`${ENV.API_URL}/api/parent-events`, payload).then((res) => {
                commit("show", res.data);
                return (res.data);
            });
        },
        update({commit}, payload) {
            return axios.put(`${ENV.API_URL}/api/parent-events/${payload.id}`, payload).then((res) => {
                commit("show", res.data);
                return (res.data);
            });
        },
        delete({commit}, payload) {
            return axios.delete(`${ENV.API_URL}/api/parent-events/${payload}`).then(() => {
                commit("delete", payload);
                return (payload);
            });
        }
    },
    getters: {
        parentEvents: state => {
            return state.parentEvents;
        },
        parentEvent: state => {
            return state.parentEvent;
        }
    },
    modules: {
    }
};
