<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>CATEGORÍAS DE TORNEO</h2>
          <CRUDIndex
                :permissionRoot="'Doyangs'"
                :items="objects"
                :headers="headers"
                :storeModel="storeModel"
                :itemsPerPage="itemsPerPage"
              />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  computed: {
    objects () {
      return this.$store.getters['TournamentCategory/tournamentCategories']
    },
  },
  data () {
    return {
      headers: [
        { text: 'Categoría', value: 'name' },
      ],
      filterThisYearOnly: true,
      storeModel: 'TournamentCategory',
      routeCreate: 'TournamentCategoryCreate',
      routeEdit: 'TournamentCategoryEdit',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
