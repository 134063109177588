<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <h2 v-if="!this.$route.params['id']">CREAR NUEVO REQUISITO</h2>
        <h2 v-else>EDITAR NUEVO REQUISITO</h2>
        <v-form
        ref="form"
        >
        <CapsVTextField v-model="requirement.name" outlined label="Nombre*" :rules="nameRules"/>
        </v-form>
      </v-col>
      <v-col cols=6>
        <v-text-field v-model="requirement.absences" :type="'number'" outlined label="Faltas"/>
      </v-col>
      <v-col cols=6>
        <v-text-field v-model="requirement.partial" :type="'number'" outlined label="Parcial al que corresponde"/>
      </v-col>
      <v-col md=6>
        <v-row>
          <v-col md=12>
            <h2>GRADOS</h2>
          </v-col>
          <v-row>
            <v-col>
              <v-autocomplete v-model="requirement.min_grade_id" :items="degrees" item-value="id" item-text="name" outlined label="GRADO MÍNIMO" chips/>
              <v-autocomplete v-model="requirement.max_grade_id" :items="degrees" item-value="id" item-text="name" outlined label="GRADO MÁXIMO" chips/>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
      <v-col md=6>
        <v-row>
          <v-col md=!2>
            <h2>EDADES</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col md=12>
            <v-text-field v-model="requirement.min_age_years" outlined label="MIN AÑOS"/>
            <v-text-field v-model="requirement.max_age_years" outlined label="MAX AÑOS"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>TIEMPO MÍNIMO ANTES EL EXAMEN SIGUIENTE (MESES)</h2>
      </v-col>
      <v-col>
        <v-text-field type="number" v-model="requirement.min_time_before_next_exam" outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12>
        <h2>PUNTOS</h2>
      </v-col>
      <v-col>
        <v-text-field type="number" v-model="requirement.min_points" :label="'Ideal'" outlined></v-text-field>
      </v-col>
      <v-col>
        <v-text-field type="number" v-model="requirement.warning_points" :label="'Curriculum oficial'" outlined></v-text-field>
      </v-col>
      <v-col>
        <v-text-field type="number" v-model="requirement.no_right_points" :label="'Inaceptable'" outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
        label="Productos"
        solo
        chips
        multiple
        v-model="requirement.products"
        :items="products"
        item-text="name"
        return-object
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
        label="Otros Requisitos"
        solo
        chips
        multiple
        v-model="requirement.other_exam_requisites"
        :items="otherExamRequisites"
        item-text="name"
        return-object
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-btn v-if="!this.$route.params['id']" primary @click="createRequirement" class="blue ligthten-2" dark>Agregar</v-btn>
          <v-btn v-else primary @click="editRequirement" class="yellow ligthten-2">Editar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('Product/index')
    this.$store.dispatch('Degree/index')
    this.$store.dispatch('OtherExamRequisite/index')
    if(this.$route.params['id']) {
      this.$store.dispatch('Requirement/show', `${this.$route.params['id']}?with=products,other_exam_requisites`).then((data) => {
        console.log(data)
        this.requirement = data
        this.requirement.min_time_before_next_exam = this.requirement.min_time_before_next_exam ? parseInt(this.requirement.min_time_before_next_exam) : 0
        this.requirement.min_points = this.requirement.min_points ? parseInt(this.requirement.min_points) : 0
        this.requirement.warning_points = this.requirement.warning_points ? parseInt(this.requirement.warning_points) : 0
        this.requirement.no_right_points = this.requirement.no_right_points ? parseInt(this.requirement.no_right_points) : 0
        this.requirement.min_grade_id = this.requirement.min_grade_id ? parseInt(this.requirement.min_grade_id) : 0
        this.requirement.max_grade_id = this.requirement.max_grade_id ? parseInt(this.requirement.max_grade_id) : 0
      })
    }
  },
  methods: {
    createRequirement: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('Requirement/create', this.requirement)
        this.$router.push({name: 'RequirementIndex'})
      }
    },
    editRequirement: function () {
      if (this.$refs.form.validate() === true){
        this.$store.dispatch('Requirement/update', this.requirement)
        this.$router.push({name: 'RequirementIndex'})
      }
    },
  },
  computed: {
    products(){
      return this.$store.getters['Product/products']
    },
    otherExamRequisites(){
      return this.$store.getters['OtherExamRequisite/otherExamRequisites']
    },
    degrees () {
      return this.$store.getters['Degree/degrees']
    }
  },
  data () {
    return {
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      requirement: {
        name:'',
        partial: null,
        min_grade_id: null,
        max_grade_id: null,
        min_age_months: null,
        min_age_years: null,
        max_age_days: null,
        max_age_months: null,
        max_age_years: null,
        products: []
      }
    }
  }
}
</script>

<style>

</style>