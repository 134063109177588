<template>
  <v-container>
    <v-row>
      <v-col>
          <h2>SINODALES MDK</h2>
          <CRUDIndex
            :permissionRoot="'Sinodales'"
            :headers="headers"
            :payload="payload"
            :storeModel="storeModel"
            :itemsPerPage="itemsPerPage"
          />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ENV from '../../../env'
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data () {
    return {
      payload: `?&sinodal=1&with=degree`,
      headers: [
        { text: 'Nombre Completo', value: 'full_name' },
        { text: 'Grado', value: 'degree.name' },
      ],
      storeModel: 'Sinodal',
      itemsPerPage: 20
    }
  },
}
</script>

<style>

</style>
