<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Doyang {{ doyang.name }}</h2>
      </v-col>
      <v-col cols="12">
        <v-btn :to="{ name: 'DoyangGroupAttendance', params: { id: 1 } }"
          >Click Aquí para doyangGroupAttendances</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :to="{ name: 'DoyangGroupAttendance', params: { id: group.id } }" cols="12" 
        class="mt-2 float-left" v-for="group in doyang.groups" :key="group.id">
      <v-card-title>
        {{ group.name }}
      </v-card-title>
      <v-card-subtitle>
        {{ group.begins_at }} - {{ group.ends_at }}
      </v-card-subtitle>
    </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("Doyang/show", `${this.$route.params.id}?with=groups`);
    
  },
  data() {
    return this.$store.getters["Doyang/doyang"];
  },
  computed: {
    doyang() {
      return this.$store.getters["Doyang/doyang"];
    },
    doyangGroupsSorted() {
      let copyDoyangGroups = Object.assign(this.doyang.groups)
      return copyDoyangGroups.sort((firstDoyang,secondDoyang)=> firstDoyang.begins_at > secondDoyang.begins_at?1:-1)
    },
    doyangGroups() {
      return this.$store.getters["DoyangGroup/doyang_groups"]
    }
  },
};
</script>

<style>
</style>