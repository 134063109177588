<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="!this.$route.params.id">NUEVA CATEGORÍA DE DOYANG</h2>
        <h2 v-else>EDITAR CATEGORÍA DE DOYANG</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols=12 md=6
        ><caps-v-text-field v-model="doyangCategory.name" outlined label="Nombre" type="text"></caps-v-text-field>
      </v-col>
      <v-col cols=12 md=6
        ><v-text-field v-model="doyangCategory.inscription_price" outlined label="Costo de inscripción" prefix="$" type="number"></v-text-field>
      </v-col>
      <v-col cols=12 md=6
        ><v-text-field v-model="doyangCategory.monthly_fee_price" outlined label="Costo de mensualidad" prefix="$" type="number"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class= "orange lighten-2 white--text" @click="editNewDoyangCategory" v-if="this.$route.params.id">Editar</v-btn>
        <v-btn primary @click="createNewDoyangCategory" v-else class="blue ligthten-2" dark>Agregar</v-btn>
      </v-col>
    </v-row>
    <br>
    <h3>PAQUETES EN ESTE DOYANG</h3>
    <v-btn class="green lighten-2" @click="goToCreateDoyangBundle()" dark>Añadir paquetes</v-btn>
    <v-data-table
      :sort-by="['created_at']"
      :sort-desc="[true]"
      :headers="doyangsBundlesHeaders"
      :items="doyangCategory.bundles"
      item-text="name"
      item-value="id"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon @click="goToCreateDoyangBundle( item )">mdi-pencil</v-icon>
        <v-icon @click="deleteBundle( item )">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <v-row>
      <v-col cols=12>
        <h3>REQUISITOS</h3>
      </v-col>
      <v-col cols=12>
        <router-link :to="{name:'DoyangCategoryRequisiteCreate', params: {doyangCategory: doyangCategory}}">
          <v-btn class="green lighten-2" dark>Crear requisito</v-btn>
        </router-link>
      </v-col>
      <v-col cols=12>
        <v-data-table
          :headers="requisitesHeaders"
          :items="doyangCategoryRequisites"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon @click="goToEditDoyangCategoryRequisite( item )">mdi-pencil</v-icon>
            <v-icon @click="goToDeleteDoyangCategoryRequisite( item )">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <h3>DOYANGS DE ESTE TIPO</h3>
    <v-data-table
      :key="doyangCategory.id"
      :headers="doyangsTableHeaders"
      :items="doyangCategory.doyangs"
      item-text="name"
      item-value="id"
      :items-per-page="5"
      class="elevation-1"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import ENV from '../../../env'
// import CreateDoyangBundlesComponent from '../doyang_bundles/Create.vue'

export default {
  components: {
    // CreateDoyangBundlesComponent
  },
  mounted () {
    this.$store.dispatch('DoyangCategoryRequisite/index', `?doyang_category_id=${this.$route.params.id}`)
    this.$store.dispatch('Professor/index', `?role_id=${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_TECHNICAL_DIRECTOR}`)
    this.$store.dispatch('DoyangType/index', ``)
    if (this.$route.params.id) {
      this.$store.dispatch('DoyangCategory/show', `${this.$route.params.id}?with=bundles`)
    }
  },
  methods:{
    goToCreateDoyangBundle (item) {
      console.log('goToCreateDoyangBundle', event)
      const id = item?.id || false
      if (!id) {
        return this.$router.push({name: 'DoyangBundleCreate', params: { doyangCategory : this.doyangCategory } })
      }
      this.$router.push({name: 'DoyangBundleEdit', params: { id, doyangCategory : this.doyangCategory  }})
    },
    deleteBundle (item) {
        this.$store.dispatch('DoyangBundle/delete', item.id).then(() => {
            const bundles = this.doyangCategory.bundles.filter((prevBundle) => prevBundle.id !== item.id)
            this.$set(this.doyangCategory, 'bundles', bundles)
        })
    },
    // goToEditBundle: function (id) {
    //   // this.edittingBundleId = id
    //   // this.createBundleDialog = true
    // },
    triggereEditDoyangBundle: function () {
      this.triggerEditBundle = true
      this.createBundleDialog = false
    },
    errorSavingBundle: function () {
      this.triggerCreateBundle = false
    },
    triggerCreateDoyangBundle: function () {
      this.edittingBundleId = 0
      this.createBundleDialog = false
      this.triggerCreateBundle = true
    },
    createNewDoyangCategory: function () {
      this.$store.dispatch('DoyangCategory/create', this.doyangCategory).then(() => {
        alert('Se ha creado el registro con éxito')
        this.$router.push({name: 'DoyangCategoryIndex'})
      }).catch(() => {
        alert('Hubo un error al crear tu registro')
      })
    },
    goToDeleteDoyangCategoryRequisite (item) {
      this.$store.dispatch('DoyangCategoryRequisite/delete', item.id).then(() => {
        alert('Dato borrado')
      })
    },
    goToEditDoyangCategoryRequisite (item) {
      this.$router.push({name: 'DoyangCategoryRequisiteEdit', params: {id: item.id}})
    },
    goToEditDoyang : function(item) {
      console.log(item, item.id)
      this.$router.push({name: 'DoyangEdit', params: {id: item.id}})
    },
    editNewDoyangCategory: function () {
      this.$store.dispatch('DoyangCategory/update', this.doyangCategory).then(() => {
        alert('Se ha modificado el registro con éxito')
        this.$router.push({name: 'DoyangCategoryIndex'})
        }).catch(() => {
          alert('Hubo un error al modificar tu registro')
        })
      }
  },
  computed: {
    defaultDoyangCategory () {
      return this.$store.getters['DoyangCategory/doyangCategory']
    },
    doyangCategoryRequisites () {
      return this.$store.getters['DoyangCategoryRequisite/doyangCategoryRequisites']
    },
  },
  watch : {
    defaultDoyangCategory () {
      if (this.$route.params.id){
        this.doyangCategory = this.defaultDoyangCategory
      }
    },
  },
  data () {
    return {
      requisitesHeaders: [
        {text: 'Requisito', value: 'name'},
        {text: 'Acciones', value: 'actions'}
      ],
      ENV: ENV,
      createBundleDialog: false,
      storeModel: 'Doyang',
      triggerCreateBundle: false,
      triggerEditBundle: false,
      edittingBundleId: 0,
      doyangCategory: {
        name:'',
        cellphone:'',
        inscription_price: null,
        monthly_fee_price: null
      },
      doyangsTableHeaders: [
        {text: 'Nombre', value: 'name', sortable: true},
        {text: 'Acciones', value:'actions', sortable:false},
      ],
      doyangsBundlesHeaders: [
        {text: 'Nombre', value: 'name', sortable: true},
        {text: 'Precio', value: 'price', sortable: true},
        {text: 'Acciones', value:'actions', sortable:false},
      ],
  }
}
}
</script>

<style>

</style>
