import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangBundles: [],
    doyangBundle: {}
  },
  mutations: {
    index(state, payload) {
     return state.doyangBundles = payload
    },
    show(state,payload) {
      return state.doyangBundle = payload
    },
    addToIndex (state, payload) {
      return state.doyangBundles.push(payload)
    },
    update (state, payload) {
      state.doyangBundles = state.doyangBundles.map((doyangBundle) => {
        if (doyangBundle.id == payload.id) {
          doyangBundle = payload
        }
        return doyangBundle
      })
    },
    delete(state, payload) {
        const doyangBundles = state.doyangBundles.filter( doyangBundle => payload.id != doyangBundle.id)
        state.doyangBundles = doyangBundles
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_bundles${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_bundles/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_bundles`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
      })
    },
    update({commit}, payload) {
      console.log(`${ENV.API_URL}/api/doyang_bundles/${payload.id}`)
      console.log(payload)
      return axios.put(`${ENV.API_URL}/api/doyang_bundles/${payload.id}`, payload).then((res) => {
        commit('update', res.data)
        return res.data
      })
    },
    delete({ commit }, payload) {
        return axios.delete(`${ENV.API_URL}/api/doyang_bundles/${payload}`).then((res) => {
          commit('delete', res.data)
        })
    }
  },
  getters: {
    doyangBundles: state => {
      return state.doyangBundles
    },
    doyangBundle: state => {
      return state.doyangBundle
    }
  },
  modules: {
  }
}
