import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    expenses: [],
    expense: {}
  },
  mutations: {
    index(state, payload) {
      state.expenses = payload
    },
    show(state,payload) {
      state.expense = payload
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/expenses${payload}`).then((res) => {
        commit('index', res.data)
        return (res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/expenses/${payload}`).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/expenses`, payload).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/expenses/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    }
  },
  getters: {
    expenses: state => {
      return state.expenses
    },
    expense: state => {
      return state.expense
    }
  },
  modules: {
  }
}
