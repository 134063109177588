<template>
    <v-row>
        <v-col>
            <span v-if="activeDetailsDoyang.exam_record">Tu record:
                {{ activeDetailsDoyang.exam_record.count }}
                ({{ activeDetailsDoyang.exam_record.name }})</span>
            <br><span style="background-color: green; color: white"> Verdes: </span>
            {{ green_users }},
            <span style="background-color: yellow; color: black"> Amarillos: </span>
            {{ yellow_users }},
            <span style="background-color: red; color: white"> Rojos: </span>
            {{ red_users }},
            <span style="background-color: black; color: white"> Negros: </span>
            {{ black_users }}
            <br> Porcentaje mínimo de examenes realizados entre verdes y amarillos: {{
                minAcceptableExamEfficiency
                    ? minAcceptableExamEfficiency : 0 }}% (20 alumnos)
            <!-- <br> Porcentaje realizado {{ Math.round(activeDetailsDoyang.exam_efficiency) }}% (0
            alumnos) -->
            <br><span v-if="!downloadingTrafficLight"><a href="#"
                    @click.stop="downloadTrafficLight(activeDetailsDoyang.id)">Descargar
                    semáforo</a></span>
            <br><span v-if="!downloadingTrafficLight"><a href="#"
                    @click.stop="downloadTrafficLight(activeDetailsDoyang.id, true)">Descargar
                    semáforo
                    (RECAULCULAR)</a></span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'ExamTotalsCard',
    data() {
        return {
            downloadingTrafficLight: false
        }
    },
    methods: {
        downloadTrafficLight(doyangId, recalculate = false) {
            if (recalculate) {
                if (!confirm('¿Estás seguro que quieres recalcular el semáforo, esto tomará algunos minutos?')) return;
            }
            const examsTotalsDict = this.activeDetailsDoyang?.next_exams_totals;
            const examsTotalsValues = Object.values(examsTotalsDict);
            const nextKupsExam = examsTotalsValues?.find(element => !element?.is_black_belt)
            if (!nextKupsExam) {
                alert('No hay examen siguiente');
                return;
            }
            this.downloadingTrafficLight = true;
            const recalculateString = recalculate ? '&recalculate=true&' : '';
            this.$store.dispatch('Doyang/downloadFile', `/${doyangId}/traffic_light?${recalculateString}exam_id=${this.examId}`).then((res) => {
                if (recalculate) {
                    alert('Semáforo recalculado, por favor refresca la página para ver los cambios');
                }
                this.downloadExcel(res, 'traffic_light_' + doyangId + '.xlsx')
                this.downloadingTrafficLight = false;
            }).catch(() => {
                this.downloadingTrafficLight = false;
            })
        },
        downloadExcel(response, fileName) {
            try {
                const blob = new Blob([response], { type: 'application/octet-stream' });
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        },
    },
    mounted() {
        // Code to run when the component is mounted goes here
    },
    computed: {
        green_users() {
            return this.activeDetailsDoyang?.traffic_light_report_array?.filter(user => user.stoplight === 'verde').length ?? 0;
        },
        yellow_users() {
            return this.activeDetailsDoyang?.traffic_light_report_array?.filter(user => user.stoplight === 'amarillo').length ?? 0;
        },
        red_users() {
            return this.activeDetailsDoyang?.traffic_light_report_array?.filter(user => user.stoplight === 'rojo').length ?? 0;
        },
        black_users() {
            return this.activeDetailsDoyang?.traffic_light_report_array?.filter(user => user.stoplight === 'negro').length ?? 0;
        },
    },
    props: {
        activeDetailsDoyang: {
            type: Object,
            required: true
        },
        minAcceptableExamEfficiency: {
            type: Number,
            required: false
        },
        examId: {
            type: Number,
            required: false,
            default: 36
        }
    }
};
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>