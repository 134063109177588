<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col cols=12>
          <h2 v-if="this.$route.params.id">EDITAR SINODAL</h2>
          <h2 v-else>NUEVO SINODAL</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=2 md=2>
          <v-autocomplete chips outlined label="Honorifico" v-model="user.honorific_title_id" :items="honorificTitles"
            item-value="id" item-text="name"></v-autocomplete>
        </v-col>
        <v-col cols=10 md=3>
          <CapsVTextField v-model="user.name" outlined label="Nombre(s)" :rules="nameRules"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=3>
          <CapsVTextField v-model="user.last_name" outlined label="Apellido" :rules="nameRules"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=4>
          <CapsVTextField v-model="user.second_last_name" outlined label="Segundo Apellido"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=12>
          <CapsVTextField v-model="user.address" outlined label="Ciudad de residencia"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=3>
          <v-autocomplete chips :rules="degreeRules" v-model="user.degree_id" :items="degrees"
            :loading="isLoadingDegree" :search-input.sync="search" outlined color="white" hide-no-data hide-selected
            item-text="name" item-value="id" label="Cinta" placeholder="Cinta"></v-autocomplete>
        </v-col>
        <v-col cols=12 md=4>
          <DMYDateField :label="`Fecha de nacimiento`" v-model="user.birth_date" />
          <v-text-field :value="edadString" disabled outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CapsVTextField outlined v-model="user.sinodals_professor" label="Alumno de"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-file-input outlined v-model="user.file"></v-file-input>
        </v-col>
      </v-row>
      <v-row v-if="this.$route.params.id && user.all_doyangs && user.all_doyangs.length > 0">
        <v-col cols=12>
          <h3>DOYANGS DONDE DA CLASES</h3>
        </v-col>
        <v-col>
          <v-data-table :key="user.id" :headers="doyangsTableHeaders" :items="user.all_doyangs" item-text="name"
            item-value="id" :items-per-page="5" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <v-icon @click="goToEditDoyang(item)">mdi-arrow-right-bold</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-2">
      <v-col>
        <v-btn primary @click="createNewStudent" v-if="!this.$route.params.id" class="blue ligthten-2">Agregar</v-btn>
        <v-btn primary @click="updateStudent" v-else>Editar</v-btn>
      </v-col>
    </v-row>
    <v-alert class="mt-5" type="success" v-model="createdSuccess">El registro se ha creado con exito</v-alert>
    <v-alert class="mt-5" type="error" v-model="submitError">Hubo un error al guardar los datos, intentalo mas
      tarde</v-alert>
    <v-alert style="position: absolute; y:50px;" class="mt-5" type="success" v-model="edittedSuccess">El registro se ha
      editado con exito</v-alert>
  </v-container>
</template>

<script>
var debounce = require('debounce');
import ENV from '../../../env'
export default {
  mounted: function () {
    this.$store.dispatch('HonorificTitle/index')
    this.$store.dispatch('Professor/index', `?role_id=${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_TECHNICAL_DIRECTOR}`)
    this.$store.dispatch('Degree/index')
    if (this.$route.params.id) {
      this.$store.dispatch('Student/show', `${this.$route.params.id}?append=all_doyangs`)
    }
  },
  methods: {
    ageInYears(birthDate) {
      if (!birthDate) {
        return '';
      }
      var today = new Date();
      birthDate = new Date(birthDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    createNewStudent: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('Student/create', this.user).then(() => {
        this.toggleCreatedSuccess()
        this.$router.push({ name: 'SinodalIndex' })
      }
      )
    },
    updateStudent: function () {
      this.$store.dispatch('Student/update', this.user).then(() => {
        this.toggleEdittedSuccess()
        this.$router.push({ name: 'SinodalIndex' })
      }).catch(() => {
        this.toggleSubmitError()
      }
      )
    },
    goToEditDoyang: function (item) {
      console.log(item, item.id)
      this.$router.push({ name: 'DoyangEdit', params: { id: item.id } })
    },
    toggleCreatedSuccess: function () {
      if (this.createdSuccess === false) {
        this.createdSuccess = true,
          setTimeout(this.toggleCreatedSuccess, 2000)
      }
      else {
        this.createdSuccess = false
      }
    },
    toggleSubmitError: function () {
      if (this.submitError === false) {
        this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
      }
      else {
        this.submitError = false
      }
    },
    toggleEdittedSuccess: function () {
      if (this.edittedSuccess === false) {
        this.edittedSuccess = true,
          setTimeout(this.toggleEdittedSuccess, 2000)
      }
      else {
        this.edittedSuccess = false
      }
    },
  },
  computed: {
    edadString() {
      const ageInYears = this.ageInYears(this.user.birth_date)
      return `${ageInYears} años`
    },
    degrees: function () {
      return this.$store.getters['Degree/degrees']
    },
    honorificTitles: function () {
      return this.$store.getters['HonorificTitle/honorificTitles']
    },
    objectDefaultStudent: function () {
      let defaultStudent = this.$store.getters['Student/student']
      defaultStudent.sinodal = true;
      return defaultStudent
    },
    professors() {
      return this.$store.getters['Professor/professors']
    },
  },
  watch: {
    search: debounce(function () {
      this.$store.dispatch('Degree/index', this.search ? `?name=${this.search}` : '')
    }, 500),
    objectDefaultStudent: function () {
      this.user = this.objectDefaultStudent
      this.user.honorific_title_id = this.objectDefaultStudent.honorific_title_id ? Number(this.objectDefaultStudent.honorific_title_id) : this.objectDefaultStudent.honorific_title_id
      this.user.degree_id = this.objectDefaultStudent.degree_id ? Number(this.objectDefaultStudent.degree_id) : this.objectDefaultStudent.degree_id
    }
  },
  data() {
    return {
      createdSuccess: false,
      submitError: false,
      edittedSuccess: false,
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      emailRules: [
        // v => !!v || 'Ingrese el email',
        // v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El email tiene que ser valido',
        // v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      phoneRules: [
        // v => (this.user.cellphone.length > 0 || this.user.phone1.length > 0 || this.user.phone2.length > 0 || !!v) || "Debes introducir al menos un telefono"
      ],
      degreeRules: [
        // v => !!v || "Selecciona la cinta"
      ],
      doyangsTableHeaders: [
        { text: 'Nombre', value: 'name', sortable: true },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      roleArray: [
        { name: 'Kiosa dependiente', id: ENV.ROLE_DEPENDANT_KYOSA_ID },
        { name: 'Kiosa de club', id: ENV.ROLE_CLUB_KYOSA_ID },
        { name: 'Kiosa de doyang', id: ENV.ROLE_DOYANG_KYOSA_ID },
        { name: 'Profesor', id: ENV.ROLE_PROFESSOR_ID },
        { name: 'Profesor certificado', id: ENV.ROLE_CERTIFIED_PROFESSOR_ID },
        { name: 'Director técnico', id: ENV.ROLE_TECHNICAL_DIRECTOR },
        { name: 'Director general', id: ENV.ROLE_GENERAL_DIRECTOR },
      ],
      datePickerMenu: false,
      menuDatePicker: false,
      isLoadingDegree: false,
      search: null,
      user: {
        birth_date: null,
        sinodal: true,
        name: '',
        last_name: '',
        role_id: 0,
        second_last_name: '',
        user_responsible_id: 0,
        honorific_title_id: 0,
        email: '',
        address: '',
        cellphone: '',
        phone1: '',
        phone2: '',
        degree_id: null,
        all_doyangs: [],
        sinodals_professor: null
      }
    }
  }
}
</script>

<style></style>
