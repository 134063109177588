<template>
  <v-container>
    <h2>POOMSES</h2>
    <CRUDIndex :permissionRoot="'Poomses'" :headers="headers" :storeModel="storeModel"
      :storeModelPlural="storeModelPlural" />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data() {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
      ],
      storeModel: 'Poomse',
      storeModelPlural: 'Poomses',
    }
  }
}
</script>

<style></style>e
