const roles = {
  ROLE_ADMINISTRATIVE_DIRECTOR: 11,
  ROLE_STUDENT_ID: 10,
  ROLE_DEPENDANT_KYOSA_ID: 9,
  ROLE_CLUB_KYOSA_ID: 8,
  ROLE_DOYANG_KYOSA_ID: 7,
  ROLE_PROFESSOR_ID: 6,
  ROLE_CERTIFIED_PROFESSOR_ID: 5,
  ROLE_LINK_AND_ORIENTATION: 4,
  ROLE_PUBLIC_RELATIONS: 12,
  ROLE_TECHNICAL_DIRECTOR: 3,
  ROLE_GENERAL_DIRECTOR: 2,
  DOYANG_TYPE_DOYANG_ID: 1,
  DOYANG_TYPE_CLUB_ID: 2,
  PAYMENT_CONCEPT_EXAM_ID: 1,
  PAYMENT_CONCEPT_EVENT_ID: 2,
  PAYMENT_CONCEPT_TOURNAMENT_ID: 3,
  PAYMENT_CONCEPT_PRODUCT_ID: 4,
  PAYMENT_CONCEPT_MONTHLY_PAYMENT: 5,
  PAYMENT_CONCEPT_INSCRIPTION: 6,
  PAYMENT_CONCEPT_INSURANCE_ID: 7,
  PAYMENT_CONCEPT_COMISION: 9,
  PAYMENT_CONCEPT_BUNDLE: 8,
  ROLE_PROGRAMMER: 1,
}
const DEFAULT_DOYANGS = [
    {
        "id": "1",
        "name": "MDK ORIENTE CENTRAL",
        "active": true
    },
    {
        "id": "2",
        "name": "MDK ORIENTE CENTENARIO LMV",
        "active": true
    },
    {
        "id": "3",
        "name": "MDK ORIENTE VIÑEDOS",
        "active": true
    },
    {
        "id": "4",
        "name": "CLUB MDK ORIENTE COLEGIO VALLADOLID",
        "active": true
    },
    {
        "id": "6",
        "name": "CLUB MDK ORIENTE COL. FERNANDEZ DE LIZARDI",
        "active": true
    },
    {
        "id": "10",
        "name": "MDK ORIENTE JUAN JOSE RIOS",
        "active": true
    },
    {
        "id": "11",
        "name": "MDK ORIENTE EL CARRIZO",
        "active": true
    },
    {
        "id": "12",
        "name": "CLUB MDK ORIENTE SAN MIGUEL",
        "active": true
    },
    {
        "id": "13",
        "name": "MDK ORIENTE MOCHICAHUI",
        "active": true
    },
    {
        "id": "15",
        "name": "CLUB MDK ORIENTE RUIZ CORTINES",
        "active": true
    },
    {
        "id": "23",
        "name": "MDK ORIENTE DIRECCIÓN GENERAL",
        "active": true
    },
    {
        "id": "26",
        "name": "MDK ORIENTE BOSQUES LMV",
        "active": true
    },
    {
        "id": "28",
        "name": "MDK ORIENTE HERMOSILLO",
        "active": true
    },
    {
        "id": "32",
        "name": "MDK ORIENTE DOYANG DE PRUEBA",
        "active": true
    },
    {
        "id": "33",
        "name": "CLUB MDK ORIENTE COLEGIO FRAY PEDRO DE GANTE",
        "active": true
    },
    {
        "id": "34",
        "name": "CLUB MDK ORIENTE TOPOLOBAMPO",
        "active": true
    },
    {
        "id": "35",
        "name": "CLUB MDK ORIENTE PARQUE SAN FRANCISCO",
        "active": true
    },
    {
        "id": "36",
        "name": "CLUB MDK ORIENTE LICEO TECNOLOGICO DE SONORA",
        "active": true
    }
];
const months = {
  1: {
    name: 'enero',
    abv: 'ene'
  },
  2: {
    name: 'febrero',
    abv: 'feb'
  },
  3: {
    name: 'marzo',
    abv: 'mar'
  },
  4: {
    name: 'abril',
    abv: 'abr'
  },
  5: {
    name: 'mayo',
    abv: 'may'
  },
  6: {
    name: 'junio',
    abv: 'jun'
  },
  7: {
    name: 'julio',
    abv: 'jul'
  },
  8: {
    name: 'agosto',
    abv: 'ago'
  },
  9: {
    name: 'septiembre',
    abv: 'sep'
  },
  10: {
    name: 'octubre',
    abv: 'oct'
  },
  11: {
    name: 'noviembre',
    abv: 'nov'
  },
  12: {
    name: 'diciembre',
    abv: 'dic'
  },
}
export default {
  INSURANCE_PRODUCT_ID: 3,
  // API_URL: 'http://localhost:1350',
  API_URL: 'https://api.sistemaoriente.com',
  DIRECCIÓN_GENERAL_ID: 23,
  DOYANG_PREPEND_STRING: 'MDK ORIENTE',
  CLUB_PREPEND_STRING: 'CLUB MDKO',
  DEFAULT_DOYANGS,
  ROLE_ARRAY: [
        {name: 'Alumno', id: roles.ROLE_STUDENT_ID},
        {name: 'Orientación y enlace', id: roles.ROLE_LINK_AND_ORIENTATION},
        {name: 'Kiosa dependiente', id: roles.ROLE_DEPENDANT_KYOSA_ID},
        {name: 'Kiosa de club', id: roles.ROLE_CLUB_KYOSA_ID},
        {name: 'Kiosa de doyang', id: roles.ROLE_DOYANG_KYOSA_ID},
        {name: 'Relaciones Pública', id: roles.ROLE_PUBLIC_RELATIONS},
        {name: 'Profesor', id: roles.ROLE_PROFESSOR_ID},
        {name: 'Profesor certificado', id: roles.ROLE_CERTIFIED_PROFESSOR_ID},
        {name: 'Directora administrativa', id: roles.ROLE_ADMINISTRATIVE_DIRECTOR},
        {name: 'Director técnico', id: roles.ROLE_TECHNICAL_DIRECTOR},
        {name: 'Programador', id: roles.ROLE_PROGRAMMER},
        {name: 'Director general', id: roles.ROLE_GENERAL_DIRECTOR},
        {name: 'Relaciones públicas', id: roles.ROLE_PUBLIC_RELATIONS},
    ],
  CIVIL_STATES: [
    'CASADO',
    'SOLTERO',
    'DIVORCIADO',
    'UNION LIBRE',
    'VIUDO',
  ],
  PAYMENT_TYPES: [
    'FAVOR DE DEFINIR',
    'EFECTIVO',
    'TARJETA DE DÉBITO',
    'TARJETA DE CRÉDITO',
    'DEPÓSITO',
  ],
  BLOOD_TYPES: [
    { id: null, text: 'No especifcado' },
    { id: 1, text: 'A+' },
    { id: 2, text: 'B+' },
    { id: 3, text: 'AB+' },
    { id: 4, text: 'O+' },
    { id: 5, text: 'A-' },
    { id: 6, text: 'B-' },
    { id: 7, text: 'AB-' },
    { id: 8, text: 'O-' },
  ],
  ...roles,
  permissions: {
    can_see_general_direction: [
      roles.ROLE_PROGRAMMER,
      roles.ROLE_ADMINISTRATIVE_DIRECTOR,
      roles.ROLE_TECHNICAL_DIRECTOR,
      roles.ROLE_GENERAL_DIRECTOR
    ],
    can_see_catalogos: [
      roles.ROLE_PROGRAMMER,
      roles.ROLE_ADMINISTRATIVE_DIRECTOR,
      roles.ROLE_TECHNICAL_DIRECTOR,
      roles.ROLE_GENERAL_DIRECTOR
    ],
    can_see_taekwondo: [
      roles.ROLE_PROGRAMMER,
      roles.ROLE_ADMINISTRATIVE_DIRECTOR,
      roles.ROLE_TECHNICAL_DIRECTOR,
      roles.ROLE_GENERAL_DIRECTOR
    ],
    can_see_administration: [
      roles.ROLE_PROGRAMMER,
      roles.ROLE_ADMINISTRATIVE_DIRECTOR,
      roles.ROLE_TECHNICAL_DIRECTOR,
      roles.ROLE_GENERAL_DIRECTOR
    ]
  },
  months,
  __parseDateToText(date) {
    const dateObj = new Date(date)
    const month = months[dateObj.getUTCMonth() + 1]['abv'].toUpperCase()
    const year = dateObj.getUTCFullYear()
    return `${year}/${month}`
  },
  __parseDateToTextYMD(date) {
    if (!date) return 'N/A'
    const dateObj = new Date(date)
    const month = months[dateObj.getUTCMonth() + 1]['abv'].toUpperCase()
    const year = dateObj.getUTCFullYear()
    return `${dateObj.getUTCDate()}/${month}/${year}`
  }
}
