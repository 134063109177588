<template>
    <v-col class="graph-node">
        <input type="checkbox" v-if="user && user.placement">
        <span class="graph-user-text">
            <span v-if="user">
                <span>{{ user.full_name }} {{ user.age }}A</span>
                <span v-if="graphCompetitionType == 1">
                    | {{ user.weight_kg ? `(${user.weight_kg}KG)` : `` }}
                </span>
                <span>
                    | {{ user.doyang_name.replace('MDK ORIENTE ', '') }}
                </span>
                <span>
                    | {{ user.group_professor_name }}
                </span>
            </span>
        </span>
    </v-col>
</template>

<script>
export default {
    name: 'UserBox',
    props: {
        user: Object,
        graphCompetitionType: Number
    }
}
</script>

<style scoped>
.graph-node {
    font-size: 9pt;
    border-width: 2px;
    border-style: solid;
    height: 80px;
    text-align: left;
}

.graph-user-text {
    font-size: 8pt;
    font-weight: 900;
    line-height: 0;
}
</style>