var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('a',[_vm._v(_vm._s(_vm.examName)+":")])])]}}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.examName)+" | "+_vm._s(_vm.renderDoyang.name))]),_c('v-card-text',[_c('v-container',[_c('ExamTotalsCard',{attrs:{"activeDetailsDoyang":_vm.doyangWithTraffic,"examId":_vm.examId,"minAcceptableExamEfficiency":1}}),_c('v-row',[_c('v-col',[_c('span',[(_vm.exam_record.count == undefined)?_c('span',[_vm._v("No hay torneos")]):_c('strong',[_vm._v("Tu record:")]),_vm._v(" "+_vm._s(_vm.exam_record.count)+" ("+_vm._s(_vm.exam_record.name)+") ")])])],1),_c('v-row',[_c('v-col',[_c('span',[_c('strong',[_vm._v("Ventas: ")]),_vm._v(_vm._s(_vm.exam ? _vm.exam['count'] : 0)+"/"+_vm._s(_vm.exam && _vm.exam['goal'] || 0)+" (meta "),(_vm.canEditGoals && _vm.areGoalsModifiable)?_c('v-icon',{on:{"click":function($event){return _vm.establishGoal(_vm.renderDoyang.id, _vm.examName, 'exam')}}},[_vm._v("mdi-pencil")]):_vm._e(),_vm._v(") ")],1)])],1),_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.downloadExamListCsv(_vm.exam['id'], _vm.renderDoyang.id)}}},[_vm._v("Descargar csv | ")]),_c('span',{staticStyle:{"margin-left":"6px","color":"blue","cursor":"pointer"},on:{"click":function($event){_vm.downloadExamHotelBusListExcel(
        (_vm.renderDoyang.next_exams_totals && _vm.exam ? _vm.exam['id'] : null)
        , _vm.renderDoyang.id)}}},[_vm._v("Descargar excel de camión/hotel")])])],1)],1),(_vm.exam && _vm.exam.show_table_in_dashboard)?_c('v-data-table',{attrs:{"headers":[
        { 'value': 'range', 'text': 'Rango' },
        { 'value': 'percentage', 'text': 'Porciento' },
        { 'value': 'price', 'text': 'Costo del examo' },
        { 'value': 'utility', 'text': 'Utilidad' },
        { 'value': 'minimum', 'text': 'Mínimo' },
        { 'value': 'maximum', 'text': 'Máximo' },
        { 'value': 'annual', 'text': 'Anual' },
    ],"hide-default-footer":"","items":_vm.doyang_surrogation_exams},scopedSlots:_vm._u([{key:"item.range",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.min_inscriptions)+"-"+_vm._s(item.max_inscriptions)+" ")])]}},{key:"item.percentage",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.percentage)+"% ")])]}},{key:"item.price",fn:function({ }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format(_vm.exam['price'] || "$0"))+" ")])]}},{key:"item.utility",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.exam['price'] || 0) * (item.percentage / 100)))+" ")])]}},{key:"item.minimum",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.exam['price'] || 0) * item.min_inscriptions * (item.percentage / 100)))+" ")])]}},{key:"item.maximum",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.exam['price'] || 0) * item.max_inscriptions * (item.percentage / 100)))+" ")])]}},{key:"item.annual",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormatter.format( (_vm.exam['price'] || 0) * item.max_inscriptions * (item.percentage / 100) * 6))+" ")])]}}],null,false,895089494)}):_vm._e()],1),_c('v-card-actions',[_c('v-btn',[_vm._v("Close")])],1)],1)],1),_c('span',[_vm._v(" "+_vm._s(_vm.exam && _vm.exam['count'] ? _vm.exam['count'] : 0)+"/"+_vm._s(_vm.exam ? _vm.exam['goal'] : 0)+" | Requeridos "+_vm._s(_vm.exam ? _vm.exam['required'] : 0)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }