<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR SEDE</h2>
        <h2 v-else>EDITAR SEDE</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=5>
          <CapsVTextField
            outlined
            v-model="venue.name"
            label="Nombre del lugar"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col md=3>
          <v-autocomplete
            outlined
            chips
            v-model="state"
            label="Estado"
            :items="states"
            item-text="name"
            item-value="id"
            return-object
            />
        </v-col>
        <v-col md=4>
          <v-autocomplete
            outlined
            chips
            v-model="venue.municipality_id"
            label="Municipio"
            :items="municipalities"
            item-text="name"
            item-value="id"
            />
        </v-col>
        <v-col md=4>
          <v-autocomplete
            outlined
            chips
            v-model="venue.city_id"
            label="Localidad"
            :items="cities"
            item-text="name"
            item-value="id"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=4>
          <CapsVTextField
            outlined
            v-model="venue.contact_name"
            label="Nombre del Contacto"
            />
        </v-col>
        <v-col cols=12 md=4>
          <v-text-field
            outlined
            phone
            v-model="venue.phone"
            label="Teléfono"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createVenue">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateVenue">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('State/index');

      this.$store.dispatch('City/index', this.$route.params.id).then((res) => {
        this.cities = res
        console.log(res)
        if (this.$route.params.id) {
          this.$store.dispatch('Venue/show', this.$route.params.id).then(() => {
            const venue = this.$store.getters['Venue/venue']
            this.venue = venue
            this.venue.state_id = venue.state_id ? Number(venue.state_id) : venue.state_id
            this.venue.municipality_id = venue.municipality_id ? Number(venue.municipality_id) : venue.municipality_id
            this.venue.city_id = venue.city_id ? Number(venue.city_id) : venue.city_id
            let stateId = this.venue.state_id
            if (stateId != undefined) {
              this.$store.dispatch('State/show', stateId).then((res) => {
                this.state = res
              })
            }
          })
        }
      })
  },
  components: {
  },
  methods: {
    createVenue: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('Venue/create', this.venue).then(() => {
          this.$router.push({name: 'VenueIndex'})
        }
      )
    },
    updateVenue: function () {
      this.$store.dispatch('Venue/update', this.venue).then(() => {
          this.$router.push({name: 'VenueIndex'})
        }).catch((error) => {
          console.log(error)
          }
        )
    },
  },
  watch: {
    'state': function () {
      this.municipalities = this.state.municipalities
      this.venue.state_id = this.state.id
    }
  },
  computed : {
    states()  {
      return this.$store.getters['State/states'];
    },
  },
  data () {
    return {
      municipalities: [],
      cities: [],
      state: null,
      coso: null,
      venue: {
        name: '',
        phone: null,
        contact_name: null,
        state_id: null,
        municipality_id: null,
        city_id: null,
      },
    }
  }
}
</script>

<style>

</style>