import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    enrollments: [],
    enrollment: {},
  },
  mutations: {
    delete(state,payload) {
      state.enrollments = state.enrollments.filter((enrollment) => enrollment.id != payload.id)
    },
    empty() {},
    index(state, payload) {
      state.enrollments = payload
    },
    show(state,payload) {
      state.enrollment = payload
    },
  },
  actions: {
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/enrollments/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    },
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/enrollments${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    printApplication ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/enrollment_applications/enrollment/${payload.enrollmentId}/user/${payload.userId}${payload.payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'solicitud_de_enrollmenten.pdf'
        link.click()
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/enrollments/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      axios.post(`${ENV.API_URL}/api/enrollments`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/enrollments/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    aproveRejectProspect ({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/enrollments/${payload.enrollmentId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('approveRejectUser', res.data)
      })
    },
    assignResult ({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/enrollments/${payload.enrollmentId}/prospects/${payload.userId}/result`, payload).then((res) => {
        commit('addResult', res)
      })
    },
    deleteProspect({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/enrollments/${payload.enrollmentId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('deleteProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    addProspect({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/enrollments/${payload.enrollmentId}/prospects`, {userId : payload.userId}).then((res) => {
        commit('addProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    printSheet({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/enrollments/result_sheet/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'solicitud_de_enrollmenten.pdf'
        link.click()
        return true
      }).catch(() => 0)
    },
    printSheetByCertifier({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/enrollments/result_sheet_by_certifier/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        console.log('hi')
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'solicitud_de_enrollmenten.pdf'
        link.click()
      }).catch(() => 0)
    },
  },
  getters: {
    enrollments: state => {
      return state.enrollments
    },
    enrollment: state => {
      return state.enrollment
    }
  },
  modules: {
  }
}
