import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    payments: [],
    payment: {},
    totalItems: 0
  },
  mutations: {
    index(state, payload) {
      state.payments = payload
    },
    indexPaginated(state, payload) {
      state.payments = payload.data
      state.totalItems = payload.total
    },
    show(state, payload) {
      state.payment = payload
    },
    substitute (state, res) {
      res = !Array.isArray(res) ? [res] : res
      res.forEach( element => {
        state.payments = state.payments.map( payment => element.id == payment.id ? element : payment)
      });
    }
  },
  actions: {
    index({ commit },payload) {
      return axios.get(`${ENV.API_URL}/api/payments${payload ? payload : ''}`).then((res) => {
        if (payload.includes('amounts_only=1')) {
          return res.data
        }
        if (payload.includes('paginate=1')) {
          commit('indexPaginated', res.data)
        }
        else
          commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      axios.get(`${ENV.API_URL}/api/payments/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({ commit }, payload) {
      return axios.post(`${ENV.API_URL}/api/payments`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/payments/${payload.id}`, payload).then((res) => {
        if (!payload?.models) 
          commit('show', res.data)
        commit('substitute', res.data)
      }).catch(() => {console.log('hi')})
    }
  },
  getters: {
    payments: state => {
      return state.payments
    },
    payment: state => {
      return state.payment
    },
    totalItems: state => {
      return state.totalItems
    },
  },
  modules: {
  }
}
