import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    receipts: [],
    receipt: {},
    totalItems: 0,
  },
  mutations: {
    index(state, payload) {
      state.receipts = payload
    },
    indexPaginated(state, payload) {
      state.receipts = payload.data
      state.totalItems = payload.total
    },
    show(state, payload) {
      state.receipt = payload
    },
    update(state, payload) {
      if (Array.isArray(payload)){
        state.receipts = state.receipts.map( receipt => {
            return receipt.id == payload.id ? payload : receipt
        })
      } else {
        Object.keys(state.receipts).forEach( (index) => {
            const receipt = state.receipts[index]
            if (receipt.id == payload.id)
                state.receipts[index] = payload
        })
      }
    },
    empty() {},
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/receipts${payload || ''}`).then((res) => {
        if (payload.includes('paginate=1')) {
          commit('indexPaginated', res.data)
        }
        else
          commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/receipts/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/receipts`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/receipts/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        commit('update', res.data)
        return res.data
      })
    },
    print ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/receipts/${payload}`, {crossDomain: true, responseType: 'blob'}).then((response) => {
        commit('empty')
        console.log(response.data)
        let blob = new Blob([response.data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'recibo.pdf'
        link.click()
      })
    },
  },
  getters: {
    receipts: state => {
      return state.receipts
    },
    receipt: state => {
      return state.receipt
    },
    totalItems: state => {
      return state.totalItems
    }
  },
  modules: {
  }
}
