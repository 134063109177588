import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    examResults: [],
    examResult: {} 
  },
  mutations: {
    index(state, payload) {
      state.examResults = payload
    },
    show(state, payload) {
      state.examResult = payload
    }
  },
  actions: {
    index ({commit}) {
      axios.get(`${ENV.API_URL}/api/exam_results`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      axios.get(`${ENV.API_URL}/api/exam_results/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      axios.post(`${ENV.API_URL}/api/exam_results`, payload).then((res) => {
        commit('show', res.data)
      })
    },
    uptade({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/exam_results/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
      })
    }
  },
  getters: {
    examResults: state => {
      return state.examResults
    },
    examResult: state => {
      return state.examResult
    }
  },
  modules: {
  }
}
