<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR CIUDAD</h2>
        <h2 v-else>EDITAR CIUDAD</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <caps-v-text-field
            outlined
            v-model="city.name"
            label="Nombre la localidad"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createCity">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateCity">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue';
export default {
  mounted () {
    this.$store.dispatch('State/index');
    if (this.$route.params.id) {
      this.$store.dispatch('City/index', this.$route.params.id).then((res) => {
        this.cities = res
        this.$store.dispatch('City/show', this.$route.params.id).then(() => {
          this.city = this.$store.getters['City/city']
          let stateId = this.city.state_id
          if (stateId != undefined) {
            this.$store.dispatch('State/show', stateId).then((res) => {
              this.state = res
            })
          }
        });
      })
    }
  },
  components: {
    CapsVTextField
  },
  methods: {
    createCity: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('City/create', this.city).then(() => {
          this.$router.push({name: 'CityIndex'})
        }
      )
    },
    updateCity: function () {
      this.$store.dispatch('City/update', this.city).then(() => {
          this.$router.push({name: 'CityIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
  },
  computed : {
  },
  data () {
    return {
      city: {
        name: '',
      },
    }
  }
}
</script>

<style>

</style>
