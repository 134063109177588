<template>
    <div>
        <v-dialog v-model="active">
            <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                    <a>{{ tournamentName }}:</a>
                </span>
            </template>
            <v-card>
                <v-card-title>{{ tournamentName }} | {{ doyang.name }}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-row>
                                <v-col>
                                    <TournamentTotalsCard :activeDetailsDoyang="doyangWithTraffic"
                                        :tournamentId="tournamentId" :minAcceptableExamEfficiency="1" />
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span>
                                    <span v-if="tournament_record.count == undefined">No hay torneos</span>
                                    <strong v-else>Tu record:</strong>
                                    {{ tournament_record.count }}
                                    ({{ tournament_record.name }} -
                                    {{ tournament_record.date }})
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span>
                                    <strong>Ventas: </strong>{{ tournament ? tournament['count'] : 0 }}/{{ tournament ?
                                        tournament['goal']
                                        : 0 }} (meta <v-icon
                                        @click="establishGoal(renderDoyang.id, tournamentName, 'tournament')"
                                        v-if="canEditGoals && areGoalsModifiable">mdi-pencil</v-icon>)
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span style="color: blue; cursor: pointer;"
                                    @click="downloadTournamentListCsv(tournament['id'], doyang.id)">Descargar
                                    csv | </span>
                                <span style="margin-left:6px; color: blue; cursor: pointer;" @click="downloadTournamentListExcel(
                                    (doyang.next_tournaments_totals && tournament ? tournament['id'] : null)
                                    , doyang.id)">Descargar excel | </span>
                                <span style="margin-left:6px; color: blue; cursor: pointer;" @click="downloadTournamentHotelBusListExcel(
                                    (doyang.next_tournaments_totals && tournament ? tournament['id'] : null)
                                    , doyang.id)">Descargar excel de camión/hotel</span>

                            </v-col>
                        </v-row>
                    </v-container>
                    <v-data-table :headers="[
                        { 'value': 'range', 'text': 'Rango' },
                        { 'value': 'percentage', 'text': 'Porciento' },
                        { 'value': 'price', 'text': 'Costo del evento' },
                        { 'value': 'utility', 'text': 'Utilidad' },
                        { 'value': 'minimum', 'text': 'Mínimo' },
                        { 'value': 'maximum', 'text': 'Máximo' },
                        { 'value': 'annual', 'text': 'Anual' },
                    ]" v-if="tournament && tournament.show_table_in_dashboard" hide-default-footer
                        :items="doyang_surrogation_tournaments">
                        <template v-slot:item.range="{ item }">
                            <div>
                                {{ item.min_inscriptions }}-{{ item.max_inscriptions }}
                            </div>
                        </template>
                        <template v-slot:item.percentage="{ item }">
                            <div>
                                {{ item.percentage }}%
                            </div>
                        </template>
                        <template v-slot:item.price="{}">
                            <div>
                                {{ currencyFormatter.format(tournament['price']
                                    || "$0") }}
                            </div>
                        </template>
                        <template v-slot:item.utility="{ item }">
                            <div>
                                {{ currencyFormatter.format(
                                    (tournament['price'] || 0)
                                    * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.minimum="{ item }">
                            <div>
                                {{ currencyFormatter.format(
                                    (tournament['price'] || 0)
                                    * item.min_inscriptions * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.maximum="{ item }">
                            <div>
                                {{ currencyFormatter.format(
                                    (tournament['price'] || 0)
                                    * item.max_inscriptions * (item.percentage / 100)) }}
                            </div>
                        </template>
                        <template v-slot:item.annual="{ item }">
                            <div>
                                {{ currencyFormatter.format(
                                    (tournament['price'] || 0)
                                    * item.max_inscriptions * (item.percentage / 100) * 6) }}
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn>Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <span>
            <span>
                {{ tournament ? tournament['count'] : 0 }}/{{ tournament ? tournament['goal'] :
                    0 }} | Requeridos {{ tournament ?
                    tournament['required'] : 0 }}
            </span>
        </span>
    </div>
</template>

<script>
import ENV from '../../../env.js'
import TournamentTotalsCard from './TournamentTotalsCard.vue';

export default {
    components: {
        TournamentTotalsCard
    },
    mounted() { },
    data() {
        return {
            doyangWithTraffic: {},
            active: false,
            renderDoyang: {},
        };
    },
    props: {
        tournamentId: {
            type: Number,
            required: true
        },
        doyang: {
            type: Object,
            required: true
        },
        tournamentName: {
            type: String,
            required: true
        },
        areGoalsModifiable: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        doyang() {
            this.renderDoyang = JSON.parse(JSON.stringify(this.doyang)) || {}
        },
        active() {
            if (this.active) {
                this.$store.dispatch('Doyang/show', `${this.doyang.id}?tournament_id=${this.tournamentId}&with=doyang_surrogation,doyang_surrogation.doyang_surrogation_tournaments&append=next_tournaments_totals,tournament_record,traffic_light_tournament_report_array`).then((res) => {
                    this.doyangWithTraffic = res
                })
            }
            this.$store.dispatch('Configuration/show', 1).then((res) => {
                this.bimesterStart = res.value
            })
            this.renderDoyang = JSON.parse(JSON.stringify(this.doyang)) || {}
        }
    },
    methods: {
        downloadTournamentHotelBusListExcel(eventId, doyangId) {
            this.$store.dispatch('Tournament/downloadFile', `/${eventId}/payment_bus_hotel_list_excel?doyang_id=${doyangId}`).then((res) => {
                this.downloadExcel(res, 'tournament_excel_' + eventId + '.xlsx')
            })
        },
        downloadTournamentListExcel(eventId, doyangId) {
            this.$store.dispatch('Tournament/downloadFile', `/${eventId}/payment_list_excel?doyang_id=${doyangId}`).then((res) => {
                this.downloadExcel(res, 'tournament_excel_' + eventId + '.xlsx')
            })
        },
        downloadTournamentListCsv(eventId, doyangId) {
            this.$store.dispatch('Tournament/index', `/${eventId}/payment_list?doyang_id=${doyangId}`).then((res) => {
                this.downloadStringInNewPage(res, 'events.csv')
            })
        },
        downloadStringInNewPage(string, filename) {
            const blob = new Blob([string], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);
            a.setAttribute('download', filename);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        establishGoal(doyangId, tournamentName, type) {
            console.log('tournamentName', tournamentName);
            const goal = prompt('Introduce la nueva meta')
            this.$store.dispatch('Doyang/stablish_goal', {
                doyang_id: doyangId,
                tournament_name: tournamentName,
                type,
                goal,
                tournament_id: this.tournament.id
            }).then(() => {
                this.renderDoyang.next_tournaments_totals[tournamentName]['goal'] = goal
            })
        },
        downloadExcel(response, fileName) {
            try {
                const blob = new Blob([response], { type: 'application/octet-stream' });
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        },
    },
    computed: {
        canEditGoals() {
            return true;
        },
        canSeeGeneralDirection() {
            if (!this.authInfo) return false
            return ENV.permissions.can_see_general_direction.includes(parseInt(this?.authInfo?.user?.role_id))
        },
        isWindowToEditGoals() {
            const bimesterStart = new Date(this.bimesterStart)
            const today = new Date()
            const fifteenDaysAfterBimesterStart = new Date(bimesterStart.setDate(bimesterStart.getDate() + 15))
            return today < fifteenDaysAfterBimesterStart
        },
        authInfo() {
            return this.$store.getters['Auth/authInfo']
        },
        currencyFormatter() {
            return new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN'
            });
        },
        tournament_record() {
            return this.$store.getters['Doyang/doyang']?.tournament_record || {}
        },
        doyang_surrogation_tournaments() {
            return this.$store.getters['Doyang/doyang']?.doyang_surrogation?.doyang_surrogation_tournaments || []
        },
        tournament() {
            return this.renderDoyang?.next_tournaments_totals?.[this.tournamentName] || {}
        }
    }
};
</script>

<style scoped>
h1 {
    color: blue;
}

p {
    font-size: 18px;
}
</style>