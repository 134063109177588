import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    poomses: [],
    poomse: {}
  },
  mutations: {
    index(state, payload) {
      state.poomses = payload
    },
    show(state,payload) {
      state.poomse = payload
    },
    delete(state, payload) {
      const poomses = state.poomses.filter( poomse => payload.id != poomse.id)
      state.poomses = poomses
    },
    create(state, payload) {
      state.poomses.push(payload)
    },
    createCategory(state, payload) {
      state.poomse.tournament_poomse_categories.push(payload)
    },
    deleteCategory(state, payload) {
      const categories = state.poomse.tournament_poomse_categories.filter( category => payload.id != category.id)
      state.poomse.tournament_poomse_categories = categories
    }
  },
  actions: {
    index ({commit}) {
      return axios.get(`${ENV.API_URL}/api/poomses`).then((res) => {
        commit('index', res.data)
        return (res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/poomses/${payload}`).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/poomses`, payload).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/poomses/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return (res.data)
      })
    },
    delete({ commit }, payload) {
      return axios.delete(`${ENV.API_URL}/api/poomses/${payload}`).then((res) => {
        commit('delete', res.data)
        return (res.data);
      })
    },
    createCategory({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/poomses/${payload.id}/tournament_poomse_categories`, payload).then((res) => {
        commit('createCategory', res.data)
        return (res.data)
      })
    },
    deleteCategory({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/poomses/${payload.poomse_id}/tournament_poomse_categories/${payload.id}`).then((res) => {
        commit('deleteCategory', res.data)
        return (res.data)
      })
    }
  },
  getters: {
    poomses: state => {
      return state.poomses
    },
    poomse: state => {
      return state.poomse
    }
  },
  modules: {
  }
}
