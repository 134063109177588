<template>
  <v-container>
    <v-dialog transition="dialog-bottom-transition" max-width="1000" v-model="dialogStudentExist">
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="green lighten-2" dark>Coincidencias</v-toolbar>
          <v-card-text>
            <div class="text-h6 pa-12">Puede que este usuario. ¿Lo reconoces entre alguno de los siguientes?</div>
            <v-container>
              <v-row>
                <v-col class="text-left">
                  <ul>
                    <li v-for="eStudent in existingStudents" :key="eStudent.id">{{ eStudent.full_name }} -
                      {{ eStudent.doyang ? eStudent.doyang.name : 'SIN DOYANG' }}</li>
                  </ul>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text class="green lighten-2" dark @click="createNewStudent($event, true)">No lo reconozco
              (crear)</v-btn>
            <v-btn text @click="dialog.value = false">Lo reconozco (no crear)</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-form ref="form">
      <v-row>
        <v-col cols=12>
          <h2 v-if="this.$route.params.id">EDITAR USUARIO</h2>
          <h2 v-else>NUEVO USUARIO</h2>
        </v-col>
      </v-row>
      <v-row v-if="this.$route.params.id">
        <v-col cols=12>
          <v-btn color="yellow lighten-2" @click="goToStudentData">Ver Ficha Completa</v-btn>
          <v-btn color="blue lighten-2" @click="loginAsUser">Ingresar como usuario</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=2 md=2>
          <v-autocomplete chips outlined label="Honorifico" v-model="user.honorific_title_id" :items="honorificTitles"
            item-value="id" item-text="name"></v-autocomplete>
        </v-col>
        <v-col cols=10 md=3>
          <CapsVTextField v-model="user.name" outlined label="Nombre(s)" :rules="nameRules"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=3>
          <CapsVTextField v-model="user.last_name" outlined label="Apellido" :rules="nameRules"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=4>
          <CapsVTextField v-model="user.second_last_name" outlined label="Segundo Apellido"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-outline'" v-model="user.password"
            label="Constraseña" :type="!passwordVisibility ? 'password' : 'text'"
            @click:append="() => passwordVisibility = !passwordVisibility" outlined></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox label="¿Certifica?" v-model="user.certifier" color="blue lighten-2" hide-details></v-checkbox>
        </v-col>
        <v-col>
          <v-autocomplete chips outlined label="Alumno de" v-model="user.user_responsible_id" :items="professors"
            item-value="id" item-text="full_name"></v-autocomplete>
        </v-col>
        <v-col>
          <v-select outlined label="Categoría" v-model="user.role_id" :items="roleArray" item-text="name"
            item-value="id">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=3>
          <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined v-model="parsedDate" label="Fecha de nacimiento" readonly v-bind="attrs"
                v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="user.birth_date" locale="es-MX" @input="datePickerMenu = false"></v-date-picker>
          </v-menu>
          <v-text-field :value="edadString" disabled outlined></v-text-field>
        </v-col>
        <v-col cols=12 md=3>
          <v-autocomplete chips :rules="degreeRules" v-model="user.degree_id" :items="degrees"
            :loading="isLoadingDegree" :search-input.sync="search" outlined color="white" hide-no-data hide-selected
            item-text="name" item-value="id" label="Cinta" placeholder="Cinta"></v-autocomplete>
          <a href="#" @click.stop="user.degree_id = null" style="color:gray">x Quitar Grado</a>
        </v-col>
        <v-col cols=12 md=6>
          <v-text-field v-model="user.email" :rules="emailRules" type="email" outlined label="Email"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=12>
          <CapsVTextField v-model="user.address" outlined label="Direccion"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 md=4>
          <CapsVTextField v-model="user.cellphone" :rules="phoneRules" outlined label="Celular"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=4>
          <CapsVTextField v-model="user.phone1" :rules="phoneRules" outlined label="Telefono 1"></CapsVTextField>
        </v-col>
        <v-col cols=12 md=4>
          <CapsVTextField v-model="user.phone2" :rules="phoneRules" outlined label="Telefono 2"></CapsVTextField>
        </v-col>
      </v-row>
      <v-row v-if="this.$route.params.id && user.all_doyangs != undefined && user.all_doyangs.length > 0">
        <v-col cols=12>
          <h3>DOYANGS DONDE DA CLASES</h3>
        </v-col>
        <v-col>
          <v-data-table :key="user.id" :headers="doyangsTableHeaders" :items="user.all_doyangs" item-text="name"
            item-value="id" :items-per-page="5" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <v-icon @click="goToEditDoyang(item)">mdi-arrow-right-bold</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-2">
      <v-col>
        <v-btn primary @click="createNewStudent" v-if="!this.$route.params.id" class="blue ligthten-2">Agregar</v-btn>
        <v-btn primary @click="updateStudent" class="yellow lighten-2" v-else>Editar</v-btn>
      </v-col>
    </v-row>
    <v-alert class="mt-5" type="success" v-model="createdSuccess">El registro se ha creado con exito</v-alert>
    <v-alert class="mt-5" type="error" v-model="submitError">Hubo un error al guardar los datos, intentalo mas
      tarde</v-alert>
    <v-alert class="mt-5" type="success" v-model="edittedSuccess">El registro se ha editado con exito</v-alert>
  </v-container>
</template>

<script>
var debounce = require('debounce');
import ENV from '../../../env'
import CapsVTextField from '../globals/CapsVTextField.vue'
export default {
  components: { CapsVTextField },
  mounted: function () {
    this.$store.dispatch('HonorificTitle/index')
    this.$store.dispatch('Professor/index', `?role_id=${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_TECHNICAL_DIRECTOR},${ENV.ROLE_ADMINISTRATIVE_DIRECTOR},${ENV.ROLE_PROGRAMMER}`)
    this.$store.dispatch('Degree/index')
    if (this.$route.params.id) {
      this.$store.dispatch('Student/show', `${this.$route.params.id}?append=all_doyangs`).then((student) => {
        this.originalStudent = student
      })
    }
  },
  methods: {
    loginAsUser() {
      const params = { hiddenId: this.$route.params.id }
      this.$store.dispatch('Auth/login', params).then(
        () => {
          alert('authenticated')
          this.$router.push({ name: 'Home' })
        });
    },
    ageInYears(birthDate) {
      if (!birthDate) {
        return '';
      }
      var today = new Date();
      birthDate = new Date(birthDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    goToStudentData() {
      if (!confirm('¿Estás seguro que deseas salir de esta ventana? Los datos no guardados se perderán.')) return
      this.$router.push({
        name: 'StudentEdit', params: {
          id: this.$route.params.id,
          title: 'EDITAR USUARIO',
        }
      });
    },
    createNewStudent: async function (event, comesFromDialog) {
      if (!this.$refs.form.validate())
        return
      if (!comesFromDialog) {
        await this.$store.dispatch('Student/index', `?birth_date=${this.user.birth_date}&with=doyang`).then(res => {
          if (res.length > 0) {
            this.dialogStudentExist = true
            this.existingStudents = res
          }
        })
        if (this.dialogStudentExist) {
          return
        }
      }
      this.$store.dispatch('Student/create', this.user).then(() => {
        this.toggleCreatedSuccess()
        alert('Registro creado con éxito');
        this.$router.push({ name: 'ProfessorIndex' })
      }
      )
    },
    updateStudent: function () {
      this.$store.dispatch('Student/update', this.user).then(() => {
        this.toggleEdittedSuccess
        this.$router.push({ name: 'ProfessorIndex' })
      }).catch(() => {
        this.toggleSubmitError()
      }
      )
    },
    goToEditDoyang: function (item) {
      console.log(item, item.id)
      this.$router.push({ name: 'DoyangEdit', params: { id: item.id } })
    },
    toggleCreatedSuccess: function () {
      if (this.createdSuccess === false) {
        this.createdSuccess = true,
          setTimeout(this.toggleCreatedSuccess, 2000)
      }
      else {
        this.createdSuccess = false
      }
    },
    toggleSubmitError: function () {
      if (this.submitError === false) {
        this.submitError = true,
          setTimeout(this.toggleSubmitError, 2000)
      }
      else {
        this.submitError = false
      }
    },
    toggleEdittedSuccess: function () {
      if (this.edittedSuccess === false) {
        this.edittedSuccess = true,
          setTimeout(this.toggleEdittedSuccess, 2000)
      }
      else {
        this.edittedSuccess = false
      }
    },
  },
  computed: {
    degrees: function () {
      return this.$store.getters['Degree/degrees']
    },
    honorificTitles: function () {
      return this.$store.getters['HonorificTitle/honorificTitles']
    },
    objectDefaultStudent: function () {
      return this.$store.getters['Student/student']
    },
    professors() {
      return this.$store.getters['Professor/professors']
    },
    edadString() {
      const ageInYears = this.ageInYears(this.user.birth_date)
      return `${ageInYears} años`
    }
  },
  watch: {
    'user.birth_date': function () {
      if (!this.user.birth_date) return null
      const date = this.user.birth_date
      const [year, month, day] = date.split('-')
      this.parsedDate = `${day.padStart(2, '0')}/${ENV.months[parseInt(month)].abv.toUpperCase()}/${year}`
    },
    search: debounce(function () {
      this.$store.dispatch('Degree/index', this.search ? `?name=${this.search}` : '')
    }, 500),
    objectDefaultStudent: function () {
      this.user = this.objectDefaultStudent
      this.user.cellphone = this.user.cellphone ? this.user.cellphone : ''
      this.user.honorific_title_id = this.user.honorific_title_id ? Number(this.user.honorific_title_id) : this.user.honorific_title_id
      this.user.user_responsible_id = this.user.user_responsible_id ? Number(this.user.user_responsible_id) : this.user.user_responsible_id
      this.user.role_id = this.user.role_id ? Number(this.user.role_id) : this.user.role_id
      this.user.degree_id = this.user.degree_id ? Number(this.user.degree_id) : this.user.degree_id
      this.user.sex = this.objectDefaultStudent.sex ? Number(this.objectDefaultStudent.sex) : this.objectDefaultStudent.sex
      this.user.password = null
    }
  },
  data() {
    return {
      dialogStudentExist: false,
      existingStudents: [],
      parsedDate: null,
      createdSuccess: false,
      submitError: false,
      edittedSuccess: false,
      groupRules: [
        v => !!v || 'El campo no puede estar vacio',
      ],
      nameRules: [
        v => !!v || 'El campo no puede estar vacio',
        v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      emailRules: [
        // v => !!v || 'Ingrese el email',
        // v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'El email tiene que ser valido',
        // v => v.length <= 100 || 'Debe ser menos de 100 caracteres',
      ],
      phoneRules: [
        v => (this.user.cellphone.length > 0 || this.user.phone1.length > 0 || this.user.phone2.length > 0 || !!v) || "Debes introducir al menos un telefono"
      ],
      degreeRules: [
      ],
      doyangsTableHeaders: [
        { text: 'Nombre', value: 'name', sortable: true },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      roleArray: [
        { name: 'Alumno', id: ENV.ROLE_STUDENT_ID },
        { name: 'Kiosa dependiente', id: ENV.ROLE_DEPENDANT_KYOSA_ID },
        { name: 'Kiosa de club', id: ENV.ROLE_CLUB_KYOSA_ID },
        { name: 'Kiosa de doyang', id: ENV.ROLE_DOYANG_KYOSA_ID },
        { name: 'Relaciones públicas', id: ENV.ROLE_PUBLIC_RELATIONS },
        { name: 'Profesor', id: ENV.ROLE_PROFESSOR_ID },
        { name: 'Profesor certificado', id: ENV.ROLE_CERTIFIED_PROFESSOR_ID },
        { name: 'Director administrativo', id: ENV.ROLE_ADMINISTRATIVE_DIRECTOR },
        { name: 'Director técnico', id: ENV.ROLE_TECHNICAL_DIRECTOR },
        { name: 'Programador', id: ENV.ROLE_PROGRAMMER },
        { name: 'Director general', id: ENV.ROLE_GENERAL_DIRECTOR },
      ],
      datePickerMenu: false,
      menuDatePicker: false,
      isLoadingDegree: false,
      search: null,
      passwordVisibility: null,
      user: {
        name: '',
        last_name: '',
        role_id: null,
        second_last_name: '',
        user_responsible_id: 0,
        honorific_title_id: 0,
        email: '',
        address: '',
        cellphone: '',
        phone1: '',
        phone2: '',
        degree_id: null,
        password: null,
        all_doyangs: []
      }
    }
  }
}
</script>

<style></style>
