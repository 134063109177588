import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    events: [],
    event: {},
    configuration: {
      filterThisYearOnly: true,
    }
  },
  mutations: {
    setConfiguration(state, payload) {
      console.log('payload', payload)
      for (let key in Object.keys(payload)) {
        state.configuration[key] = payload[key]
      }
    },
    index(state, payload) {
      state.events = payload
    },
    show(state,payload) {
      state.event = payload
    },
    deleteProspect(state, payload) {
      let deletedProspect = {}
      state.event.prospects = state.event.prospects.filter((prospect) => {
        if (payload.userId == prospect.id) {
          deletedProspect = Object.assign(prospect)
        }
        return payload.userId != prospect.id
      })
      state.event.candidates.push(deletedProspect)
    },
    delete(state, payload) {
        const events = state.events.filter( event => payload.id != event.id)
        state.events = events
    },
    addProspect(state, payload) {
      let candidateAdded = {}
      state.event.candidates = state.event.candidates.filter((candidate) => {
        if (payload.userId == candidate.id) {
          candidateAdded = payload.user
        }
          return payload.userId != candidate.id
      })
      state.event.prospects.push(candidateAdded)
    },
    addResult (state, {userId, resultId}) {
      state.event.prospects.forEach((user) => {
        if (user.id == userId) {
          user.pivot.event_result_id = resultId
        }
      })
    },
    approveRejectUser (state, {userId, isProspectApproved}) {
      state.event.prospects.forEach((user) => {
        if (user.id == userId) {
          user.pivot.is_prospect_approved = isProspectApproved
        }
      })
    }
  },
  actions: {
    downloadFile({ commit }, payload) {
    return axios({
            url: `${ENV.API_URL}/api/events${payload ? payload : ''}`,
            method: 'GET',
            responseType: 'blob',
        }).then((res) => {
            console.log(commit)
            return res.data
    })
    },
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/events${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/events/${payload ? payload : ''}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/events`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/events/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    aproveRejectProspect ({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/events/${payload.eventId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('approveRejectUser', res.data)
      })
    },
    assignResult ({commit}, payload) {
        console.log('payload', payload)
      return axios.post(`${ENV.API_URL}/api/events/${payload.eventId}/prospects/${payload.userId}/result`, payload).then((res) => {
        commit('addResult', res)
        return res
      })
    },
    deleteProspect({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/events/${payload.eventId}/prospects/${payload.userId}`, payload).then((res) => {
        commit('deleteProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    addProspect({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/events/${payload.eventId}/prospects`, {userId : payload.userId}).then((res) => {
        commit('addProspect', res.data)
        return res.data
      }).catch(() => 0)
    },
    delete({ commit }, payload) {
        return axios.delete(`${ENV.API_URL}/api/events/${payload}`).then((res) => {
          commit('delete', res.data)
        })
    }
  },
  getters: {
    events: state => {
      return state.events
    },
    event: state => {
      return state.event
    },
    configuration: state => {
      return state.configuration
    }
  },
  modules: {
  }
}
