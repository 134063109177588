import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    productVariants: [],
    productVariant: {}
  },
  mutations: {
    index(state, payload) {
      state.productVariants = payload
    },
    show(state, payload) {
      state.productVariant = payload
    },
    delete(state,payload) {
      state.productVariants = state.productVariants.filter((productVariant) => productVariant.id != payload.id)
      console.log(state.productVariants)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/product_variants${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/product_variants/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      axios.post(`${ENV.API_URL}/api/product_variants`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      axios.put(`${ENV.API_URL}/api/product_variants/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/product_variants/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    productVariants: state => {
      return state.productVariants
    },
    productVariant: state => {
      return state.productVariant
    }
  },
  modules: {
  }
}
