<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
        <h2 v-if="!$route.params.id">CREAR TAREA BIMESTRAL</h2>
        <h2 v-else>EDITAR TAREA BIMESTRAL</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <CapsVTextField
            outlined
            v-model="monthlyDuty.name"
            label="Nombre de la tarea"
            />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DMYDateField v-model="monthlyDuty.due_date" :label="'Fecha límite'"/>
        </v-col>
      </v-row>
      <v-row v-if="isGeneralDirection">
        <v-col>
          <v-checkbox
            outlined
            v-model="monthlyDuty.to_doyang"
            label="Para doyangs"
            />
        </v-col>
        <v-col>
          <v-checkbox
            outlined
            v-model="monthlyDuty.super_important"
            label="Máxima importancia"
            />
        </v-col>
        <v-col>
          <v-checkbox
            outlined
            v-model="monthlyDuty.to_general_direction"
            label="Para dirección general"
            />
        </v-col>
      </v-row>
      <v-row v-if="isGeneralDirection">
        <v-col lg=6>
          <h3>Doyangs que ya cumplieron</h3>
          <ul>
            <li v-for="doyang in doneDoyangs" :key="doyang.id">
              {{doyang.name}}
            </li>
          </ul>
        </v-col>
        <v-col lg=6>
          <h3>Doyangs que no han cumplido</h3>
          <ul>
            <li v-for="doyang in notDoneDoyangs" :key="doyang.id">
              {{doyang.name}}
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" dark @click="createMonthlyDuty">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updateMonthlyDuty">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ENV from '../../../env'

export default {
  mounted () {
    this.$store.dispatch('State/index');
    if (!this.isGeneralDirection) {
        this.monthlyDuty.to_doyang = true
        this.monthlyDuty.to_general_direction = false
        this.monthlyDuty.doyang_id = this.selectedDoyang?.id
    }
    if (this.$route.params.id) {
      this.$store.dispatch('Doyang/index', `active=1`)
      this.$store.dispatch('MonthlyDuty/show', `${this.$route.params.id}?append=doyangs_done`).then(res => {
        this.monthlyDuty = res
        this.monthlyDuty.checked = this.monthlyDuty?.checked ? parseInt(this.monthlyDuty.checked) != 0 : false
        this.monthlyDuty.to_doyang = this.monthlyDuty?.to_doyang ? parseInt(this.monthlyDuty.to_doyang) != 0 : false
        this.monthlyDuty.to_general_direction = this.monthlyDuty?.to_general_direction ? parseInt(this.monthlyDuty.to_general_direction) != 0 : false
      })
    }
  },
  components: {
  },
  methods: {
    createMonthlyDuty: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('MonthlyDuty/create', this.monthlyDuty).then(() => {
          this.$router.push({name: 'MonthlyDutyIndex'})
        }
      )
    },
    updateMonthlyDuty: function () {
      if(!confirm('¿Estás seguro de editar esta tarea bimestral? Esto hará que los doyangs que la tuvieran cumplida, ya no la tengan.')) return;
      this.$store.dispatch('MonthlyDuty/update', this.monthlyDuty).then(() => {
          this.$router.push({name: 'MonthlyDutyIndex'})
        }).catch(() => {
          }
        )
    },
  },
  watch: {
  },
  computed : {
    isGeneralDirection() {
      return (this.selectedDoyang?.id == ENV.DIRECCIÓN_GENERAL_ID)
    },
    doyangs () {
      return this.$store.getters['Doyang/doyangs']
    },
    doneDoyangs () {
      return this.doyangs.filter( doyang => {
        return this.monthlyDuty?.doyangs_done?.includes(doyang?.id?.toString()) ||
          this.monthlyDuty?.doyangs_done?.includes(doyang?.id)
      })
    },
    notDoneDoyangs () {
      return this.doyangs.filter( doyang => {
        return !(this.monthlyDuty?.doyangs_done?.includes(doyang?.id?.toString()) ||
        this.monthlyDuty?.doyangs_done?.includes(doyang?.id))
      })
    },
    selectedDoyang () {
      return this.$store.getters['Auth/selectedDoyang']
    },
  },
  data () {
    return {
      ENV,
      monthlyDuty: {
        name: null,
        checked: false,
        due_date: null,
        to_doyang: false,
        to_general_direction: false,
        doyang_id: null,
      },
    }
  }
}
</script>

<style>

</style>
