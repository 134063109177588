<template>
  <v-container>
    <h2>LOCALIDADES</h2>
    <CRUDIndex
      :permissionRoot="'Localidades'"
      :headers="headers"
      :storeModel="storeModel"
      :storeModelPlural="storeModelPlural"
    />
  </v-container>
</template>

<script>
import CRUDIndex from '../globals/CRUDIndex'
export default {
  components: {
    CRUDIndex
  },
  data () {
    return {
      headers: [
        { text: 'Nombre', value: 'name' },
      ],
      storeModel: 'City',
      storeModelPlural: 'Cities',
    }
  }
}
</script>

<style>

</style>e
