<template>
  <v-container>
    <v-form ref="form">
      <v-row>
        <v-col col=12>
          <h2 v-if="!$route.params.id">CREAR POOOMSE</h2>
          <h2 v-else>EDITAR POOMSE</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12>
          <caps-v-text-field outlined v-model="poomse.name" label="Nombre" />
        </v-col>
      </v-row>
      <v-row v-if="this.$route.params.id">
        <v-col>
          <v-select label="Grupo de edad" v-model="tournamentAgeGroupId" :items="tournamentAgeGroups" item-value="id"
            item-text="name" outlined></v-select>
        </v-col>
        <v-col>
          <v-select label="Grado" v-model="degreeId" :items="degrees" item-value="id" item-text="name"
            outlined></v-select>
        </v-col>
        <v-col>
          <v-btn class="blue ligthten-2" dark @click="addCategory"><v-icon>mdi-plus</v-icon>Añadir</v-btn>
        </v-col>
        <v-col cols=12>
          <v-row>
            <v-col>
              <v-card v-for="category in poomse.tournament_poomse_categories" :key="category.id">
                <v-card-title>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <p>{{ category.tournament_age_group.name }}</p>
                        </v-col>
                        <v-col>
                          <p>{{ category.degree.name }}</p>
                        </v-col>
                        <v-col>
                          <v-btn class="red lighten-2"
                            @click="deleteCategory(category.id)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="!$route.params.id" class="blue lighten-2" @click="createPoomse">Agregar</v-btn>
          <v-btn v-else class="yellow lighten-2" @click="updatePoomse">Editar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CapsVTextField from '../globals/CapsVTextField.vue';
export default {
  mounted() {
    this.$store.dispatch(`Poomse/show`, `${this.$route.params.id}?with=tournament_poomse_categories,tournament_poomse_categories.degree,tournament_poomse_categories.tournament_age_group`).then((response) => {
      this.poomse = response
    })
    this.$store.dispatch(`TournamentAgeGroup/index`)
    this.$store.dispatch(`Degree/index`)
  },
  components: {
    CapsVTextField
  },
  methods: {
    deleteCategory(id) {
      this.$store.dispatch(`Poomse/deleteCategory`, {
        poomse_id: this.$route.params.id,
        id: id
      })
    },
    addCategory() {
      this.$store.dispatch(`Poomse/createCategory`, {
        id: this.$route.params.id,
        tournament_age_group_id: this.tournamentAgeGroupId,
        degree_id: this.degreeId
      })
    },
    createPoomse: function () {
      if (!this.$refs.form.validate())
        return
      this.$store.dispatch('Poomse/create', this.poomse).then(() => {
        this.$router.push({ name: 'PoomseIndex' })
      }
      )
    },
    updatePoomse: function () {
      this.$store.dispatch('Poomse/update', this.poomse).then(() => {
        this.$router.push({ name: 'PoomseIndex' })
      }).catch(() => {
      }
      )
    },
  },
  watch: {
  },
  computed: {
    tournamentAgeGroups() {
      const tournamentAgeGroups = this.$store.getters['TournamentAgeGroup/tournamentAgeGroups']
      return tournamentAgeGroups
    },
    degrees() {
      const degrees = this.$store.getters['Degree/degrees']
      return degrees
    },
  },
  data() {
    return {
      tournamentAgeGroupId: null,
      degreeId: null,
      poomse: {
        name: '',
        tournament_poomse_categories: []
      },
    }
  }
}
</script>

<style></style>
