import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    doyangCategories: [],
    doyangCategory: {}
  },
  mutations: {
    index(state, payload) {
      state.doyangCategories = payload
    },
    show(state,payload) {
      state.doyangCategory = payload
    },
    addToIndex (state, payload) {
      state.doyangCategories.push(payload)
    },
    delete (state, payload) {
      state.doyangCategories = state.doyangCategories.filter( doyangCategory => doyangCategory.id != payload.id )
    },
    update (state, payload) {
      state.doyangCategories = state.doyangCategories.map((doyangCategory) => {
        console.log(doyangCategory.id, payload.id)
        if (doyangCategory.id == payload.id) {
          doyangCategory = payload
        }
        return doyangCategory
      })
    },
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_categories${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/doyang_categories/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/doyang_categories`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
      })
    },
    update({commit}, payload) {
      axios.put(`${ENV.API_URL}/api/doyang_categories/${payload.id}`, payload).then((res) => {
        commit('update', res.data)
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/doyang_categories/${payload.id}`, payload).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    doyangCategories: state => {
      return state.doyangCategories
    },
    doyangCategory: state => {
      return state.doyangCategory
    }
  },
  modules: {
  }
}
