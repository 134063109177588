import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    productSizes: [],
    productSize: {}
  },
  mutations: {
    index(state, payload) {
      state.productSizes = payload
    },
    show(state, payload) {
      state.productSize = payload
    },
    delete(state,payload) {
      state.productSizes = state.productSizes.filter((size) => size.id != payload.id)
    }
  },
  actions: {
    index({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/product_sizes${payload}`).then((res) => {
        commit('index', res.data)
        return res.data
      })
    },
    show({ commit }, payload) {
      return axios.get(`${ENV.API_URL}/api/product_sizes/${payload}`).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    create({ commit }, payload) {
      axios.post(`${ENV.API_URL}/api/product_sizes`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    update({ commit }, payload) {
      return axios.put(`${ENV.API_URL}/api/product_sizes/${payload.id}`, payload).then((res) => {
        commit('show', res.data)
        return res.data
      })
    },
    delete({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/product_sizes/${payload}`).then((res) => {
        commit('delete', res.data)
        return res.data
      })
    }
  },
  getters: {
    productSizes: state => {
      return state.productSizes
    },
    productSize: state => {
      return state.productSize
    }
  },
  modules: {
  }
}
