import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ENV from '../../env'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    eventGroups: [],
    eventGroup: {} 
  },
  mutations: {
    index(state, payload) {
      state.eventGroups = payload
    },
    show(state,payload) {
      state.eventGroup = payload
    },
    addToIndex (state, payload) {
      state.eventGroups.push(payload)
    },
    update (state, payload) {
      state.eventGroups = state.eventGroups.map((eventGroup) => {
        if (eventGroup.id == payload.id) {
          eventGroup = payload
        }
        return eventGroup
      })
    },
    removeeventGroup(state, payload) {
      state.eventGroups = state.eventGroups.filter((eventGroup) => eventGroup.id != payload)
    }
  },
  actions: {
    index ({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/event_groups${payload ? payload : ''}`).then((res) => {
        commit('index', res.data)
      })
    },
    show({commit}, payload) {
      return axios.get(`${ENV.API_URL}/api/event_groups/${payload}`).then((res) => {
        commit('show', res.data)
      })
    },
    create({commit}, payload) {
      return axios.post(`${ENV.API_URL}/api/event_groups`, payload).then((res) => {
        commit('show', res.data)
        commit('addToIndex', res.data)
      })
    },
    update({commit}, payload) {
      return axios.put(`${ENV.API_URL}/api/event_groups/${payload.id}`, payload).then((res) => {
        commit('update', res.data)
      })
    },
    deleteGroup ({commit}, payload) {
      return axios.delete(`${ENV.API_URL}/api/exams/${payload.eventId}/event_groups/${payload.eventGroupId}`, payload).then((res) => {
        commit('removeeventGroup', res.data)
      })
    },
  },
  getters: {
    eventGroups: state => {
      return state.eventGroups
    },
    eventGroup: state => {
      return state.eventGroup
    }
  },
  modules: {
  }
}
