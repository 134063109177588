var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.chartData)?_c('div',{staticClass:"chart-container"},[_c('Bar',{attrs:{"chart-options":{
    scales: {
        y: {
            beginAtZero: true
        }
    }
},"chart-data":_vm.chartDataCopy,"chart-id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":200,"height":200}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }