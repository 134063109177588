<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 v-if="id || hasManyId">EDITAR GRUPO</h2>
        <h2 v-else>CREAR UN NUEVO GRUPO</h2>
        <CapsVTextField v-model="doyangGroup.name" outlined label="Nombre del grupo"></CapsVTextField>
        <v-autocomplete chips outlined label="A cargo del grupo" v-model="doyangGroup.user_responsible_id"
          :items="personal" item-value="id" item-text="full_name"></v-autocomplete>
        <v-autocomplete chips outlined label="Profesor Certificador" v-model="doyangGroup.user_certifier_id"
          :items="certifiedProfessors" item-value="id" item-text="full_name"></v-autocomplete>
        <AMPMComponent v-model="doyangGroup.begins_at" :label="'Empieza a las'" />
        <AMPMComponent v-model="doyangGroup.ends_at" :label="'Termina a las'" />
        <v-row>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.monday" label="Lunes"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.tuesday" label="Martes"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.wednesday" label="Miercoles"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.thursday" label="Jueves"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.friday" label="Viernes"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.saturday" label="Sabado"></v-checkbox>
          <v-checkbox class="day-of-the-week" v-model="doyangGroup.json_week.sunday" label="Domingo"></v-checkbox>
        </v-row>
        <v-col cols="12">
          <h3>KIOSAS</h3>
        </v-col>
        <v-col cols=12>
          <v-autocomplete chips outlined label="Busca un Kiosa" v-model="kyosaToAddId" :items="doyang.personal"
            item-value="id" item-text="full_name_honorific"></v-autocomplete>
          <v-btn dark class="green lighten-2" @click.stop="addKyosa">Añadir kiosa</v-btn>
        </v-col>
        <v-col>
        </v-col>
        <v-col cols="12">
          <v-data-table :items="kyosas" :headers="[
            { text: 'Nombre', value: 'full_name_honorific' },
            { text: 'Acciones', value: 'actions' }
          ]">
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteKyosa(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-col>
      <v-col cols=12>
        <v-btn v-if="!hideAddButton && !this.$route.params.id" primary @click="createNewDoyangGroup"
          class="blue ligthten-2" dark>Agregar</v-btn>
        <v-btn v-if="!hideAddButton && this.$route.params.id" primary @click="updateDoyangGroup"
          class="yellow ligthten-2">EDITAR</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.day-of-the-week {
  display: inline;
  margin-right: 15px;
}
</style>
<script>
var debounce = require('debounce');
import ENV from '../../../env'
import AMPMComponent from '../../components/globals/AMPMComponent.vue'
export default {
  components: {
    AMPMComponent
  },
  mounted() {
    this.doyangGroup.user_certifier_id = this.doyang.user_certifier_id
    this.$store.dispatch('Degree/index')
    this.$store.dispatch('Professor/index', `?role_id=${ENV.ROLE_CERTIFIED_PROFESSOR_ID},${ENV.ROLE_PROFESSOR_ID},${ENV.ROLE_GENERAL_DIRECTOR},${ENV.ROLE_TECHNICAL_DIRECTOR}`)
    this.$store.dispatch('Doyang/show', `${this.selectedDoyang.id}?with=personal&append=all_personal`)
    if (this.hasManyId != 0 || this.id) {
      this.$store.dispatch('DoyangGroup/show', this.hasManyId ? `${this.hasManyId}?with=kyosas&append=personal` : `${this.id}?with=kyosas&append=personal`).then(() => {
        let doyangGroup = Object.assign(this.$store.getters['DoyangGroup/doyangGroup'])
        this.$store.dispatch('Doyang/show', `${doyangGroup.doyang_id}?with=personal&append=all_personal`)
        doyangGroup.json_week = doyangGroup.json_week ? doyangGroup.json_week : {}
        this.doyangGroup = doyangGroup
        this.doyangGroup.user_responsible_id = this.doyangGroup.user_responsible_id ? Number(this.doyangGroup.user_responsible_id) : this.doyangGroup.user_responsible_id
        this.doyangGroup.user_certifier_id = this.doyangGroup.user_certifier_id ? Number(this.doyangGroup.user_certifier_id) : this.doyangGroup.user_certifier_id
        this.doyangGroup.students = null
      })
    }
  },
  methods: {
    deleteKyosa(item) {
      this.$store.dispatch('DoyangGroup/deleteKyosa', { id: this.doyangGroup.id, kyosas: item.id })
    },
    addKyosa() {
      this.$store.dispatch('DoyangGroup/update', { id: this.doyangGroup.id, kyosas: this.kyosaToAddId })
    },
    createNewDoyangGroup() {
      this.doyangGroup.doyang_id = this.selectedDoyang.id
      this.$store.dispatch('DoyangGroup/create', this.doyangGroup).then(() => {
        if (this.onCreatedRedirect) {
          this.$router.push({ name: this.onCreatedRedirect })
        } else {
          this.$emit('created', this.doyangGroup)
        }
      })
    },
    updateDoyangGroup() {
      this.$store.dispatch('DoyangGroup/update', this.doyangGroup).then(() => {
        this.$router.push({ name: 'DoyangGroupIndex' })
      })
    },
  },
  props: {
    id: Number,
    hideAddButton: {
      type: Boolean,
      default: false
    },
    onCreatedRedirect: {
      type: String
    },
    triggerCreate: {
      type: Boolean,
      deafult: false
    },
    triggerEdit: {
      type: Boolean,
      deafult: false
    },
    parentId: {
      type: Number,
      default: 0
    },
    hasManyId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    selectedDoyang() {
      return this.$store.getters["Auth/selectedDoyang"];
    },
    personal() {
      const personal = this.doyang.personal || []
      if (this.doyang?.responsible?.full_name && this.doyang?.user_responsible_id) {
        personal.push({
          id: +this.doyang?.user_responsible_id,
          full_name: this.doyang?.responsible?.full_name
        })
      }
      console.log('personal', personal)
      return personal
    },
    kyosas() {
      return this.$store.getters['DoyangGroup/doyangGroup'].kyosas
    },
    professors() {
      return this.$store.getters['Professor/professors']
    },
    certifiedProfessors() {
      return this.$store.getters['Professor/certifiedProfessors']
    },
    doyang() {
      return this.$store.getters['Doyang/doyang']
    },
  },
  watch: {
    hasManyId: function () {
      this.$store.dispatch('DoyangGroup/show', this.hasManyId ? `${this.hasManyId}?with=kyosas` : `${this.id}?with=kyosas`).then(() => {
        let doyangGroup = Object.assign(this.$store.getters['DoyangGroup/doyangGroup'])
        this.$store.dispatch('Doyang/show', `${doyangGroup.doyang_id}?with=personal,responsible&append=all_personal`)
        doyangGroup.json_week = doyangGroup.json_week ? doyangGroup.json_week : {}
        this.doyangGroup = doyangGroup
        this.doyangGroup.user_responsible_id = this.doyangGroup.user_responsible_id ? Number(this.doyangGroup.user_responsible_id) : this.doyangGroup.user_responsible_id
        this.doyangGroup.user_certifier_id = this.doyangGroup.user_certifier_id ? Number(this.doyangGroup.user_certifier_id) : this.doyangGroup.user_certifier_id
        this.doyangGroup.students = null
      })
    },
    triggerCreate: function () {
      if (this.triggerCreate) {
        this.doyangGroup.doyang_id = this.parentId
        this.$store.dispatch('DoyangGroup/create', this.doyangGroup).then(() => {
          if (this.onCreatedRedirect) {
            this.$router.push({ name: this.onCreatedRedirect })
          } else {
            this.$emit('created', this.doyangGroup)
          }
        })
      }
    },
    triggerEdit: function () {
      if (this.triggerEdit) {
        this.doyangGroup.doyang_id = this.parentId
        this.$store.dispatch('DoyangGroup/update', this.doyangGroup).then(() => {
          this.$emit('editted', this.doyangGroup)
        })
      }
    },
    searchDegree: debounce(function () {
      this.$store.dispatch('Degree/index', this.search ? `?name=${this.search}` : '')
    }, 500),
  },
  data() {
    return {
      kyosaToAddId: null,
      beginsAtMenu: '',
      endsAtMenu: '',
      doyangGroup: {
        name: '',
        user_responsible_id: 0,
        user_certifier_id: 0,
        begins_at: '',
        ends_at: '',
        json_week: { monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false }
      },
    }
  }
}
</script>
