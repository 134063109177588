var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chart-container"},[(_vm.chartData)?_c('div',[_c('LineChart',{attrs:{"options":{
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        },"data":_vm.chartData,"chartData":_vm.chartData,"id":_vm.chartId,"dataset-id-key":_vm.datasetIdKey,"plugins":_vm.plugins,"css-classes":_vm.cssClasses,"styles":_vm.styles,"width":_vm.width,"height":_vm.height}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }